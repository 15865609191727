import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';

import { ExcelHeaders } from 'src/app/constants';
import { ExcelService, FileStatusService } from 'src/app/services';

@Component({
  selector: 'app-monitoring-by-source',
  templateUrl: './monitoring-by-source.component.html',
  styleUrls: ['./monitoring-by-source.component.scss']
})
export class MonitoringBySourceComponent implements OnInit {
  searchConfig = {
    action: { label: 'Export', type: 'export', icon: 'download' },
    showAdvancedSearch: false,
    showToggle: false,
    formValue: '',
    module: 'bySource',
    btnClass: 'btn-clear',
    sourceType: 'Active'
  }
  dataSourceList: any = [];
  loading: boolean = true;
  sortColumn: string = 'sourceName';
  sortDirection: number = 1;
  frequencyOrder: any = {
    Daily: 1,
    Weekly: 2,
    Monthly: 3,
  };
  fileStatusOrder: any= {
    overdue: 1,
    late: 2,
    received: 3
  }
  constructor(private excelExportService: ExcelService, private dataSourceService: FileStatusService) { }

  ngOnInit(): void {
    this.getDataSourceList();
  }

  handleBaseAction() {
    if (this.dataSourceList.rows?.length) {
      let reportData = this.dataSourceList.rows.map((x: any) => {
        return {
          ...x, status: this.searchConfig.sourceType == 'Inactive' ? 'Inactive' : x.status.fileStatus.charAt(0).toUpperCase() + x.status.fileStatus.substr(1).toLowerCase(),
          lastRunDate: x.lastRunDate ? moment(new Date(x.lastRunDate)).format('MM/DD/YYYY hh:mm a') : '',
          interval: x.frequency.interval,
          ftpUsername: x.ftp.username,
          ftpLocation: x.ftp.location,
          contactPerson: x.contact.contactPerson,
          contactPhone: x.contact.contactPhone,
          contactEmail: x.contact.contactEmail
        }
      })
      const content = {
        header: ExcelHeaders.BySource,
        data: reportData,
        reportTitle: 'File Status By Source',
        fileName: 'File-By-Source_Report'
      }
      this.excelExportService.exportExcel(content);
    }
  }

  getDataSourceList(event?:any) {
    this.loading = true;
    this.dataSourceService.getDataSource(event ? event : {status:this.searchConfig.sourceType}).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.dataSourceList = response.data;
        this.dataSourceList.rows = this.dataSourceList.rows.map((x: any) => {
          return {
            ...x, fileName: x.fileName ? x.fileName.split('/')[x.fileName.split('/').length - 1] : ''
          }
        })
        this.sort('status.fileStatus');
      }
    })
  }

  sort(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = -this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = 1;
    }
    switch (column) {
      case 'frequency.interval':
        this.dataSourceList.rows = this.dataSourceList.rows.sort((a: any, b: any) => {
          const intervalA = a.frequency.interval;
          const intervalB = b.frequency.interval;
          return this.frequencyOrder[intervalA] - this.frequencyOrder[intervalB];
        });
        this.dataSourceList.rows = this.sortDirection == -1 ? this.dataSourceList.rows.reverse() : this.dataSourceList.rows;
        break;
      case 'status.fileStatus':
        this.dataSourceList.rows = this.dataSourceList.rows.sort((a:any, b:any) => b.status.days - a.status.days);
        this.dataSourceList.rows = this.dataSourceList.rows.sort((a: any, b: any) => {
          const statusA = a.status.fileStatus;
          const statusB = b.status.fileStatus;
          return this.fileStatusOrder[statusA] - this.fileStatusOrder[statusB];
        });
        this.dataSourceList.rows = this.sortDirection == -1 ? this.dataSourceList.rows.reverse() : this.dataSourceList.rows;
        break;
      default:
        this.dataSourceList.rows = this.dataSourceList.rows.sort((a: any, b: any) => {
          const valueA = this.getPropertyValue(a, this.sortColumn);
          const valueB = this.getPropertyValue(b, this.sortColumn);
          if (typeof valueA === 'object' && typeof valueB === 'object') {
            // Both values are objects, compare their properties
            const nestedPropA = this.getNestedPropertyValue(valueA, column);
            const nestedPropB = this.getNestedPropertyValue(valueB, column);
            if ((nestedPropA).toLowerCase() < (nestedPropB).toLowerCase()) {
              return -1 * this.sortDirection;
            } else if ((nestedPropA).toLowerCase() > (nestedPropB).toLowerCase()) {
              return 1 * this.sortDirection;
            } else {
              return 0;
            }
          } else {
            // At least one value is not an object, compare the values directly
            if ((valueA).toLowerCase() < (valueB).toLowerCase()) {
              return -1 * this.sortDirection;
            } else if ((valueA).toLowerCase() > (valueB).toLowerCase()) {
              return 1 * this.sortDirection;
            } else {
              return 0;
            }
          }
        });
    }
  }

  getPropertyValue(item: any, column: string): any {
    const keys = column.split('.');

    let value = item;
    for (const key of keys) {
      value = value[key];
    }

    return value;
  }

  getNestedPropertyValue(item: any, column: string): any {
    const nestedProp = this.getPropertyValue(item, column);
    return typeof nestedProp !== 'object' ? nestedProp : '';
  }
}
