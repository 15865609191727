import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { AuthenticationService, HttpClientService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class IframeGuard {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private httpService: HttpClientService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (route.queryParams['token']) {
      let iframeUser: any = await this.authService.validate(
        route.queryParams['token']
      );
      if (iframeUser.success) {
        this.httpService.setIframeToken(route.queryParams['token']);
        route.queryParams = {
          ...route.queryParams,
          bcc: iframeUser.data.email,
          userId: iframeUser.data.userId,
        };
        return true;
      } else {
        this.router.navigate(['/invalid']);
        return false;
      }
    } else {
      this.router.navigate(['/invalid']);
      return false;
    }
  }
}
