<div class="general pt-3" [formGroup]="planDetailForm">
    <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Plan Details </p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div class="col-12 d-flex flex-wrap">
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Plan Period Start Month</label>
                <div class="col-7">
                    <!-- <input type="text" class="form-control" formControlName="planPeriodStartDate" id="period-period-start-date"> -->
                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                        formControlName="planPeriodStartDate" name="fiscalMonth">
                        <ng-option *ngFor="let option of parentComponent.monthList " [value]="option.value">
                            {{option.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Employee Count</label>
                <div class="col-7">
                    <input type="text" class="form-control" formControlName="employeeCount" id="Employee-Count">
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Effective Date</label>
                <div class="col-7">
                    <!-- <input type="text" class="form-control" formControlName="effectiveDate" id="Effective-date"> -->
                    <input [mdbDatepicker]="effDate" type="text" class="form-control" id="effDate"
                        (click)="effDate.open()" formControlName="effectiveDate" name="effectiveDate"
                        (blur)="onDateStartBlur($event, 'effectiveDate')" autocomplete="off" />
                    <mdb-datepicker-toggle [mdbDatepicker]="effDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #effDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Term Date</label>
                <div class="col-7">
                    <input [mdbDatepicker]="termDate" type="text" class="form-control" id="termDate"
                        (click)="termDate.open()" formControlName="terminationDate" name="terminationDate"
                        (blur)="onDateStartBlur($event, 'terminationDate')" autocomplete="off" />
                    <mdb-datepicker-toggle [mdbDatepicker]="termDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #termDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Roll Over Start Date</label>
                <div class="col-7">
                    <!-- <input type="text" class="form-control" formControlName="rollOverStartDate"
                        id="roll-over-start-date"> -->
                    <input [mdbDatepicker]="rollOStartDate" type="text" class="form-control" id="rollOStartDate"
                        (click)="rollOStartDate.open()" formControlName="rollOverStartDate" name="rollOverStartDate"
                        autocomplete="off" (blur)="onDateStartBlur($event, 'rollOverStartDate', 'rollOverEndDate')" />
                    <mdb-datepicker-toggle [mdbDatepicker]="rollOStartDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #rollOStartDate [inline]="true" [format]="'mm/dd/yyyy'"
                        (closed)="datePickerClosed(planDetailForm.get('rollOverStartDate')?.value, 'rollOverEndDate')"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Roll Over End Date</label>
                <div class="col-7">
                    <input [mdbDatepicker]="rollOEndDate" type="text" class="form-control" id="rollOEndDate"
                        (click)="rollOEndDate.open()" formControlName="rollOverEndDate" name="rollOverEndDate"
                        autocomplete="off" (blur)="onDateStartBlur($event, 'rollOverEndDate')" />
                    <mdb-datepicker-toggle [mdbDatepicker]="rollOEndDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #rollOEndDate [inline]="true" [format]="'mm/dd/yyyy'" [minDate]="planDetailForm.get('rollOverStartDate')?.value"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Benefit Period Start Date</label>
                <div class="col-7">
                    <input [mdbDatepicker]="benefitStartDate" type="text" class="form-control" id="benefitStartDate"
                        (blur)="onDateStartBlur($event, 'benefitStartDate', 'benefitEndDate')" (click)="benefitStartDate.open()"
                        formControlName="benefitStartDate" name="benefitStartDate" autocomplete="off" />
                    <mdb-datepicker-toggle [mdbDatepicker]="benefitStartDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #benefitStartDate [inline]="true" [format]="'mm/dd/yyyy'"
                        (closed)="datePickerClosed(planDetailForm.get('benefitStartDate')?.value, 'benefitEndDate')"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Benefit Period End Date</label>
                <div class="col-7">
                    <input [mdbDatepicker]="benefitEndDate" type="text" class="form-control" id="benefitEndDate"
                        (blur)="onDateStartBlur($event, 'benefitEndDate')" (click)="benefitEndDate.open()"
                        formControlName="benefitEndDate" name="benefitEndDate" autocomplete="off" />
                    <mdb-datepicker-toggle [mdbDatepicker]="benefitEndDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #benefitEndDate [inline]="true" [format]="'mm/dd/yyyy'" [minDate]="planDetailForm.get('benefitStartDate')?.value"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5">Enrollment Start Date</label>
                <div class="col-7">
                    <input [mdbDatepicker]="enrollmentStartDate" type="text" class="form-control"
                        (blur)="onDateStartBlur($event, 'enrollmentStartDate', 'enrollmentEndDate')" id="enrollmentStartDate"
                        (click)="enrollmentStartDate.open()" formControlName="enrollmentStartDate"
                        name="enrollmentStartDate" autocomplete="off" />
                    <mdb-datepicker-toggle [mdbDatepicker]="enrollmentStartDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #enrollmentStartDate [inline]="true" [format]="'mm/dd/yyyy'"
                        (closed)="datePickerClosed(planDetailForm.get('enrollmentStartDate')?.value, 'enrollmentEndDate')"></mdb-datepicker>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex col-12 pt-3 align-items-center">
                <label class="pt-0 text-end pe-3 col-5"> Enrollment End Date</label>
                <div class="col-7">
                    <input [mdbDatepicker]="enrollmentEndDate" type="text" class="form-control" id="enrollmentEndDate"
                        (blur)="onDateStartBlur($event, 'enrollmentEndDate')" (click)="enrollmentEndDate.open()"
                        formControlName="enrollmentEndDate" name="enrollmentEndDate" autocomplete="off" />
                    <mdb-datepicker-toggle [mdbDatepicker]="enrollmentEndDate"></mdb-datepicker-toggle>
                    <mdb-datepicker #enrollmentEndDate [inline]="true" [format]="'mm/dd/yyyy'"  [minDate]="planDetailForm.get('enrollmentStartDate')?.value"></mdb-datepicker>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end pb-3 pt-3">
        <button type="button" class="btn btn-primary" (click)="onSavePlanDetail()" [disabled]="planDetailForm.invalid"> 
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
                <span class="visually-hidden">Loading...</span>
            </div>
            Save
        </button>
    </div>
</div>