import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss']
})
export class LayoutMainComponent implements OnInit {
  
  slimWidth = 70;
  fullWidth = 285;

  constructor() { }

  ngOnInit(): void {
  }

}
