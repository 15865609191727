const CryptoJS = require("crypto-js");


export class Crypto {
    static secretKey = "@lith!as2.0";

    static encryptObject(data: any) {
        try {
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
            return ciphertext;
        }
        catch (ex) {
            return null;
        }

    }

    static decryptObject(ciphertext: any): any {
        try {
            var bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
            var plainObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return plainObject;
        }
        catch (ex) {
            return null;
        }

    }
}
