export class SmartSearchModel {
  procedureId: any = null;
  companyId: any = null;
  memberId: any = null;
  radius: number = 30;
  zipCode: any = null;
  state: string | null = null;
  [key: string]: any;
  providerNPI: any = null;
  selectedProvider:any = null;
}
