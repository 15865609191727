import { Component, Input, Output, EventEmitter } from '@angular/core';
import {MdbPaginationChange, MdbTableDirective} from 'mdb-angular-ui-kit/table';

import { TableConfig, Searchable } from 'src/app/interfaces';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() tableConfig: TableConfig;
  @Input() dataSource: Array<any> = [];
  @Input() actions: Array<any> = [];
  @Input() extendedView: string = '';
  @Input() searchable: Searchable;

  @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() paginationChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() baseAction: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onSortChange(event: any): void {
    // this.sortChange.emit(event);
  }

  toggleCollapse(data: any): void {
    data.collapsed = !data.collapsed;
  }

  receiveActionEvent($event: any) {
    switch ($event.toLowerCase()) {
      case 'view':
        // perform view action in modal.
        break;
      case 'edit':
        // perform edit action either in modal or indline.
        break;
      case 'delete':
        // perform delete action.
        break;
      default:
        break;
    }
  }

  onPaginationChange(event: MdbPaginationChange): void {
    this.paginationChange.emit(event);
  }

  checkKeyType(data:any) {
  }
}
