import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { CompanyService, FormInitService, HttpCancelService, ToastrService } from '../../../../../services';
import { ValueBasedContractModel } from '../../../../../model';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { CompanyDropdownConstant } from 'src/app/constants';
import { ConfirmationModalComponent } from 'src/app/modal';

@Component({
  selector: 'app-value-based-benefits',
  templateUrl: './value-based-benefits.component.html',
  styleUrls: ['./value-based-benefits.component.scss']
})
export class ValueBasedBenefitsComponent implements OnInit {

  @Input() valueBasedBenifits!: any
  dropdownConstants = CompanyDropdownConstant;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;


  valueBasedBenefitForm: UntypedFormGroup;
  saving: boolean = false;
  networkAutocomplete: any = null;
  
  constructor(private formInit: FormInitService,
    private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private modalService: MdbModalService,
    private cancelService: HttpCancelService) {
    this.valueBasedBenefitForm = new UntypedFormGroup({
      valueBasedContractInfo: this.formBuilder.array([])
    })
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.valueBasedBenifits?.length) {
      let arr = this.getFormArray(this.valueBasedBenefitForm, 'valueBasedContractInfo');
      arr.clear();
      this.valueBasedBenifits.forEach((x: any) => {
        this.addInfoForms(this.valueBasedBenefitForm, 'valueBasedContractInfo', x)
      });
    };
  }

  getControl(fromControl: any, controlName: string): UntypedFormGroup {
    return fromControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }


  get persons(): UntypedFormArray {
    return this.valueBasedBenefitForm.controls['valueBasedContractInfo'].get('vendorsDetails') as UntypedFormArray;
  }


  delete(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    item.loading = true;
    if (item.valueBasedId || item.contactId) {
      switch (arrayName) {
        case 'vendorDetails':
          this.companyService.deleteValueBasedBenefitsContact(this.parentComponent.currentCompanyId, formControlName.controls['valueBasedId'].value, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
          break;
        case 'valueBasedContractInfo':
          this.modalRef = this.modalService.open(ConfirmationModalComponent, {
            modalClass: 'modal-dialog-centered',
            ignoreBackdropClick: true,
            data: {
              modalTitle: 'Delete Confirmation',
              modalBody: `Are you sure you want to delete <strong>${item.contractBenefitsType}</strong>?`,
            }
          });
          this.modalRef.onClose.subscribe((message: any) => {
            if (message == 'confirmDeactivate') {
              this.saveForms();
              let control = <UntypedFormArray>formControlName.controls[arrayName];
              control.removeAt(index);
            }
          })
          break;
      }
    }
    else {
      control.removeAt(index);
    }
  }

  async addContactForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initContactDetails(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails);
  }

  saveForms() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService.updateValueBasedBenifits(this.parentComponent.currentCompanyId, this.valueBasedBenefitForm.value.valueBasedContractInfo).pipe(finalize(() => {
        this.saving = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
        }
      })
    }
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newService = this.initValueBasedForm(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) : formArray.insert(0, newService);
    if (value?.vendorDetails.length) {
      value?.vendorDetails.forEach((x: any) => {
        this.addContactForms(newService, 'vendorDetails', x)
      });
    }
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      contactName: [value?.contactName],
      contactType: [value?.contactType],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
      contactId: [value?.contactId]
    })
  }

  initValueBasedForm(value?: any) {
    return this.formInit.createFormGroup(ValueBasedContractModel.setValueBasedContractModel(value));
  }

  selectNetwork(value: any, item: any) {
    if (value) {
      item.controls['network'].controls['id'].setValue(value.alithiasNetworkId);
      item.controls['network'].controls['name'].setValue(value.networkName);
      item.controls['network'].controls['code'].setValue(value.networkCode);
    }
  }


  searchNetwork(event: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() &&
        event.inputType == 'deleteContentBackward')
    ) {
      this.parentComponent.searchNetwork({keyword:event.target.value});
    }
  }

}
