<div class="col-12 px-3">
  <div class="col">
    <div class="col-12">
      <app-report-header
        (baseAction)="handleBaseAction($event)"
      ></app-report-header>
    </div>
    <div class="pt-5 mt-5" *ngIf="loading">
      <loader></loader>
    </div>
  </div>
  <div *ngIf="!loading && luckyList">
    <div class="col-12 py-3">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fw-bold mb-0 ps-1">Lucky Report Preview</p>
        <button type="button" class="btn btn-clear px-4 py-2 border" (click)="exportExcel()">
          <i-bs name="download" class="pe-2"></i-bs>Export
      </button>
      </div>
    </div>
    <div class="col-12 py-3" style="height: 150px">
      <div class="d-flex justify-content-evenly">
        <div class="col-3 px-2 ps-0">
          <div
            style="height: 150px"
            class="text-center bg-gray-light border-top border-4 border-primary rounded-1 p-3"
          >
            <p class="fw-bold">Total No. of people that called for service</p>
            <h1 class="fw-bold">
              {{ luckyList.peopleCallForService }}
            </h1>
          </div>
        </div>

        <div class="col-3 px-2">
          <div
            style="height: 150px"
            class="text-center bg-gray-light border-top border-4 border-gray-main rounded-1 p-3"
          >
            <p class="fw-bold">Total No. of people that used the service</p>
            <h1 class="fw-bold">
              {{ luckyList.peopleUseTheService }}
            </h1>
          </div>
        </div>
        <div class="col-3 px-2">
          <div
            style="height: 150px"
            class="text-center bg-gray-light border-top border-4 border-secondary rounded-1 p-3"
          >
            <p class="fw-bold">
              Total No. of people that used a high value provider
            </p>
            <h1 class="fw-bold">
              {{ luckyList.highValueProvider }}
            </h1>
          </div>
        </div>
        <div class="col-3 px-2 pe-0">
          <div
            style="height: 150px"
            class="text-center bg-gray-light border-top border-4 border-gray-main rounded-1 p-3"
          >
            <p class="fw-bold">
              Total No. of people that used a low value provider
            </p>
            <h1 class="fw-bold">
              {{ luckyList.lowValueProvider }}
            </h1>
          </div>
        </div>
      </div>
      <div class="pt-3">
        <table class="table table-report table-lucky" id="incentive">
          <thead>
            <tr class="fs-2 bordered">
              <th scope="col col-3" class="fw-bold text-center">Case ID</th>
              <th scope="col" class="fw-bold text-center">Employee ID</th>
              <th scope="col" class="fw-bold text-center">Patient</th>
              <th scope="col" class="fw-bold text-center">Procedure</th>
              <th scope="col" class="fw-bold text-center">Provider</th>
              <th scope="col" class="fw-bold text-center">State Avg</th>
              <th scope="col" class="fw-bold text-center">Est. Cost</th>
              <th scope="col" class="fw-bold text-center">Date of Service</th>
              <th scope="col" class="fw-bold text-center">Value</th>

            </tr>
          </thead>
          <tbody id="incentive">
            <tr>
              <td
                colspan="12"
                *ngIf="!luckyList.caseDate.length"
                class="text-center"
              >
                No records found.
              </td>
            </tr>
            <ng-container *ngFor="let l of luckyList.caseDate; let i = index">
              <tr
                class="fs-2 bordered text-center"
                [ngClass]="{ 'row-striped': i % 2 !== 0 }"
              >
                <td>{{ l.caseId }}</td>
                <td>{{ l.employeeId }}</td>
                <td>{{ l.patientFirstName }} {{ l.patientLastName }}</td>
                <td>{{ l.service[0]?.name }}</td>
                <td>{{ l.providerName }}</td>
                <td>{{ (l.providerStateAverage?.amount || l.providerStateAverage) | currency }}</td>
                <td>{{ (l.providerEstCost?.amount  || l.providerEstCost) | currency }}</td>
                <td>{{ l.dateOfService | date : "MM/dd/yyyy" }}</td>
                <td>{{ l.providerValue }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
