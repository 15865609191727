<div class="d-flex justify-content-center pb-5">
  <div class="smart-search">
    <div class="iframe-header bg-primary-lighter">
      <div class="w-100">
        <img
          src="../../../../assets/images/Alithias Logo with Power Slogan Transparent.png"
          alt=""
          class="img-fluid pe-3"
          alt=""
          style="height: 60px; width: 170px"
        />
      </div>
    </div>
    <div class="py-3 pt-0">
      <div class="pt-5 mt-5" *ngIf="responseLoading">
        <loader></loader>
      </div>
      <div class="mt-2 mb-2 advanced-collapse" *ngIf="!responseLoading">
        <div class="bg-gray-lighter p-3">
          <div
            *ngIf="!surveyResponse"
            class="d-flex justify-content-center w-100 p-3"
          >
            <p class="fw-bold">This survey has been obsolete</p>
          </div>
          <div
            *ngIf="surveyResponse"
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="fw-bold">Survey Response</h4>
            <div class="text-end">
              <label>
                By: {{ surveyResponse.patientFullName | titleCase }}</label
              >
              <br />
              <label>Case Id: {{ surveyResponse.caseId }}</label>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div *ngFor="let x of surveyResponse.survey">
            <p class="fw-bold mb-2">{{ x.question }}</p>
            <p class="fs-2">{{ x.answer }}</p>
            <hr class="mt-1" />
          </div>
        </div>
        <!-- <div class="d-flex justify-content-end p-3">
                    <button type="button" class="btn btn-primary" (click)="close()">
                        Close
                      </button>
                </div> -->
      </div>
    </div>
  </div>
</div>
