<div class="d-flex justify-content-center pb-5">
  <div class="smart-search">
    <div class="iframe-header bg-primary-lighter">
      <div class="w-100">
        <img src="../../../../assets/images/Alithias Logo with Power Slogan Transparent.png" alt=""
          class="img-fluid pe-3" alt="" style="height: 60px; width: 170px" />
      </div>
    </div>
    <div class="py-3 pt-0">
      <div class="mt-2 mb-2 advanced-collapse">
        <!--form content for activityLog-->
        <form #smartSearchform="ngForm" autocomplete="off">
          <div class="p-3">
            <div class="d-flex">
              <div class="col-12 px-2">
                <div class="row">
                  <div class="col-4 px-2">
                    <label class="form-label">Procedure</label>
                    <div class="input-group input-group-lg">
                      <input mdbInput [mdbAutocomplete]="procedureAutoComplete" type="" class="form-control"
                        id="procedureAutoComplete" aria-describedby="basic-addon3" autocomplete="off"
                        [(ngModel)]="searchProcedureInput" (input)="searchProcedure($event)" name="procedure"
                        [disabled]="isIframe" />
                      <mdb-autocomplete #procedureAutoComplete="mdbAutocomplete" [displayValue]="displayProcedureValue"
                        (selected)="
                          onSmartSearchFieldSelect($event, 'procedure')
                        ">
                        <ng-container *ngIf="autocompleteLoader">
                          <mdb-option> Loading... </mdb-option>
                        </ng-container>
                        <ng-container *ngIf="keywordProcedureResult && !autocompleteLoader">
                          <mdb-option *ngFor="
                              let option of keywordProcedureResult
                                | sort : 'asc' : 'procedureName'
                            " [value]="option">
                            {{ option.procedureName }}
                          </mdb-option>
                        </ng-container>
                      </mdb-autocomplete>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <label class="form-label">Company</label>
                    <div class="input-group input-group-lg">
                      <input mdbInput [mdbAutocomplete]="companyAutoComplete" type="" class="form-control"
                        id="companyAutoComplete" aria-describedby="basic-addon3" autocomplete="off"
                        [(ngModel)]="searchCompanyInput" (input)="searchCompany($event)" name="clientLegalName"
                        [disabled]="isIframe" />
                      <mdb-autocomplete #companyAutoComplete="mdbAutocomplete" [displayValue]="displayCompanyValue"
                        (selected)="onSmartSearchFieldSelect($event, 'company')">
                        <ng-container *ngIf="autocompleteLoader">
                          <mdb-option> Loading... </mdb-option>
                        </ng-container>
                        <ng-container *ngIf="keywordCompanyResult && !autocompleteLoader">
                          <mdb-option *ngFor="let option of keywordCompanyResult" [value]="option">
                            {{ option.clientLegalName }}
                          </mdb-option>
                        </ng-container>
                      </mdb-autocomplete>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <label class="form-label">Member</label>
                    <div class="input-group input-group-lg">
                      <input mdbInput [mdbAutocomplete]="memberAutoComplete" type="" class="form-control"
                        id="memberAutoComplete" aria-describedby="basic-addon3" autocomplete="off"
                        [(ngModel)]="searchMemberInput" (input)="searchMember($event)" name="member"
                        [disabled]="isIframe" />
                      <mdb-autocomplete #memberAutoComplete="mdbAutocomplete" [displayValue]="displayMemberValue"
                        (selected)="onSmartSearchFieldSelect($event, 'member')">
                        <ng-container *ngIf="autocompleteLoader">
                          <mdb-option> Loading... </mdb-option>
                        </ng-container>
                        <ng-container *ngIf="keywordMemberResult && !autocompleteLoader">
                          <mdb-option *ngFor="let option of keywordMemberResult" [value]="option">
                            {{ option.firstName + " " + option.lastName }}
                          </mdb-option>
                        </ng-container>
                      </mdb-autocomplete>
                    </div>
                  </div>
                  <div class="col-4 px-2 pt-3">
                    <label class="form-label">Location</label>
                    <div class="input-group input-group-lg">
                      <input mdbInput [mdbAutocomplete]="locationAutoComplete" type="" class="form-control"
                        id="locationAutoComplete" aria-describedby="basic-addon3" autocomplete="off"
                        [(ngModel)]="searchLocationInput" (input)="searchLocation($event)" name="location"
                        placeholder="State, City or Zip" />
                      <mdb-autocomplete #locationAutoComplete="mdbAutocomplete" [displayValue]="displayLocationValue"
                        (selected)="
                          onSmartSearchFieldSelect($event, 'location')
                        ">
                        <ng-container *ngIf="autocompleteLoader">
                          <mdb-option> Loading... </mdb-option>
                        </ng-container>
                        <ng-container *ngIf="keywordLocationResult && !autocompleteLoader">
                          <mdb-option *ngFor="let option of keywordLocationResult" [value]="option">
                            {{
                            option.city +
                            ", " +
                            option.stateCode +
                            " " +
                            option.zipCode
                            }}
                          </mdb-option>
                        </ng-container>
                      </mdb-autocomplete>
                    </div>
                  </div>
                  <div class="col-2 px-2 pt-3">
                    <label class="form-label">Radius</label>
                    <ng-select class="form-control p-1" [searchable]="false" [(ngModel)]="distance"
                      (change)="changeRadius($event)" name="radius">
                      <ng-option [value]="option" *ngFor="let option of radiusList">{{option + "mi"}}</ng-option>
                      <!-- <ng-option [value]="60">60mi</ng-option>
                      <ng-option [value]="120">120mi</ng-option>
                      <ng-option [value]="200">200mi</ng-option>
                      <ng-option [value]="240">240mi</ng-option> -->
                    </ng-select>
                  </div>
                  <div class="col-2 px-2 pt-3"></div>
                  <div class="col-4 pt-4 px-2">
                    <div class="pt-4">
                      <div class="d-flex justify-content-end">
                        <div class="col-6 pe-2" *ngIf="!isIframe">
                          <button class="btn w-100 btn-clear border" (click)="reset()">
                            Reset
                          </button>
                        </div>
                        <div class="col-6 ps-2">
                          <button class="btn w-100 btn-secondary" (click)="searchPricing()" [disabled]="
                              !this.smartSearchModel.procedureId ||
                              !this.smartSearchModel.zipCode
                            ">
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="listing">
      <div class="header bg-gray-lighter">
        <h4 class="fw-bold mb-0 p-3 pb-0">Smart Search</h4>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex p-3 pb-0">
            <div class="py-1 px-3" [ngClass]="{ active: activeTab === i }" *ngFor="let t of tabs; let i = index">
              <a class="" href="javascript:void(0)" (click)="selectTab(i)">{{
                t
                }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-3">
        <h6 class="fw-bold">
          {{ procedureName || "--" }}
        </h6>
        <div class="col-12" *ngIf="!loading">
          <div class="d-flex justify-content-between">
            <div class="col-6">
              <div class="d-flex align-items-center pt-2">
                <h6 class="fw-bold mb-0">
                  {{ (stateAvg | currency) || "N/A" }}
                </h6>
                <label class="ps-2">(State Average Cost)</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5 mt-5" *ngIf="loading">
        <loader></loader>
      </div>
      <table mdbTable class="mt-3" *ngIf="!loading">
        <thead>
          <tr>
            <th scope="col" class="text-center">
              <label class="fw-bold-2 fs-1">Score</label>
            </th>
            <th scope="col" style="width: 400px">
              <label class="fw-bold-2 fs-1">Provider</label>
            </th>
            <th scope="col">
              <label class="fw-bold-2 text-center fs-1">Network</label>
            </th>
            <th scope="col"><label class="fw-bold-2 fs-1">Savings</label></th>
            <th scope="col"><label class="fw-bold-2 fs-1">Cost</label></th>
            <th scope="col">
              <label class="fw-bold-2 fs-1">Incentives</label>
            </th>
            <th scope="col"><label class="fw-bold-2 fs-1">Source</label></th>
            <th scope="col" class="text-center">
              <label class="fw-bold-2 fs-1">Add</label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!providersList.length">
            <td colspan="7" class="text-center fw-bold">
              No provider opt available.
            </td>
          </tr>

          <tr mdbTableCol *ngFor="let p of providersList" [ngClass]="{ 'bg-primary-lighter': p.selected }">
            <td scope="row" class="px-0">
              <div class="text-center">
                <i-bs name="star-fill" width="32" height="32" class="text-primary"></i-bs>
                <h6 class="fw-bold pt-2">{{ p.confidenceLevel }}</h6>
              </div>
            </td>
            <td class="text-left">
              <ul class="list-group list-group-light list-group-small" [ngClass]="{ 'bg-primary-lighter': p.selected }">
                <li class="list-group-item border-0 p-0 pb-2">
                  <div class="d-flex align-items-center">
                    <i-bs name="buildings" class="text-default fs-1"></i-bs>
                    <label class="d-inline-block text-wrap text-default ps-2 fs-1">{{
                      p.facility?.displayName
                      ? p.facility?.displayName
                      : ((p.facility?.businessName
                      ? p.facility?.businessName
                      : null
                      ) | titleCase)
                      }}
                    </label>
                  </div>
                </li>
                <li class="list-group-item border-0 p-0 pb-2">
                  <div class="d-flex align-items-center">
                    <i-bs name="geo-alt-fill" class="text-default fs-1"></i-bs>
                    <label class="text-default ps-2 fs-1">{{ p.facility?.address?.city | titleCase }},
                      {{ p.facility?.address?.state }}
                      {{ p.facility?.address?.zip }}
                    </label>
                  </div>
                </li>
                <li class="list-group-item border-0 p-0 pb-2">
                  <div class="d-flex align-items-center">
                    <i-bs name="person-vcard" class="text-default fs-1"></i-bs>
                    <label class="text-default ps-2 fs-1">{{
                      p.facility.npi
                      }}</label>
                  </div>
                </li>
              </ul>
            </td>
            <td class="">
              <p class="mb-0 text-center">
                {{ p?.network?.name || p?.network?.code || '-' }}
              </p>
            </td>
            <td class="">
              <p class="mb-0">
                {{
                p.providerStateAverage - p.price?.amount - p.incentiveValue
                | currency
                }}
              </p>
            </td>
            <td class="">
              <p class="mb-0">{{ p.price?.amount | currency }}</p>
            </td>
            <td class="">
              <p class="mb-0">{{ p.incentiveValue | currency }}</p>
            </td>
            <td>
              <span class="badge d-inline badge-success">{{p?.network?.code == 'Tier 1' ? 'Tier 1' : (p.price.type == "Likely" ? "Claims" : p.price.type)}}</span>
            </td>
            <td class="text-center background-primary-lighter">
              <i-bs [name]="!p.selected ? 'check-circle' : 'check-circle-fill'" (click)="selectProvider(p)" [ngClass]="{
                  'text-primary': p.selected,
                  'text-default': !p.selected,
                  pointer: checkSelected(p),
                  'not-allowed': !checkSelected(p)
                }"></i-bs>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end p-3">
        <!-- <button type="button" class="btn btn-clear">
                  Cancel
              </button> -->
        <button type="button" class="btn btn-primary" (click)="submit()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>