export const CompanyDropdownConstant = {
  brokerType: [
    { name: 'Account Manager', value: 'accountManager' },
    { name: 'Broker', value: 'broker' },
  ],

  assignedAccountManager: [
    { name: 'Primary', value: 'primary' },
    { name: 'Back-up', value: 'backUp' },
  ],

  assignedAdvocates: [
    { name: 'Care Navigator', value: 'careNavigator' },
    { name: 'SME Advocate', value: 'smeAdvocate' },
    { name: 'CN Back up', value: 'cnBackUp' },
    { name: 'SME Back Up', value: 'smeBackUp' },
  ],

  electronicFileTypes: [
    { name: 'Eligibility', value: 'eligibility' },
    { name: 'Claims', value: 'claims' },
    { name: 'Rx Claims', value: 'rxClaims' },
    { name: 'All', value: '' },
  ],

  brokerTitle: [
    { name: 'Broker', value: 'broker' },
    { name: 'Account Manager', value: 'accountManager' },
  ],

  clientServiceType: [
    { name: 'Care Navigation', value: 'careNavigation' },
    { name: 'Benefit Support', value: 'benefitSupport' },
    { name: 'Both', value: '' },
  ],

  incentivePaymentMethods: [
    { name: 'Paycheck', value: 'paycheck' },
    { name: 'Seperate Check', value: 'seperateCheck' },
    { name: 'HRA/HSA Deposit', value: 'hra/hsaDeposit' },
  ],

  invoiceToList: [
    { name: 'Broker', value: 'broker' },
    { name: 'Client', value: 'client' },
    { name: 'TPA', value: 'tpa' },
  ],

  alertType: [
    { name: 'Benefit Call Outs', value: 'benefitCallOuts' },
    { name: 'Care Navigation Call Outs', value: 'careNavigationCallOuts' },
    { name: 'Key Requirement Reminders', value: 'keyRequirementReminders' },
  ],
  frequencyList: [
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Bi-Weekly', value: 'biWeekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Quaterly', value: 'quaterly' },
  ],

  pricingServiceTypes: [
    { name: 'Care Navigation', value: 'careNavigation' },
    { name: 'Benefit Support', value: 'benefitSupport' },
    { name: 'Incentive', value: 'incentive' },
    { name: 'Implementation', value: 'implementation' },
    { name: 'Renewal', value: 'renewal' },
  ],
  contractBenefitsType: [
    { name: 'Direct Contract', value: 'directContract' },
    { name: 'TPA Contract', value: 'tpaContract' },
    { name: 'Network Contract', value: 'networkContract' },
  ],

  addOnAlithiasServiceType: [
    { name: 'Provider Search', value: 'providerSearch' },
    { name: 'Care Management', value: 'careManagement' },
    { name: 'Billing Assistance', value: 'billingAssistance' },
    { name: '2nd Opinion', value: '2ndOpinion' },
    { name: 'Nurse Line', value: 'nurseLine' },
    { name: 'Multiple', value: 'multiple' },
  ],

  medicalNetworkType: [
    { name: 'Primary', value: 'primary' },
    { name: 'Secondary', value: 'secondary' },
    { name: 'Wrap', value: 'wrap' },
    { name: 'Out Of Area', value: 'outOfArea' },
  ],

  medicalPlanDetailType: [
    { name: 'Deductible', value: 'deductible' },
    { name: 'OOP Maximum', value: 'OOPMaximum' },
  ],

  ancillaryBenefitProgramType: [
    { name: 'Dental', value: 'dental' },
    { name: 'Vision', value: 'vision' },
    { name: 'EAP', value: 'eap' },
    { name: 'Behavioral Health', value: 'behavioralHealth' },
    { name: 'Telemedicine', value: 'telemedicine' },
    { name: 'COBRA', value: 'cobra' },
    { name: 'LTD', value: 'ltd' },
    { name: 'STD', value: 'std' },
    { name: 'Life', value: 'life' },
    { name: 'AD&D', value: 'ad&d' },
    { name: 'HSA', value: 'hsa' },
    { name: 'FSA', value: 'fsa' },
    { name: 'HRA', value: 'hra' },
    { name: 'Wellness', value: 'wellness' },
    { name: 'Diabetes', value: 'diabetes' },
    { name: 'Mayo Clinic Complex Care', value: 'mayoClinicComplexCare' },
    { name: 'Chronic Care', value: 'chronicCare' },
    { name: 'Critical Illness', value: 'criticalIllness' },
    { name: 'Orthopedic Care', value: 'orthopedicCare' },
    { name: 'Pet Insurance', value: 'petInsurance' },
    { name: 'Travel Assistance', value: 'travelAssistance' },
  ],

  pharmacyProgramType: [
    { name: 'Specialty Drugs', value: 'specialtyDrugs' },
    { name: 'Mail Order', value: 'mailOrder' },
    { name: 'Rx Savings', value: 'rxSavings' },
  ],

  medicalPlanAccumulatedMethod: [
    { name: 'Embedded', value: 'embedded' },
    { name: 'Non-Embedded', value: 'nonEmbedded' },
  ],
  pricingType: [
    { name: 'PEPM', value: 'pepm' },
    { name: 'Fee for Service', value: 'feeForService' },
    { name: 'Onetime Fee', value: 'oneTimeFee' },
  ],

  medicalExamTypes: [
    { name: 'Vision', value: 'vision' },
    { name: 'Hearing', value: 'hearing' },
  ],

  medicalPlanNetworkType: [
    { name: 'In-Network', value: 'inNetwork' },
    { name: 'Out-of-Network', value: 'outOfNetwork' },
    { name: 'Open Access', value: 'openAccess' },
  ],

  serviceOptionList: [
    {
      value: 'Cash',
      label: 'Cash',
    },
    {
      value: 'Direct Contract',
      label: 'Direct Contract',
    },
    {
      value: 'Episode of Care',
      label: 'Episode of Care',
    },
    {
      value: 'In Network',
      label: 'In Network',
    },
    {
      value: 'TPA/Network Bundle',
      label: 'TPA/Network Bundle',
    },
  ],

  tilesActionType: [
    {value: 'Phone', label: 'Phone'},
    {value: 'Link', label: 'Link'}
  ],
  
  reportTypeList: [
    {value: 'Utilization & Savings Report', label: 'Utilization & Savings Report'},
    {value: 'Incentive Report', label: 'Incentive Report'},
    {value: 'Savings Detailed Report', label: 'Savings Detailed Report'}
  ]

};
