import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class CompanyModel {
    static setGeneralCompanyDetailModel(){
        let model : FormFields[] = [
            {
                name: 'id',
                id: 'id',
                controlType: 'input',
                controlName: 'id',
                required: true,
                value: null
            },
            {
                name: 'clientLegalName',
                id: 'clientLegalName',
                controlType: 'input',
                controlName: 'clientLegalName',
                required: true,
                value: '' 
            },
            {
                name: 'alithiasCompanyId',
                id: 'alithiasCompanyId',
                controlType: 'input',
                controlName: 'alithiasCompanyId',
                value: null,
                disabled : true
            },
            {
                name: 'companyStatus',
                id: 'companyStatus',
                controlType: 'input',
                controlName: 'companyStatus',
                value: false
            },
            {
                name: 'groupNumber',
                id: 'groupNumber',
                controlType: 'input',
                controlName: 'groupNumber',
                value: null
            },
            {
                controlType: 'subsidiary',
                controlName: 'subsidiary',
                name: 'subsidiary',
                id: 'subsidiary',
                value: [],
                formArray:[]
            },
            {
                name: 'phone',
                id: 'phone',
                controlType: 'input',
                controlName: 'phone',
                value: ''
            },
            {
                name: 'akaName',
                id: 'akaName',
                controlType: 'input',
                controlName: 'akaName',
                value: []
            },
            {
                name: 'phone',
                id: 'phone',
                controlType: 'input',
                controlName: 'phone',
                value: null
            },
            {
                controlType: 'address',
                controlName: 'address',
                subFields: [{
                    name: 'addressLine1',
                    id: 'addressLine1',
                    controlType: 'input',
                    controlName: 'addressLine1',
                    value: ''
                },
                {
                    name: 'addressLine2',
                    id: 'addressLine2',
                    controlType: 'input',
                    controlName: 'addressLine2',
                    value: ''
                },
                {
                    name: 'city',
                    id: 'city',
                    controlType: 'input',
                    controlName: 'city',
                    value: ''
                },
                {
                    name: 'state',
                    id: 'state',
                    controlType: 'input',
                    controlName: 'state',
                    value: ''
                },
                {
                    name: 'zip',
                    id: 'zip',
                    controlType: 'input',
                    controlName: 'zip',
                    value: null
                },
                ]
            },
            {
                controlType: 'logo',
                controlName: 'logo',
                subFields: [{
                    name: 'fileName',
                    id: 'fileName',
                    controlType: 'input',
                    controlName: 'fileName',
                    value: ''
                },
                {
                    name: 'filePath',
                    id: 'filePath',
                    controlType: 'input',
                    controlName: 'filePath',
                    value: ''
                }
                ]
            },
            {
                controlType: 'clientContacts',
                controlName: 'clientContacts',
                formArray: [],
                value : []
            }
        ]
        return model;
    } 

    static setElectronicFilesProvider(){
        let model : FormFields[] = [
            {
                name: 'fileSource',
                id: 'fileSource',
                controlType: 'input',
                controlName: 'fileSource',
                value: ''
            },
            {
                controlType: 'contactInfo',
                controlName: 'contactInfo',
                formArray: [],
                value : []
            }
        ]
        return model;
    } 

    static setTPAInformation(value:any){

        let model : FormFields[] = [
            {
                name: 'tpaId',
                id: 'tpaId',
                controlType: 'input',
                controlName: 'tpaId',
                required: true,
                value: value?.tpaId || null
            },
            {
                name: 'createdAt',
                id: 'createdAt',
                controlType: 'input',
                controlName: 'createdAt',
                required: true,
                value: value?.createdAt || null
            },
            {
                name: 'tpaName',
                id: 'tpaName',
                controlType: 'input',
                controlName: 'tpaName',
                required: true,
                value: value?.tpaName || ''
            },
            {
                name: 'servicePhone',
                id: 'servicePhone',
                controlType: 'input',
                controlName: 'servicePhone',
                required: true,
                value: value?.servicePhone || ''
            },
            {
                name: 'tpaStartDate',
                id: 'tpaStartDate',
                controlType: 'input',
                controlName: 'tpaStartDate',
                value: value?.tpaStartDate  ? new Date(value.tpaStartDate) : null
            },
            {
                name: 'tpaEndDate',
                id: 'tpaEndDate',
                controlType: 'input',
                controlName: 'tpaEndDate',
                value: value?.tpaEndDate ?  new Date(value.tpaEndDate) : null
            },
            {
                controlType: 'customerServiceContactInfo',
                controlName: 'customerServiceContactInfo',
                id: 'customerServiceContactInfo',
                value: value?.customerServiceContactInfo || [],
                formArray:[]
            },
            {
                name: 'webPortalLink',
                id: 'webPortalLink',
                controlType: 'input',
                controlName: 'webPortalLink',
                value: value?.webPortalLink || null
            },
            {
                controlType: 'webContactInfo',
                controlName: 'webContactInfo',
                formArray: [],
                value: value?.webContactInfo || [],
            }
        ]
        return model;
    } 

    static setBrokerDetailnformation(value:any){
        let model : FormFields[] = [
            {
                name: 'brokerId',
                id: 'brokerId',
                controlType: 'input',
                controlName: 'brokerId',
                required: true,
                value: value?.brokerId || null
            },
            {
                name: 'brokerAgencyName',
                id: 'brokerAgencyName',
                controlType: 'input',
                controlName: 'brokerAgencyName',
                required: true,
                value: value?.brokerAgencyName || ''
            },
            {
                name: 'mainPhone',
                id: 'mainPhone',
                controlType: 'input',
                controlName: 'mainPhone',
                required: true,
                value: value?.mainPhone || ''
            },
            {
                name: 'startDate',
                id: 'startDate',
                controlType: 'input',
                controlName: 'startDate',
                value: value?.startDate  ? new Date(value.startDate) : null
            },
            {
                name: 'endDate',
                id: 'endDate',
                controlType: 'input',
                controlName: 'endDate',
                value: value?.endDate ?  new Date(value.endDate) : null
            },
            {
                controlType: 'brokerDetails',
                controlName: 'brokerDetails',
                id: 'brokerDetails',
                value: value?.brokerDetails || [],
                formArray:[]
            }
        ]
        return model;
    }

    static setClientReporting(value:any){
        let model : FormFields[] = [
            {
                name: 'clientReportId',
                id: 'clientReportId',
                controlType: 'input',
                controlName: 'clientReportId',
                required: true,
                value: value?.clientReportId || null
            },
            {
                name: 'frequency',
                id: 'frequency',
                controlType: 'input',
                controlName: 'frequency',
                required: true,
                value: value?.frequency || ''
            },
            {
                name: 'reportName',
                id: 'reportName',
                controlType: 'input',
                controlName: 'reportName',
                required: true,
                value: value?.reportName || ''
            },
            {
                controlType: 'reportReceipentInfo',
                controlName: 'reportReceipentInfo',
                id: 'reportReceipentInfo',
                value: value?.reportReceipentInfo || [],
                formArray:[]
            }
        ]
        return model;
    }

    static setAssignedAccountManager() {
        let model : FormFields[] = [
            {
                controlType: 'assignedAccountManager',
                controlName: 'assignedAccountManager',
                id: 'assignedAccountManager',
                value: [],
                formArray:[]
            },
            {
                controlType: 'assignedAdvocate',
                controlName: 'assignedAdvocate',
                id: 'assignedAdvocate',
                value: [],
                formArray:[]
            }
        ]
        return model;
    }

    static setCompanyBillings(value?:any){
        let model : FormFields[] = [
            {
                name: 'invoiceTo',
                id: 'invoiceTo',
                controlType: 'input',
                controlName: 'invoiceTo',
                required: true,
                value:value?.invoiceTo || ''
            },
            {
                name: 'billingEmail',
                id: 'billingEmail',
                controlType: 'input',
                controlName: 'billingEmail',
                required: true,
                value:value?.billingEmail || ''
            },
            {
                name: 'value',
                id: 'billingAddress',
                controlType: 'input',
                controlName: 'billingAddress',
                value: value?.billingAddress || ''
            },
            {
                name: 'city',
                id: 'city',
                controlType: 'input',
                controlName: 'city',
                value:value?.city
            },
            {
                name: 'state',
                id: 'state',
                controlType: 'input',
                controlName: 'state',
                value: value?.state
            },
            {
                name: 'zip',
                id: 'zip',
                controlType: 'input',
                controlName: 'zip',
                value:value?.zip
            },
            {
                controlType: 'pricingInfo',
                controlName: 'pricingInfo',
                formArray: [],
                value : value?.pricingInfo || []
            },
            {
                controlType: 'contactInfo',
                controlName: 'contactInfo',
                formArray: [],
                value : value?.contactInfo || []
            },
            {
                controlType: 'addOnAlithiasServices',
                controlName: 'addOnAlithiasServices',
                formArray: [],
                value : value?.addOnAlithiasServices || []
            }
        ]
        return model;
    }


    static planDetails(value?:any){
        let model : FormFields[] = [
            {
                name: 'effectiveDate',
                id: 'effectiveDate',
                controlType: 'input',
                controlName: 'effectiveDate',
                required: true,
                value: value?.effectiveDate ? new Date(value.effectiveDate) : null
            },
            {
                name: 'planPeriodStartDate',
                id: 'planPeriodStartDate',
                controlType: 'input',
                controlName: 'planPeriodStartDate',
                required: true,
                value: value?.planPeriodStartDate || null
            },
            {
                name: 'benefitStartDate',
                id: 'benefitStartDate',
                controlType: 'input',
                controlName: 'benefitStartDate',
                required: true,
                value:  value?.benefitStartDate ? new Date(value.benefitStartDate) : null
            },
            {
                name: 'benefitEndDate',
                id: 'benefitEndDate',
                controlType: 'input',
                controlName: 'benefitEndDate',
                required: true,
                value: value?.benefitEndDate ? new Date(value.benefitEndDate) : null
            },
            {
                name: 'employeeCount',
                id: 'employeeCount',
                controlType: 'input',
                controlName: 'employeeCount',
                disabled: true,
                value:  value?.employeeCount || null

            },
            {
                name: 'rollOverStartDate',
                id: 'rollOverStartDate',
                controlType: 'input',
                controlName: 'rollOverStartDate',
                required: true,
                value: value?.rollOverStartDate ? new Date(value.rollOverStartDate) : null
            },
            {
                name: 'rollOverEndDate',
                id: 'rollOverEndDate',
                controlType: 'input',
                controlName: 'rollOverEndDate',
                required: true,
                value:  value?.rollOverEndDate ? new Date(value.rollOverEndDate) : null
            },
            {
                name: 'terminationDate',
                id: 'terminationDate',
                controlType: 'input',
                controlName: 'terminationDate',
                required: true,
                value: value?.terminationDate ? new Date(value.terminationDate) : null
            },
            {
                name: 'enrollmentStartDate',
                id: 'enrollmentStartDate',
                controlType: 'input',
                controlName: 'enrollmentStartDate',
                required: true,
                value: value?.enrollmentStartDate ? new Date(value.enrollmentStartDate) : null
            },
            {
                name: 'enrollmentEndDate',
                id: 'enrollmentEndDate',
                controlType: 'input',
                controlName: 'enrollmentEndDate',
                required: true,
                value: value?.enrollmentEndDate ? new Date(value.enrollmentEndDate) : null
            },
        ]
        return model
    }
}






