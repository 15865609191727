import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import {
  CommonListService,
  HttpCancelService,
  NetworkService,
  ProcedureService,
} from '../../services';
import {
  ActivityLogSearchModel,
  ClaimsSearchModel,
  MemberSearchModel,
  MonitoringModel,
} from '../../model';
import { ActivityLogDateRange, ReportDateRange } from '../../constants';
import { DateCalc } from '../../utils';
import { EnterpriseService } from 'src/app/services/enterprise.service';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() searchConfig: any;
  @Input() searchList: any;

  @Output() baseAction: EventEmitter<any> = new EventEmitter();
  @Output() searchLogEmit: EventEmitter<any> = new EventEmitter();

  activityLogSearchModel: ActivityLogSearchModel = new ActivityLogSearchModel();
  memberSearchModel: MemberSearchModel = new MemberSearchModel();
  monitoringModel: MonitoringModel = new MonitoringModel();
  claimsSearchModel: ClaimsSearchModel = new ClaimsSearchModel();
  userList: any;
  companyList: any;
  procedureList: any;
  networkList: any;
  dateType: any = null;
  logTypeList = [
    { label: 'Error', value: 'error' },
    { label: 'Information', value: 'information' },
    { label: 'Warning', value: 'warning' },
  ];
  dateTypeList = ActivityLogDateRange;
  reportingDateTypeList = ReportDateRange;
  backspaceCounter = 0;
  company = null;
  revenueCodeList: any = [];
  keywordSearchResult: any[];
  keywordTpaSearchResult: any[];
  keywordProcedureResult: any[];
  keywordNetworkResult: any[];
  keywordLocationResult: any[];
  searchText: any = null;
  tpaList: any = [];
  exporting: boolean = false;
  getRequest: any;
  autocompleteLoader: boolean = false;

  constructor(
    private commonListService: CommonListService,
    private enterpriseService: EnterpriseService,
    private cancelService: HttpCancelService,
    private procedureService: ProcedureService,
    private networkService: NetworkService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    let hasQueryPrams: boolean = false;
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length) { hasQueryPrams = true }
      else { hasQueryPrams = false }
    })
    if (this.router.url.split('/')[2]?.includes('episode-detail')) {
      if (hasQueryPrams) {
        this.claimsSearchModel.providerNPI = this.searchConfig.prefillFilter?.providerNPI;
        this.claimsSearchModel.networkName = this.searchConfig.prefillFilter?.networkName;
        this.claimsSearchModel.networkID = this.searchConfig.prefillFilter?.networkID;
        this.claimsSearchModel.procedureID = this.searchConfig.prefillFilter?.procedureID;
        this.claimsSearchModel.procedureName = this.searchConfig.prefillFilter?.procedureName;
      } else {
        this.claimsSearchModel = new ClaimsSearchModel()
      }
    }
    else {
      this.getAndCreateLocalStorage(this.claimsSearchModel, 'get');
    }
    this.dateType = this.searchConfig.module == 'activityLog' ? 'today' : null;
    if(this.router.url.split('/')[2] == 'member') {
      this.tpaList = await this.commonListService.getTpaList();

    }
    this.companyList = await this.commonListService.getCompanyList({
      limit: 0,
      companyStatus: true,
    });
    if(this.router.url.split('/')[1] == 'activity-log') {
      this.userList = await this.commonListService.getUserList();
      this.userList = [{ id: '', firstName: 'All' }, ...this.userList.rows];
    }
  }

  getAndCreateLocalStorage(data: any, method: string) {
    if (method === 'get') {
      const localData: string | null = localStorage.getItem('pricing');
      if (!localData) {
        localStorage.setItem('pricing', JSON.stringify(data));
      } else {
        let parsedData: ClaimsSearchModel = JSON.parse(localData);
        Object.keys(parsedData).forEach((item) => {
          let key = item as keyof ClaimsSearchModel;
          this.claimsSearchModel[key] = parsedData[key];
        });
      }
    } else {
      const pricingData = {
        distance: data.distance,
        location: data.location,
        networkID: data.networkID,
        networkName: data.networkName,
        procedureID: data.procedureID,
        procedureName: data.procedureName,
        providerZip: data.providerZip,
        state: data.state,
        stateCode: data.stateCode,
      };
      let stringifyData = JSON.stringify(pricingData);
      localStorage.setItem('pricing', stringifyData);
    }
  }

  onBaseAction(event: any) {
    this.baseAction.emit(event);
  }

  handleDateChange(event: any) {
    const calculatedDate = DateCalc.calculateDate(event);
    switch (this.searchConfig.module) {
      case 'activityLog':
        this.activityLogSearchModel.createdDateFrom = calculatedDate.from;
        this.activityLogSearchModel.createdDateTo = calculatedDate.to;
        break;
      case 'monitoring':
        this.monitoringModel.start = calculatedDate.from;
        this.monitoringModel.end = calculatedDate.to;
    }
  }

  searchLog(event?: any) {
    switch (this.searchConfig.module) {
      case 'member':
        if (event !== 'reset') {
          this.memberSearchModel = {
            ...this.memberSearchModel,
            keywords: event?.target.value.trim(),
          };
          this.searchLogEmit.emit(this.memberSearchModel);
        } else this.searchLogEmit.emit(event);
        break;
      case 'activityLog':
        this.activityLogSearchModel = {
          ...this.activityLogSearchModel,
          keyword: event?.target?.value.trim(),
        };
        this.searchLogEmit.emit(this.activityLogSearchModel);
        break;
      case 'monitoring':
        if (event !== 'reset') {
          this.searchLogEmit.emit(this.monitoringModel);
        } else this.searchLogEmit.emit(event);
        break;
      case 'bySource':
        this.searchLogEmit.emit({ status: this.searchConfig.sourceType });
        break;
      case 'pricing':
        let pricingParams: any = {
          procedureID: this.claimsSearchModel.procedureID,
          networkID: this.claimsSearchModel.networkID,
          distance: this.searchConfig.distance,
          procedureName: this.claimsSearchModel?.procedureName
        };
        if (this.claimsSearchModel.stateCode) {
          if (!this.claimsSearchModel.networkID) delete pricingParams.networkID;
          this.claimsSearchModel.providerZip = null;
          pricingParams.stateCode = this.claimsSearchModel.stateCode;
        } else {
          if (!this.claimsSearchModel.networkID) delete pricingParams.networkID;
          this.claimsSearchModel.stateCode = null;
          pricingParams.providerZip = this.claimsSearchModel.providerZip;
        }
        this.getAndCreateLocalStorage(this.claimsSearchModel, 'create');
        this.searchLogEmit.emit(pricingParams);
        break;
      case 'episode':
        this.searchLogEmit.emit(event == 'reset' ? 'reset' : this.claimsSearchModel);
        break;
      default:
        let params: any = { limit: this.searchConfig.limit, keywords: event?.target?.value.trim() || this.searchConfig.formValue }
        params = this.searchConfig.sourceType ? { ...params, companyStatus: this.searchConfig.sourceType == 'Active' ? true : false } : params
        this.searchLogEmit.emit(params);
        break;
    }
  }

  async searchProcedure(event?: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let params = { keyword: event?.target?.value.trim() };
      if (!this.getRequest) {
        this.getProcedure(params) as any;
      } else {
        this.cancelService.cancelPendingRequests();
        this.getProcedure(params) as any;
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.claimsSearchModel.procedureID = null;
        this.keywordProcedureResult = [];
      }
    } else {
      this.claimsSearchModel.procedureID = null;
      this.keywordProcedureResult = [];
      return;
    }
  }

  getProcedure(params: any) {
    this.autocompleteLoader = true;
    this.getRequest = this.procedureService
      .getProcedureList(params)
      .pipe(
        finalize(() => {
          this.autocompleteLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.keywordProcedureResult = response.data.rows;
        }
      });
  }

  async searchNetworks(event?: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let params = { keyword: event?.target?.value.trim() };
      if (!this.getRequest) {
        this.getNetwork(params) as any;
      } else {
        this.cancelService.cancelPendingRequests();
        this.getNetwork(params) as any;
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.claimsSearchModel.networkID = null;
        this.keywordNetworkResult = [];
      }
    } else {
      this.claimsSearchModel.networkID = null;
      this.keywordNetworkResult = [];
      return;
    }
  }

  getNetwork(params: any) {
    this.autocompleteLoader = true;
    this.getRequest = this.networkService
      .getGroupNetworkList(params.keyword)
      .pipe(
        finalize(() => {
          this.autocompleteLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.keywordNetworkResult = response.data;
        }
      });
  }

  async searchLocation(event?: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let params = { keyword: event?.target?.value.trim() };
      if (!this.getRequest) {
        this.getLocation(params);
      } else {
        this.cancelService.cancelPendingRequests();
        this.getLocation(params);
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.claimsSearchModel.providerZip = null;
        this.claimsSearchModel.stateCode = null;
        this.claimsSearchModel.location = '';
        this.keywordLocationResult = [];
      }
    } else {
      this.claimsSearchModel.providerZip = null;
      this.claimsSearchModel.stateCode = null;
      this.claimsSearchModel.location = '';
      this.keywordLocationResult = [];
      return;
    }
  }

  getLocation(params: any) {
    this.autocompleteLoader = true;
    this.getRequest = this.enterpriseService
      .getLocation(params)
      .pipe(
        finalize(() => {
          this.autocompleteLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.keywordLocationResult = response.data.rows;
        }
      });
  }

  reset() {
    this.dateType = this.searchConfig.module == 'activityLog' ? 'today' : null;
    this.activityLogSearchModel = new ActivityLogSearchModel();
    this.memberSearchModel = new MemberSearchModel();
    this.monitoringModel = new MonitoringModel();
    this.claimsSearchModel = new ClaimsSearchModel();
    this.searchConfig.limit = 25;
    this.company = null;
    this.keywordSearchResult = [];
    this.keywordTpaSearchResult = [];
    this.keywordProcedureResult = [];
    this.keywordProcedureResult = [];
    this.keywordNetworkResult = [];
    this.keywordLocationResult = [];
    this.searchText = '';
    this.searchConfig.formValue = null;
    this.searchLog(
      this.searchConfig.module == 'member' || 'monitoring' ? 'reset' : ''
    );
  }

  onKeyup(event: any) {
    if (
      this.searchConfig.module !== 'bySource' &&
      this.searchConfig.module !== 'monitoring'
    ) {
      if (
        event.target.value.trim() ||
        (!event.target.value.trim() &&
          event.inputType == 'deleteContentBackward')
      ) {
        this.searchLog(event);
      }
    }
  }

  onCompanyChange(event: any) {
    switch (this.searchConfig.module) {
      case 'member':
        if (event.option.value) {
          this.memberSearchModel.alithiasGroupId =
            event.option.value.alithiasCompanyId;
        } else {
          this.memberSearchModel.alithiasGroupId = null;
        }
        break;
      case 'monitoring':
        if (event.option.value) {
          this.monitoringModel.companyId = event.option.value.alithiasCompanyId;
        } else {
          this.monitoringModel.companyId = null;
        }
        break;
    }
  }

  checkCompanyChange(event: any) {
    if (!event.target.value) {
      this.searchText = null;
      this.memberSearchModel.groupCode = null;
      this.monitoringModel.companyId = null;
    }
  }

  clearInput(type: any) {
    if (type == 'date') {
      this.memberSearchModel.dob = null;
    } else {
      this.searchConfig.formValue = '';
      this.searchLog(this.searchConfig.module == 'monitoring' ? 'reset' : '');
    }
  }

  onEnter(event: any) {
    event.preventDefault();
  }

  filter(value: any, type: string) {
    if (value) {
      if (type == 'company') {
        const filterValue =
          typeof value == 'string'
            ? value.toLowerCase()
            : value.clientLegalName.toLowerCase();
        this.keywordSearchResult = this.companyList.filter((item: any) =>
          item.clientLegalName?.toLowerCase().includes(filterValue)
        );
      } else if (type == 'tpa') {
        const filterValue = value.toLowerCase();
        this.keywordTpaSearchResult = this.tpaList.filter((item: any) =>
          item.toLowerCase().includes(filterValue)
        );
      }
    } else {
      this.keywordSearchResult = [];
      this.keywordTpaSearchResult = [];
      this.keywordProcedureResult = [];
      this.keywordNetworkResult = [];
    }
  }

  displayValue(value: any): string {
    return value ? value.clientLegalName : '';
  }


  displayProcedureValue = (value: any): string => {
    let procedure = value
      ? value.procedureName
      : this.claimsSearchModel.procedureName;
    if (!procedure) return this.claimsSearchModel.procedureName;
    return procedure;
  };

  displayNetworkValue = (value: any): string => {
    let network = value
      ? value.groupNetworkName
      : this.claimsSearchModel.networkName;
    if (!network) return this.claimsSearchModel.networkName;
    return network;
  };

  displayLocationValue = (value: any): string => {
    if (value?.zipCode) {
      return value ? `${value.city}, ${value.stateCode} ${value.zipCode}` : '';
    } else {
      return value?.state;
    }
  };

  onClaimsFieldSelect(event: any, type: string) {
    let eventData = event.option.value;
    switch (type) {
      case 'procedure':
        this.claimsSearchModel.procedureID = eventData.procedureId;
        this.claimsSearchModel.procedureName = eventData.procedureName;
        break;
      case 'network':
        this.claimsSearchModel.networkID = eventData.groupNetworkId;
        this.claimsSearchModel.networkName = eventData.groupNetworkName;
        break;
      case 'location':
        if (eventData.zipCode) {
          this.claimsSearchModel.providerZip = eventData.zipCode;
          this.claimsSearchModel.location = eventData;
        } else {
          this.claimsSearchModel.stateCode = eventData.stateCode;
          this.claimsSearchModel.location = eventData;
        }
    }
  }

  onDatePickerClosed() {
    switch (this.searchConfig.module) {
      case 'activityLog':
        this.activityLogSearchModel.createdDateFrom = this
          .activityLogSearchModel.createdDateFrom
          ? moment(this.activityLogSearchModel.createdDateFrom).format(
            'MM/DD/YYYY'
          )
          : null;
        this.activityLogSearchModel.createdDateTo = this.activityLogSearchModel
          .createdDateTo
          ? moment(this.activityLogSearchModel.createdDateTo).format(
            'MM/DD/YYYY'
          )
          : null;
        break;
      case 'monitoring':
        this.monitoringModel.start = this.monitoringModel.start
          ? moment(this.monitoringModel.start).format('MM/DD/YYYY')
          : null;
        this.monitoringModel.end = this.monitoringModel.end
          ? moment(this.monitoringModel.end).format('MM/DD/YYYY')
          : null;
        break;
    }
  }
}
