import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from  '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private http: HttpClientService
    ) { }

    getUsers(keyword?:any) {
        const queryParams = QueryParams.addParams(keyword);
        return this.http.get(`${ApiConstant.USER}?` + queryParams)
        .pipe(map(response => {
            return response;
        }))
    }
    
    deactivateUser(userInfo:any) {
        return this.http.remove(`${ApiConstant.USER}/${userInfo.id}`)
        .pipe(map((response: any) => {
          return response;
        }));
    }

    addNewUser(userInfo:any) {
        return this.http.post(`${ApiConstant.USER}`, userInfo)
        .pipe(map((response: any) => { 
          return response;
        }));
    }

    editUser(user:any) {
        return this.http.put(`${ApiConstant.USER}/${user.id}`, user)
        .pipe(map((response: any) => { 
          return response;
        }));
    }

    getRolesList(keyword?:any) {
        const queryParams = QueryParams.addParams(keyword);
        return this.http.get(`${ApiConstant.ROLES}?` + queryParams)
        .pipe(map(response => {
            return response;
        }))
    }

    addNewRole(newRole:any) {
        return this.http.post(`${ApiConstant.ROLES}`, newRole)
        .pipe(map(response => {
            return response;
        }))
    }

    editRole(roleInfo:any) {
        return this.http.put(`${ApiConstant.ROLES}/${roleInfo.roleId}`, roleInfo)
        .pipe(map(response => {
            return response;
        }))
    }

    deleteRole(role:any) {
        return this.http.remove(`${ApiConstant.ROLES}/${role.roleId}`)
        .pipe(map((response: any) => {
          return response;
        }));
    }

    getPermissionByUserId(user:any) {
        return this.http.get(`${ApiConstant.USER}/${user.id}/permissions`)
        .pipe(map(response => {
            return response;
        }))
    }

    editUsersPermission(userId:any, permission:any) {
        return this.http.put(`${ApiConstant.USER}/${userId}/permissions`, permission)
        .pipe(map(response => {
            return response;
        }))
    } 

    getAllResources() {
        return this.http.get(`${ApiConstant.RESOURCE}`)
        .pipe(map(response => {
            return response;
        }))
    }

    resetPassword(body:any) {
        return this.http.post(`${ApiConstant.ACCOUNT}/reset-password?token=${body.token}`, body)
        .pipe(map(response => {
            return response;
        }))
    }

    activateAccount(body:any) {
        return this.http.put(`${ApiConstant.USER}/set-password?token=${body.token}`, body)
        .pipe(map(response => {
            return response;
        }))
    }

    forgotPassword(body:any) {
        return this.http.post(`${ApiConstant.ACCOUNT}/forgot-password?email=${body}`, body)
        .pipe(map(response => {
            return response;
        }))
    }

    sendInvitation(userId:any) {
        return this.http.post(`${ApiConstant.USER}/send-invitation/${userId}`, userId)
        .pipe(map(response => {
            return response;
        }))
    }

    verifyOtp(body:any) {
        return this.http.post(`${ApiConstant.USER}/verify-otp`, body)
        .pipe(map(response => {
            return response;
        }))
    }

    assignCompany(user:any, company:any) {
        return this.http.put(`${ApiConstant.USER}/${user}/companies/assign`, company)
        .pipe(map(response => {
            return response;
        }))
    }
}