import { Routes } from '@angular/router';

import * as common from '../common';
import * as components from '../components';
import { RoleGuard } from '../guards';

export const RouteSecure: Routes = [
    { path: 'welcome', component: common.WelcomeComponent},
    { path: 'users', component: components.UsersComponent, canActivate: [RoleGuard]},
    { path: 'roles', component: components.RolesPermissionComponent, canActivate: [RoleGuard]},
    { path: 'activity-log', component: components.ActivityLogComponent, canActivate: [RoleGuard]},
    { path: 'member-profile', component: components.MemberProfileComponent, canActivate: [RoleGuard]},
    { path: 'admin/procedure', component: components.ProcedureComponent, canActivate: [RoleGuard]},
    { path: 'admin/procedure/create', component: components.ProcedureAddEditComponent, canActivate: [RoleGuard]},
    { path: 'admin/procedure/edit/:id', component: components.ProcedureAddEditComponent, canActivate: [RoleGuard]},
    { path: 'admin/network', component: components.NetworkComponent, canActivate: [RoleGuard]},
    { path: 'admin/company', component: components.CompanyComponent, canActivate: [RoleGuard]},
    { path: 'admin/procedure-pricing', component: components.PricingComponent, canActivate: [RoleGuard]},
    { path: 'admin/episode-detail', component: components.EpisodesComponent, canActivate: [RoleGuard]},
    { path: 'admin/company/create', component: components.CompanyAddEditComponent, canActivate: [RoleGuard]},
    { path: 'admin/company/edit/:id', component: components.CompanyAddEditComponent, canActivate: [RoleGuard]},
    { path: 'admin/member', component: components.MemberComponent, canActivate: [RoleGuard]},
    { path: 'etl/by-file', component: components.FilesStatusComponent, canActivate: [RoleGuard]},
    { path: 'etl/by-company', component: components.EtlMonitoringComponent, canActivate: [RoleGuard]},
    { path: 'etl/by-source', component: components.MonitoringBySourceComponent, canActivate: [RoleGuard]},
    { path: 'etl/dashboard', component: components.EtlDashboardComponent, canActivate: [RoleGuard]},
    { path: 'reports/incentive-report', component: components.IncentiveReportComponent, canActivate: [RoleGuard]},
    { path: 'reports/savings-report', component: components.SavingReportComponent,  canActivate: [RoleGuard]},
    { path: 'reports/dashboard-detailed-report', component: components.DashboardDetailedReportComponent, canActivate: [RoleGuard]},
    { path: 'reports/lucky-report', component: components.LuckyReportComponent, canActivate: [RoleGuard]},
    { path: 'reports/tag-report', component: components.TagReportComponent, canActivate: [RoleGuard]},
    { path: 'reports/reason-code-report', component: components.ReasonCodeReportComponent, canActivate: [RoleGuard]},
    // { path: 'reports/account-status-update', component: components.AccountStatusUpdateComponent, canActivate: [RoleGuard]},
    { path: 'reports/saving-detailed-report', component: components.SavingDetailedReportComponent, canActivate: [RoleGuard]},
    { path: 'reports/advocacy-satisfaction-report', component: components.AdvocacySatisfactionReportComponent, canActivate: [RoleGuard]},
    { path: 'raw-filter-data', component:components.RawFilterDataComponent, canActivate: [RoleGuard]},
    { path: 'settings/custom-report-messaging', component: components.CustomReportMessagingComponent, canActivate: [RoleGuard]},
    { path: '404', component: common.WelcomeComponent},
    { path: '**', redirectTo: 'welcome', pathMatch: 'full' },

]
