import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CompanyService, FormInitService, ToastrService } from '../../../../../services';
import { AcillaryBenefitModel } from '../../../../../model';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmationModalComponent } from 'src/app/modal';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-ancillary-benefit-program',
  templateUrl: './ancillary-benefit-program.component.html',
  styleUrls: ['./ancillary-benefit-program.component.scss'],
})
export class AncillaryBenefitProgramComponent implements OnInit {
  @Input() ancillaryBenifitProgram!: any;
  ancillaryBenefitForm: UntypedFormGroup;
  saving: boolean = false;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formInit: FormInitService,
    public parentComponent: CompanyAddEditComponent,
    private modalService: MdbModalService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
  ) {
    this.ancillaryBenefitForm = this.formBuilder.group({
      ancillaryBenefitInfo: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {}
  ngOnChanges() {
    this.resetFormArray()
    if (this.ancillaryBenifitProgram?.length) {
      this.ancillaryBenifitProgram.forEach((x: any) => {
        this.addInfoForms(this.ancillaryBenefitForm, 'ancillaryBenefitInfo', x);
      });
    }
  }

  resetFormArray(){
    let formArray = this.getFormArray(this.ancillaryBenefitForm, 'ancillaryBenefitInfo')
    formArray.clear()
  }

  getControl(fromControl: any, controlName: string): UntypedFormGroup {
    return fromControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }

  delete(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if (item.contactId) {
      this.companyService.deleteAncillaryBenifitProgram(this.parentComponent.currentCompanyId,formControlName.controls['ancillaryId'].value, item).pipe(finalize(() => {
        item.loading = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          control.removeAt(index);
          this.toastrService.showSuccess(response.message);
        }
      })
    }
    else {
      control.removeAt(index);
    }
  }

  async addContactForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initContactDetails(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails);
  }

  saveForms() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService.updateAncillaryBenifitProgram(this.parentComponent.currentCompanyId, this.ancillaryBenefitForm.value.ancillaryBenefitInfo).pipe(finalize(() => {
        this.saving = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          this.parentComponent.getAncillaryBenifitProgram()
        }
      })
    }
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newService = this.initValueBasedForm(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) : formArray.insert(0, newService);
    if (value?.vendorDetails.length) {
      value?.vendorDetails.forEach((x: any) => {
        this.addContactForms(newService, 'vendorDetails', x);
      });
    }
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      contactId: [value?.contactId],
      contactName: [value?.contactName],
      contactType: [value?.contactType],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
    });
  }

  initValueBasedForm(value?: any) {
    return this.formInit.createFormGroup(
      AcillaryBenefitModel.setAcillaryBenefitModel(value)
    );
  }


  openConfirmationModel(
    index: number,
    item: any,
    arrayName: any,
    formControlName: any
  ) {
    if (item.value.ancillaryId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.programType}</strong> ?`,
        },
      });
      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          let control = <UntypedFormArray>formControlName.controls[arrayName];
          control.removeAt(index)
          this.saveForms()
        }
      });
    } else {
      let control = <UntypedFormArray>formControlName.controls[arrayName];
      control.removeAt(index)
    }
  }
}
