import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(
        private http: HttpClientService
    ) { }

    getSavingReport(params?: any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/saving?` + queryParams)
            .pipe(map(response => {
                return response;
            }))
    }
    getIncentiveReport(params?: any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/incentive?` + queryParams)
            .pipe(map(response => {
                return response;
            }))
    }
    getSavingDetailedReport(params?: any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/saving-detail?` + queryParams)
            .pipe(map(response => {
                return response;
            }))
    }

    getAccountStatusReport(keyword?: any) {
        return this.http.get(`${ApiConstant.REPORTS}/account-status-update`)
            .pipe(map(response => {
                return response;
            }))
    }

    getTagReport(keyword?: any) {
        const queryParams = QueryParams.addParams(keyword);
        return this.http.get(`${ApiConstant.REPORTS}/tags?` + queryParams)
            .pipe(map(response => {
                return response;
            }))
    }

    getCareOptionReport(caseId: any, requestType?: any) {
        return this.http.get(`${ApiConstant.REPORTS}/care-option/${caseId}`, null, requestType)
            .pipe(map(response => {
                return response;
            }))
    }

    sendCareReport(body: any, requestType?: any) {
        return this.http.post(`${ApiConstant.REPORTS}/care-option/${body.caseId}/send-message`, body, false, requestType)
            .pipe(map(response => {
                return response;
            }))
    }

    getDashboardReport(params: any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/dashboard/report-details?` + queryParams)
            .pipe(map(response => {
                return response;
            }))
    }

    getLuckyReport(params: any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/lucky?` + queryParams)
            .pipe(map(response => {
                return response;
            }))
    }

    sendSavingsReport(body: any) {
        return this.http.post(`${ApiConstant.REPORTS}/saving-detail/send-email`, body)
            .pipe(map(response => {
                return response;
            }))
    }

    getTagList() {
        return this.http.get(`${ApiConstant.REPORTS}/tags-option`)
            .pipe(map(response => {
                return response;
            }))
    }

    getAdvocacyReport(params:any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/satisfaction-report?` + queryParams)
        .pipe(map(response => {
            return response;
        }))
    }
    
    getReasonCodeReport(params:any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.REPORTS}/reason-code?`+ queryParams )
        .pipe(map(response => {
            return response;
        }))
    }
}