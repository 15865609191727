import {
    search,
    barChartLineFill,
    journalArrowUp,
    ticketDetailed,
    diagram3,
    graphDownArrow,
    personWorkspace,
    listUl,
    barChartSteps,
    personCircle,
    hddNetwork,
    hospital,
    fileMedical,
    building,
    kanban,
    boxArrowRight,
    viewStacked,
    keyFill,
    personBoundingBox,
    viewList,
    eyeFill,
    alarm,
    alarmFill,
    alignBottom,
    personFill,
    lock,
    starFill,
    grid,
    gear,
    cameraReels,
    pencilSquare,
    trashFill,
    threeDotsVertical,
    eye,
    eyeSlashFill,
    eyeSlash,
    envelopeFill,
    bellFill,
    caretRightFill,
    houseDoorFill,
    chevronRight,
    list,
    gem,
    plusCircleFill,
    dashCircleFill,
    plus,
    dash,
    funnel,
    gridFill,
    film,
    download,
    gearFill,
    fileCode,
    arrowClockwise,
    threeDots,
    chevronDown,
    chevronBarRight,
    caretDownFill,
    caretUpFill,
    plusCircle,
    dashCircle,
    chevronLeft,
    calendarFill,
    toggleOn,
    toggleOff,
    checkCircleFill,
    checkCircle,
    personLinesFill,
    folderSymlink,
    journalArrowDown,
    x,
    fileEarmarkEasel,
    boxSeam,
    circleFill,
    exclamationCircleFill,
    peopleFill,
    chevronUp,
    upload,
    infoCircle,
    globeAmericas,
    cashCoin,
    check,
    xLg,
    geoAltFill,
    chevronExpand,
    personFillUp,
    clockHistory,
    envelope,
    phoneFill,
    barChartFill,
    currencyDollar,
    personVcard,
    buildings,
    journals,
    printerFill,
    send,
    telephoneFill,
    personFillGear,
    exclamationTriangleFill,
    personVideo2,
    filePdf,
    fileSpreadsheet,
    lightbulbFill
} from 'ngx-bootstrap-icons';

// Select some icons (use an object, not an array)
export const AppIcons = {
    search,
    barChartLineFill,
    journalArrowUp,
    ticketDetailed,
    diagram3,
    graphDownArrow,
    personWorkspace,
    listUl,
    barChartSteps,
    personCircle,
    hddNetwork,
    hospital,
    fileMedical,
    building,
    kanban,
    boxArrowRight,
    viewStacked,
    keyFill,
    viewList,
    eyeFill,
    alarm,
    alarmFill,
    alignBottom,
    personFill,
    lock,
    starFill,
    grid,
    gear,
    cameraReels,
    pencilSquare,
    trashFill,
    threeDotsVertical,
    eye,
    eyeSlash,
    envelopeFill,
    bellFill,
    caretRightFill,
    houseDoorFill,
    chevronRight,
    list,
    gem,
    plusCircleFill,
    dashCircleFill,
    plus,
    dash,
    funnel,
    gridFill,
    film,
    download,
    gearFill,
    fileCode,
    arrowClockwise,
    personBoundingBox,
    threeDots,
    chevronDown,
    chevronBarRight,
    caretDownFill,
    caretUpFill,
    plusCircle,
    dashCircle,
    chevronLeft,
    calendarFill,
    toggleOn,
    toggleOff,
    checkCircleFill,
    checkCircle,
    personLinesFill,
    folderSymlink,
    journalArrowDown,
    x,
    fileEarmarkEasel,
    boxSeam,
    circleFill,
    exclamationCircleFill,
    peopleFill,
    chevronUp,
    upload,
    infoCircle,
    globeAmericas,
    cashCoin,
    check,
    xLg,
    eyeSlashFill,
    geoAltFill,
    phoneFill,
    chevronExpand,
    personFillUp,
    clockHistory,
    envelope,
    barChartFill,
    currencyDollar,
    personVcard,
    buildings,
    journals,
    printerFill,
    send,
    telephoneFill,
    personFillGear,
    exclamationTriangleFill,
    personVideo2,
    filePdf,
    fileSpreadsheet,
    lightbulbFill
};
  // bootstrap icon ends
