import { Component, Output, EventEmitter } from '@angular/core';

import { MemberSearchModel } from '../../../model';
import { CommonListService, MemberService } from 'src/app/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-member-profile-filter',
  templateUrl: './member-profile-filter.component.html',
  styleUrls: ['./member-profile-filter.component.scss']
})
export class MemberProfileFilterComponent {

  @Output() emitSelectedMember: EventEmitter<any> = new EventEmitter();

  memberSearchModel: MemberSearchModel = new MemberSearchModel();
  loading: boolean = false;
  companyList: any = [];
  companySearchText: any = null;
  keywordSearchList: any = [];
  memberDropdownList: any = [];

  constructor(private memberService: MemberService, private commonListService: CommonListService) { }

  async ngOnInit() {
    this.companyList = await this.commonListService.getCompanyList({
      limit: 0,
      companyStatus: true,
    });
  }

  displayValue(value: any): string {
    return value ? value.clientLegalName : '';
  }

  filterList(value: any) {
    if (value) {
      const filterValue =
        typeof value == 'string'
          ? value.toLowerCase()
          : value.clientLegalName.toLowerCase();
      this.keywordSearchList = this.companyList.filter((item: any) =>
        item.clientLegalName?.toLowerCase().includes(filterValue)
      );
    }
    else {
      this.keywordSearchList = [];
    }

  }

  checkCompanyChange(event: any) {
    if (!event.target.value) {
      this.companySearchText = null;
      this.memberSearchModel.groupCode = null;
    }
  }


  searchMember() {
    this.memberDropdownList = [];
    this.loading = true;
    this.memberService.getMemberList({ ...this.memberSearchModel, sortBy: 'lastModified' })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.memberDropdownList = response.data;
        }
      })
  }

  reset() {
    this.memberSearchModel = new MemberSearchModel();
    this.keywordSearchList = null;
    this.companySearchText = null;
    this.memberDropdownList = [];
    this.emitSelectedMember.emit('reset');
  }

  onCompanyChange(event: any) {
    if (event.option.value) {
      this.memberSearchModel.alithiasGroupId =
        event.option.value.alithiasCompanyId;
    } else {
      this.memberSearchModel.alithiasGroupId = null;
    }
  }

  memberSelected(member: any) {
    this.memberSearchModel.subscriberId = member.subscription.subscriberId;
    this.memberSearchModel.phone = member.phone?.find((x:any)=>x.code == 'Cell')?.value;
    this.memberSearchModel.dob = member.dob? new Date(member.dob) : '';
    this.memberSearchModel.middleName = member.middleName;
    this.memberSearchModel.firstName = member.firstName;
    this.memberSearchModel.lastName = member.lastName;
    this.companySearchText = this.companyList.find((x:any)=> x.alithiasCompanyId == +member.subscription.alithiasGroupId);
    this.emitSelectedMember.emit(member);
  }

}
