import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import * as FileSaver from 'file-saver';

import { TPAFileModel } from '../../model';
import { CompanyService, ToastrService } from '../../services';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss']
})
export class UploadFileModalComponent implements OnInit {

  tpaFileList: any = []
  tpaInfo: any;
  fileArray: any = [];
  modalTitle: any = 'File Upload';

  constructor(public modalRef: MdbModalRef<UploadFileModalComponent>,
    private companyService: CompanyService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  saveFiles() {
    this.modalRef.close(this.fileArray);
  }

  fileAdded(event: any) {
    if (event.length) {
      event.forEach(async (file: any) => {
        if (file && !this.fileArray.find(((x: any) => x.name == file.name))) {
          file.base64 = await this.changefileContentToBase64(file);
          let fileDetails = TPAFileModel.setTpaFileModel(file)
          this.fileArray.push(fileDetails)
        }
      })
    }
  }

  async changefileContentToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1];
        if (base64String) {
          resolve(base64String);
        } else {
          reject(new Error('Failed to convert the file to base64.'));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  deleteFile(file: any) {
    file.deleting = true;
    if (file.fileId) {
      this.companyService.deleteTpaFile(this.tpaInfo.companyId, this.tpaInfo.contactForm, this.tpaInfo.parentForm.tpaId, file).pipe(finalize(() => {
        file.deleting = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          this.modalRef.close();
        }
      })
    }
  }

  fileRemoved(file:any) {
    if(file) {
      this.fileArray = this.fileArray.filter((x:any)=> x.name.toLowerCase() !== file.name.toLowerCase());
    }
  }

  downloadFile(file: any) {
    file.downloading = true;
    if (file.fileId) {
      this.companyService.downloadFile(file).pipe(finalize(() => {
        file.downloading = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          file.base64 = response.data.file;
          file.contentType = response.data.contentType;
          this.convertBase64ToFile(file);
          // this.toastrService.showSuccess(response.success)
        }
      })
    }
  }

  convertBase64ToFile(file:any) {
    try {
      // Decode the Base64 string to binary data
      const byteCharacters = atob(file.base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: file.type });

      // Save the file using FileSaver.js
      FileSaver.saveAs(blob, file.fileName); // Replace 'output_file.jpg' with the desired file name and extension
    } catch (error:any) {
      this.toastrService.showError('An error occurred:', error);
    }
  }

}
