import { Injectable } from "@angular/core";

@Injectable()
export class QueryParams {
  static addParams(keyword: any) {
    try {
      let keyValues:any
      if (keyword) {
        keyValues = Object.entries(keyword).map(([key, value]) => {
          if (value !== undefined && value !== null) {
            return `${key}=${value}`
          }
          if (key == 'limit') {
            return `${key}=${value ? value : 0}`
          }
        })
      }
      else {
        keyValues = ["limit=25"];
      }
      keyValues = keyValues.filter((x: any) => x !== undefined).join('&');
      return keyValues;
    }
    catch (ex) {
      return null;
    }
  }
}