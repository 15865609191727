<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (baseAction)="openUserModal($event, null)"
        [searchList]="userList.rows" (searchLogEmit)="searchUser($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="usersLoading">
        <loader></loader>
    </div>
    <table class="table table-striped mb-0" *ngIf="!usersLoading">
        <thead>
            <tr>
                <th class="fw-bold">Name
                    <!-- <i-bs [name]="reverse ? 'caret-up-fill' : 'caret-down-fill'"></i-bs> -->
                </th>
                <th class="fw-bold">Email</th>
                <th class="fw-bold" style="width:400px">Role</th>
                <th class="fw-bold">Created By</th>
                <th class="fw-bold">Created On</th>
                <th class="fw-bold"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngIf="!userList.rows.length && !this.usersLoading">
                <td colspan="6" class="fw-bold">Users not found.</td>
            </tr>
            <tr class="position-relative" *ngFor="let user of userList.rows | sort:'asc':'firstName'">
                <td>{{user.firstName + (user.middleName ? ' ' + user.middleName + ' ': ' ') + user.lastName}}</td>
                <td>
                    <div class="d-flex align-items-center">
                        <div class="">
                            <p class="text-muted mb-0">{{user.email}}</p>
                        </div>
                    </div>
                </td>
                <td>
                    <span class="badge d-inline badge-success m-1" *ngFor="let role of user.roles">{{role.name}}</span>
                </td>
                <td>
                    {{user.createdBy}}
                </td>
                <td>{{user.createdAt | date: 'MM/dd/yyyy'}}</td>
                <td class="p-1">
                    <div mdbDropdown class="dropdown" *ngIf="!user.loading">
                        <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                            aria-expanded="false">
                            <i-bs name="three-dots-vertical"></i-bs>
                        </button>
                        <ul autoClose="true" mdbDropdownMenu class="dropdown-menu user-list shadow position-absolute">
                            <li>
                                <button class="dropdown-item" type="button" (click)="sendInvitation(user)">
                                    {{ !user.isEmailVerified ? 'Send Activation Link' : 'Reset Password'}}
                                </button>
                            </li>
                            <li>
                                <button class="dropdown-item" type="button"
                                    (click)="openRolesModal('managePermission', user)">
                                    Permissions
                                </button>
                            </li>
                            <li>
                                <button class="dropdown-item" type="button" (click)="openUserModal('editUser', user)">
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button class="dropdown-item" type="button"
                                    (click)="openUserModal('assignCompany', user)">
                                    Assign Company
                                </button>
                            </li>
                            <li *ngIf="currentUser.id !== user.id">
                                <button class="dropdown-item" type="button" (click)="openConfirmationModal(user)">
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="user.loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <p class="py-3 mb-0 fw-bold fs-2" *ngIf="!usersLoading && userList.rows.length">Showing
        {{userList.rows.length >= searchConfig.limit ? searchConfig.limit : userList.rows.length}} of
        {{userList.rows.length}} {{userList.rows.length > 1 ? 'results' : 'result'}}</p>
</div>