import { Component, Input } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { moveItemsInContainer, MdbDropEvent } from 'mdb-angular-drag-and-drop';

import { TilesModel } from 'src/app/model';
import { CompanyService, FormInitService, ToastrService } from 'src/app/services';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { ConfirmationModalComponent, TilesManagementModelComponent } from 'src/app/modal';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss']
})
export class TilesComponent {
  @Input() tiles!: any;
  tileDetailForm: UntypedFormGroup
  saving: Boolean = false;
  infoSaving: Boolean = false;
  modalRef: MdbModalRef<ConfirmationModalComponent | TilesManagementModelComponent>;
  searchKeyword: any = null;
  searchConfig = {
    action: { label: 'Add Tiles', type: 'addTiles', icon: 'plus' },
    showAdvancedSearch: false,
    showToggle: false,
    formValue: this.searchKeyword,
    module: 'alerts',
    limit: 25,
  };

  constructor(
    private formInit: FormInitService,
    private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private modalService: MdbModalService,
    private companyService: CompanyService,
    private toastrService: ToastrService) {
    this.tileDetailForm = this.formBuilder.group({
      tileInfo: this.formBuilder.array([]),
    });
  }

  async openEditTileModel(tile: any, type: string) {
    let tileData: any = {};
    if (tile && type == 'editTiles') {
      tileData = await this.getTileById(tile);
    }
    this.modalRef = this.modalService.open(TilesManagementModelComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-dialog-centered',
      data: {
        modalTitle: type == 'editTiles' ? `Edit ${tileData.title}` : 'Add Tiles',
        tilesModel: type == 'editTiles' ? tileData : new TilesModel(),
      },
    });

    this.modalRef.onClose.subscribe((tiles: any) => {
      if (type == 'addTiles' && tiles != 'close') {
        this.parentComponent.loadingGeneralDetails = true;
        this.saveTile(tiles);
      }
      if (type == 'editTiles' && tiles != 'close') {
        this.parentComponent.loadingGeneralDetails = true;
        this.updateTileById(tiles);
      }
    });
  }

  openDeleteConfirmationModel(index: any, item: any) {
    if (item.tileId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.title}</strong> ?`,
        },
      });

      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          this.parentComponent.loadingGeneralDetails = true;
          this.deleteTileById(item);
        }
      });
    }
  }

  saveTile(tile: any) {
    this.companyService
      .saveNewTile(tile, this.parentComponent.currentCompanyId)
      .pipe(finalize(() => { }))
      .subscribe(async (response: any) => {
        if (response && response.success) {
          await this.parentComponent.getTilesList();
          this.toastrService.showSuccess(response.message);
        }
      });
  }

  getTileById(tile: any) {
    return new Promise(async (resolve: any) => {
      this.companyService
        .getTileById(tile, this.parentComponent.currentCompanyId)
        .pipe(finalize(() => { }))
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data);
          }
        });
    });
  }

  updateTileById(tile: any, sortIds?: any) {
    this.companyService
      .updateTile(tile, this.parentComponent.currentCompanyId, sortIds)
      .pipe(finalize(() => { }))
      .subscribe(async (response: any) => {
        if (response && response.success) {
          await this.parentComponent.getTilesList();
          this.toastrService.showSuccess(response.message);
        }
      });
  }

  deleteTileById(tile: any) {
    this.companyService
      .deleteTile(tile, this.parentComponent.currentCompanyId)
      .pipe(finalize(() => { }))
      .subscribe(async (response: any) => {
        if (response && response.success) {
          this.parentComponent.getTilesList();
          this.toastrService.showSuccess(response.message);
        }
      });
  }

  onDrop(e: MdbDropEvent) {
    if (e.newIndex !== e.previousIndex) {
      moveItemsInContainer(this.tiles, e.previousIndex, e.newIndex);
      if (this.tiles[e.newIndex].tileId) {
        const beforeId = this.tiles[e.newIndex + 1]?.tileId || '';
        const afterId = (e.newIndex - 1) >= 0 ? this.tiles[e.newIndex - 1]?.tileId : '';
        this.updateTileById(this.tiles[e.newIndex], { beforeId: beforeId, afterId: afterId });
      }
    }

  }
} 
