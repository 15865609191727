<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (searchLogEmit)="handleSearchEmit($event)"
        (baseAction)="handleExport('current')">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="listLoading">
        <loader></loader>
    </div>
    <div class="text-center pt-4" *ngIf="!companyStatusList">
    </div>
    <table class="table table-striped pt-5 mb-0" *ngIf="companyStatusList.rows?.length">
        <thead>
            <tr>
                <th scope="col" class="fw-bold pointer ps-3" style="width: 400px" (click)="sort('companyName')">Company
                    <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                        *ngIf=" sortColumn === 'companyName'"></i-bs>
                </th>
                <th scope="col" class="fw-bold pointer  ps-3" (click)="sort('source')">Source
                    <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                        *ngIf=" sortColumn === 'source'"></i-bs>
                </th>
                <ng-container *ngIf="!filterParams">
                    <th scope="col" class="fw-bold pointer ps-3" (click)="sort('currentCount')">Current <br> Employees
                        <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'currentCount'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer ps-3" (click)="sort('previousCount')">Previous <br> Employees
                        <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'previousCount'"></i-bs>

                    </th>
                    <th scope="col" class="fw-bold pointer ps-3" (click)="sort('variance')">Variance(%)
                        <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'variance'"></i-bs>
                    </th>
                </ng-container>
                <ng-container *ngIf="filterParams">
                    <th scope="col" class="fw-bold pointer ps-3" (click)="sort('totalMembers')">Employees
                        <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'totalMembers'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer ps-3" (click)="sort('dependencies')">Dependents
                        <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'dependencies'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer ps-3" (click)="sort('total')">Total
                        <i-bs [name]="sortDirection == 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'total'"></i-bs>
                    </th>
                </ng-container>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let company of companyStatusList.rows | filterKeyword:searchConfig.formValue">
                <td>{{company.clientLegalName}}</td>
                <td>{{company.source}}</td>
                <ng-container *ngIf="!filterParams">
                    <td>{{company.currentCount| number}}</td>
                    <td>{{company.previousCount| number}}</td>
                    <td>{{company.variance}}</td>
                </ng-container>
                <ng-container *ngIf="filterParams">
                    <td>{{company.totalMembers | number}}</td>
                    <td>{{company.dependencies | number}}</td>
                    <td>{{company.total | number}}</td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>