import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { NetworkModel } from '../../../model';

import { NetworkManagementModalComponent, ConfirmationModalComponent } from '../../../modal';
import { NetworkService, ToastrService, HttpCancelService, ExcelService } from '../../../services';
import { CompanyDropdownConstant } from 'src/app/constants';
import { ExcelHeaders } from 'src/app/constants';


@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
  searchKeyword: any = null;
  searchConfig = {
    action: [
      // { label: 'Export', type: 'export', icon: 'download', btnClass: 'btn-clear px-4 pt-2 border'},
      { label: 'Add Network', type: 'addNetwork', icon: 'plus', btnClass: 'btn-gradient' }],
    showAdvancedSearch: false,
    showToggle: true,
    formValue: this.searchKeyword,
    module: 'network',
    limit: 25,
    exportToggle: true
  }
  networkLoading: boolean = true;
  networkList: any = [];
  modalRef: MdbModalRef<NetworkManagementModalComponent | ConfirmationModalComponent> | null = null;
  order = 'networkCode';
  reverse: boolean = false;
  networkGroupList: any;
  dropdownConstants = CompanyDropdownConstant;
  getRequest: any;

  constructor(private modalService: MdbModalService, private networkService: NetworkService, private toastrService: ToastrService,
    private cancelService: HttpCancelService,
    private excelExportService: ExcelService) { }

  ngOnInit(): void {
    this.getNetworkSourceList();

  }


  async handleNetworkEvent(network: any, event: any) {
    switch (event) {
      case 'current':
        this.handleExport();
        break;
      case 'all':
        await this.getNetwork({limit: 0}, 'all');
        break;
      default:
        this.openNetworkMgmtModal(network, event);
    }

  }
  async openNetworkMgmtModal(network: any, action: any) {
    let resNetworkData: any = {};
    if (action == 'editNetwork' && network.id) {
      resNetworkData = await this.getNetworkById(network);
    }
    this.modalRef = this.modalService.open(NetworkManagementModalComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-md modal-dialog-centered',
      data: {
        action: action,
        modalTitle: action === 'addNetwork' ? 'Add Network' : `Edit Network - ${network.networkName}`,
        networkModel: (action == 'editNetwork' && network.id) ? resNetworkData : new NetworkModel(),
        networkGroupList: this.networkGroupList
      }
    })
    this.modalRef.onClose.subscribe((network: any) => {
      if (action === 'addNetwork' && network !== 'close') {
        this.addNetwork(network);
      }
      else if (action === 'editNetwork' && network !== 'close') {
        this.editNetwork(network);
      }
    })
  }

  openConfirmationModal(network: any) {
    this.modalRef = this.modalService.open(ConfirmationModalComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        modalTitle: 'Confirmation',
        modalBody: `Are you sure you want to delete <strong> ${network.networkName}</strong>?`,
      }
    });
    this.modalRef.onClose.subscribe((message: any) => {
      if (message == 'confirmDeactivate') {
        this.deleteNetwork(network);
      }
    })
  }

  getNetwork(keyword?: any, action?:any) {
    this.networkLoading = true;
    this.getRequest = this.networkService.getNetworkList(keyword).pipe(finalize(() => {
      this.networkLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        if(action === 'all') {
          this.handleExport(response.data.rows);
        }
        else {
          this.networkList = response.data;
          this.networkList.rows = this.networkList.rows.map((x: any) => {
            return { ...x, displayTypeName: x.networkType ? this.dropdownConstants.contractBenefitsType.find((y: any) => y.value == x.networkType)?.name : x.networkType }
          })
        }
      }
    })
  }

  addNetwork(networkInfo: any) {
    this.networkService.addNewNetwork(networkInfo).pipe(finalize(() => {

    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getNetworkSourceList();
      }
    })
  }

  editNetwork(networkInfo: any) {
    this.networkService.editNetwork(networkInfo).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getNetworkSourceList();
      }
    })
  }

  deleteNetwork(networkInfo: any) {
    this.networkService.deleteNetwork(networkInfo).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getNetworkSourceList()
      }
    })
  }

  getNetworkSourceList() {
    this.networkService.getNetworkGroup().pipe(finalize(() => {

    })).subscribe((response: any) => {
      if (response && response.success) {
        this.networkGroupList = response.data;
        this.getNetwork();
      }
    })
  }

  searchNetwork(event: any) {
    if (!this.getRequest) {
      this.getNetwork(event);
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getNetwork(event);
    }
  }

  getNetworkById(network: any) {
    return new Promise(async (resolve: any) => {
      await this.networkService.getNetworkById(network).pipe(finalize(() => {
      })).subscribe((response: any) => {
        if (response && response.success) {
          resolve(response.data);
        }
      })
    })
  }

  handleExport(list?:any) {
    if (list || this.networkList.rows?.length) {
      const content = {
        header: ExcelHeaders.AdminNetwork,
        data: list || this.networkList.rows,
        reportTitle: 'Networks',
        fileName: 'Network_Report'
      }
      this.excelExportService.exportExcel(content);
    }
  }
}
