<div class="pt-4 p-0">
  <div class="mt-2 mb-2 advanced-collapse" id="collapseExample" mdbCollapse #basicCollapse="mdbCollapse"
    [collapsed]="false">
    <form #activityLogSearchForm="ngForm" autocomplete="off">
      <div class="p-3">
        <div class="d-flex">
          <div class="col-12 px-2">
            <div class="row">
              <div class="px-2 no-scroll"
                *ngIf="currentModule !== 'dashboard-detailed-report' && currentModule !== 'advocacy-satisfaction-report'"
                [ngClass]="{'col-2' : currentModule == 'tag-report',  'col-3': currentModule !== 'tag-report'}">
                <label for="date" class="form-label">Company</label>
                <ng-select class="form-control p-0" [items]="companyInputList"
                  bindLabel="clientLegalName" [selectableGroupAsModel]="false" style="height: auto !important"
                  [multiple]="multiSelect" [closeOnSelect]="!multiSelect" [searchable]="true"
                  (search)="toggleSelection($event)" placeholder="Select Company" name="company"
                  [(ngModel)]="reportFilterModel.company" (change)="selectCompany($event)" style="min-height: 2.5rem">
                  <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                    let-index="index">
                    <!-- <input type="checkbox" id="{{ item.value }}" name="{{ item.index }}" class="form-check-input"
                      [checked]="item$.selected" *ngIf="multiSelect" name="company" /> -->
                    <label class="ps-2" for="item">{{
                      item.clientLegalName | titleCase
                      }}</label>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-2 px-2 no-scroll" *ngIf="currentModule === 'tag-report'">
                <label for="logType" class="form-label">Tags</label>
                <ng-select class="form-control p-0" [items]="tagDropDownList" bindLabel="name"
                  [selectableGroupAsModel]="false" style="height: auto !important" [multiple]="true"
                  [closeOnSelect]="false" [searchable]="true" placeholder="Select Tags" name="tag"
                  [(ngModel)]="multiTagInput" (change)="selectReportTag($event)" style="min-height: 2.5rem">
                  <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                    let-index="index">
                    <!-- <input type="checkbox" id="{{ item.value }}" name="{{ item.index }}" class="form-check-input"
                      [checked]="item$.selected" name="tag" /> -->
                    <label class="ps-2" for="item">{{
                      item.name | titleCase
                      }}</label>
                  </ng-template>
                </ng-select>
              </div>
              <div class="px-2"
                [ngClass]="{'col-2' : currentModule == 'tag-report',  'col-3': currentModule !== 'tag-report'}">
                <label for="logType" class="form-label">Filter Option</label>
                <ng-select class="form-control p-0" name="createdDate" (change)="handleDateChange($event)"
                  [(ngModel)]="dateType">
                  <ng-option *ngFor="let type of reportingDateTypeList" [value]="type.value">{{ type.label }}
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-2 px-2">
                <label for="date" class="form-label">From</label>
                <div class="input-group input-group-lg">
                  <input mdbInput [mdbDatepicker]="fromDatePicker" type="text" class="form-control"
                    [(ngModel)]="reportFilterModel.from" [disabled]="dateType !== 'dateRange'" id="exampleDatepicker"
                    (click)="fromDatePicker.open()" name="from" autocomplete="off" />
                  <mdb-datepicker-toggle [mdbDatepicker]="fromDatePicker"  [disabled]="dateType !== 'dateRange'"></mdb-datepicker-toggle>
                  <mdb-datepicker #fromDatePicker [inline]="true" [format]="'mm/dd/yyyy'"  [disabled]="dateType !== 'dateRange'"></mdb-datepicker>
                </div>
              </div>
              <div class="col-2 px-2">
                <label for="date" class="form-label">To</label>
                <div class="input-group input-group-lg">
                  <input mdbInput [mdbDatepicker]="toDatePicker" type="text" class="form-control" id="exampleDatepicker"
                    [(ngModel)]="reportFilterModel.to" [disabled]="dateType !== 'dateRange'" name="to"
                    autocomplete="off" (click)="toDatePicker.open()" />
                  <mdb-datepicker-toggle [mdbDatepicker]="toDatePicker" [disabled]="dateType !== 'dateRange'"></mdb-datepicker-toggle>
                  <mdb-datepicker #toDatePicker [inline]="true" [format]="'mm/dd/yyyy'" [disabled]="dateType !== 'dateRange'"></mdb-datepicker>
                </div>
              </div>
              <div class="col-2 pt-4 px-2">
                <div class="row pt-2">
                  <div class="d-flex">
                    <div class="col-6 pe-1">
                      <button class="btn w-100 btn-secondary" (click)="submit()"
                        [disabled]="currentModule === 'tag-report' ? (!this.tagReportModel.company.length || !dateType || !this.tagReportModel?.tag?.length) : 
                        (currentModule === 'dashboard-detailed-report' || currentModule === 'advocacy-satisfaction-report') ?  !dateType : (!dateType && !reportFilterModel.company?.length)">
                        Submit
                      </button>
                    </div>
                    <div class="col-6 ps-1">
                      <button class="btn w-100 btn-clear border" (click)="reset()">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="p-3 pt-0" *ngIf="multiSelect">
      <div class="d-flex" *ngIf="tagReportModel?.company?.length">
        <div class="col-2">
          <p class="mb-0 fw-bold pe-2">Selected {{tagReportModel.company.length > 1 ? 'Companies' : 'Company'}}</p>
        </div>
        <div class="col-10">
          <span class="badge multi-option bg-selected-bg me-2 mb-1" *ngFor="let x of tagReportModel.company">
            <i-bs name="x" class="badge-x-icon pointer" (click)="deleteMultiple(x)"></i-bs>
            <span class="px-2">{{x.clientLegalName}}</span></span>
        </div>
      </div>
      <div class="d-flex" *ngIf="tagReportModel?.tag?.length">
        <div class="col-2">
          <p class="mb-0 fw-bold pe-2">Selected {{tagReportModel.tag.length > 1 ? 'Tags' : 'Tag'}}</p>
        </div>
        <div class="col-10">
          <span class="badge multi-option bg-selected-bg me-2 mb-1" *ngFor="let x of tagReportModel.tag">
            <i-bs name="x" class="badge-x-icon pointer" (click)="deleteMultiple(x)"></i-bs>
            <span class="px-2">{{x.name}}</span></span>
        </div>
      </div>
    </div>
  </div>
  <div class="alert alert-warning rounded-0 p-3 d-flex align-items-center mt-3" *ngIf="showNoPlanMsg" role="alert">
    <i-bs name="exclamation-triangle-fill" class="pe-2"></i-bs><label class="fs-1 pt-1"> Plan information
      missing.</label>
  </div>
  <!-- <div class="d-flex justify-content-end pt-2">
    <div class="d-flex" *ngIf="reportFilterConfig.keywordSearch">
      <div class="md-form pe-3">
        <div class="input-group input-group-lg mb-3">
          <span class="input-group-text" id="basic-addon3">
            <i-bs name="search"></i-bs>
          </span>
          <input mdbInput type="text" class="form-control position-relative pe-4" id="username" placeholder="Search"
            aria-describedby="basic-addon3" name="search" />
          <i-bs name="x" class="position-absolute" style="right: 7px; top: 7px; cursor: pointer"></i-bs>
        </div>
      </div>
    </div>
    <div class="d-flex ">
      <div class="" *ngIf="reportFilterConfig.showLimit">
        <div class="mb-3 limit-input">
          <ng-select class="form-control p-1" style="background: transparent !important" name="itemsPerPage"
            [searchable]="false">
            <ng-option [value]="10" selected>Show 10</ng-option>
            <ng-option [value]="25">Show 25</ng-option>
            <ng-option [value]="50">Show 50</ng-option>
            <ng-option [value]="100">Show 100</ng-option>
          </ng-select>
        </div>
      </div>
      <div *ngIf="reportFilterConfig.action">
        <button type="button" class="btn ms-3" *ngFor="let x of reportFilterConfig.action" [ngClass]="{
              'btn-clear px-4 pt-2 border': x.btnClass,
              'btn-gradient': !x.btnClass
            }" (click)="onBaseAction(x.type)">
          <i-bs [name]="x.icon" class="pe-2"></i-bs>{{ x.label }}
        </button>
      </div>
    </div>
  </div> -->
</div>