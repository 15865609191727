<div class="col-12 p-2">
  <div class="col">
    <div class="col-12">
      <app-report-header (baseAction)="handleBaseAction($event)"></app-report-header>
    </div>
    <div class="pt-5 mt-5" *ngIf="loading">
      <loader></loader>
    </div>
    <div *ngIf="!loading && reportContent">
      <div class="d-flex justify-content-end pt-2">
        <div class="d-flex">
          <div>
            <button type="button" class="btn btn-clear px-4 pt-2 border ms-3" (click)="printPDF('print')">
              <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true" *ngIf="printing"></span>
              <i-bs name="printer-fill" *ngIf="!printing" class="pe-2"></i-bs>Print
            </button>
            <button type="button" class="btn btn-gradient ms-3" (click)="handleSendReport()">
              <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true" *ngIf="sending"></span>
              <i-bs name="send" class="pe-2" *ngIf="!sending"></i-bs>Send
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div style="width:1170px">
          <div class="col p-3 mt-3 mb-3 advanced-collapse" id="savings" *ngIf="reportContent">
            <div style="min-height:calc(100vh - 70px);">
              <div class="col-12 pb-3">
                <div class="d-flex align-items-center justify-content-between p-3 pt-0">
                  <div class="d-flex align-items-center">
                    <img *ngIf="filterParams.company?.logo?.filePath"
                      [src]="sanitizer.bypassSecurityTrustResourceUrl(filterParams.base64 || filterParams.company?.logo?.filePath)" alt=""
                      class="img-fluid pe-3" alt="" style="height: 30px" />
                    <img *ngIf="!filterParams.company?.logo?.filePath" src="../../../../assets/images/login-logo.png"
                      alt="" class="img-fluid pe-3" alt="" style="height: 30px" />
                    <div class="vertical-line ps-3 ms-3 d-flex align-items-center">
                      <h5 class="fw-bold ps-3 mb-0">{{filterParams.company.clientLegalName}}</h5>
                    </div>
                  </div>
                  <div class="">
                    <h6 class="fw-bold m-0">Utilization and Savings Reports</h6>
                    <p class="m-0">Report Period: {{filterParams.from | date: 'MM/dd/yyyy'}} to {{filterParams.to |
                      date:
                      'MM/dd/yyyy'}}</p>
                    <p class="m-0">{{planPeriod}} </p>
                  </div>
                </div>
                <hr class="m-0" />
              </div>
              <!-- ----------------Utilization------------------- -->
              <div class="col-12 pb-2 pt-3">
                <div class="d-flex align-items-center pb-4">
                  <i-bs name="people-fill" class="text-gray-main fs-3 me-2" width="32" height="32"></i-bs>
                  <h4 class="fw-bold text-gray-main m-0">Utilization</h4>
                </div>
                <div class="col-12 d-flex">
                  <div class="col-6 pe-2 ">
                    <div class="bg-gray-lighter border-top border-4 border-secondary rounded-1">
                      <p class="fw-bold text-capitalize p-2 mb-0">
                        Total Number of Interaction Received {{getDateLabel(filterParams.dateType)}}
                      </p>
                      <div class="chart ut-block">
                        <div class="col-12 p-3">
                          <div class="d-flex">
                            <div class="col-1"></div>
                            <div class="col-3">
                              <h1 class="fw-bold m-0">{{reportContent?.callsData?.totalCalls}}</h1>
                              <p class="fs-2 m-0">Total Interactions</p>
                            </div>
                            <div class="col-1">
                              <h1 class="fw-bold m-0">=</h1>
                            </div>
                            <div class="col-3">
                              <h1 class="fw-bold m-0">{{reportContent?.callsData?.totalCn}}</h1>
                              <p class="fs-2 m-0">Care Navigation</p>
                            </div>
                            <div class="col-1">
                              <h1 class="fw-bold m-0">+</h1>
                            </div>
                            <div class="col-3">
                              <h1 class="fw-bold m-0">{{reportContent?.callsData?.totalBs}}</h1>
                              <p class="fs-2 m-0">Benefit Support</p>
                            </div>
                          </div>
                        </div>
                        <canvas mdbChart [type]="'bar'" [datasets]="reportContent?.callCanvasData?.data"
                          [labels]="reportContent.callCanvasData.months" [options]="savingChartOption" class="p-3 pt-0"
                          style="max-height: 335px;"></canvas>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 ps-2">
                    <div class="bg-gray-lighter border-top border-4 border-primary rounded-1">
                      <p class="fw-bold mb-0 p-2">
                        Our service used PLAN YEAR TO DATE ({{getYTDDate() | date:'MM/dd/yyyy'}} -
                        {{format(filterParams.to)}})
                      </p>
                      <div class="col-12">
                        <div class="ut-block">
                          <div class="p-3">
                            <div class="d-flex justify-content-between">
                              <div class="d-flex">
                                <i-bs name="people-fill" class="text-primary fs-3 pt-1 pe-3" width="32"
                                  height="32"></i-bs>
                                <div>
                                  <h1 class="fw-bold">{{reportContent?.savingsData?.runningDistinctUser}}</h1>
                                  <p class="text-center fw-bold fs-2 text-gray-main">Total Unique Users</p>
                                </div>
                              </div>
                              <div class="d-flex">
                                <i-bs name="people-fill" class="text-secondary fs-3 pt-1 pe-3" width="32"
                                  height="32"></i-bs>
                                <div>
                                  <h1 class="fw-bold">{{reportContent?.savingsData?.totalEmployees | number}}</h1>
                                  <p class="text-center fw-bold fs-2 text-gray-main">Total Employees</p>
                                </div>
                              </div>
                            </div>
                            <div class="progress" style="height: 30px">
                              <div class="progress-bar bg-primary" role="progressbar"
                                [style.width.%]="reportContent?.savingsData?.utilization" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100">
                              </div>
                            </div>
                            <div class="d-flex align-items-center pt-3">
                              <h6 class="text-primary pe-2 mb-0 fw-bold">{{reportContent?.savingsData?.utilization |
                                number:
                                '1.0-2'}}%</h6>
                              <p class="mb-0 fw-bold">Utilization - YTD</p>
                            </div>
                          </div>
                          <div class="bg-gray-light rounded-bottom p-3 pb-0" *ngIf="reportContent.reasonCode"
                            style="height: 232px;">
                            <div class="">
                              <p class="fw-bold">Below are the top reason codes PLAN YTD:</p>
                            </div>
                            <div class="d-flex">
                              <div class="col-6">
                                <div class="p-1 px-2 border-start border-4 border-bar-color">
                                  <p class="mb-0">Care Navigation</p>
                                </div>
                                <div class="px-2 pt-2">
                                  <ol class="list-group-numbered p-0">
                                    <li class="list-group-item m-0 fw-light"
                                      *ngFor="let c of reportContent?.reasonCode?.cnCodes">{{c.callRegarding |
                                      titleCase}}
                                    </li>
                                  </ol>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="p-1 px-2 border-start border-4 border-secondary">
                                  <p class="mb-0">Benefit Support</p>
                                </div>
                                <div class="px-2 pt-2">
                                  <ol class="list-group-numbered p-0">
                                    <li class="list-group-item m-0 fw-light"
                                      *ngFor="let c of reportContent?.reasonCode?.bsCodes">{{c.callRegarding |
                                      titleCase}}
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pt-2" *ngIf="!reportContent.reasonCode">
                            <div class="bg-primary border border-primary tat-block p-2">
                              <div class="d-flex align-items-center text-white p-4">
                                <!-- <embed src="../../../../assets/images/Improve.svg" style="height:32px; width: 32px;" /> -->
                                <i-bs name="lightbulb-fill" width="32" height="32"></i-bs>
                                <h5 class="fw-bold m-0 ps-3 ">Ways to Improve</h5>
                              </div>
                              <div class="pt-3">
                                <ol class="text-white">
                                  <li *ngFor="let w of reportContent?.reportConstants?.waysToImprove">{{w}}</li>
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ---------------saving----------------- -->
              <div class="col-12 pt-4 pb-2">
                <div class="d-flex align-items-center pb-4">
                  <i-bs name="currency-dollar" class="text-gray-main fs-3 me-2" width="32" height="32"></i-bs>
                  <h4 class="fw-bold text-gray-main m-0">Savings</h4>
                </div>
                <div class="d-flex">
                  <div class="col-4 pe-2">
                    <div class="border-top border-4 border-secondary rounded-1">
                      <div class="bg-gray-lighter sv-block ">
                        <p class="fw-bold  text-capitalize p-3 mb-0">Total Savings From
                          {{getDateLabel(filterParams.dateType)}}
                        </p>
                        <div class="p-3">
                          <div class="d-flex align-items-center justify-content-between">
                            <p>Total Savings Offered</p>
                            <h6 class="fw-bold">${{(reportContent?.savingsData?.possibleSavings || 0) |number}}</h6>
                          </div>
                          <div class="d-flex align-items-center justify-content-between">
                            <p>Total Lost Opportunity</p>
                            <h6 class="fw-bold text-gray-main">-${{(reportContent?.savingsData?.potentialLoss || 0)
                              |number}}
                            </h6>
                          </div>
                          <div class="d-flex align-items-center justify-content-between">
                            <p class="mb-0">Total Incentive Paid</p>
                            <h6 class="fw-bold text-gray-main">-${{(reportContent?.savingsData?.incentivesPaid ||
                              0)|number}}
                            </h6>
                          </div>
                          <hr class="mt-1">
                          <div class="d-flex align-items-center justify-content-between">
                            <p class="fw-bold">Total Net Savings</p>
                            <h4 class="fw-bold fs-4 text-secondary">${{(reportContent?.savingsData?.netSavings || 0)
                              |number}}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8 ps-2">
                    <div class="border-4 border-primary border-top rounded-1">
                      <div class="bg-gray-lighter sv-block p-3">
                        <p class="fw-bold pb-3 mb-0">Total Savings From PLAN YEAR TO DATE ({{getYTDDate() |
                          date:'MM/dd/yyyy'}}
                          -
                          {{format(filterParams.to)}})</p>
                        <div class="col-12 d-flex">
                          <div class="col-4">
                            <div class="pb-3">
                              <div class="p-3 rounded-2 bg-primary text-white-bg" style="height: 104px !important;">
                                <p class="mb-2">Offered Savings - YTD</p>
                                <h3 class="fw-bold">${{reportContent?.ytdData?.totalOfferedSavingsYTD | number:"1.0-0"}}
                                </h3>
                              </div>
                            </div>
                            <div class="pb-3">
                              <div class="p-3 rounded-2 bg-gray-light" style="height: 104px !important;">
                                <p class="mb-2 text-gray-main">Lost Opportunities - YTD</p>
                                <h3 class="fw-bold text-gray-main">${{reportContent?.ytdData?.totalOfferedSavingsYTD -
                                  reportContent?.ytdData?.totalActualSavingsYTD -
                                  reportContent?.ytdData?.totalIncentiveYTD
                                  |
                                  number:"1.0-0"}}</h3>
                              </div>
                            </div>
                            <div class="">
                              <div class="p-3 rounded-2 bg-secondary text-white-bg" style="height: 104px !important;">
                                <p class="mb-2">Net Savings - YTD</p>
                                <h3 class="fw-bold">${{reportContent?.ytdData?.totalActualSavingsYTD | number:"1.0-0" }}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <div class="">
                              <canvas mdbChart [type]="'bar'" [datasets]="reportContent?.ytdCanvasData?.data"
                                [labels]="reportContent.ytdCanvasData.months" [options]="savingChartOption"
                                class="p-2 mt-4" style="max-height: 320px;"></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-lighter note-block p-3 mt-3">
                  <div class="d-flex">
                    <div class="col-6 border-end border-2 border-gray-light">
                      <div class="d-flex">
                        <p class="fs-3 "><label class="fw-bold fs-3 "> Total Offered Savings :</label> Savings from
                          all options
                          presented to
                          members.</p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-0 fs-3"><label class="fw-bold fs-3">Total Incentive Paid :</label> Incentives paid
                          from
                          options
                          used by
                          members.</p>
                      </div>
                    </div>
                    <div class="col-6 ps-3">
                      <div class="d-flex">
                        <p class="fs-3"><label class="fw-bold fs-3"> Total Lost Opportunities :</label> Total
                          saving from
                          unused options.
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="mb-0 fs-3"><label class="fw-bold fs-3">Total Net Savings : </label> Offered savings
                          minus
                          incentives
                          paid minus
                          Lost Opportunities.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ----------------------Turn Around Time------------- -->
              <div class="col-12">
                <div class="d-flex align-items-center py-4">
                  <i-bs name="clock-history" class="text-gray-main fs-3 me-2" width="32" height="32"></i-bs>
                  <h4 class="fw-bold text-gray-main m-0">TAT (Turn Around Time)</h4>
                </div>
                <div class="col-12 d-flex">
                  <div class="col-4 pe-2">
                    <div
                      class="bg-gray-lighter tat-block p-3 text-center border-4 border-secondary border-top rounded-1">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex align-items-center position-relative" style="height: 150px; width: 150px;">
                          <canvas mdbChart [type]="'doughnut'" [datasets]="tatChartConst.dataset"
                            [options]="tatOptConst"></canvas>
                          <h4 class="fw-bold tat-label position-absolute">{{reportContent?.savingsData?.tatScore}} <br>
                            Days
                          </h4>

                        </div>

                      </div>
                      <p class="text-center text-secondary pt-3 fs-2">
                        {{reportContent?.reportConstants?.tatNote ? '*' + reportContent?.reportConstants?.tatNote :
                        reportContent?.reportConstants?.tatNote}}
                      </p>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="bg-gray-lighter tat-block border-4 border-primary border-top rounded-1 p-3">
                      <div class="pb-3">
                        <p class="fw-bold m-0">{{reportContent?.reportConstants?.toName ?
                          (reportContent?.reportConstants?.toName + ',') : reportContent?.reportConstants?.toName}}</p>
                        <p class="fs-2">
                          {{reportContent?.reportConstants?.reportContent}}
                        </p>
                      </div>
                      <div class="pb-3">
                        <p class="fw-bold mb-1">Account Management Team</p>
                        <div class="d-flex align-items-center  mb-1">
                          <i-bs name="envelope-fill" class="text-primary"></i-bs>
                          <p class="m-0 ps-2 fs-2">AccountManagement@alithias.com</p>
                        </div>

                        <div class="d-flex align-items-center">
                          <i-bs name="phone-fill" class="text-primary"></i-bs>
                          <p class="m-0 ps-2 fs-2">855 843-8783</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 ps-2">
                    <div class="bg-primary border border-primary tat-block p-2">
                      <div class="d-flex align-items-center text-white p-4">
                        <!-- <embed src="../../../../assets/images/Improve.svg" style="height:32px; width: 32px;" /> -->
                        <i-bs name="lightbulb-fill" width="32" height="32"></i-bs>
                        <h5 class="fw-bold m-0 ps-3 ">Ways to Improve</h5>
                      </div>
                      <div class="pt-3">
                        <ol class="text-white">
                          <li *ngFor="let w of reportContent?.reportConstants?.waysToImprove">{{w}}</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr />
                </div>
              </div>
            </div>

            <!-- ----------Footer-------------------------------- -->
            <div class="d-flex align-items-center justify-content-between pt-2">
              <p class="m-0">Copyright © {{thisYear}}. All Rights Reserved.
                {{getEligibilityMonth(reportContent?.eligibilityDate)}}</p>
              <p class="m-0">Report Run Date: {{today}}</p>
              <div class="d-flex align-items-center">
                <p class="m-0 me-3">Powered by</p>
                <div>
                  <img src="../../../../assets/images/login-logo.png" class="img-fluid" alt="" style="height: 30px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="pdf" class="d-none">
  <app-report-pdf-format [id]="'incentive'" [reportConstants]="incentiveReportConfig?.reportConstants"
    [reportData]="incentiveReportConfig?.dataList" [filterParams]="filterParams"></app-report-pdf-format>
  <app-report-pdf-format [id]="'savings-detailed'" [reportConstants]="savingsDetailedReportConfig?.reportConstants"
    [reportData]="savingsDetailedReportConfig?.dataList" [filterParams]="filterParams"></app-report-pdf-format>
</div>