<div [formGroup]="directPrimaryCareForm" class="pt-4 general">
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <p class="fw-bold pt-3 ps-2 pe-2">Direct Primary Care Clinic</p>
      <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingDirectPrimaryCare">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <button type="button" class="btn btn-primary" (click)="addInfoForms(directPrimaryCareForm, 'directPrimaryInfo')">
      <i-bs name="plus" class="pe-1"></i-bs> Add New Clinic
    </button>
  </div>
  <div formArrayName="directPrimaryInfo">
    <div *ngIf="!getFormArray(directPrimaryCareForm, 'directPrimaryInfo').length">
      <p class="text-center">No direct primary care clinic.</p>
    </div>
    <div *ngFor="
        let item of getFormArray(directPrimaryCareForm, 'directPrimaryInfo')
          .controls;
        let i = index
      " [formGroupName]="i">
      <div class="col-12 pb-4">
        <button
          class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
          type="button" (click)="preferredProviderCollapse.toggle()"
          [attr.aria-expanded]="!preferredProviderCollapse.collapsed" aria-controls="collapseExample">
          <span  class="text-capitalize">
            <i-bs [name]="
                !preferredProviderCollapse.collapsed
                  ? 'chevron-down'
                  : 'chevron-up'
              " class="pe-1"></i-bs>
            {{ item.get("clinicName")?.value || "New Clinic Details" }}
          </span>
          <span>
            <i-bs name="trash-fill" class="pull-right text-gray-main" (click)="
              openConfirmationModal(i, item, 'directPrimaryInfo', directPrimaryCareForm)
              "></i-bs>
          </span>
        </button>
      </div>
      <div class="pb-3" id="collapseExample" mdbCollapse #preferredProviderCollapse="mdbCollapse" [collapsed]="false">
        <div class="px-2 collapse-container">
          <div class="col-12 d-flex justify-content-between">
            <div class="col-6">
              <div class="d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Clinic Name</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="clinicName" formControlName="clinicName"
                    name="clinicName" />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex">
                <label for="name" class="pt-0 col-5 text-end pe-3">Clinic Details</label>
                <div class="col-7">
                  <textarea mdbInput class="form-control" id="textAreaExample" rows="3" formControlName="clinicDetails"
                    name="clinicDetails"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2">
          <table class="table table-reponsive">
            <thead>
              <tr>
                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                  Address
                </th>
                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                  City
                </th>
                <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                  State
                </th>
                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                  Zip
                </th>
                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                  Phone
                </th>
                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                  Email
                </th>
                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                  Fax
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody formArrayName="addressDetail">
              <tr *ngIf="!getFormArray(item, 'addressDetail').length">
                <th colspan="6" class="fw-bold text-center">No address info</th>
              </tr>
              <tr *ngFor="
                  let contactForm of getFormArray(item, 'addressDetail')
                    .controls;
                  let i = index
                " [formGroupName]="i">
                <td>
                  <input type="text" class="form-control" id="addressLine" formControlName="addressLine"
                    name="addressLine" />
                </td>
                <td>
                  <input type="text" class="form-control" id="city" formControlName="city" name="city" />
                </td>
                <td>
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                    formControlName="state" name="state">
                    <ng-option *ngFor="
                        let option of parentComponent.statesList
                          | sort:'asc':'value'
                      " value="{{ option.value }}">
                      {{ option.value }}</ng-option>
                  </ng-select>
                </td>
                <td>
                  <input type="text" class="form-control" id="zip" formControlName="zip" name="zip" />
                </td>
                <td>
                  <input type="text" class="form-control" id="phone" formControlName="phone" name="phone"
                    mask="(000) 000-0000" />
                </td>
                <td>
                  <input type="text" class="form-control" id="email" formControlName="email" name="email"
                    [pattern]="parentComponent.mailformat" />
                </td>
                <td>
                  <input type="text" class="form-control" id="fax" formControlName="fax" name="fax"
                    mask="(000) 000-0000" />
                </td>
                <td>
                  <!-- <i-bs name="trash-fill"></i-bs> -->

                  <i-bs *ngIf="!contactForm.value.loading" name="trash-fill" class="pointer text-gray-main"
                    (click)="delete(i, contactForm.value, 'addressDetail', item)"></i-bs>
                  <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                    *ngIf="contactForm.value.loading">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <a class="text-primary pointer" type="button" (click)="addAddressForms(item, 'addressDetail')"><i-bs
              name="plus"></i-bs>Add More</a>
          <hr class="mt-5" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end pb-3"
      *ngIf="getFormArray(directPrimaryCareForm, 'directPrimaryInfo').length">
      <button type="button" class="btn btn-primary" (click)="saveForms()" [disabled]="!directPrimaryCareForm.valid">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </div>
</div>