import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { SendReportComponent } from 'src/app/modal';
import { PdfService, ReportService, EmailService, ToastrService } from 'src/app/services';
import { DateCalc, Sanitizer } from '../../../utils';
import { environment } from '../../../../environments/environment';
import { PhoneFormatPipe } from 'src/app/pipe';
@Component({
  selector: 'app-care-option-report',
  templateUrl: './care-option-report.component.html',
  styleUrls: ['./care-option-report.component.scss'],
})
export class CareOptionReportComponent {
  currentYear = new Date().getFullYear();
  caseId: any = null;
  reportContent: any = null;
  modalRef: MdbModalRef<SendReportComponent> | null = null;
  printing: boolean = false;
  templateList: any = [];
  reportLoading: boolean = true;
  generating: boolean = false;
  fileList: any = [];
  filesLoading: boolean = false;
  userId: any;
  bccUser: any;
  vitafyUrl = environment.Vitafy_URL;
  faxView: boolean = false;

  constructor(
    private pdfService: PdfService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private modalService: MdbModalService,
    private emailService: EmailService,
    public sanitizer: DomSanitizer,
    private sanitizerUtil: Sanitizer,
    private phoneFormatPipe: PhoneFormatPipe,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      this.caseId = this.sanitizerUtil.sanitizeParams(params['caseId']);
      this.userId = this.sanitizerUtil.sanitizeParams(params['userId']);
      this.bccUser = this.sanitizerUtil.sanitizeParams(params['bcc']);
    });
    this.getCareReport();
  }

  async printReport(type: any) {
    return new Promise(async (resolve) => {
      this.printing = type === 'print';
      let careOptionPdf: any = await this.pdfService.captureCareReportScreen(
        this.reportContent.providerOptions,
        ''
      );
      // let careOptionBase64 = await this.changefileContentToBase64(careOptionPdf.output('bloburl'));
      if (type == 'send') {
        let pdfString = careOptionPdf.output('datauristring');
        resolve({
          filename: `${this.reportContent?.patientInfo?.patientName}-Care Option Report-${DateCalc.reportFormating(new Date())}-${this.caseId}` + '.pdf',
          content: pdfString ? pdfString.split(',')[1] : '',
          encoding: 'base64',
          isNewCareReport: true,
        });
       
      } else {
        window.open(careOptionPdf.output('bloburl'), '_blank');
        this.printing = false;
        resolve(1);
      }
    });
  }

  getCareReport() {
    this.reportService
      .getCareOptionReport(this.caseId, 'standalone')
      .pipe(
        finalize(() => {
          this.reportLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.reportContent = response.data;
          this.reportContent.companyLogo
            ? (this.reportContent.companyLogo.filePath = this.reportContent.companyLogo?.filePath ? this.reportContent.companyLogo?.fileName ? `data:image/png;base64,${this.reportContent.companyLogo.filePath}`
              : `data:image/${this.reportContent.companyLogo?.fileName.split('.')[
              length - 1
              ]
              };base64,${this.reportContent.companyLogo.filePath}`
              : null)
            : null;
          this.reportContent?.providerOptions?.forEach((x: any) => {
            x.costs?.forEach((y: any) => {
              x.costlabel =
                (x.serviceOption?.includes('Direct Contract') || x.serviceOption?.includes('TPA/Network Bundle')
                  || x.serviceOption?.includes('Episode of Care'))
                  ? 'Bundled Cost'
                  : x.serviceOption?.toLowerCase() == 'cash'
                    ? 'Cash Payment Cost'
                    : 'Est Avg Cost';
              x.costValue =
                y.type?.toLowerCase() !== 'StateAvg' ? y.amount : 0;
            });
            if (x.customMessage?.length) {
              x.customMessage?.forEach((c: any) => {
                c.message = c.message.replace(
                  /@WhiteLabelPhone/g,
                  this.phoneFormatPipe.transform(this.reportContent.phone)
                );
                c.message = c.message.replace(
                  /@PatientName/g,
                  this.reportContent.patientInfo.patientName
                );
                c.message = c.message.replace(
                  /@PatientPhoneNumber/g,
                  this.phoneFormatPipe.transform(this.reportContent.patientInfo.tel)
                );
                c.message = c.message.replace(
                  /@CompanyName/g,
                  this.reportContent.employerInfo.clientLegalName
                );
                c.message = c.message.replace(
                  /@MemberServicePhone/g,
                  this.phoneFormatPipe.transform(this.reportContent.phone)
                );
              });
            }
          });
          this.reportContent.providerOptions =
            this.reportContent?.providerOptions?.sort((a: any, b: any) =>
              a.name.localeCompare(b.name)
            );
          this.reportContent.patientInfo.cell = this.reportContent?.patientInfo?.tel?.find((x: any) => x.type.toLowerCase() == 'cell')?.value;
          this.reportContent.patientInfo.homePhone = this.reportContent?.patientInfo?.tel?.find((x: any) => x.type.toLowerCase() == 'home')?.value;
          this.reportContent.patientInfo.workPhone = this.reportContent?.patientInfo?.tel?.find((x: any) => x.type.toLowerCase() == 'work')?.value;
          this.reportContent.employerInfo.corporateClinicInfo = this.reportContent.employerInfo?.corporateClinicInfo?.length ? this.reportContent.employerInfo?.corporateClinicInfo?.filter((x:any)=>x.addressDetail[0]?.fax): []  ;
        }
      });
  }

  async openSendReportModal() {
    await this.getFileList();
    this.modalRef = this.modalService.open(SendReportComponent, {
      modalClass: 'modal-lg modal-dialog-centered modal-dialog-scrollable',
      ignoreBackdropClick: true,
      data: {
        careReportTemplateList: this.templateList,
        modalTitle: 'Care Option',
        reportContent: { ...this.reportContent, caseId: this.caseId },
        fileList: this.fileList,
        attachments: [], //attachmentContent
        caseId: this.caseId,
        userId: this.userId,
        bccUser: this.bccUser,
      },
    });
    let attachmentContent:any;
    let faxAttachment:any
    if (this.reportContent.employerInfo?.corporateClinicInfo.length && this.reportContent.employerInfo?.corporateClinicInfo.some((x: any) => x.addressDetail[0]?.fax)) {
      this.faxView = true;
      faxAttachment=[await this.printReport('send')];
      faxAttachment[0].filename= `${this.reportContent?.patientInfo?.patientName}-Care Option Report Fax-${DateCalc.reportFormating(new Date())}-${this.caseId}` + '.pdf'
      this.faxView = false;
    }
    this.modalRef.onClose.subscribe(async (event: any) => {
      if ( event && event?.to) {
        attachmentContent = event.faxNumber ? [...faxAttachment, await this.printReport('send')] : [await this.printReport('send')];
        this.sendCareReport(event, attachmentContent);
      }
      else{
        this.generating =false;
      }
    });
  }

  handleSendReport() {
    this.generating = true;
    this.emailService
      .getCareReportTemplateList('standalone')
      .pipe(finalize(() => { }))
      .subscribe((response: any) => {
        if (response && response.success) {
          this.templateList = response.data.rows;
          this.templateList = this.templateList.filter(
            (x: any) => x.ICode == 'CO'
          );
          this.openSendReportModal();
        }
      });
  }

  getFileList() {
    return new Promise(async (resolve) => {
      this.filesLoading = true;
      this.emailService
        .getCaseFileList(this.caseId, 'standalone')
        .pipe(
          finalize(() => {
            this.filesLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            this.fileList = response.data;
            this.fileList = this.fileList.map((x: any) => {
              return { ...x, selected: false };
            });
            resolve(this.fileList);
          } else {
            resolve([]);
          }
        });
    });
  }

  sendCareReport(sendFormValue:any, attachments:any) {
    let params = {
      ...sendFormValue, attachments: [...attachments, ...this.fileList.filter((x: any) => x.selected)],
      patientPhone: sendFormValue.notifyByText ? this.reportContent?.patientInfo?.tel : null, caseId: this.reportContent?.caseId || this.caseId,
      patientName :  this.reportContent?.patientInfo?.patientName,
      userId: this.userId
    };
    params.attachments = params.attachments?.map((x: any) => {
      return {
        fileName: x.filename || x.name,
        fullpath: x.fullPath || null,
        contentType: x.contentType || x.encoding,
        isNewCareReport: x.isNewCareReport || false,
        content: x.content || null,
        size: x.size || null,
        path:x.path || null
      }
    })
    this.reportService.sendCareReport(params, 'standalone').pipe(finalize(()=>{
      this.generating = false;
    })).subscribe((response:any)=>{
      if(response && response.success) {
        this.toastrService.showSuccess('Email sent successfully.');
          window.parent.postMessage(
          {
            type: 'reportSent',
            success: true,
          },
          this.vitafyUrl // Replace with the actual origin of your parent window
        );
      }
    })
  }
}
