import { OnInit, Component } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { AlertsModel } from "../../model";

@Component({
  selector: "app-alerts-management-model",
  templateUrl: 'alerts-management-modal.component.html',
  styleUrls: ['alerts-management-modal.component.scss']
})
export class AlertsManagementModelComponent  implements OnInit{

  alerts: Array<any>;
  alertsModel: AlertsModel = new AlertsModel()
  alertToggle:Boolean = false
  modalTitle: string = ''
  constructor(public modalRef: MdbModalRef<AlertsManagementModelComponent> ){

  }

  ngOnInit(): void {
      if(this.alertsModel.alert){
        this.alertToggle = this.alertsModel.alert;
      }else{
        this.alertsModel.alert  = this.alertToggle 
      }
  }


  onSave(){
    this.modalRef.close(this.alertsModel)
  }

  chnageAlert(){
    this.alertsModel.alert = !this.alertToggle
    this.alertToggle = !this.alertToggle
  }
}