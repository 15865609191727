import { Component } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { CustomMessagingReportModalComponent } from 'src/app/modal';
import { CustomMessageService, HttpCancelService, ToastrService } from '../../../services';
import { CustomMessagingModel } from '../../../model';
import { finalize } from 'rxjs';
import { ToastrComponent } from 'src/app/common';

@Component({
  selector: 'app-custom-report-messaging',
  templateUrl: './custom-report-messaging.component.html',
  styleUrls: ['./custom-report-messaging.component.scss']
})
export class CustomReportMessagingComponent {

  modalRef: MdbModalRef<CustomMessagingReportModalComponent> | null = null;

  searchKeyword: any = '';
  searchConfig = {
    action: [
      { label: 'Add Message', type: 'addMessage', icon: 'plus', btnClass: 'btn-gradient' }],
    showAdvancedSearch: false,
    formValue: this.searchKeyword,
    module: 'crm',
    limit: 25
  }
  crmList: any = [];
  listLoading: boolean = true;
  crmById : any;
  getRequest: any;

  constructor(private modalService: MdbModalService,
    private customMessageService: CustomMessageService,
    private toastrServive: ToastrService,
    private cancelService: HttpCancelService,
    ) { }

  ngOnInit() {
    this.getCRMList();
  }

  async manageCrm(action: any, crm?: any) {
    if(crm?.id) {
      crm.processing = true;
      await this.getCrmById(crm);
    }
    this.modalRef = this.modalService.open(CustomMessagingReportModalComponent, {
      modalClass: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        action: action,
        modalTitle: action === 'addCrm' ? 'Add Message' : `Edit Message`,
        crmModel: action === 'addCrm' ? new CustomMessagingModel() :( this.crmById || new CustomMessagingModel()) ,
      }
    })
    this.modalRef.onClose.subscribe((event: any) => {
      if (action === 'addCrm' && event !== 'close') {
        this.addNewCrm(event);
      }
      else if (action === 'editCrm' && event !== 'close') {
        this.editCrm(event);
      }
    })
  }

  searchCrm(event: any) {
    this.listLoading = true;
    if (!this.getRequest) {
      this.getCRMList(event);
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getCRMList(event);
    }
  }

  getCRMList(event?:any) {
    this.customMessageService.getCrmList(event).pipe(finalize(() => {
      this.listLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.crmList = response.data.rows;
        this.crmList = this.crmList.map((x:any)=>{return {...x, processing:false}})
      }
    })

  }

  getArrayLabel(arr: any, field?: any) {
    if (arr?.length) {
      return arr.map((x: any) => field ? x[field] : x).join(' | ')
    }
    else return null
  }

  addNewCrm(crmModel:any) {
    this.listLoading = true;
    this.customMessageService.saveNewCrm(crmModel).pipe(finalize(() => {
      this.listLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.getCRMList();
        this.toastrServive.showSuccess(response.message)
      }
    })

  }

  getCrmById(crm:any) {
    return new  Promise((resolve:any)=>{
      this.customMessageService.getCrmById(crm.id).pipe(finalize(() => {
        crm.processing = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.crmById = response.data;
          this.crmById.effectiveStartDate = new Date(this.crmById.effectiveStartDate);
          this.crmById.effectiveEndDate = this.crmById.effectiveEndDate ? new Date(this.crmById.effectiveEndDate) : null;
          resolve(true);
        }
      })
    })

  }

  deleteCrm(crm:any) {
    crm.processing = true;
    this.customMessageService.deleteCrm(crm).pipe(finalize(() => {
      crm.processing = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.getCRMList();
        this.toastrServive.showSuccess(response.message)
      }
    })
  }

  editCrm(crm:any) {
    crm.processing = true;
    this.customMessageService.editCrm(crm).pipe(finalize(() => {
      crm.processing= false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.getCRMList();
        this.toastrServive.showSuccess(response.message)
      }
    })
  }
}
