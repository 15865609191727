import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbStepperComponent } from 'mdb-angular-ui-kit/stepper';
import { finalize } from 'rxjs';

import { UserService, ToastrService } from '../../services';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {
  @ViewChild('stepper') stepper!: MdbStepperComponent;

  validPassword = {
    islengthValid: false,
    hasCapital: false,
    hasNumber: false,
    hasSpecialChr: false,
    isSame: false
  }
  password: any;
  confirmPassword: any;
  isActivate: boolean;
  isValid: boolean = false;
  urlToken: any;
  activating: boolean = false;
  showPassword: boolean = false;
  userName:any;


  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.isActivate = this.router.url.split('/')[1].includes('activate');
    this.activatedRoute.queryParams.subscribe(params => {
      this.urlToken = params['token'];
    });
  }

  confirm() {
    if (this.isValid) {
      this.activating = true;
      this.isActivate ? this.activateAccount() : this.resetPassword();      
    }
  }

  checkPassword() {
    const regex = /\d/g;
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.validPassword.islengthValid = this.password.length >= 8 && this.password.length <= 20;
    this.validPassword.hasCapital = this.password.match(/[A-Z]/) ? true : false;
    this.validPassword.hasNumber = regex.test(this.password)
    this.validPassword.hasSpecialChr = format.test(this.password)
    if (this.confirmPassword && this.password) {
      this.validPassword.isSame = this.confirmPassword == this.password;
    }
    if (this.validPassword.islengthValid && this.validPassword.hasCapital && this.validPassword.hasNumber && this.validPassword.hasSpecialChr && this.validPassword.isSame) {
      this.isValid = true;
    }
  }

  login() {
    this.router.navigate(['/login']);
  }
  showPasswordToggle() {
    this.showPassword = !this.showPassword;
  }

  activateAccount(){
    const params = {
      confirmPassword:this.confirmPassword,
      password : this.password,
      token: this.urlToken
    }
    this.userService.activateAccount(params).pipe(finalize(() => {
      this.activating = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.userName = response.data.username;
        this.toastrService.showSuccess('Account activated successfully.');
        this.stepper.next();
      }
    })
  }

  resetPassword() {
    const params = {
      confirmPassword:this.confirmPassword,
      newPassword : this.password,
      token: this.urlToken
    }
    this.userService.resetPassword(params).pipe(finalize(() => {
      this.activating = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.userName = response.data.username;
        this.toastrService.showSuccess('Password reset successfully.');
        this.stepper.next();
      }
    })
  }
}
