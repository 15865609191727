<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (baseAction)="manageProcedure($event)" [searchList]="procedureList"
        (searchLogEmit)="searchProcedure($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="procedureLoading">
        <loader></loader>
    </div>
    <table class="table" *ngIf="!procedureLoading">
        <thead>
            <tr>
                <th class="fw-bold px-1" style="width: 150px !important;">Procedure ID</th>
                <th class="fw-bold" style="width: 300px !important;">Procedure Name</th>
                <th class="fw-bold" style="width: 160px !important;">Procedure Type</th>
                <th class="fw-bold" style="width: 200px !important;">CPT Codes</th>
                <th class="fw-bold" style="width: 200px !important;">Created By</th>
                <th style="width: 150px !important;"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngIf="!procedureList.length">
                <td colspan="5" class="fw-bold">Procedures not found.</td>
            </tr>
            <ng-container *ngFor="let procedure of procedureList  |  sort:'asc':'procedureName', let i = index">
                <tr class="text-left" [ngClass]="{'row-striped': i % 2 === 0}">
                    <td class="px-1">
                        <a href="javascript:void(0)" class="pe-2 text-gray-darker" role="button"
                            [attr.aria-expanded]="!procedure.collapsed" aria-controls="collapseExample"
                            (click)="toggleCollapse(procedure)">
                            <i-bs [name]="procedure.collapsed ? 'plus-circle' : 'dash-circle'"></i-bs>
                        </a>{{procedure.procedureId}}
                    </td>
                    <td>
                        {{procedure.procedureName}}
                    </td>
                    <td>
                        <div class="d-flex flex-wrap">
                            <span class="badge d-inline badge-success m-1"
                                *ngFor="let c of procedure.category">{{c.name}}</span>
                        </div>

                    </td>
                    <td>
                        <div *ngIf="procedure.primaryCptCodes.length > 20">
                            {{procedure.primaryCptCodes.slice(0, 20)}}<span class="pointer"
                                mdbPopover="{{procedure.primaryCptCodes}}" trigger="hover">...</span>
                        </div>
                        <div *ngIf="procedure.primaryCptCodes.length < 20">
                            {{procedure.primaryCptCodes}}
                        </div>
                    </td>
                    <td>{{procedure.createdBy}}</td>
                    <td class="p-1">
                        <div mdbDropdown class="dropdown">
                            <button class="btn btn-clear btn-floating dropdown-toggle text-start shadow-none"
                                mdbDropdownToggle aria-expanded="false">
                                <i-bs name="three-dots-vertical"></i-bs>
                            </button>
                            <ul autoClose="true" mdbDropdownMenu class="dropdown-menu dropstart shadow"
                                style="min-width: 120px;">
                                <li>
                                    <button class="dropdown-item" type="button" (click)="manageProcedure(procedure)">
                                        Edit
                                    </button>
                                </li>
                                <li>
                                    <button class="dropdown-item" type="button"
                                        (click)="openConfirmationModal(procedure)">
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
                <tr style="border:transparent" id="collapseExample" mdbCollapse #procedure="mdbCollapse"
                    [collapsed]="procedure.collapsed">
                    <td colspan="11" style="padding: 0 !important;" class="fs-2">
                        <div class="light-bg">
                            <div class="d-flex col-12 p-2">
                                <div class="col-6 pe-1">
                                    <div class="bg-white p-2">
                                        <p class="fw-bold">Description</p>
                                        <div class="col-12 d-flex ">
                                            {{procedure.description || 'N/A'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 ps-1">
                                    <div class="p-2 bg-white">
                                        <p class="fw-bold">What's Included</p>
                                        <div class="col-12 d-flex">
                                            {{procedure.included || 'N/A'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>

        </tbody>
    </table>
</div>