<div class="col-12 text-center">
    <div class="spinner-grow text-primary m-1" style="width: 1rem; height: 1rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary m-1" style="width: 0.9rem; height: 0.9rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-grow text-primary m-1" style="width: 0.9rem; height: 0.9rem;" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>