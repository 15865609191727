export const ChartConstants = {
  ChartColors: {
    primaryColor: {
      borderColor: '#F7911E',
      backgroundColor: '#F7911E',
      borderWidth: 2,
      pointBackgroundColor: "#fff"
    },
    secondaryColor: {
      backgroundColor: '#87B540',
      borderColor: '#87B540',
      borderWidth: 2,
      pointBackgroundColor: "#fff"
    },

    lightColor: {
      backgroundColor: '#E1E2E2',
      borderColor: '#E1E2E2',
      borderWidth: 2,
      pointBackgroundColor: "#fff"
    },

    tealColor: {
      backgroundColor: '#1b9aaa',
      borderColor: '#1b9aaa',
      borderWidth: 2,
      pointBackgroundColor: "#fff"
    }

  },

  commonOption: {
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 50,
          beginAtZero: true
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const datasetIndex = context.datasetIndex;
            const dataset = context.chart.data.datasets[datasetIndex];
            const currentValue = dataset.data[context.dataIndex];
            const variance = dataset.variance[context.dataIndex];
            let tooltipLabel = ''
            tooltipLabel = dataset.label + ': ' + currentValue;
            if (variance && tooltipLabel.toLowerCase().includes('current')) {
              tooltipLabel += `      (Variance: ${variance}%)`;
            }
            return tooltipLabel;
          }
        }
      },
      legend: {
        labels: {
          boxWidth: 14,
          boxHeight: 14
        }
      }
    }
  },

  savingsReportOption: {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: function (value: any) {
            if (value % 1 === 0) {
              return value
            }
            return '';
          },
          beginAtZero: true
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 14,
          boxHeight: 14,
        }
      }
    }

  },

  TATChartConstant: {
    dataset: [
      {
        data: [70, 30],
        backgroundColor: [
          '#87B540', '#B4E864'
        ],
        borderColor: [
          '#87B540', '#B4E864'
        ],
        overBackgroundColor: [
          '#87B540', '#B4E864'
        ],
        borderWidth: [10, 10]
      },
    ]
  },

  TATOption: {
    cutout: 90,
    plugins: {
      tooltip: {
        callbacks: {
          label: {
            display: false,
          }
        }
      },
    }
  }
}
