import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';


import { ReportDateRange } from 'src/app/constants';
import { ReportFilter } from 'src/app/model/reports-filter.model';
import { CommonListService, ReportService } from 'src/app/services';
import { DateCalc } from '../../../utils';
import { TagReportModel } from 'src/app/model/reports-filter.model';


@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss'],
})
export class ReportHeaderComponent {
  @Input() reportFilterConfig: any;
  @Output() baseAction: EventEmitter<any> = new EventEmitter();

  tagDropDownList: any = [];
  reportingDateTypeList = ReportDateRange;
  reportFilterModel: ReportFilter = new ReportFilter();
  tagReportModel: TagReportModel = new TagReportModel();
  dateType: any = null;
  companyList: any = null;
  companyInputList: any[] = [];
  multiSelect: boolean = false;
  companyId: any = null;
  showNoPlanMsg: boolean = false;
  planDateRange: any;
  currentModule: any;

  //for tag & account-status report 
  multiTagInput: any = [];

  constructor(private commonListService: CommonListService, private router: Router, private reportService: ReportService) { }

  async ngOnInit() {
    this.currentModule = this.router.url.split('/')[2];
    this.multiSelect = (this.router.url.split('/')[2] == 'account-status-update' || this.router.url.split('/')[2] == 'tag-report');
    this.companyList = await this.commonListService.getCompanyList({
      limit: 0,
      companyStatus: true,
    });
    this.companyInputList = this.companyList.sort((x: any, y: any) => x.clientLegalName.localeCompare(y.clientLegalName.toLowerCase()));
    this.companyInputList = this.multiSelect ? [{ alithiasCompanyId: '', clientLegalName: 'Select All' }, ...this.companyInputList] : this.companyInputList;
    if (this.currentModule == 'tag-report') {
      this.getTagList();
    }
  }

  isSelected(item: any): boolean {
    return this.companyList.some(
      (selectedItem: any) =>
        selectedItem.clientLegalName === item.clientLegalName
    );
  }

  toggleSelection(item: any) {
    if (item.term && this.reportFilterModel?.company) {
      this.companyInputList = this.companyList?.filter((selectedItem: any) =>
        selectedItem.clientLegalName
          .toLowerCase()
          .includes(item.term.toLowerCase())
      );
    } else {
      this.companyInputList = this.companyList;
    }
  }

  handleDateChange(event: any) {
    this.reportFilterModel.from = null;
    this.reportFilterModel.to = null;
    if (this.reportFilterModel.company?.planPeriodStartDate) {
      let activeDate = this.generatePlanStartDate();
      if (event !== 'dateRange') {
        this.reportFilterModel.from = DateCalc.calculatePlanDate(event, activeDate).from;
        this.reportFilterModel.to = DateCalc.calculatePlanDate(event, activeDate).to;
      }
    }
    else {
      if (event !== 'dateRange') {
        this.reportFilterModel.from = new Date(DateCalc.calculateDate(event).from);
        this.reportFilterModel.to = new Date(DateCalc.calculateDate(event).to);
      }
    }
  }

  reset() {
    this.showNoPlanMsg = false;
    this.reportFilterModel = new ReportFilter();
    this.tagReportModel = new TagReportModel();
    this.dateType = null;
    this.multiTagInput = [];
    this.companyInputList = this.multiSelect ? [{ alithiasCompanyId: '', clientLegalName: 'Select All' }, ...this.companyList] : this.companyList;
    this.baseAction.emit('reset');
  }

  submit() {
    if (!this.multiSelect) {
      let psd = this.generatePlanStartDate();
      this.baseAction.emit({ ...this.reportFilterModel, dateType: this.dateType, planStart: psd, planEnd: psd ? moment(new Date(psd)).add(1, 'year').format('MM/DD/YYYY') : null });
    } else {
      this.baseAction.emit({ ...this.tagReportModel, from: this.reportFilterModel.from, to: this.reportFilterModel.to });
    }

  }

  selectCompany(event: any) {
    if (!this.multiSelect) {
      if (event?.alithiasCompanyId) {
        this.reportFilterModel.from = null;
        this.reportFilterModel.to = null;
        this.dateType = null;
        this.showNoPlanMsg = !event.planPeriodStartDate ? true : false;
        this.showNoPlanMsg ? this.baseAction.emit('reset') : null;
      }
      else {
        this.companyInputList = this.companyList;
      }
    } else {
      if (!event[event.length - 1]?.alithiasCompanyId) {
        this.tagReportModel.company = event.filter((x: any) => !x.alithiasCompanyId);
      }
      else {
        if (event.find((x: any) => !x.alithiasCompanyId)) {
          this.tagReportModel.company = this.tagReportModel.company.filter((x: any) => x.alithiasCompanyId);
          this.tagReportModel.company = event.filter((x: any) => x.alithiasCompanyId);
          this.reportFilterModel.company = [];
        }
        else {
          this.tagReportModel.company = (!this.tagReportModel?.company?.length || !this.tagReportModel?.company?.find((x: any) => x.alithiasCompanyId == event[0].alithiasCompanyId))
            ? this.tagReportModel.company.concat(event) : this.tagReportModel.company;
          this.reportFilterModel.company = [];
          this.companyInputList = [{ alithiasCompanyId: '', clientLegalName: 'Select All' }, ...this.companyList] 
        }
      }
    }
  }

  onBaseAction(type: any) {
    this.baseAction.emit(type)
  }

  generatePlanStartDate() {
    const pm = this.reportFilterModel.company?.planPeriodStartDate - 1;
    const pd = 1;
    const py = new Date().getFullYear();
    return this.reportFilterModel.company?.planPeriodStartDate ? moment(new Date(py, pm, pd)).format('MM/DD/YYYY') : null;
  }

  selectReportTag(event: any) {
    if (event?.length) {
      if (event[0]?._id == 'all') {
        this.tagReportModel.tag = this.tagDropDownList.filter((x: any) => x._id !== 'all');
      }
      else {
        this.tagReportModel.tag = (!this.tagReportModel?.tag?.length || !this.tagReportModel?.tag?.find((x: any) => x._id == event[0]._id))
          ? this.tagReportModel.tag.concat(event) : this.tagReportModel.tag;
        this.multiTagInput = [];
      }
    }
    else {
      this.tagReportModel.tag = [];
    }

  }

  getTagList() {
    this.reportService.getTagList().subscribe((response: any) => {
      if (response && response.success) {
        this.tagDropDownList = response.data;
        this.tagDropDownList = [{ name: 'Select All', _id: 'all' }, ...this.tagDropDownList];
      }
    })
  }

  deleteMultiple(e: any) {
    if (e && e?.clientLegalName) {
      this.tagReportModel.company = this.tagReportModel.company.filter((x: any) => x.alithiasCompanyId !== e.alithiasCompanyId);
    }
    else {
      this.tagReportModel.tag = this.tagReportModel.tag.filter((x: any) => x._id !== e._id);
    }

  }
}
