<a
  class="app-brand shadow"
  [ngClass]="{ 'd-block': !slim, 'd-none': slim }"
  href="#"
>
  <img
    src="../../../assets/images/logo-sidenav.png"
    class="img-fluid"
    alt=""
    style="height: 30px"
  />
  <!-- <img src="../../../assets/images/Alithias Logo with Power Slogan Transparent.png" class="img-fluid" alt="" style="height: 70px; width:200px" /> -->
</a>
<ul
  id="scroll-container"
  class="sidenav-menu overflow-autopt-3"
  mdbScrollbar
  [ngClass]="{ 'slim-space': slim }"
>
  <mdb-sidenav-item *ngFor="let menu of menuList">
    <a
      class="sidenav-link p-2"
      [routerLink]="menu.routePath ? [menu.routePath] : []"
    >
      <i-bs [name]="menu?.icon"></i-bs>
      <span slim="false" class="p-2">{{ menu?.name }}</span>
    </a>
    <ul
      class="sidenav-collapse"
      [collapsed]="!rla.isActive || slim"
      #rla="routerLinkActive"
      routerLinkActive
      mdbCollapse
      *ngIf="menu.submenu.length"
    >
      <li class="sidenav-item" *ngFor="let s of menu.submenu">
        <a
          class="sidenav-link p-4"
          (click)="openSmartSearchPage()"
          [routerLinkActive]="'active'"
          *ngIf="s.routePath === '/service-case/smart-search'"
        >
          <!-- <i-bs [name]="s.icon"></i-bs> -->
          <span slim="false" class="p-2">{{ s.name }}</span>
        </a>
        <a
          class="sidenav-link p-4"
          [routerLink]="[s.routePath]"
          [routerLinkActive]="'active'"
          *ngIf="s.routePath !== '/service-case/smart-search'"
        >
          <!-- <i-bs [name]="s.icon"></i-bs> -->
          <span slim="false" class="p-2">{{ s.name }}</span>
        </a>
      </li>
    </ul>
  </mdb-sidenav-item>
</ul>
