import { Pipe, PipeTransform } from '@angular/core';

type sortOrder = 'asc' | 'desc';

@Pipe({
    name: 'sort'
})
export class sortPipe implements PipeTransform {
  transform(
    value: any[],
    sortOrder: sortOrder | string = 'asc',
    sortKey?: string
  ): any {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;

    let numberArray = [];
    let stringArray = [];

    const collator = new Intl.Collator(undefined, { sensitivity: 'base' }); //This ensures case-insensitive and accent-insensitive sorting while considering the natural order of words.

    if (!sortKey) {
      return value
    } else {
      numberArray = value
        .filter((item) => typeof item[sortKey] === 'number')
        .sort((a, b) => a[sortKey] - b[sortKey]);
      stringArray = value
        .filter((item) => typeof item[sortKey] === 'string')
        .sort((a, b) => collator.compare(a[sortKey], b[sortKey]));
    }
    const sorted = numberArray.concat(stringArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}
