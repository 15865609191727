<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (searchLogEmit)="handleLogEmit($event)"
        (baseAction)="exportMember($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="loading">
        <loader></loader>
    </div>
    <div class="text-center pt-4" *ngIf="!memberList">
        <p class="fw-bold">Member not found.</p>
    </div>
    <div *ngIf="memberList.length && !loading">
        <div class="d-flex justify-content-between py-2">
            <p class="p-3 mb-0 fw-bold fs-2" style="font-size: 14px;">Showing {{memberList.length}} of
                {{totalMemberCount}}
                {{memberList.length > 1 ? 'results' : 'result'}}</p>
            <!-- <button type="button" class="btn ms-3 " *ngFor="let a of searchConfig.action" [ngClass]="a.btnClass"
            (click)="exportMember(a.type)">
            <i-bs [name]="'download'" class="pe-2"></i-bs>{{a.label }}
        </button> -->
            <div class="d-flex align-items-center">
                <div class="text-center" *ngIf="checkingMemberCount">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div mdbDropdown class="dropdown ms-3" *ngIf="searchConfig.exportToggle" [disabled]="checkingMemberCount">
                    <button class="btn  btn-clear border dropdown-toggle" type="button" id="dropdownMenuButton"
                        aria-expanded="false" mdbDropdownToggle>
                        <i-bs name="download" class="pe-2"></i-bs>
                        Export
                    </button>
                    <ul mdbDropdownMenu class="dropdown-menu">
                        <li>
                            <button class="dropdown-item" type="button" (click)="toggleMemberExport('current')">
                                Export Current
                            </button>
                            <button class="dropdown-item" type="button" (click)="toggleMemberExport('all')">
                                <label class="d-flex align-items-center">Export All <i-bs name="info-circle"
                                        class="text-primary ps-2 "
                                        mdbTooltip="This may take some time, depending on the number of records."></i-bs></label>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <table class="table mb-0">
            <thead>
                <tr>
                    <th class="fw-bold px-1" style="width:180px">Member Id</th>
                    <th class="fw-bold">First <br> Name</th>
                    <!-- <th class="fw-bold">Middle Name</th> -->
                    <th class="fw-bold">Last <br> Name</th>
                    <th class="fw-bold" style="width:10px">SSN</th>
                    <th class="fw-bold" style="width:250px">Company</th>
                    <th class="fw-bold" style="width:15px">Dependent</th>
                    <th class="fw-bold" style="width:10px">TPA</th>
                    <th class="fw-bold">Last <br> Modified</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let member of memberList, let i = index">
                    <tr [ngClass]="{'row-striped': i % 2 === 0}">
                        <td class="px-1">

                            <a href="javascript:void(0)" class="pe-1 text-gray-darker" (click)="toggleCollapse(member)"
                                role="button" [attr.aria-expanded]="!member.collapsed" aria-controls="collapseExample">
                                <i-bs [name]="member.collapsed ? 'plus-circle' : 'dash-circle'"></i-bs>
                            </a>
                            {{member.subscription.subscriberId}}
                        </td>
                        <td>
                            {{member.firstName}}
                        </td>
                        <!-- <td>
                        {{member.middleName}}
                    </td> -->
                        <td>{{member.lastName}}</td>
                        <td>{{member.ssn}}</td>
                        <td>{{member.subscription.groupName}}</td>
                        <td>{{member.subscription.relationshipName}}</td>
                        <td>{{member.tpa}}</td>
                        <td>{{member.lastModified | date: 'MM/dd/yyyy'}} <br> {{member.lastModified | date: 'h:mm a'}}
                        </td>
                    </tr>
                    <tr style="border:transparent" id="collapseExample" mdbCollapse #member="mdbCollapse"
                        [collapsed]="member.collapsed">
                        <td colspan="9" style="padding: 0 !important;">
                            <div class="light-bg">
                                <div class="pt-2">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class="d-flex">
                                            <div class="tabSet" [ngClass]="{'active': member.activeTab ===  index}"
                                                *ngFor="let t of memberTabs, let index = index">
                                                <a href="javascript:void(0)" (click)="selectTab(member,index)"
                                                    class="text-large">{{t}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0" style="color: #B3B3B3">
                                </div>
                                <div class="p-3">
                                    <table class="table table-member mb-0" *ngIf="member.activeTab == 0">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="fw-bold">Plan</th>
                                                <th scope="col" class="fw-bold">Primary <br> Network Group</th>
                                                <th scope="col" class="fw-bold">Secondary <br> Network Group</th>
                                                <th scope="col" class="fw-bold">Primary <br> Network</th>
                                                <th scope="col" class="fw-bold">Secondary <br> Network </th>
                                                <th scope="col" class="fw-bold">Source <br> File</th>
                                                <th scope="col" class="fw-bold">Date <br> of Service</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngIf="!member.enrollments.length">
                                                <td colspan="5">Member enrollment information not found.</td>
                                            </tr>
                                            <!-- <ng-template *ngIf="member.enrollments.length"> -->
                                            <tr *ngFor="let e of member.enrollments">
                                                <td scope="row">{{e.planName || e.planCode}}</td>
                                                <td>{{e.primaryNetworkGroupName}}</td>
                                                <td>{{e.secondaryNetworkGroupName}}</td>
                                                <td>{{e.primaryNetworkName || e.primaryNetworkCode}}</td>
                                                <td>{{e.secondaryNetworkName || e.secondaryNetworkCode}}</td>
                                                <td class="text-break">{{e.fileName}}</td>
                                                <td>
                                                    <p class="mb-1">
                                                        {{e.startDate | date: 'MM/dd/yyyy'}} {{e.endDate ? '-' +
                                                        (e.endDate |
                                                        date: 'MM/dd/yyyy') : ''}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <!-- </ng-template> -->

                                        </tbody>
                                    </table>
                                    <div class="col-12 p-3 d-flex table-member" *ngIf="member.activeTab == 1">
                                        <div class="col-4" [ngClass]="{'col-3':member.secondaryEmail}">
                                            <div class="col-12 d-flex pb-1">
                                                <div class="col-4 fw-bold">
                                                    Name :
                                                </div>
                                                <div class="col-8">
                                                    {{member.firstName}} {{member.middleName}} {{member.lastName}}
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex pb-1">
                                                <div class="col-4 fw-bold">
                                                    Alithias UID :
                                                </div>
                                                <div class="col-8">
                                                    {{member.alithiasUID}}
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex pb-1">
                                                <div class="col-4 fw-bold">
                                                    DOB :
                                                </div>
                                                <div class="col-8">
                                                    {{member.dob | date:'MM/dd/yyyy'}}
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex  pb-1">
                                                <div class="col-4 fw-bold">
                                                    Gender :
                                                </div>
                                                <div class="col-8">
                                                    {{member.gender}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5" [ngClass]="{'col-4':member.secondaryEmail}">
                                            <div class="col-12 d-flex pb-1" *ngIf="member.primaryEmail">
                                                <div class="col-4 fw-bold">
                                                    Primary Email :
                                                </div>
                                                <div class="col-8">
                                                    {{member.primaryEmail}}
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex pb-1" *ngIf="member.primaryPhone">
                                                <div class="col-4 fw-bold">
                                                    Primary Phone :
                                                </div>
                                                <div class="col-8">
                                                    {{member.primaryPhone}}
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex pb-1">
                                                <div class="col-4 fw-bold">
                                                    Address :
                                                </div>
                                                <div class="col-8">
                                                    {{member.address.addressLine1}}
                                                    {{member.address.addressLine2}}
                                                    <p>{{member.address.city}}, {{member.address.state}}
                                                        {{member.address.zip}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="col-12 d-flex pb-1" *ngIf="member.secondaryEmail">
                                                <div class="col-4 fw-bold">
                                                    Work Email :
                                                </div>
                                                <div class="col-8">
                                                    {{member.secondaryEmail}}
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex pb-1" *ngIf="member.secondaryPhone">
                                                <div class="col-4 fw-bold">
                                                    Work Phone :
                                                </div>
                                                <div class="col-8">
                                                    {{member.secondaryPhone}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-3 d-flex table-member" *ngIf="member.activeTab == 4">
                                        <div class="text-break">
                                            {{member.rawData}}
                                        </div>
                                    </div>
                                    <div class="col-12 p-3 d-flex table-member" *ngIf="member.activeTab == 2">
                                        <table class="table table-member mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="fw-bold">Field Name</th>
                                                    <th scope="col" class="fw-bold">Original Value</th>
                                                    <th scope="col" class="fw-bold">New Value</th>
                                                    <th scope="col" class="fw-bold">Source File</th>
                                                    <th scope="col" class="fw-bold">Updated Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="member.historyLoading">
                                                    <td colspan="5">
                                                        <loader></loader>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="!member.history?.length && !member.historyLoading">
                                                    <td colspan="5">Member history not found.</td>
                                                </tr>
                                                <!-- <ng-template *ngIf="member.enrollments.length"> -->
                                                <tr *ngFor="let history of member.history">
                                                    <th scope="row">{{history.key}}</th>
                                                    <td>{{history.orgValue}}</td>
                                                    <td>{{history.newValue}}</td>
                                                    <td>{{history.fileName}}</td>
                                                    <td>
                                                        <p class="mb-1">
                                                            {{history.timeStamp | date: 'MM/dd/yyyy h:mm a'}}
                                                    </td>
                                                </tr>
                                                <!-- </ng-template> -->

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="p-3 table-member" *ngIf="member.activeTab == 3">
                                        <div class="row px-3">
                                            <div class="col-4 p-1" *ngFor="let acc of member.eligAccumulator">
                                                <div class="col-12 d-flex">
                                                    <div class="col-8 fw-bold text-capitalize">
                                                        {{acc.code}} :
                                                    </div>
                                                    <div class="col-4">
                                                        {{acc.value}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>