<!-- Full Page Intro -->
<div class="view vh-100 position-relative">
  <!-- Mask & flexbox options-->
  <div
    class="h-100 bg-primary d-flex justify-content-center align-items-center overflow-auto login-background"
  >
    <!-- Content -->
    <div class="container h-100">
      <!--Grid row-->
      <div class="row flex-column h-100 flex-nowrap">
        <!--Grid column-->
        <div class="col mx-auto">
          <p class="text-center mt-lg-5 mb-2">
            <img src="../../../assets/images/Alithias Logo with Power Slogan Transparent.png" alt="" style="height: 90px; width:250px"/>
          </p>
          <!--Form-->
          <div class="d-flex justify-content-center">
            <div class="card rounded-2" style="width: 400px">
              <div class="card-body p-4">
                <div class="" *ngIf="actionType == 'login'">
                  <h5 class="dark-grey-text font-weight-bold mb-3 text-center">
                    Member Login
                  </h5>
                  <div class="alert bg-alert text-red d-flex p-3 fs-2" *ngIf="lockedUser">
                    <i-bs
                      name="exclamation-circle-fill"
                      style="padding-right: 10px;"
                      width = "24"
                      height="24"
                    ></i-bs>
                    Your account has been locked for {{minutes + 1}} minutes due to multiple
                    failed attempts
                  </div>
                  <form #loginForm="ngForm">
                    <div class="md-form">
                      <label for="username" class="form-label">Username</label>
                      <div class="input-group input-group-lg mb-3">
                        <span
                          class="input-group-text ps-3 pe-3"
                          id="basic-addon3"
                        >
                          <i-bs
                            class="text-gray-main"
                            name="person-fill"
                          ></i-bs>
                        </span>
                        <input
                          mdbInput
                          type="text"
                          class="form-control px-1"
                          id="username"
                          aria-describedby="basic-addon3"
                          [(ngModel)]="loginModel.email"
                          name="username"
                          #username="ngModel"
                        />
                        <span
                          class="input-group-text ps-3 pe-3"
                          id="basic-addon3"
                          *ngIf="!loginModel.email"
                        >
                          <a
                            href="#"
                            mdbTooltip="Required field"
                            placement="right"
                            ><i
                              class="fa fa-exclamation-circle text-gray-main"
                            ></i
                          ></a>
                        </span>
                      </div>
                    </div>
                    <div class="md-form">
                      <label for="password" class="form-label">Password</label>
                      <div class="input-group input-group-lg mb-3">
                        <span
                          class="input-group-text ps-3 pe-3"
                          id="basic-addon3"
                        >
                          <i-bs name="lock" class="text-gray-main"></i-bs>
                        </span>
                        <input
                          [type]="showPassword ? 'text' : 'password'"
                          class="form-control px-1"
                          id="password"
                          aria-describedby="basic-addon3"
                          [(ngModel)]="loginModel.password"
                          name="password"
                        />
                        <span
                          class="input-group-text ps-3 pe-3"
                          id="basic-addon3"
                          *ngIf="!loginModel.password"
                        >
                          <a
                            href="#"
                            mdbTooltip="Required field"
                            placement="right"
                            ><i
                              class="fa fa-exclamation-circle text-gray-main"
                            ></i
                          ></a>
                        </span>
                        <span
                          class="input-group-text ps-3 pe-3"
                          (click)="showPasswordToggle()"
                          *ngIf="loginModel.password"
                        >
                          <i
                            class="fa-eye clickable text-gray-main"
                            [ngClass]="{
                              far: !showPassword,
                              fas: showPassword
                            }"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3 mb-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          Remember Me
                        </label>
                      </div>
                      <a
                        href="javascript:void(0)"
                        (click)="baseAction('fpw')"
                        style="font-size: 14px"
                        >Forgot password?</a
                      >
                    </div>

                    <button
                      type="submit"
                      class="btn btn-lg btn-block btn-gradient text-white"
                      (click)="login('login')"
                      [disabled]="!loginModel.email || !loginModel.password || lockedUser"
                    >
                      <span
                        class="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                        *ngIf="loading"
                      ></span>
                      Login
                    </button>
                  </form>
                </div>
                <div class="" *ngIf="actionType == 'otp'">
                  <h5 class="dark-grey-text font-weight-bold mb-3 text-center">
                    Verification Confirmation
                  </h5>
                  <div class="alert bg-alert text-red d-flex p-3 fs-2" *ngIf="lockedUser">
                    <i-bs
                      name="exclamation-circle-fill"
                      style="padding-right: 10px;"
                      width = "24"
                      height="24"
                    ></i-bs>
                    Your account has been locked for {{minutes + 1}} minutes due to multiple
                    failed attempts
                  </div>
                  <p class="text-center" *ngIf="!lockedUser">
                    We've just sent an email with a verification code to your
                    email address.
                  </p>
                  <code-input
                    [isCodeHidden]="false"
                    [codeLength]="4"
                    (codeCompleted)="onCodeCompleted($event)"
                    (codeChanged)="onCodeChanged($event)"
                  >
                  </code-input>
                  <p class="text-center">
                    Didn't receive a code?
                    <a href="javascript:void(0)" (click)="login('resend')">
                      Send new code.
                      <span
                        *ngIf="sending"
                        class="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span
                    ></a>
                  </p>
                </div>
                <div class="" *ngIf="actionType == 'fpw'">
                  <div class="md-form">
                    <h5
                      class="dark-grey-text font-weight-bold mb-3 text-center"
                    >
                      Forgot Password?
                    </h5>
                    <div class="md-form">
                      <label for="username" class="form-label">Email</label>
                      <div class="input-group input-group-lg mb-3">
                        <span
                          class="input-group-text ps-3 pe-3"
                          id="basic-addon3"
                        >
                          <i-bs name="envelope-fill"></i-bs>
                        </span>
                        <input
                          mdbInput
                          type="text"
                          class="form-control px-1"
                          id="username"
                          aria-describedby="basic-addon3"
                          [(ngModel)]="loginModel.email"
                          name="username"
                          #username="ngModel"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-between"
                  *ngIf="actionType != 'login'"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="baseAction('login')"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-gradient text-white"
                    (click)="submit()"
                    [disabled]="loading || lockedUser"
                  >
                    Submit
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="loading"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--/.Form-->
          <div class="text-center py-5">
            <p class="mb-0">
              <a class="text-gray-darker" href="">Terms & Conditions</a> |
              <a class="text-gray-darker" href="">Privacy Policy</a>
            </p>
            <p>
              © {{ currentYear }} Copyright Alithias. Developed by
              <a href="https://noveltytechnology.com/" target="_blank">
                Novelty Technology</a
              >
            </p>
          </div>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Content -->
  </div>
  <!-- Mask & flexbox options-->
</div>
<!-- Full Page Intro -->
<!-- Main navigation -->
