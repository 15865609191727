import * as moment from 'moment';

export class ActivityLogSearchModel {
    type: any = 'information';
    userId: string = '';
    createdDateFrom: any = moment().format('MM/DD/YYYY');
    createdDateTo: any = '';
    limit: any = 25;
    keyword: '';
    createdAt :any = null;
}