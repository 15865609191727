import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormControl,
  Validators,
  UntypedFormArray,
  Form,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { MdbTabChange } from 'mdb-angular-ui-kit/tabs/tabs.component';
import { finalize } from 'rxjs';

import {
  CompanyService,
  ToastrService,
  FormInitService,
  CommonListService,
  NetworkService,
  HttpCancelService,
} from '../../../../services';
import { CompanyDropdownConstant, MonthList } from '../../../../constants';
import { CompanyModel, MedicalPlanDetailsModel } from '../../../../model';

@Component({
  selector: 'app-company-add-edit',
  templateUrl: './company-add-edit.component.html',
  styleUrls: ['./company-add-edit.component.scss'],
})
export class CompanyAddEditComponent implements OnInit {
  @ViewChild('tabs') tabs: MdbTabsComponent;

  companyOnboardingForm: UntypedFormGroup;
  medicalPlanTypeDetail: any = '';
  saving: boolean = false;
  company: any;
  allowAssignTpa: boolean = true;
  monthList = MonthList;
  isActiveSwitch: boolean = false;
  tabWidth = 240;
  statesList: any = [];
  currentCompanyId: any = '';
  loadingGeneralDetails: boolean = false;
  dropdownConstants = CompanyDropdownConstant;
  mailformat = /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  getRequest:any;
  networkLoading : boolean = false;
  //forms
  billings: any = null;
  clientServiceType: any = null;
  addOnServices: any = null;
  planDetails: any = '';
  alerts: any = '';
  preferredProvider: any = '';
  directPrimaryCare: any = '';
  pharmacyPlanDetails: any = '';
  ancillaryBenifitProgram: any = '';
  valueBasedBenifits: any = '';
  loadingPreferredProvider: boolean = false;
  loadingDirectPrimaryCare: boolean = false;
  loadingPharmacyPlanDetails: boolean = false;
  loadingAncillaryBenifitProgram: boolean = false;
  loadingValueBasedBenifits: boolean = false;
  loadingMedicalPlantypeDetail: boolean = false;
  networkList:any  =[];
  tiles:any = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private formInit: FormInitService,
    private commonListService: CommonListService,
    private renderer: Renderer2,
    private networkService: NetworkService,
    private cancelService: HttpCancelService
  ) {
    this.companyOnboardingForm = this.formInit.createFormGroup(
      CompanyModel.setGeneralCompanyDetailModel()
    );
  }

  async ngOnInit() {
    this.statesList = await this.commonListService.getStateList();
    this.currentCompanyId = this.route.snapshot.params['id'];
    if (this.currentCompanyId) {
      this.getCompanyById(this.currentCompanyId);
    }
  }

  ngAfterViewInit() {
    this.tabs.setActiveTab(1);
    this.cd.detectChanges();
    const element1 = document.querySelector('.nav-tabs') as HTMLElement;
    const element2 = document.querySelector('.vertical-tab') as HTMLElement;
    if (element1.classList.contains('nav-tabs')) {
      this.renderer.removeClass(element1, 'col-3');
      this.renderer.addClass(element1, 'company-vetical-tab');
    }
    if (element2.classList.contains('row')) {
      this.renderer.removeClass(element2, 'row');
    }
  }

  async onTabChange(event: MdbTabChange) {
    if (this.currentCompanyId && event.index > 1) {
      this.loadingGeneralDetails = true;
      switch (event.index) {
        case 2:
          this.getBillingInfo();
          break;
        case 3:
          this.getClientServiceType();
          break;
        case 4:
          this.getPlanDetails();
          break;
        case 5:
          this.getAddOnService();
          break;
        case 6:
          this.getValueBasedBenifits();
          break;
        case 7:
          this.getAncillaryBenifitProgram();
          break;
        case 8:
          this.getPreferredProvider();
          break;
        case 9:
          this.getPlanTypeDetails();
          break;
        case 10:
          this.getDirectPrimaryCare();
          break;
        case 11:
          this.getPharmacyPlanDetails();
          break;
        case 12:
          this.getAlerts();
          break;
        case 13:
          await this.getTilesList()
          break;
      }
    }
  }

  saveAccountdetails() {
    this.saving = true;
    this.companyOnboardingForm.controls['companyStatus'].setValue(
      this.isActiveSwitch
    );
    this.companyOnboardingForm.controls['alithiasCompanyId'].enable();
    if (this.companyOnboardingForm.value.id) {
      this.editAccountDetailForm(this.companyOnboardingForm.value);
    } else {
      this.companyService
        .addNewCompany(this.companyOnboardingForm.value)
        .pipe(
          finalize(() => {
            this.saving = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            this.toastrService.showSuccess(response.message);
            window.close();
            this.currentCompanyId = response.data.id;
            let url = this.router.serializeUrl(this.router.createUrlTree([`/admin/company/edit/${response.data.id}`]));
            window.open(url, '_blank');
          }
        });
    }
  }

  editAccountDetailForm(formValue: any) {
    this.companyService
      .editCompany(formValue)
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.companyOnboardingForm.controls['alithiasCompanyId'].disable();
          this.toastrService.showSuccess(response.message);
          if (response.data.subsidiary.length) {
            let formArray = this.getFormArray('subsidiary');
            formArray.clear();
            response.data.subsidiary.forEach((x: any) => {
              this.addForms('subsidiary', x);
            });
          }
          if (response.data.clientContacts.length) {
            let formArray = this.getFormArray('clientContacts');
            formArray.clear();
            response.data.clientContacts.forEach((x: any) => {
              this.addForms('clientContacts', x);
            });
          }
        }
      });
  }

  // get tpaForm() {
  //   return this.accountDetailForm.controls["assignedTpa"] as FormArray;
  // }

  // initTpaForm(value?: any) {
  //   return this.formBuilder.group({
  //     sourceTpa: [value?.sourceTpa, Validators.required],
  //     effectiveStartDate: [value?.effectiveStartDate, Validators.required],
  //     effectiveEndDate: [value?.effectiveEndDate],
  //     companyCode: [value?.companyCode, Validators.required],
  //     brokerAgency : [value?.brokerAgency],
  //     displayTpa : [value?.displayTpa],
  //     networks :[value?.networks]
  //   })
  // }

  delete(index: number, item: any, arrayName: any) {
    let control = <UntypedFormArray>this.companyOnboardingForm.controls[arrayName];
    item.loading = true;
    switch (arrayName) {
      case 'subsidiary':
        if (item.subsidiaryId) {
          this.companyService.deleteSubsidiaryByCompany(this.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'clientContacts':
        if (item.contactId) {
          this.companyService.deleteGeneralContactDetails(this.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
    }
  }

  getCompanyById(id: any) {
    this.loadingGeneralDetails = true;
    this.companyService
      .getCompanyById(id)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          response.data.fiscalMonthStart = !response.data.fiscalMonthStart
            ? null
            : response.data.fiscalMonthStart;
          this.company = response.data;
          this.companyOnboardingForm.controls['id'].setValue(
            this.currentCompanyId
          );
          this.companyOnboardingForm.patchValue(this.company);
          if (this.company.clientContacts.length) {
            let formArray = this.getFormArray('clientContacts');
            formArray.clear();
            this.company.clientContacts.forEach((x: any) => {
              this.addForms('clientContacts', x);
            });
          }
          if (this.company.subsidiary.length) {
            let formArray = this.getFormArray('subsidiary');
            formArray.clear();
            this.company.subsidiary.forEach((x: any) => {
              this.addForms('subsidiary', x);
            });
          }
          this.isActiveSwitch = this.company.companyStatus;
        }
      });
  }


  ondatepickerClose(formValue: any) {
    if (formValue.effectiveEndDate) {
      this.allowAssignTpa = true;
    }
  }

  toggleIsPrimary() {
    this.isActiveSwitch = !this.isActiveSwitch;
  }

  addForms(type: any, value?: any) {
    let formArray: any;
    switch (type) {
      case 'subsidiary':
        const newSubsidiary = this.initSubsidiary(value);
        formArray = this.getFormArray('subsidiary');
        value ? formArray.push(newSubsidiary) : formArray.insert(0, newSubsidiary);
        break;
      case 'clientContacts':
        const newContactDetails = this.initContactDetails(value);
        formArray = this.getFormArray('clientContacts');
        value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails);
        break;
    }
  }

  initSubsidiary(value?: any) {
    return this.formBuilder.group({
      subsidiaryCompanies: [value?.subsidiaryCompanies],
      subGroup: [value?.subGroup],
      subsidiaryId: [value?.subsidiaryId],
    });
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      contactName: [value?.contactName],
      contactTitle: [value?.contactTitle],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
      contactId: [value?.contactId]
    });
  }

  getControl(controlName: string): UntypedFormGroup {
    return this.companyOnboardingForm.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(arrayName: string): UntypedFormArray {
    return this.companyOnboardingForm.get(arrayName) as UntypedFormArray;
  }

  getBillingInfo() {
    this.companyService
      .getCompanyBillingInfo(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.billings = response.data;
        }
      });
  }

  getClientServiceType() {
    this.companyService
      .getClientServiceTypeInfo(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.clientServiceType = response.data;
        }
      });
  }

  getAddOnService() {
    this.companyService
      .getAddOnServiceInfo(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.addOnServices = response.data;
        }
      });
  }

  getPlanDetails() {
    this.companyService
      .getPlanDetails(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.planDetails = response.data;
        }
      });
  }

  getAlerts() {
    this.companyService
      .getAlerts(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          response.data = response.data.map((x:any)=>{
            return {
              ...x, alertTypeName:x.alertType ?  this.dropdownConstants.alertType.find((y:any)=>y.value == x.alertType)?.name : ''
            }
          })
          this.alerts = response.data;
          
        }
      });
  }

  getPreferredProvider() {
    this.companyService
      .getPreferredProvider(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingPreferredProvider = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.preferredProvider = response.data;
        }
      });
  }
  getDirectPrimaryCare() {
    this.loadingDirectPrimaryCare = true;
    this.companyService
      .getDirectPrimaryCare(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingDirectPrimaryCare = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.directPrimaryCare = response.data;
        }
      });
  }
  getPharmacyPlanDetails() {
    this.loadingPharmacyPlanDetails = true;
    this.companyService
      .getPharmacyPlanDetails(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingPharmacyPlanDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.pharmacyPlanDetails = response.data;
        }
      });
  }
  getAncillaryBenifitProgram() {
    this.loadingAncillaryBenifitProgram = true;
    this.companyService
      .getAncillaryBenifitProgram(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingAncillaryBenifitProgram = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.ancillaryBenifitProgram = response.data;
        }
      });
  }
  getValueBasedBenifits() {
    this.loadingValueBasedBenifits = true;
    this.companyService
      .getValueBasedBenifits(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingValueBasedBenifits = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.valueBasedBenifits = response.data;
          this.getNetwork({limit:25});
        }
      });
  }

  getPlanTypeDetails() {
    this.loadingMedicalPlantypeDetail = true;
    this.companyService
      .getPlanTypeDetails(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingMedicalPlantypeDetail = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.medicalPlanTypeDetail = response.data;
        }
      });
  }

  getNetwork(keyword?: any) {
    this.networkLoading = true;
    this.getRequest = this.networkService.getNetworkList(keyword).pipe(finalize(() => {
      this.networkLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.networkList = response.data.rows;
      }
    })
  }

  searchNetwork(event: any) {
    if (!this.getRequest) {
      this.getNetwork(event);
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getNetwork(event);
    }
  }

  getTilesList() {
    return new Promise((resolve)=>{
      this.companyService
      .getTilesList(this.currentCompanyId)
      .pipe(
        finalize(() => {
          this.loadingGeneralDetails = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.tiles = response.data;
          resolve(true);
        }
      });
    })
  }

}
