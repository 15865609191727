import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { ApiConstant } from '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClientService) { }

  getCompanyList(keyword?: any, requestType?: any) {
    const keyValues = QueryParams.addParams(keyword);
    return this.http.get(`${ApiConstant.COMPANY}?` + keyValues, null, requestType).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addNewCompany(company: any) {
    return this.http.post(`${ApiConstant.COMPANY}`, company).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editCompany(company: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${company.id}`, company).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteCompany(company: any) {
    return this.http.remove(`${ApiConstant.COMPANY}/${company.id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCompanyById(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getTpaList() {
    return this.http.get(`${ApiConstant.TPA}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCompanyEmployeeStatus(keyword?: any) {
    const keyValues = QueryParams.addParams(keyword);
    return this.http
      .get(
        `${ApiConstant.COMPANY}/members-counts?${!keyword.includeNull ? 'includeNull=false&' : ''
        }` + keyValues
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getElectronicFilesProviderByCompany(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/file-providers`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editElectronicFilesProviderByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/file-providers`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTPAInformationByCompany(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/tpas`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editTPAInformationByCompany(companyId: any, body: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/tpas`, body).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getBrokerDetailsByCompany(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/brokers`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editBrokersInformationByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/brokers`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getClientReportingByCompany(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/reports`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editClientReportingByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/reports`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAccountManagerByCompany(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/account-managers`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editAccountManageByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/account-managers`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCompanyBillingInfo(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/billings`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  editBillingsByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/billings`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getClientServiceTypeInfo(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/service-types`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editClientServiceTypeByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/service-types`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPlanDetails(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/plan-details`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editPlanDetails(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/plan-details`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAlerts(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/alerts`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAlertById(alert: any, companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/alerts/${alert.alertId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  createAlert(body: any, companyId: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/alerts`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateAlert(body: any, companyId: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/alerts/${body.alertId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteAlert(body: any, companyId: any) {
    return this.http
      .remove(`${ApiConstant.COMPANY}/${companyId}/alerts/${body.alertId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAddOnServiceInfo(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/additional-services`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  editAddOnServiceByCompany(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/additional-services`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // Preferred Provider Service
  getPreferredProvider(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/preferred-providers`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deletePreferredProvider(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/preferred-providers`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteSubsidiaryByCompany(companyId: any, obj: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/subsidiaries/${obj.subsidiaryId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteGeneralContactDetails(companyId: any, obj: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/client-contacts/${obj.contactId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteFileContactDetails(companyId: any, obj: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/file-providers/client-contacts/${obj.contactId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteTPACustomerService(companyId: any, obj: any, tpaId: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/tpas/${tpaId}/customer-service/${obj.contactId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDirectPrimaryCare(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/dpcs`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateDirectPrimaryCare(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/dpcs`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteDPCAddress(companyId: any, primaryId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/dpcs/${primaryId}/address/${body.addressId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // Pharmacy Plan Details
  getPharmacyPlanDetails(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/pharmacy-plans`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updatePharmacyPlanDetails(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/pharmacy-plans`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deletePharmacyPlanProgram(companyId: any, body: any, pharmacyId: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/pharmacy-plans/pharmacy-programs/${pharmacyId}/access-contact/${body.contactId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deletePharmacyPlanContactDetail(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/pharmacy-plans/contacts/${body.contactId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteTpaWebPortal(companyId: any, obj: any, tpaId: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/tpas/${tpaId}/web-portal/${obj.contactId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteBrokerContact(companyId: any, obj: any, brokerId: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/brokers/${brokerId}/details/${obj.contactId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteReportReceipient(companyId: any, obj: any, clientReportId: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/reports/${clientReportId}/recipient-info/${obj.recipientId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteAssignedAccountManager(companyId: any, obj: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/account-managers/assigned-manager/${obj.assignedId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteAssignedAdvocateManager(companyId: any, obj: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/account-managers/assigned-advocate/${obj.assignedId}`,
        obj
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //Ancillary-Benefit-Program
  getAncillaryBenifitProgram(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/ancillary-benefits`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateAncillaryBenifitProgram(companyId: any, body: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/ancillary-benefits`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteAncillaryBenifitProgram(companyId: any, ancillaryId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/ancillary-benefits/${ancillaryId}/vendor-details/${body.contactId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }



  //Value Based Benefit
  getValueBasedBenifits(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/benefits`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateValueBasedBenifits(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/benefits`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //pricingInfo
  deleteValueBasedBenefitsContact(companyId: any, valueBaseId: any, body: any,) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/benefits/${valueBaseId}/vendors/${body.contactId}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  //pricingInfo 
  deletePricingInfo(companyId: any, body: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/billings/pricing/${body.pricingId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deletePricigContactInfo(companyId: any, body: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/billings/contacts/${body.contactId}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteAlithiasServicePricingInfo(companyId: any, body: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/billings/alithias-services/${body.pricingId}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //client service type
  deleteClientContact(companyId: any, body: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/service-types/client-service/${body.contactId}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deletePhoneSetup(companyId: any, body: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/service-types/phone-setup/${body.contactId}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteEmailSetup(companyId: any, body: any) {
    return this.http
      .put(
        `${ApiConstant.COMPANY}/${companyId}/service-types/email-setup/${body.contactId}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //Meical Plan Details

  getPlanTypeDetails(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/medical-plans`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updatePlanTypeDetails(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/medical-plans`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteMedicalNetworkDetails(companyId: any, item: any, parentObj: any) {
    return this.http
      .delete(
        `${ApiConstant.COMPANY}/${companyId}/medical-plans/${parentObj.medicalPlanTypeId}/network-details/${item.networkDetailId}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteMedicalNetworkInfo(companyId: any, item: any, parentObj: any, firstParent: any) {
    return this.http
      .delete(
        `${ApiConstant.COMPANY}/${companyId}/medical-plans/${firstParent.medicalPlanTypeId}/network-details/${parentObj.networkDetailId}/networks/${item.networkId}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPreCertification(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/pre-certifications`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updatePreCertification(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/pre-certifications`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  deletePreCertification(companyId: any, data: any) {
    return this.http
      .delete(
        `${ApiConstant.COMPANY}/${companyId}/pre-certifications/${data.value.precertificationId}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getRoutineExams(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/exams`).pipe(
      map((response) => {
        return response;
      })
    );
  }
  updateRoutineExams(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/exams`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  deleteRoutineExams(companyId: any, data: any) {
    return this.http
      .delete(
        `${ApiConstant.COMPANY}/${companyId}/exams/${data.value.routineExamId}`,
        {}
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getMedicalNetworks(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/medical-networks`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  updateMedicalNetworks(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/medical-networks`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getReferencePricingProgram(companyId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/rbp-programs`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteRBPPortalContact(companyId: any, body: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/rbp-programs/contacts/${body.referenceContactId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //add on service
  deleteAddOnServiceContact(companyId: any, addOnserviceId: any, body: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/additional-services/${addOnserviceId}/vendor-details/${body.contactId}`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  updateReferencePricingProgram(companyId: any, body: any) {
    return this.http
      .put(`${ApiConstant.COMPANY}/${companyId}/rbp-programs`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // TPA file upload
  uploadTpaFile(companyId: any, body: any, filesArray: any = [], tpaId: any) {
    return this.http.post(`${ApiConstant.COMPANY}/${companyId}/tpas/${tpaId}/web-portal/${body.contactId}/file-upload`, filesArray)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTpaFileList(companyId: any, body: any, tpaId: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/${companyId}/tpas/${tpaId}/web-portal/${body.contactId}/files`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteTpaFile(companyId: any, body: any, tpaId: any, file: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/tpas/${tpaId}/web-portal/${body.contactId}/file-delete/${file.fileId}?filePath=${file.filePath}`, file)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  downloadFile(file: any) {
    return this.http
      .get(`${ApiConstant.COMPANY}/file-download?filePath=${file.filePath}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  uploadClientLogo(companyId: any, file: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/logo-upload`, file)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFileBase64(path: any) {
    return this.http.get(`${ApiConstant.COMPANY}/file-download?filePath=${path}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //tiles
  getTilesList(companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/tiles`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveNewTile(tile: any, companyId: any) {
    return this.http.put(`${ApiConstant.COMPANY}/${companyId}/tiles`, tile)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getTileById(tile: any, companyId: any) {
    return this.http.get(`${ApiConstant.COMPANY}/${companyId}/tiles/${tile.tileId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateTile(tile:any, companyId:any, sortIds?:any) {
    let query  = (sortIds?.beforeId || sortIds?.afterId) ? `${ApiConstant.COMPANY}/${companyId}/tiles/${tile.tileId}?beforeId=${sortIds?.beforeId}&afterId=${sortIds?.afterId}` : `${ApiConstant.COMPANY}/${companyId}/tiles/${tile.tileId}`;
    return this.http.put(query, tile)
    .pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteTile(tile:any, companyId:any) {
    return this.http.remove(`${ApiConstant.COMPANY}/${companyId}/tiles/${tile.tileId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
