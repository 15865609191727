<div [formGroup]="addOnServiceForm" class="pt-3 general">
    <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Add On Service</p>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="addInfoForms(addOnServiceForm, 'addOnServiceInfo')">
            <i-bs name="plus" class="pe-1"></i-bs> Add New Service
        </button>
    </div>
    <div formArrayName="addOnServiceInfo">
        <div *ngIf="!getFormArray(addOnServiceForm, 'addOnServiceInfo').length">
            <p class="text-center">No Service Information</p>
        </div>
        <div *ngFor="let item of getFormArray(addOnServiceForm, 'addOnServiceInfo').controls; let i = index"
            [formGroupName]="i">
            <div class="col-12 pb-4">
                <button
                    class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
                    type="button" (click)="addOnService.toggle()" [attr.aria-expanded]="!addOnService.collapsed"
                    aria-controls="collapseExample">
                    <span class="text-capitalize">
                        <i-bs [name]="!addOnService.collapsed ? 'chevron-down' : 'chevron-up'" class="pe-1"></i-bs>
                        {{item.get('vendorName')?.value || 'New Service'}}
                    </span>
                    <span>
                        <i-bs name="trash-fill" class="pull-right text-gray-main"
                            (click)="delete(i, item.value, 'addOnServiceInfo', addOnServiceForm)"></i-bs>
                    </span>
                </button>
            </div>
            <div class="pb-3" id="collapseExample" mdbCollapse #addOnService="mdbCollapse" [collapsed]="false">
                <div class="px-2  collapse-container">
                    <div class="d-flex justify-content-between pt-3">
                        <div class="col-6 d-flex align-items-center">
                            <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Name</label>
                            <div class="col-7">
                                <input type="text " class="form-control" id="vendorName" formControlName="vendorName"
                                    name="vendorName" />
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Portal URL</label>
                            <div class="col-7">
                                <input type="text" class="form-control" id="vendorPortalUrl"
                                    formControlName="vendorPortalUrl" name="vendorPortalUrl" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between pt-3">
                        <div class="col-6 d-flex align-items-center">
                            <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Phone</label>
                            <div class="col-7">
                                <input type="text" class="form-control" id="vendorPhone" formControlName="vendorPhone"
                                    name="vendorPhone" mask='(000) 000-0000' />
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Email</label>
                            <div class="col-7">
                                <input type="text" class="form-control" id="vendorEmail" formControlName="vendorEmail"
                                    name="vendorEmail" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between pt-3">
                        <div class="col-6 d-flex align-items-center">
                            <label for="name" class="pt-0 col-5 text-end pe-3"> Start Date</label>
                            <div class="col-7">
                                <input [mdbDatepicker]="startDate" type="text" class="form-control" id="startDate"
                                    (click)="startDate.open()" formControlName="startDate" name="startDate"
                                    autocomplete="off" />
                                <mdb-datepicker-toggle [mdbDatepicker]="startDate"></mdb-datepicker-toggle>
                                <mdb-datepicker #startDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                            </div>
                        </div>
                        <div class="col-6 d-flex align-items-center">
                            <label for="name" class="pt-0 col-5 text-end pe-3">End Date</label>
                            <div class="col-7">
                                <input [mdbDatepicker]="endDate" type="text" class="form-control" id="endDate"
                                    (click)="endDate.open()" formControlName="endDate" name="endDate"
                                    autocomplete="off" />
                                <mdb-datepicker-toggle [mdbDatepicker]="endDate"></mdb-datepicker-toggle>
                                <mdb-datepicker #endDate [inline]="true" [format]="'mm/dd/yyyy'" [minDate]="item.get('startDate')?.value"></mdb-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="divider py-3">
                        <hr class="divider-hr">
                        <span class="divider-label fs-3">Vendor Details</span>
                    </div>
                    <table class="table table-div table-reponsive">
                        <thead>
                            <tr>
                                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Contact Name
                                </th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 400px !important;">Contact Type</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 1</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 100px !important;">Ext</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 2</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody formArrayName="vendorDetails">
                            <tr *ngIf="!getFormArray(item, 'vendorDetails').length">
                                <th colspan="6" class="fw-bold text-center">No contact info</th>
                            </tr>
                            <tr *ngFor="let contactForm of getFormArray(item, 'vendorDetails').controls; let i = index"
                                [formGroupName]="i">
                                <td> <input type="text" class="form-control" id="contactName"
                                        formControlName="contactName" name="contactName" /></td>
                                <td> <input type="text" class="form-control" id="contactType"
                                        formControlName="contactType" name="contactType" /></td>
                                <td> <input type="text" class="form-control" id="phone1" formControlName="phone1"
                                        mask='(000) 000-0000' name="phone1" /></td>
                                <td> <input type="text" class="form-control" id="extension" formControlName="extension"
                                        name="extension" maxlength="6" minlength="4" /></td>
                                <td> <input type="text" class="form-control" id="phone2" formControlName="phone2"
                                        mask='(000) 000-0000' name="phone2" /></td>
                                <td> <input type="text" class="form-control" id="email" formControlName="email"
                                        [pattern]="parentComponent.mailformat" name="email" /></td>
                                <td>
                                    <i-bs *ngIf="!contactForm.value.loading" name="trash-fill"
                                        class="pointer text-gray-main"
                                        (click)="delete(i, contactForm.value, 'vendorDetails', item)"></i-bs>
                                    <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                        *ngIf="contactForm.value.loading">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="text-primary pointer" type="button" (click)="addContactForms(item, 'vendorDetails')"><i-bs
                            name="plus"></i-bs>Add
                        More</a>
                    <hr class="mt-5">
                    <p class="fw-bold pt-3 ps-3 fs-2">Add on Account Information</p>
                    <div class="px-2 d-flex justify-content-between"
                        [formGroup]="getControl(item, 'addOnAccountInformation')">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">PEPM Cost</label>
                                <div class="col-7">
                                    <input type="text " class="form-control" id="pepmCost" formControlName="pepmCost"
                                        name="pepmCost" [prefix]="getControl(item, 'addOnAccountInformation').get('pepmCost')?.value ? '$ ' : ''" mask="separator.2" thousandSeparator="," />
                                </div>
                            </div>
                            <div class="d-flex align-items-center pt-3">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Per Service Cost</label>
                                <div class="col-7">
                                    <input type="text" class="form-control" id="perServiceCost"
                                        formControlName="perServiceCost" name="perServiceCost" [prefix]="getControl(item, 'addOnAccountInformation').get('perServiceCost')?.value ? '$ ' : ''" mask="separator.2"
                                        thousandSeparator="," />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Account Notes</label>
                                <div class="col-7">
                                    <textarea mdbInput class="form-control" id="textAreaExample" rows="3"
                                        formControlName="accountNotes" name="accountNotes"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    </div>
    <div class="d-flex justify-content-end pb-3" *ngIf="getFormArray(addOnServiceForm, 'addOnServiceInfo').length">
        <button type="button" class="btn btn-primary " (click)="saveForms()">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
                <span class="visually-hidden">Loading...</span>
            </div>
            Save
        </button>
    </div>
</div>