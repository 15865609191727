import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {
  CompanyService,
  FormInitService,
  ToastrService,
} from '../../../../../services';
import { PreferredProviderModel } from '../../../../../model';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-preferred-provider',
  templateUrl: './preferred-provider.component.html',
  styleUrls: ['./preferred-provider.component.scss'],
})
export class PreferredProviderComponent implements OnInit {
  @Input() preferredProvider!: any;

  preferredProviderForm: UntypedFormGroup;
  saving: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private formInit: FormInitService
  ) {
    this.preferredProviderForm = this.formBuilder.group({
      preferredProviderInfo: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.preferredProvider = [];
  }

  ngOnChanges() {
    let formArray = this.getFormArray(
      this.preferredProviderForm,
      'preferredProviderInfo'
    );
    formArray.clear();
    if (this.preferredProvider) {
      this.preferredProvider.forEach((value: any) => {
        this.addInfoForms(
          this.preferredProviderForm,
          'preferredProviderInfo',
          value
        );
      });
    }
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newService = this.initValueBasedForm(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) :  formArray.insert(0, newService);
  }

  initValueBasedForm(value?: any) {
    return this.formInit.createFormGroup(
      PreferredProviderModel.setPreferredProviderModel(value)
    );
  }

  getControl(fromControl: any, controlName: string): UntypedFormGroup {
    return fromControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }

  delete(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    control.removeAt(index);
    this.saveForms();
  }

  saveForms() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId && this.preferredProviderForm.value.preferredProviderInfo.length >0) {
      this.companyService
        .deletePreferredProvider(
          this.parentComponent.currentCompanyId,
          this.preferredProviderForm.value.preferredProviderInfo
        )
        .pipe(
          finalize(() => {
            this.saving = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            this.toastrService.showSuccess(response.message);
          }
        });
    }
  }
}
