export const ActivityLogDateRange  = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "last7days", label: "Last 7 days" },
    { value: "lastMonth", label: "Last Month" },
    { value: "dateRange", label: "Date Range" }
]

export const ReportDateRange = [
    { value: "thisMonth", label: "This Month" },
    { value: "lastMonth", label: "Last Month" },
    { value: "lastQuarter", label: "Last Quarter" },
    { value: "ytd", label: "YTD" },
    { value: "lastYear", label: "Last Year" },
    { value: "dateRange", label: "Date Range" }
]
