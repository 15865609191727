import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from  '../constants';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class SurveyService {
    constructor(
        private http: HttpClientService
    ) { }

    getSurveyResponse(id:any) {
        return this.http.get(`${ApiConstant.SURVEY}/${id}`)
        .pipe(map(response => {
            return response;
        }))
    }
}