<div class="col-12 px-3">
    <div class="col">
        <div class="col-12">
            <app-report-header (baseAction)="handleBaseAction($event)"></app-report-header>
        </div>
        <div class="pt-5 mt-5" *ngIf="loading">
            <loader></loader>
        </div>
    </div>
    <!-- *ngIf="!loading && reportDetails" -->
    <div *ngIf="reportDetails && !loading">
        <div class="col-12 py-3">
            <div class="d-flex align-items-center justify-content-between">
                <div class="col-4">
                    <p class="fw-bold mb-0 ps-1">Advocacy Satisfaction Report Preview</p>
                </div>
                <div class="col-8">
                    <div class="col-12 d-flex justify-content-end">
                        <div class="col-5 me-3" *ngIf="reportDetails?.companyNpsDetail?.nps">
                            <div class="text-center bg-gray-light p-2">
                                <p class="fw-bold mb-0">Alithias Net Promoter Score: <label
                                        class="text-primary">{{reportDetails.companyNpsDetail.nps | number:
                                        '1.0-0'}}</label></p>
                            </div>
                        </div>
                        <div class="dropdown" mdbDropdown *ngIf="reportDetails?.satisfactionScoreDetail?.length">
                            <button class="btn btn-clear border dropdown-toggle" type="button" id="dropdownMenuButton"
                                aria-expanded="false" mdbDropdownToggle>
                                <i-bs name="download" class="pe-2" *ngIf="!exporting"></i-bs>
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                                    *ngIf="exporting"></span>
                                Export
                            </button>
                            <ul mdbDropdownMenu class="dropdown-menu">
                                <li>
                                    <button class="dropdown-item" type="button" (click)="exportExcel()">
                                        <i-bs name="file-spreadsheet" class="pe-2"></i-bs>
                                        Excel
                                    </button>
                                    <button class="dropdown-item" type="button" (click)="exportPdf()">
                                        <i-bs name="file-pdf" class="pe-2"></i-bs>
                                        PDF
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive ep-table" >
            <ng-container>
                <table class="table table-report w-100">
                    <thead>
                        <tr class="fs-2 bordered text-center">
                            <th scope="col" class="fw-bold text-nowrap">
                                Care Advocate
                            </th>
                            <th scope="col" class="fw-bold  text-nowrap">Number of Cases</th>
                            <th scope="col" class="fw-bold  text-nowrap">Satisfaction Score</th>
                            <th scope="col" class="fw-bold  text-nowrap">Net Promoter Score</th>
                    </thead>
                    <tbody>
                        <tr *ngIf="!reportDetails?.satisfactionScoreDetail?.length">
                            <td colspan="4" class="text-center">No records found.</td>
                        </tr>
                        <ng-container
                            *ngFor="let a of reportDetails.satisfactionScoreDetail | sort: 'asc':'name'; let i = index">
                            <tr class="fs-2 bordered text-nowrap text-center"
                                [ngClass]="{ 'row-striped': i % 2 !== 0 }">
                                <td>
                                    {{a.name}}
                                </td>
                                <td>{{a.noOfServiceRequest}}</td>
                                <td>{{a.avarageTotalScorePercentage | number: '1.0-0' }}%</td>
                                <td>{{a.nps | number: '1.0-0'}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>
</div>
<div id="pdf" class="d-none">
    <app-report-pdf-format [id]="'advocate-satisfaction'" [reportConstants]="reportConstants"
        [reportData]="reportDetails?.satisfactionScoreDetail" [filterParams]="filterParams"></app-report-pdf-format>
</div>