import { Component, Input, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CompanyService, FormInitService, ToastrService } from "src/app/services";
import { CompanyModel } from '../../../../../model';
import { CompanyAddEditComponent } from "../company-add-edit.component";
import { finalize } from 'rxjs';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss']
})
export class PlanDetails implements OnInit {

  @Input() planDetails: any
  planDetailForm: UntypedFormGroup
  saving: Boolean = false;


  constructor(
    private formInit: FormInitService,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService

  ) {
    this.planDetailForm = this.formInit.createFormGroup(CompanyModel.planDetails())
  }
  ngOnInit(): void {

  }


  ngOnChanges() {
    if (this.planDetails) {
      this.planDetailForm = this.formInit.createFormGroup(CompanyModel.planDetails(this.planDetails))
    }
  }


  onSavePlanDetail() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService.editPlanDetails(this.parentComponent.currentCompanyId, this.planDetailForm.value).pipe(finalize(() => {
        this.saving = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
        }
      })
    }
  }

  onDateStartBlur(event:any, startControlname:any, endControlname?:any) {
    if(!event.target.value) {
      this.planDetailForm.controls[startControlname].setValue(null);
      if(endControlname) {
        this.planDetailForm.controls[endControlname].clearValidators();
        this.planDetailForm.controls[endControlname].updateValueAndValidity();
      }
    }
  }

  datePickerClosed(value:any,  endControlname:string) {
    if(value instanceof Date) {
      this.planDetailForm.controls[endControlname].setValidators([Validators.required]);
      this.planDetailForm.controls[endControlname].updateValueAndValidity();
    }
  }
}