import { FormFields } from '../interfaces';
import { Injectable } from '@angular/core';
@Injectable()
export class MedicalPlanDetailsModel {
  static planTypeDetails(value: any) {
    let model: FormFields[] = [
      {
        name: 'medicalPlanTypeId',
        id: 'medicalPlanTypeId',
        controlType: 'input',
        controlName: 'medicalPlanTypeId',
        required: true,
        value: value?.medicalPlanTypeId || null,
      },
      {
        name: 'preferrdProgramType',
        id: 'preferrdProgramType',
        controlType: 'input',
        controlName: 'preferrdProgramType',
        required: true,
        value: value?.preferrdProgramType || null,
      },
      {
        controlType: 'networkDetails',
        controlName: 'networkDetails',
        name: 'networkDetails',
        id: 'networkDetails',
        value: value?.networkDetails || [],
        formArray: [],
      },
    ];

    return model;
  }

  static preCertification(value: any) {
    let model: FormFields[] = [
      {
        name: 'precertificationId',
        id: 'precertificationId',
        controlType: 'input',
        controlName: 'precertificationId',
        required: true,
        value: value?.precertificationId || null,
      },
      {
        name: 'preCertificationVendorName',
        id: 'preCertificationVendorName',
        controlType: 'input',
        controlName: 'preCertificationVendorName',
        required: true,
        value: value?.preCertificationVendorName || null,
      },
      {
        name: 'preCertificationVendorPhone',
        id: 'preCertificationVendorPhone',
        controlType: 'input',
        controlName: 'preCertificationVendorPhone',
        required: true,
        value: value?.preCertificationVendorPhone || null,
      },
      {
        name: 'preCertificationWebsite',
        id: 'preCertificationWebsite',
        controlType: 'input',
        controlName: 'preCertificationWebsite',
        required: true,
        value: value?.preCertificationWebsite || null,
      },
    ];

    return model;
  }

  static routineExams(value: any) {
    let model: FormFields[] = [
      {
        name: 'routineExamId',
        id: 'routineExamId',
        controlType: 'input',
        controlName: 'routineExamId',
        required: true,
        value: value?.routineExamId || null,
      },
      {
        name: 'examTypes',
        id: 'examTypes',
        controlType: 'input',
        controlName: 'examTypes',
        required: true,
        value: value?.examTypes || null,
      },
      {
        name: 'routineExamNotes',
        id: 'routineExamNotes',
        controlType: 'input',
        controlName: 'routineExamNotes',
        required: true,
        value: value?.routineExamNotes || null,
      },
    ];
    return model;
  }

  static networks(value: any) {
    let model: FormFields[] = [
      {
        name: 'networkId',
        id: 'networkId',
        controlType: 'input',
        controlName: 'networkId',
        required: true,
        value: value?.networkId || null,
      },
      {
        name: 'type',
        id: 'type',
        controlType: 'input',
        controlName: 'type',
        required: true,
        value: value?.type || null,
      },
      {
        name: 'website',
        id: 'website',
        controlType: 'input',
        controlName: 'website',
        required: true,
        value: value?.website || null,
      },
      {
        name: 'name',
        id: 'name',
        controlType: 'input',
        controlName: 'name',
        required: true,
        value: value?.name || null,
      },
      {
        name: 'notes',
        id: 'notes',
        controlType: 'input',
        controlName: 'notes',
        required: true,
        value: value?.notes || null,
      },
      {
        name: 'phone',
        id: 'phone',
        controlType: 'input',
        controlName: 'phone',
        required: true,
        value: value?.phone || null,
      },
    ];
    return model;
  }
  static referencePricingProgram() {
    let model: FormFields[] = [
      {
        name: 'rbpVendorName',
        id: 'rbpVendorName',
        controlType: 'input',
        controlName: 'rbpVendorName',
        required: true,
        value: null,
      },
      {
        name: 'customerServicePhone',
        id: 'customerServicePhone',
        controlType: 'input',
        controlName: 'customerServicePhone',
        required: true,
        value: null,
      },
      {
        name: 'customerServiceEmail',
        id: 'customerServiceEmail',
        controlType: 'input',
        controlName: 'customerServiceEmail',
        required: true,
        value: null,
      },
      {
        name: 'rbpNotes',
        id: 'rbpNotes',
        controlType: 'input',
        controlName: 'rbpNotes',
        required: true,
        value: null,
      },
      {
        name: 'portal',
        id: 'portal',
        controlType: 'input',
        controlName: 'portal',
        required: true,
        value: null,
      },
      {
        controlType: 'refereceBasedContactInfo',
        controlName: 'refereceBasedContactInfo',
        name: 'refereceBasedContactInfo',
        id: 'refereceBasedContactInfo',
        value: [],
        formArray:[]
      }
    ];
    return model;
  }
}
