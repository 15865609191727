import {
  OnInit,
  Component,
  ViewChild,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MdbTabChange,
  MdbTabsComponent,
} from 'mdb-angular-ui-kit/tabs/tabs.component';
import {
  CompanyService,
  FormInitService,
  ToastrService,
} from 'src/app/services';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { finalize } from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MedicalPlanDetailsModel } from 'src/app/model';
import { CompanyDropdownConstant } from 'src/app/constants';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmationModalComponent } from 'src/app/modal';
@Component({
  selector: 'app-medical-plan-details',
  templateUrl: './medical-plan-details.component.html',
  styleUrls: ['./medical-plan-details.component.scss'],
})
export class MedicalPlanDetailsComponent implements OnInit {
  @ViewChild('medicalPlanDetailTabs') medicalPlanDetailTabs: MdbTabsComponent;
  @Input() companyId: any;
  @Input() medicalPlanTypeDetail!: any;
  infoLoading: boolean = false;
  infoSaving: boolean = false;
  medicalPlanTypeForm: UntypedFormGroup;
  preCertificationForm: UntypedFormGroup;
  routineExamForm: UntypedFormGroup;
  medicalNetworksForm: UntypedFormGroup;
  referencePricingProgramForm: UntypedFormGroup;
  dropdownConstants = CompanyDropdownConstant;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private formInit: FormInitService,
    private toastrService: ToastrService,
    private companyService: CompanyService,
    private modalService: MdbModalService,
    public parentComponent: CompanyAddEditComponent
  ) {
    this.medicalPlanTypeForm = this.formBuilder.group({
      medicalPlantypeInfo: this.formBuilder.array([]),
    });
    this.preCertificationForm = this.formBuilder.group({
      preCertificationInfo: this.formBuilder.array([]),
    });
    this.routineExamForm = this.formBuilder.group({
      routineExamInfo: this.formBuilder.array([]),
    });
    this.medicalNetworksForm = this.formBuilder.group({
      medicalNetworksInfo: this.formBuilder.array([]),
    });
    this.referencePricingProgramForm = this.formInit.createFormGroup(
      MedicalPlanDetailsModel.referencePricingProgram()
    );
  }
  ngOnInit(): void { }

  ngOnChanges(): void {
    if (this.medicalPlanTypeDetail) {
      let formArray = this.getFormArray(
        this.medicalPlanTypeForm,
        'medicalPlantypeInfo'
      );
      formArray.clear();
      this.medicalPlanTypeDetail.forEach((obj: any) => {
        this.addMedicalPlanTypeDetail(
          this.medicalPlanTypeForm,
          'medicalPlantypeInfo',
          obj
        );
      });
    }
  }

  ngAfterViewInit() {
    this.medicalPlanDetailTabs.setActiveTab(0);
    this.infoLoading = false;
    this.cd.detectChanges();
  }

  onTabChange(event: MdbTabChange) {
    if (this.parentComponent.currentCompanyId) {
      switch (event.index) {
        case 0:
          this.parentComponent.getPlanTypeDetails();
          break;
        case 1:
          this.getPreCertification();
          break;
        case 2:
          this.getRoutineExams();
          break;
        case 3:
          this.getMedicalNetworks();
          break;
        case 4:
          this.getRefrencePricingProgram();
          break;
      }
    }
  }

  getPreCertification() {
    this.infoLoading = true;
    this.companyService
      .getPreCertification(this.parentComponent.currentCompanyId)
      .pipe(
        finalize(() => {
          this.infoLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          if (response.data.length) {
            let formArray = this.getFormArray(
              this.preCertificationForm,
              'preCertificationInfo'
            );
            formArray.clear();

            response.data.forEach((obj: any) => {
              this.addPreCertificationForm(
                this.preCertificationForm,
                'preCertificationInfo',
                obj
              );
            });
          }
        }
      });
  }
  getRoutineExams() {
    this.infoLoading = true;
    this.companyService
      .getRoutineExams(this.parentComponent.currentCompanyId)
      .pipe(
        finalize(() => {
          this.infoLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          if (response.data.length) {
            let formArray = this.getFormArray(
              this.routineExamForm,
              'routineExamInfo'
            );
            formArray.clear();

            response.data.forEach((obj: any) => {
              this.addRoutineExams(
                this.routineExamForm,
                'routineExamInfo',
                obj
              );
            });
          }
        }
      });
  }
  getMedicalNetworks() {
    this.infoLoading = true;
    this.companyService
      .getMedicalNetworks(this.parentComponent.currentCompanyId)
      .pipe(
        finalize(() => {
          this.infoLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          if (response.data.length) {
            let formArray = this.getFormArray(
              this.medicalNetworksForm,
              'medicalNetworksInfo'
            );
            formArray.clear();

            response.data.forEach((obj: any) => {
              this.addMedicalNetworks(
                this.medicalNetworksForm,
                'medicalNetworksInfo',
                obj
              );
            });
          }
        }
      });
  }

  getRefrencePricingProgram() {
    this.infoLoading = true;
    this.companyService
      .getReferencePricingProgram(this.parentComponent.currentCompanyId)
      .pipe(
        finalize(() => {
          this.infoLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.referencePricingProgramForm.patchValue(response.data);
          if(response.data.refereceBasedContactInfo.length){
            let formArray = this.getFormArray(this.referencePricingProgramForm, 'refereceBasedContactInfo');
            formArray.clear();
            response.data.refereceBasedContactInfo.forEach((contact:any) => {
              this.addContactForms(this.referencePricingProgramForm, 'refereceBasedContactInfo', contact)
            });
          }
        }
      });
  }

  getFormArray(formControl: any, formArrayName: string): UntypedFormArray {
    return formControl.controls[formArrayName] as UntypedFormArray;
  }

  initMedicalPlanType(value: any) {
    return this.formInit.createFormGroup(
      MedicalPlanDetailsModel.planTypeDetails(value)
    );
  }
  initPreCertification(value: any) {
    return this.formInit.createFormGroup(
      MedicalPlanDetailsModel.preCertification(value)
    );
  }
  initRoutineExam(value: any) {
    return this.formInit.createFormGroup(
      MedicalPlanDetailsModel.routineExams(value)
    );
  }
  initMedicalNetwork(value: any) {
    return this.formInit.createFormGroup(
      MedicalPlanDetailsModel.networks(value)
    );
  }

  addMedicalPlanTypeDetail(formName: any, arrayName: any, obj?: any) {
    let formArray: any;
    const newMedicalPlanType = this.initMedicalPlanType(obj);
    formArray = this.getFormArray(formName, arrayName);
    obj ? formArray.push(newMedicalPlanType) : formArray.insert(0, newMedicalPlanType);
    if (obj.networkDetails.length) {
      obj.networkDetails.forEach((obj: any) => {
        this.addNetworkDetails(newMedicalPlanType, 'networkDetails', obj);
      });
    }
  }

  addPreCertificationForm(formName: any, arrayName: any, obj?: any) {
    let formArray: any;
    const newPreCertification = this.initPreCertification(obj);
    formArray = this.getFormArray(formName, arrayName);
    obj ? formArray.push(newPreCertification) : formArray.insert(0, newPreCertification);;
  }
  addRoutineExams(formName: any, arrayName: any, obj?: any) {
    let formArray: any;
    const newRoutineExam = this.initRoutineExam(obj);
    formArray = this.getFormArray(formName, arrayName);
    obj ? formArray.push(newRoutineExam) : formArray.insert(0, newRoutineExam);
  }
  addMedicalNetworks(formName: any, arrayName: any, obj?: any) {
    let formArray: any;
    const newMedicalNetwork = this.initMedicalNetwork(obj);
    formArray = this.getFormArray(formName, arrayName);
    obj ? formArray.push(newMedicalNetwork) : formArray.insert(0, newMedicalNetwork)  
  }

  addNetworkDetails(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    let newNetworkDetails = this.initNetworkDetails(value);
    formArray = this.getFormArray(formName, 'networkDetails');
    value ? formArray.push(newNetworkDetails) : formArray.insert(0, newNetworkDetails);
    if (value.networkInfos.length) {
      value.networkInfos.forEach((obj: any) => {
        this.addNetworkInfo(newNetworkDetails, 'networkInfos', obj);
      });
    }
  }

  addNetworkInfo(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    let newNetworkDetails = this.initNetworkInfo(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newNetworkDetails) : formArray.insert(0,  newNetworkDetails);
  }

  addContactForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initContactDetails(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails);
  }


  initNetworkDetails(value: any) {
    return this.formBuilder.group({
      networkDetailId: [value?.networkDetailId],
      networkType: [value?.networkType],
      networkInfos: this.formBuilder.array([]),
    });
  }

  initNetworkInfo(value: any) {
    return this.formBuilder.group({
      type: [value?.type],
      individual: [value?.individual],
      family: [value?.family],
      accumulateMethod: [value?.accumulateMethod],
      note: [value?.note],
      networkId: [value?.networkId]
    });
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      referenceContactId: [value?.referenceContactId],
      portalAccessContact: [value?.portalAccessContact],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email]
    })
  }

  savePreCertification() {
    this.infoSaving = true;
    this.companyService
      .updatePreCertification(
        this.parentComponent.currentCompanyId,
        this.preCertificationForm.value.preCertificationInfo
      )
      .pipe(
        finalize(() => {
          this.infoSaving = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.toastrService.showSuccess(response.message);
          this.getPreCertification();
        }
      });
  }
  saveRoutineExams() {
    this.infoSaving = true;
    this.companyService
      .updateRoutineExams(
        this.parentComponent.currentCompanyId,
        this.routineExamForm.value.routineExamInfo
      )
      .pipe(
        finalize(() => {
          this.infoSaving = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.toastrService.showSuccess(response.message);
          this.getRoutineExams();
        }
      });
  }
  saveMedicalNetworks() {
    this.infoSaving = true;
    this.companyService
      .updateMedicalNetworks(
        this.parentComponent.currentCompanyId,
        this.medicalNetworksForm.value.medicalNetworksInfo
      )
      .pipe(
        finalize(() => {
          this.infoSaving = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.toastrService.showSuccess(response.message);
          this.getMedicalNetworks();
        }
      });
  }
  saveReferencePricingProgram() {
    this.infoSaving = true;
    this.companyService
      .updateReferencePricingProgram(
        this.parentComponent.currentCompanyId,
        this.referencePricingProgramForm.value
      )
      .pipe(
        finalize(() => {
          this.infoSaving = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.toastrService.showSuccess(response.message);
          this.getRefrencePricingProgram();
        }
      });
  }

  savePlanTypeDetail() {
    this.infoSaving = true;
    this.companyService
      .updatePlanTypeDetails(
        this.parentComponent.currentCompanyId,
        this.medicalPlanTypeForm.value.medicalPlantypeInfo
      )
      .pipe(
        finalize(() => {
          this.infoSaving = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.data) {
          this.toastrService.showSuccess(response.message);
          this.parentComponent.getPlanTypeDetails();
        }
      });
  }

  deleteMedicalNetwork(
    index: number,
    item: any,
    arrayName: any,
    formControlName: any
  ) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    control.removeAt(index);
    this.saveMedicalNetworks();
  }

  deleteMedicalPlantype(
    index: number,
    item: any,
    arrayName: any,
    formControlName: any
  ) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    control.removeAt(index);
    this.savePlanTypeDetail();
  }

  deletePortalContactInfo(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if(item.referenceContactId) {
      item.loading = true;
      this.companyService.deleteRBPPortalContact(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
        item.loading = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          control.removeAt(index);
          this.toastrService.showSuccess(response.message);
        }
      })
    }
    else{
      control.removeAt(index);
    }
  }

  openNetworkConfirmationModel(
    index: number,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    if (arrayName == 'networkDetails' && item.value.networkId != null) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.networkType}</strong> ?`,
        },
      });

      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          this.deleteMedicalNetwork(index, item, arrayName, formControlName);
        }
      });
    } else {
      let control = <UntypedFormArray>formControlName.controls[arrayName];
      control.removeAt(index);
    }
  }

  openPlanTypeConfirmationModel(
    index: number,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    if (item.value.medicalPlanTypeId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.preferrdProgramType}</strong> ?`,
        },
      });

      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          this.deleteMedicalPlantype(index, item, arrayName, formControlName);
        }
      });
    } else {
      let control = <UntypedFormArray>formControlName.controls[arrayName];
      control.removeAt(index);
    }
  }
  openNetworkModal(
    index: number,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    if (arrayName == 'medicalNetworksInfo' && item.value.networkId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.type}</strong> ?`,
        },
      });

      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          this.deleteMedicalNetwork(index, item, arrayName, formControlName);
        }
      });
    } else {
      let control = <UntypedFormArray>formControlName.controls[arrayName];
      control.removeAt(index);
    }
  }

  openNetworkDetailConfirmationModel(
    index: number,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    if (item.value.networkDetailId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.networkType}</strong> ?`,
        },
      });

      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          this.deleteMedicalNetworkDetail(
            index,
            item,
            formControlName,
            arrayName
          );
        }
      });
    } else {
      let control = <UntypedFormArray>formControlName.controls[arrayName];
      control.removeAt(index);
    }
  }

  deletePreCertification(
    index: any,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if (arrayName == 'preCertificationInfo' && item.value.precertificationId) {
      item.value.loading = true;
      this.companyService
        .deletePreCertification(this.parentComponent.currentCompanyId, item)
        .pipe(
          finalize(() => {
            item.value.loading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.data) {
            this.toastrService.showSuccess(response.message);
            control.removeAt(index);
          }
        });
    } else {
      control.removeAt(index);
    }
  }

  deleteMedicalNetworkDetail(index: any, item: any, formControlName: any, arrayName: any) {
    item.value.loading = true;
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if (item.value.networkDetailId) {
      item.value.loading = true;
      this.companyService
        .deleteMedicalNetworkDetails(this.parentComponent.currentCompanyId, item.value, formControlName.value)
        .pipe(
          finalize(() => {
            item.value.loading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.data) {
            this.toastrService.showSuccess(response.message);
            control.removeAt(index);
          }
        });
    } else {
      control.removeAt(index);
    }
  }

  deleteRoutineExams(
    index: any,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];

    if (arrayName == 'routineExamInfo' && item.value.routineExamId) {
      item.value.loading = true;
      this.companyService
        .deleteRoutineExams(this.parentComponent.currentCompanyId, item)
        .pipe(
          finalize(() => {
            item.value.loading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.data) {
            this.toastrService.showSuccess(response.message);
            control.removeAt(index);
          }
        });
    } else {
      control.removeAt(index);
    }
  }

  deleteMedicalNetworkInfo(index: any, item: any, formControlName: any, arrayName: any, firstParent: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if (item.value.networkId) {
      this.companyService.deleteMedicalNetworkInfo(this.parentComponent.currentCompanyId, item.value, formControlName.value, firstParent)
        .pipe(
          finalize(() => {
            item.value.loading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.data) {
            this.toastrService.showSuccess(response.message);
            control.removeAt(index);
          }
        });
    } else {
      control.removeAt(index);
    }
  }

  delete(index: any, item: any, formControlName: any, arrayName: any,
    firstParent?: any) {
    item.value.loading = true;
    switch (arrayName) {
      case 'networkInfos':
        this.deleteMedicalNetworkInfo(index, item, formControlName, arrayName, firstParent);
        break;
      case 'preCertificationInfo':
        this.deletePreCertification(index, item, formControlName, arrayName);
        break;
      case 'routineExamInfo':
        this.deleteRoutineExams(index, item, formControlName, arrayName);
        break;
    }
  }

  openDeleteConfirmationModel(
    index: any,
    item: any,
    formControlName: any,
    arrayName: any
  ) {
    switch (arrayName) {
      case 'medicalPlantypeInfo':
        this.openPlanTypeConfirmationModel(
          index,
          item,
          formControlName,
          arrayName
        );
        break;
      case 'networkDetails':
        this.openNetworkDetailConfirmationModel(
          index,
          item,
          formControlName,
          arrayName
        );
        break;
      case 'medicalNetworksInfo':
        this.openNetworkModal(
          index,
          item,
          formControlName,
          arrayName
        );
        break;
    }
  }

  getNetworDisplayName(value:any) {
    return this.dropdownConstants.medicalPlanNetworkType.find((x:any)=>x.value == value)?.name;
  }

}
