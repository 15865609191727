import { LoginModel } from '../model';
import { DecodeTokenUtil } from './tokenDecoder.util';

export class CurrentUser {
    static getCurrentUser(){
        let currentUser:any = {};
        try {
            currentUser = DecodeTokenUtil.decodeToken(localStorage.getItem('currentUser'));
            if (currentUser) {
                return currentUser;
            }
            else {
                return null;
            }
        }
        catch (ex) {
            currentUser = new LoginModel();
            return currentUser;
        }
    }

    static setCurrentUser(userDetails:any) {
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', userDetails);
      }
    
}