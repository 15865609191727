import { FormFields } from '../interfaces';

import { Injectable } from '@angular/core';

@Injectable()
export class PreferredProviderModel {
  static setPreferredProviderModel(value?: any) {
    let model: FormFields[] = [
      {
        name: 'preferredId',
        id: 'preferredId',
        controlType: 'input',
        controlName: 'preferredId',
        required: true,
        value: value?.preferredId || null,
      },
      {
        name: 'vendorName',
        id: 'vendorName',
        controlType: 'input',
        controlName: 'vendorName',
        required: true,
        value: value?.vendorName || '',
      },
      {
        name: 'vendorPortalUrl',
        id: 'vendorPortalUrl',
        controlType: 'input',
        controlName: 'vendorPortalUrl',
        required: true,
        value: value?.vendorPortalUrl || '',
      },
      {
        name: 'vendorPhone',
        id: 'vendorPhone',
        controlType: 'input',
        controlName: 'vendorPhone',
        value: value?.vendorPhone || null,
      },
      {
        name: 'vendorEmail',
        id: 'vendorEmail',
        controlType: 'input',
        controlName: 'vendorEmail',
        value: value?.vendorEmail || '',
      },
      {
        name: 'preferredProviderAvailable',
        id: 'preferredProviderAvailable',
        controlType: 'input',
        controlName: 'preferredProviderAvailable',
        value: value?.preferredProviderAvailable || false,
      },
      {
        name: 'preferredProgramType',
        id: 'preferredProgramType',
        controlType: 'input',
        controlName: 'preferredProgramType',
        value: value?.preferredProgramType || '',
      },
      {
        name: 'providerNotes',
        id: 'providerNotes',
        controlType: 'input',
        controlName: 'providerNotes',
        value: value?.providerNotes || '',
      },
      {
        name: 'networkLimitation',
        id: 'networkLimitation',
        controlType: 'input',
        controlName: 'networkLimitation',
        value: value?.networkLimitation || '',
      },
    ];
    return model;
  }
}
