<div
  class="toast mx-auto"
  [class]="severity"
  id="static-example"
  role="alert"
  aria-live="assertive"
  aria-atomic="true">
  <div class="toast-header" [class]="severity">
    <i-bs [name]="icon" [class]="text" class="pe-2" width="18" height="18"></i-bs>
    <strong class="me-auto" [class]="text" style="font-size: 16px;">{{ summary }}</strong>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      [class] ="text"
      (click)="notificationRef.close()"></button>
  </div> 
  <div class="toast-body" [class]="severity" [class]="text">{{ detail }}</div>
</div>
