<div class="view vh-100">
  <!-- Mask & flexbox options-->
  <div class="h-100 bg-primary d-flex justify-content-center align-items-center overflow-auto login-background">
    <!-- Content -->
    <div class="container h-100">
      <div class="row flex-column h-100 flex-nowrap">
        <!--Grid column-->
        <div class="col mx-auto" style="max-width: 600px; max-height: 570px;">
          <p class="text-center mt-lg-5 mb-4">
            <img src="assets/images/login-logo.png" alt="" />
          </p>
          <!--Form-->
          <div class="card rounded-2">
            <div class="card-header" *ngIf="!stepper.activeStepIndex">
              <h5 class="fw-bold m-0 py-2"> Set password</h5>
            </div>
            <div class="card-body p-0">
              <!--Header-->
              <mdb-stepper [linear]="true" #stepper>
                <mdb-step [name]="'Step 2'">

                  <form class="p-2" #activationForm="ngForm">
                    <div class="md-form">
                      <div class="md-form">
                        <div class="d-flex form-inline">

                          <label for="password" class="form-label col-3 pt-2">Password</label>
                          <div class="input-group mb-3 ps-2">
                            <input mdbInput [type]="showPassword ? 'text' : 'password'" class="form-control px-3" id="password"
                              [(ngModel)]="password" aria-describedby="basic-addon3" name="password" required
                              (keyup)="checkPassword()" (paste)="checkPassword()" />
                              <span class="input-group-text ps-3 pe-3" (click)="showPasswordToggle()" *ngIf="password">
                                <i class="fa-eye clickable" [ngClass]="{'far': !showPassword, 'fas': showPassword}"></i>
                              </span>
                          </div>
                        </div>
                      </div>
                      <div class="md-form">
                        <div class="d-flex form-inline">
                          <label for="confirm" class="form-label col-3 pt-2">Confirm
                            Password</label>
                          <div class="input-group mb-3 ps-2">
                            <input mdbInput [type]="showPassword ? 'text' : 'password'" class="form-control px-3" id="confirm"
                              [(ngModel)]="confirmPassword" aria-describedby="basic-addon3" name="confirm" required
                              (keyup)="checkPassword()" (paste)="checkPassword()" />
                              <span class="input-group-text ps-3 pe-3" (click)="showPasswordToggle()" *ngIf="confirmPassword">
                                <i class="fa-eye clickable" [ngClass]="{'far': !showPassword, 'fas': showPassword}"></i>
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="form-row">
                      <p class="fw-bold">Password must include</p>
                      <ul class="ps-2">
                        <li class="d-flex">
                          <span class="pt-0">
                            <i-bs name="check-circle-fill"
                              [ngClass]="{'text-secondary': validPassword.islengthValid , 'text-danger':  !validPassword.islengthValid}">
                            </i-bs>
                          </span>
                          <label class="ps-3 pt-1"> 8-20 Characters</label>
                        </li>
                        <li class="d-flex">
                          <span>
                            <i-bs name="check-circle-fill"
                              [ngClass]="{'text-secondary': validPassword.hasCapital , 'text-danger':  !validPassword.hasCapital}">
                            </i-bs>
                          </span><label class="ps-3 pt-1">At least one capital letter</label>
                        </li>
                        <li class="d-flex">
                          <span>
                            <i-bs name="check-circle-fill"
                              [ngClass]="{'text-secondary': validPassword.hasNumber , 'text-danger':  !validPassword.hasNumber}">
                            </i-bs>
                          </span>
                          <label class="ps-3 pt-1">At least one number</label>
                        </li>
                        <li class="d-flex">
                          <span>
                            <i-bs name="check-circle-fill"
                              [ngClass]="{'text-secondary': validPassword.hasSpecialChr , 'text-danger':  !validPassword.hasSpecialChr}">
                            </i-bs>
                          </span><label class="ps-3 pt-1">At least one special character
                            @#$%</label>
                        </li>
                        <li class="d-flex">
                          <span>
                            <i-bs name="check-circle-fill"
                              [ngClass]="{'text-secondary': validPassword.isSame , 'text-danger':  !validPassword.isSame}">
                            </i-bs>
                          </span><label class="ps-3 pt-1">Password matched.</label>
                        </li>
                      </ul>
                    </div>
                    <hr class="w-100">
                    <div class="footer d-flex justify-content-end">
                      <button class="btn btn-gradient" (click)="confirm()" [disabled]="!isValid">
                        <span  class="spinner-grow spinner-grow-sm me-2" role="status" *ngIf="activating"
                        aria-hidden="true"></span>Confirm
                      </button>
                    </div>
                  </form>
                </mdb-step>
                <mdb-step [name]="'Step 3'">
                  <div class="text-center py-4">
                    <i-bs name="check-circle-fill" width="60px" height="60px" class="text-secondary"></i-bs>
                    <h5 class="fw-bold pt-4 text-secondary">{{isActivate ? 'User Activated' : 'Password Reset'}}</h5>
                    <p *ngIf="isActivate">The account for <span class="text-primary fw-bold">{{userName}}</span> has been activated.</p>
                    <p *ngIf="!isActivate">The password for <span class="text-primary fw-bold">{{userName}}</span> has been reset.</p>
                    <p class="pb-3">Please try logging in.</p>
                    <button class="btn btn-gradient btn-lg" (click)="login()"> Login
                    </button>
                  </div>
                </mdb-step>
              </mdb-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>