<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (baseAction)="manageCompany($event)" [searchList]="companyList.rows"
        (searchLogEmit)="searchCompany($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="companyLoading">
        <loader></loader>
    </div>
    <div class="table-responsive" *ngIf="!companyLoading">
        <table class="table table-striped mb-0">
            <thead>
                <tr>
                    <th class="fw-bold">ID</th>
                    <th class="fw-bold" style="width: 200px !important;">Company Name</th>
                    <th class="fw-bold">Company Code</th>
                    <th class="fw-bold">TPA</th>
                    <th class="fw-bold">Created By</th>
                    <th class="fw-bold">Created At</th>
                    <th class="fw-bold" style="width:100px !important;">Status</th>
                    <th style="width: 150px !important;"></th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-center" *ngIf="!companyList.rows.length && !companyLoading">
                    <td colspan="9" class="fw-bold">Companies not found.</td>
                </tr>
                <tr class="text-left" *ngFor="let company of companyList.rows | sort:'desc'">
                    <td>{{company.alithiasCompanyId}}</td>
                    <td class="">
                        <div class="d-flex">
                            <!-- <a href="javascript:void(0)" class="pe-2">
                                <i-bs name="plus-circle"></i-bs>
                            </a> -->
                            <label>{{company.clientLegalName}}</label>
                        </div>
                    </td>
                    <td>{{company.groupNumber}}</td>
                    <td>{{company.tpas[0]?.tpaName}}</td>
                    <td>{{company.createdBy}}</td>
                    <td>{{company.createdAt | date: 'MM/dd/yyyy'}}</td>
                    <td>
                        <span class="badge badge-success" *ngIf="company.companyStatus">Active</span>
                        <span class="badge badge-danger" *ngIf="!company.companyStatus">Inactive</span>
                    </td>
                    <td class="p-1">
                        <div mdbDropdown class="">
                            <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                                aria-expanded="false">
                                <i-bs name="three-dots-vertical"></i-bs>
                            </button>
                            <ul autoClose="true" mdbDropdownMenu class="dropdown-menu shadow" style="min-width: 120px;">
                                <li>
                                    <button class="dropdown-item" type="button" (click)="manageCompany(company)">
                                        Edit
                                    </button>
                                </li>
                                <li>
                                    <button class="dropdown-item" type="button"
                                        (click)="openConfirmationModal(company)">
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="py-3 mb-0 fw-bold fs-2">Showing {{companyList.count >= searchConfig.limit ?
            searchConfig.limit : companyList.count}} of {{companyList.count}} {{companyList.count > 1 ? 'results' :
            'result'}}</p>
    </div>
</div>