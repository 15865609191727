import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { map } from 'rxjs/operators';
import {QueryParams} from '../utils';


@Injectable({
    providedIn: 'root'
})
export class ProcedureService {
    constructor(
        private http: HttpClientService
    ) { }

    getProcedureCategoryList() {
        return this.http.get(`${ApiConstant.PROCEDURE}/category`)
        .pipe(map(response => {
            return response;
        }))
    }

    getProcedureList(keyword?:any, requestType?:any) {
        const queryParams = QueryParams.addParams(keyword);
        return this.http.get(`${ApiConstant.PROCEDURE}?` + queryParams, null, requestType)
        .pipe(map(response => {
            return response;
        }))
    }
    
    addNewProcedure(procedureInfo:any) {
        return this.http.post(`${ApiConstant.PROCEDURE}`, procedureInfo)
        .pipe(map(response => {
            return response;
        }))
    }

    editProcedure(procedureInfo:any) {
        return this.http.put(`${ApiConstant.PROCEDURE}/${procedureInfo.id}`, procedureInfo)
        .pipe(map(response => {
            return response;
        }))
    }

    deleteProcedure(procedureInfo:any) {
        return this.http.remove(`${ApiConstant.PROCEDURE}/${procedureInfo.id}`)
        .pipe(map(response => {
            return response;
        }))
    }

    getStatefromLookup() {
        return this.http.get(`${ApiConstant.LOOKUP}/state`)
        .pipe(map(response => {
            return response;
        }))
    }

    searchProcedure(params:any) {
        return this.http.get(`${ApiConstant.PROCEDURE}/search?keyword=${params.keyword}`)
        .pipe(map(response => {
            return response;
        }))
    }

    getRevenueCodeList() {
        return this.http.get(`${ApiConstant.PROCEDURE}/revenue-code?limit=600`)
        .pipe(map(response => {
            return response;
        }))
    }

    getProcedureCptCode(keyword:any) {
        return this.http.get(`${ApiConstant.PROCEDURE}/cpt-code?keyword=${keyword}&limit=100`)
        .pipe(map(response => {
            return response;
        }))
    }

    getProcedureById(id:any) {
        return this.http.get(`${ApiConstant.PROCEDURE}/${id}`)
        .pipe(map(response => {
            return response;
        }))
    }
}
