export class LoginModel {
    companies: any;
    createdAt: any;
    createdBy: any;
    dob: string;
    email: string;
    emailConfirm: boolean;
    enforcedPasswordChange: boolean;
    firstName: string;
    id: any = null;
    isActive: boolean;
    lastName: string;
    middleName: string;
    otp: any;
    password: string ='';
    permissions: any;
    phoneNumber: string;
    roles: any[] = [];
    workingHour: any;
    roleId:any;
}