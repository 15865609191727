import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { ApiConstant } from '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private http: HttpClientService) {}

  getAuthenticationToken() {
    return this.http.get(`${ApiConstant.TOKEN}/`).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
