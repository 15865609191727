import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { finalize } from 'rxjs';

import { ProcedureService, ToastrService } from '../../../../services';

@Component({
  selector: 'app-procedure-add-edit',
  templateUrl: './procedure-add-edit.component.html',
  styleUrls: ['./procedure-add-edit.component.scss']
})
export class ProcedureAddEditComponent implements OnInit {
  @ViewChild('procedureTabs') procedureTabs: MdbTabsComponent;

  procedureForm: UntypedFormGroup;
  category: any;
  states: any;
  outPatientToggle: boolean = false;
  savingProcedure: boolean = false;
  loading: boolean = true;
  currentProcedureId: any;
  procedure: any;
  revenueCodeList: any = [];
  cptResults: any = [];
  finding: boolean = false;
  deleting: boolean = false;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private procedureService: ProcedureService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private renderer: Renderer2
  ) {
    this.setProcedureDetailForm()

  }

  ngOnInit(): void {
    this.getCategoryList();
    this.getStateList();
    this.getRevenueCodeList();
    this.currentProcedureId = this.route.snapshot.params['id'];
    if (this.currentProcedureId) {
      this.getProcedure(this.currentProcedureId);
    }
  }


  ngAfterViewInit() {
    this.procedureTabs.setActiveTab(1);
    const element1 = document.querySelector('.nav-tabs') as HTMLElement;
    const element2 = document.querySelector('.tab-n') as HTMLElement;
    if (element1.classList.contains('nav-tabs')) {
      this.renderer.removeClass(element1, 'col-3');
      this.renderer.addClass(element1, 'company-vetical-tab');
    }
    if (element2.classList.contains('row')) {
      this.renderer.removeClass(element2, 'row');
    }
    this.cd.detectChanges();
  }

  setProcedureDetailForm() {
    this.procedureForm = this.formBuilder.group({
      id: null,
      procedureId: [],
      procedureName: ['', Validators.required],
      description: [''],
      included: null,
      category: [[]],
      isOutPatient: null,
      priceLimit: this.formBuilder.array([]),
      revenueCode: this.formBuilder.array([]),
      procedureMapping: this.formBuilder.array([]),
      isActive: true
    })

  }
  get revenueCodeForm() {
    return this.procedureForm.controls["revenueCode"] as UntypedFormArray;
  }

  get pricingForm() {
    return this.procedureForm.controls["priceLimit"] as UntypedFormArray;
  }
  get procedureMappingForm() {
    return this.procedureForm.controls["procedureMapping"] as UntypedFormArray;
  }

  initPriceLimitForm(price?: any) {
    return this.formBuilder.group({
      state: [price?.state, Validators.required],
      min: [price?.min, Validators.required],
      max: [price?.max, Validators.required]
    })
  }

  initProcedureMappingForm(procedure?: any) {
    return this.formBuilder.group({
      cptCode: [procedure?.cptCode, Validators.required],
      isPrimary: procedure?.isPrimary || false,
      shortDescription: procedure?.shortDescription,
      displayProcedureValue: [procedure ? (procedure.cptCode + ' - ' + procedure.shortDescription) : null, Validators.required]
    })
  }

  initRevenueForm(value?: any) {
    return this.formBuilder.group({
      revenueCode: [value?.revenueCode, Validators.required],
      description: [value?.description],
      revenueDisplayName: [value ? (value.revenueCode + ' - ' + value.description) : null, Validators.required]
    })
  }

  addForms(type: any, value?: any) {
    switch (type) {
      case 'pricingLimit':
        const newPrice = this.initPriceLimitForm(value);
        this.pricingForm.push(newPrice);
        break;
      case 'procedureMapping':
        const newMapping = this.initProcedureMappingForm(value);
        this.procedureMappingForm.push(newMapping);
        break;
      case 'revenueMapping':
        const newRevenue = this.initRevenueForm(value);
        this.revenueCodeForm.push(newRevenue);
        break;
    }
  }

  toggleIsPrimary(index: any) {
    this.procedureForm.controls["procedureMapping"].value[index].isPrimary = !this.procedureForm.controls["procedureMapping"].value[index].isPrimary;
    if (this.procedureForm.controls["procedureMapping"].value[index].isPrimary) {
      // this.procedureForm.controls["procedureMapping"].value.forEach((x: any) => {
      //   x.isPrimary = false;
      // });
      this.procedureForm.controls["procedureMapping"].value[index].isPrimary = true;
    }
  }

  getCategoryList() {
    this.procedureService.getProcedureCategoryList().subscribe((response: any) => {
      if (response && response.success) {
        this.category = response.data.rows;
        this.category = this.category.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
      }
    })
  }

  getStateList() {
    this.procedureService.getStatefromLookup().subscribe((response: any) => {
      if (response && response.success) {
        this.states = response.data.rows;
      }
    })
  }

  submitForm(boolean?: any) {
    if (this.validateProcedureCode()) {
      this.savingProcedure = true;
      let control = <UntypedFormArray>this.procedureForm.controls['revenueCode'];
      this.procedureForm.controls['isOutPatient'].setValue(this.outPatientToggle);
      this.procedureForm.value.revenueCode.forEach((x: any, i: any) => {
        x.revenueCode = x.revenueCode > 0 ? +x.revenueCode : control.removeAt(i)
      })
      if (this.currentProcedureId) {
        this.editProcedure(this.procedureForm.value, boolean);
      }
      else {
        this.procedureService.addNewProcedure(this.procedureForm.value).pipe(finalize(() => {
          this.savingProcedure = false;
        })).subscribe((response: any) => {
          if (response && response.success) {
            this.toastrService.showSuccess(response.message);
            this.router.navigate([`admin/procedure/edit/${response.data.id}`]);
            this.ngAfterViewInit();
            this.currentProcedureId = response.data.id;
            this.procedure = response.data;
          }
        })
      }
    }
  }

  getProcedure(procedureId: any) {
    this.procedureService.getProcedureById(procedureId).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.procedure = response.data;
        this.procedureForm.patchValue(this.procedure);
        this.outPatientToggle = this.procedure.isOutPatient;
        this.procedure.priceLimit.forEach((x: any) => {
          this.addForms('pricingLimit', x)
        })
        this.procedure.procedureMapping.forEach((x: any) => {
          this.addForms('procedureMapping', x);
        })
        this.procedure.revenueCode.forEach((x: any) => {
          this.addForms('revenueMapping', x);
        })
      }
    })
  }

  toggleIsOutPatient(event: any) {
    this.outPatientToggle = !this.outPatientToggle;
  }

  editProcedure(procedureInfo: any, boolean?:any) {
    this.procedureService.editProcedure(procedureInfo).pipe(finalize(() => {
      this.savingProcedure = false;
        this.deleting = boolean 
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.procedure = response.data.updatedData;
      }
    })
  }

  delete(index: number, item: any, arrayName: any) {
    let control = <UntypedFormArray>this.procedureForm.controls[arrayName];
    if (arrayName == 'revenueCode') {
      control.removeAt(index);
      if(this.procedure[arrayName].find((x: any) => x.revenueCode === item.revenueCode)) {
        this.deleting = true;
        this.submitForm(false);
      }
    }
    else {
      control.removeAt(index);
      if(this.procedure[arrayName].find((x:any)=> JSON.stringify(x)=== JSON.stringify(item))) {
        this.submitForm();
      }
    }
  }

  validateProcedureCode() {
    let isValid = 1;
    let control = <UntypedFormArray>this.procedureForm.controls['procedureMapping'];
    this.procedureForm.value.procedureMapping.forEach((x: any, i: any) => {
      if (this.procedureForm.value.procedureMapping.find((y: any) => y.cptCode == x.cptCode).length > 1) {
        isValid = 0;
        control.removeAt(i);
      }
    })
    if (!isValid) {
      this.toastrService.showError("Duplicate procedure code found.");
    }
    return isValid;
  }

  getRevenueCodeList() {
    this.procedureService.getRevenueCodeList().subscribe((response: any) => {
      if (response && response.success) {
        this.revenueCodeList = response.data.rows;
      }
    })

  }

  revenueCodeSelect(revenue: any, formGroup: any) {
    if (revenue) {
      const selected = revenue.split(' - ');
      if (revenue) {
        formGroup.controls['revenueCode'].setValue(selected[0]);
        formGroup.controls['description'].setValue(selected[1]);
      }
    }
  }

  filter(value: string) {
    if (typeof (value) == 'string' && value.length >= 3) {
      this.finding = true;
      this.cptResults = [];
      this.procedureService.getProcedureCptCode(value).pipe(finalize(() => {
        this.finding = false;
      })).subscribe((response: any) => {
        if (response && response.success && response.data.rows.length) {
          this.cptResults = response.data.rows;
        }
      })
    }
    else {
      this.cptResults = [];
    }

  }

  onCptSelect(event: any, formGroup: any) {
    if (event.option.value) {
      const selected = event.option.value.split(' - ');
      if (selected) {
        formGroup.controls['cptCode'].setValue(selected[0]);
        formGroup.controls['shortDescription'].setValue(selected[1]);
      }
    }
  }
}


