import { Component } from '@angular/core';
import { finalize } from 'rxjs';
import { ReportService } from 'src/app/services';

@Component({
  selector: 'app-account-status-update',
  templateUrl: './account-status-update.component.html',
  styleUrls: ['./account-status-update.component.scss'],
})
export class AccountStatusUpdateComponent {
  accountStatusData: any[] = [];

  constructor(private reportService: ReportService) {}

  ngOnInit(): void {
    this.getAccountStatusReport();
  }

  toggleCollapse(item: any) {
    item.collapsed = !item.collapsed;
  }

  getAccountStatusReport() {
    this.reportService
      .getAccountStatusReport()
      .pipe(finalize(() => {}))
      .subscribe((response: any) => {
        if (response && response.success) {
          if (response.data.length > 0) {
            this.accountStatusData = response.data.map((item: any) => {
              return { ...item, collapsed: true };
            });
          }
        }
      });
  }
}
