import {Injectable} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup} from '@angular/forms';
import {FormFields} from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class FormInitService {
  constructor(private fb: UntypedFormBuilder) {}

  /**
   * Creates form group
   * @param form
   * @returns form group
   */
  createFormGroup(form: FormFields[]): UntypedFormGroup {
    let group =  this.fb.group({});
    form.forEach(field => {
      if (field.subFields) {
        const grp = this.createFormGroup(field.subFields);
        group.addControl(field.controlName, grp);
      }
      if (field.formArray) {
        group.addControl(field.controlName, this.returnFormArray());
      }
      group.addControl(field.controlName, this.createControl(field));
    });
    return group;
  }

  /**
   *
   * @param config
   * @returns
   */
  createControl(config: FormFields) {
    const {disabled, value} = config;
    return this.fb.control({disabled: disabled, value});
  }

  returnFormArray() {
    return this.fb.array([]);
  }
}
