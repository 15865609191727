import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs';

import { FormInitService, CompanyService, ToastrService } from '../../../../../services';
import { CompanyModel } from '../../../../../model';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { CompanyDropdownConstant } from '../../../../../constants';

@Component({
  selector: 'app-billings',
  templateUrl: './billings.component.html',
  styleUrls: ['./billings.component.scss']
})
export class BillingsComponent implements OnInit {
  @Input() billings!: any;

  billingsForm: UntypedFormGroup;
  saving: boolean = false;
  dropdownConstants = CompanyDropdownConstant;


  constructor(
    private formInit: FormInitService,
    private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService
  ) { this.billingsForm = this.formInit.createFormGroup(CompanyModel.setCompanyBillings()) }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.billings) {
      this.billingsForm = this.formInit.createFormGroup(CompanyModel.setCompanyBillings(this.billings))
      if (this.billings.contactInfo.length) {
        let formArray = this.getFormArray('contactInfo');
        formArray.clear()
        this.billings.contactInfo.forEach((x: any) => {

          this.addContactForm(x);
        });
      }
      if (this.billings.pricingInfo.length) {
        let formArray = this.getFormArray('pricingInfo');
        formArray.clear();
        this.billings.pricingInfo.forEach((x: any) => {
          this.addPricingForm('pricingInfo', '', x);
        });
      }
      if (this.billings?.addOnAlithiasServices.length) {
        let formArray = this.getFormArray('addOnAlithiasServices');
        formArray.clear();
        this.billings.addOnAlithiasServices.forEach((x: any) => {
          this.addPricingForm('addOnAlithiasServices', 'vendorName', x);
        });
      }
    }

  }

  getControl(controlName: string): UntypedFormGroup {
    return this.billingsForm.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(arrayName: string): UntypedFormArray {
    return this.billingsForm.controls[arrayName] as UntypedFormArray;
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      contactName: [value?.contactName],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
      contactId: [value?.contactId]
    })
  }

  initPricingInfo(value?: any, additionalControlName?: any) {
    const formGroupConfig: any = {
      serviceType: [value?.serviceType],
      pricingType: [value?.pricingType],
      pricingAmount: [value?.pricingAmount],
      pricingId: [value?.pricingId],

    }
    if (additionalControlName) {
      formGroupConfig[additionalControlName] = value ? value[additionalControlName] : '';
    }
    return this.formBuilder.group(formGroupConfig);
  }



  addContactForm(value?: any) {
    let formArray: any;
    const newContactDetails = this.initContactDetails(value);
    formArray = this.getFormArray('contactInfo');
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails)
  }

  addPricingForm(array: any, additionalFieldName?: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initPricingInfo(value, additionalFieldName);
    formArray = this.getFormArray(array);
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails)
  }

  delete(index: number, item: any, arrayName: any) {
    let control = <UntypedFormArray>this.billingsForm.controls[arrayName];
    item.loading = true;
    switch (arrayName) {
      case 'pricingInfo':
        if (item.pricingId) {
          this.companyService.deletePricingInfo(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'contactInfo':
        if (item.contactId) {
          this.companyService.deletePricigContactInfo(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;

      case 'addOnAlithiasServices':
        if (item.pricingId) {
          this.companyService.deleteAlithiasServicePricingInfo(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
    }
  }

  saveBillingsInfo() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService.editBillingsByCompany(this.parentComponent.currentCompanyId, this.billingsForm.value).pipe(finalize(() => {
        this.saving = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
        }
      })
    }
  }
}
