import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import {
  AuthenticationService,
  UserService,
  ToastrService,
} from '../../services';
import { LoginModel } from '../../model';
import { CurrentUser } from '../../utils/currentUser.utils';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginModel: LoginModel = new LoginModel();
  showPassword: boolean = false;
  loading: boolean = false;
  actionType: any = 'login';
  sending: boolean = false;
  currentYear = new Date().getFullYear();
  lockedUser: boolean = false;
  minutes: number = 5;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  login(type: any) {
    if (this.loginModel.password && this.loginModel.email) {
      type !== 'resend' ? (this.loading = true) : (this.sending = true);
      let params = {
        email: this.loginModel.email,
        password: this.loginModel.password,
      };
      this.authenticationService
        .login(params)
        .pipe(
          finalize(() => {
            type !== 'resend' ? (this.loading = false) : (this.sending = false);
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            if (response.data.isLocked) {
              this.displayLockMessage(response.data)
              return;
            }
            this.toastrService.showSuccess(
              'Verification code sent successfully.'
            );
            this.actionType = 'otp';
          }
        });
    }
  }

  showPasswordToggle() {
    this.showPassword = !this.showPassword;
  }

  onCodeChanged(code: any) {
    this.loginModel.otp = code;
  }

  onCodeCompleted(code: any) {
    if (code) {
      this.loading = true;
      let params = {
        email: this.loginModel.email,
        otp: code,
      };
      this.userService
        .verifyOtp(params)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            if (response.data.isLocked) {
              this.displayLockMessage(response.data)
              return;
            }
            localStorage.setItem('token', response.data);
            CurrentUser.setCurrentUser(response.data);
            this.router.navigate(['/welcome']);
          }
        });
    }
  }

  baseAction(action: any) {
    this.actionType = action;
  }

  submit() {
    switch (this.actionType) {
      case 'fpw':
        this.loading = true;
        this.submitFogotPw();
        break;
      case 'otp':
        if (this.loginModel.otp) {
          this.onCodeCompleted(this.loginModel.otp);
        }
        break;
    }
  }

  submitFogotPw() {
    this.userService
      .forgotPassword(this.loginModel.email)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(
            'An email has been sent to your account. Please reset your password through the email.'
          );
          this.actionType = 'login';
        }
      });
  }

  displayLockMessage(data: any) {
    const currentDate = moment.utc().toDate();
    const milliSecond = moment(data.lockExpirationTime).diff(currentDate, 'milliseconds');
    this.minutes = moment(data.lockExpirationTime).diff(currentDate, 'minutes');
    this.lockedUser = true;
    setTimeout(() => {
      this.lockedUser = false;
    }, milliSecond);
  }
}
