export class RawFilterModel {
  //array
  createdFrom: any = null;
  createdTo: any = null;
  procedureId: any = null;
  companyId: any = null;
  caseType: any = null; //call in type
  callRegarding: any = null;
  //single value
  requestedByEmail: any = null;
  requestedToEmail: any = null;
  contactVia: any = null; //medium of contact
  status: any = null;
  //not present
  closedFrom: any = null;
  closedTo: any = null;
  mediumOfContact: any = null;
}
