import { Component, EventEmitter, Output } from '@angular/core';

import { RawFilterModel } from 'src/app/model';
import {MetaDataDropdownConstant} from '../../../constants'
import { CommonListService} from 'src/app/services';
import * as moment from 'moment';

@Component({
  selector: 'app-raw-filter',
  templateUrl: './raw-filter.component.html',
  styleUrls: ['./raw-filter.component.scss']
})
export class RawFilterComponent {

  @Output() submitAction: EventEmitter<any> = new EventEmitter();

  rawFilterModel:any = new RawFilterModel ();
  userList:any =[];
  companyList:any = [];
  autocompleteCompList: any= [];
  procedureList:any =[];
  dropdownConstants:any = MetaDataDropdownConstant;

  constructor(private commonListService : CommonListService) {}

  async ngOnInit() {
    this.userList = await this.commonListService.getUserList({limit:0, isActive:true});
    this.companyList = await this.commonListService.getCompanyList({
      limit: 0,
      companyStatus: true,
    });
    this.autocompleteCompList = this.companyList;
    this.procedureList = await this.commonListService.getProcedureList({limit:0})
  }
  
  onDatePickerClosed() {
    this.rawFilterModel.createdFrom =  this.rawFilterModel.createdFrom ? moment(new Date(this.rawFilterModel.createdFrom)).format('MM/DD/YYYY') : null;
    this.rawFilterModel.createdTo =  this.rawFilterModel.createdTo ? moment(new Date(this.rawFilterModel.createdTo)).format('MM/DD/YYYY') : null;
    this.rawFilterModel.closedFrom =  this.rawFilterModel.closedFrom ? moment(new Date(this.rawFilterModel.closedFrom)).format('MM/DD/YYYY') : null;
    this.rawFilterModel.closedTo =  this.rawFilterModel.closedTo ? moment(new Date(this.rawFilterModel.closedTo)).format('MM/DD/YYYY') : null;
  }


  displayProcedureValue = (value: any): string => {
    return value
      ? value.procedureName
      : ''
  };

  searchList(list:any, event:any) {
    switch(list) {
      case 'company' :
        if (event.term && event?.term?.length) {
          this.autocompleteCompList = this.companyList.filter((selectedItem:any) => selectedItem.clientLegalName.toLowerCase().includes(event.term.toLowerCase()));
        }
        else {
          this.autocompleteCompList = this.companyList;
        }
      break;
      case 'procedure' :
        if (event.term && event.term.length) {
          this.procedureList = this.procedureList.filter((selectedItem:any) => selectedItem.procedureName.toLowerCase().includes(event.term.toLowerCase()));
        }
        else {
          this.procedureList = this.procedureList;
        }
      break;
    }

  }

  searchRawFilter() {
    this.submitAction.emit(this.rawFilterModel);
  }

  reset() {
    this.rawFilterModel= new RawFilterModel();
    this.autocompleteCompList = this.companyList;
    this.submitAction.emit('reset');
  } 


}
