import { Component, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { MdbFileUploadComponent } from 'mdb-angular-file-upload';
import {
  CompanyService,
  FileStatusService,
  MemberService,
  ToastrService,
} from 'src/app/services';
import { base64Converter } from '../../utils';
import { SingleFieldModalComponent } from 'src/app/modal';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss'],
})

export class MemberProfileComponent {
  @ViewChild('fileUpload') fileUpload!: MdbFileUploadComponent;
  modalRef: MdbModalRef<SingleFieldModalComponent> | null = null;
  memberDetail: any;
  detailLoading: boolean = false;
  profileTabs = ['Member Information', 'Case History', 'Uploaded Files', 'Insurance ID'];
  activeTab = 0;
  caseHistoryList: any = [];
  fileList: any = [];
  tabLoader: boolean = false;
  dependentlist: any = [];
  dropdownInput: any;
  addingFile: boolean = false;

  constructor(
    private memberService: MemberService,
    private fileStatusService: FileStatusService,
    private toastrService: ToastrService,
    private fileUtil: base64Converter,
    public sanitizer: DomSanitizer,
    private modalService: MdbModalService,
    private companyService: CompanyService
  ) { }

  selectTab(index: any) {
    this.activeTab = index;
    switch (index) {
      case 1:
        this.tabLoader = true;
        this.getCaseHistory(this.memberDetail.alithiasUID);
        break;
      case 2:
        this.tabLoader = true;
        this.getFileList(this.memberDetail);
        break;
    }
  }

  changeDependant(dependent: any) {
    this.tabLoader = true;
    this.getMemberDetail(dependent, false);
  }

  getMemberDetail(member: any, firstLoad: boolean = true) {
    if (member == 'reset') {
      this.reset();
    } else {
      this.detailLoading = firstLoad;
      return new Promise(async (resolve) => {
        this.memberService
          .getMemberInfoById(member.alithiasUID)
          .pipe(
            finalize(() => {
              this.tabLoader = false;
            })
          )
          .subscribe(async (response: any) => {
            if (response && response.success) {
              this.memberDetail = response.data;
              // this.memberDetail.dependentList = firstLoad ? await this.getMemberDependentList(member.alithiasUID) : this.memberDetail.dependentList;
              this.dependentlist = firstLoad
                ? await this.getMemberDependentList(member.alithiasUID)
                : this.dependentlist;
              this.dependentlist = this.dependentlist.map((x: any) => {
                return {
                  ...x,
                  selected: x.alithiasUID == member.alithiasUID,
                };
              });
              this.memberDetail.benefitStartDate =
                this.memberDetail.enrollments.length > 1
                  ? moment(
                    this.memberDetail.enrollments[length - 1].startDate
                  ).format('MM/DD/YYYY')
                  : this.memberDetail.enrollments[0].startDate
                    ? moment(this.memberDetail.enrollments[0].startDate).format(
                      'MM/DD/YYYY'
                    )
                    : null;
              this.memberDetail.benefitEndDate =
                this.memberDetail.enrollments.length > 1
                  ? this.memberDetail.enrollments[length - 1].endDate
                    ? moment(
                      this.memberDetail.enrollments[length - 1].endDate
                    ).format('MM/DD/YYYY') : null : this.memberDetail.enrollments[0].endDate ? moment(this.memberDetail.enrollments[0].endDate).format(
                      'MM/DD/YYYY') : null;
              this.memberDetail.networkInfo = this.memberDetail.enrollments?.length > 1 ? this.memberDetail.enrollments[length - 1]?.primaryNetworkName : this.memberDetail.enrollments[0]?.primaryNetworkName;
              resolve(true);
            }
          });
      });
    }
  }

  getMemberDependentList(auid: any) {
    return new Promise((resolve) => {
      this.memberService
        .getMemberDependentList(auid)
        .pipe(
          finalize(() => {
            this.detailLoading = false;
            this.tabLoader = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data.rows);
          } else {
            resolve([]);
          }
        });
    });
  }

  getCaseHistory(auid: any) {
    this.tabLoader = true;
    this.caseHistoryList = [];
    this.dropdownInput = this.dependentlist.find(
      (x: any) => x.alithiasUID == auid
    ).alithiasUID;
    this.memberService
      .getMemberCaseHistory(auid)
      .pipe(
        finalize(() => {
          this.tabLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.caseHistoryList = response.data.rows;
        }
      });
  }

  getFileList(member: any) {
    this.tabLoader = true;
    this.fileList = [];
    this.dropdownInput = this.dependentlist.find(
      (x: any) => x.alithiasUID == member.alithiasUID
    );
    this.memberService
      .getMemberFileList(member?.alithiasUID)
      .pipe(
        finalize(() => {
          this.tabLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.fileList = response.data.rows;
        }
      });
  }

  reset() {
    this.memberDetail = null;
    this.activeTab = 0;
    this.dropdownInput = null;
    this.caseHistoryList = [];
  }

  downlodFile(file: any) {
    file.downloading = true;
    this.fileStatusService
      .getMemberFile(file?.path)
      .pipe(
        finalize(() => {
          file.downloading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          file.base64 = response.data;
          this.convertBase64ToFile(file);
        } else {
          this.toastrService.showError('An error occurred:');
        }
      });
  }

  convertBase64ToFile(file: any) {
    try {
      // Decode the Base64 string to binary data
      const byteCharacters = atob(file.base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: file.contentType });

      // Save the file using FileSaver.js
      FileSaver.saveAs(blob, file.name); // Replace 'output_file.jpg' with the desired file name and extension
    } catch (error: any) {
      this.toastrService.showError('An error occurred:', error);
    }
  }

  async fileAdded(e: any) {
    this.addingFile = true;
    this.fileUpload.disabled = true;
    let fileArray: any = [];
    for (let item of e) {
      if (item && item.size) {
        let idBody = {
          fileName: item.name,
          contentType: item.type,
          base64: await this.fileUtil.changefileContentToBase64(item),
          fileId: null,
          filePath: null,
        };
        fileArray.push(idBody)
      }
    }
    fileArray = this.memberDetail.insuranceCardInfo?.fileInfo?.length ? [...fileArray, ...this.memberDetail.insuranceCardInfo.fileInfo] : fileArray;
    this.memberService.postMemberInsuranceID(this.memberDetail.alithiasUID, { fileInfo: fileArray }).pipe(finalize(() => {
      this.addingFile = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.memberDetail.insuranceCardInfo = response.data;
        this.fileUpload.reset();
        this.fileUpload.disabled = false;
      }
    })
  }

  renameIdCard(idInfo: any) {
    const fileExt =  idInfo.fileName.split('.')[1]
    this.modalRef = this.modalService.open(SingleFieldModalComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-sm modal-dialog-centered',
      data: {
        modalTitle: 'Rename',
        fieldName: 'Name',
        fieldValue: idInfo.fileName.split('.')[0]
      }
    })
    this.modalRef.onClose.subscribe(async (idName: any) => {
      if (idName) {
        idInfo.uploadingId = true;
        idInfo.fileName =  `${idName}.${fileExt}`;
        await this.updateIDCard(idInfo);
        idInfo.uploadingId = false;
      }
    });
  }

  updateIDCard(event: any) {
    return new Promise((resolve)=>{
      this.memberService.updateIdInfo(this.memberDetail.alithiasUID, event).pipe(finalize(() => {
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          let renamedObj = this.memberDetail.insuranceCardInfo.fileInfo.find((x: any) => x.fileId == event.fileId)
          renamedObj.fileName = response.data.fileName;
        }
        resolve(true)
      })
    })
  }

  deleteIDCard(id: any) {
    id.uploadingId = true;
    this.memberService.deleteIdInfo(this.memberDetail.alithiasUID, id).pipe(finalize(() => {
      id.uploadingId = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.memberDetail.insuranceCardInfo.fileInfo = this.memberDetail.insuranceCardInfo.fileInfo.filter((x: any) => x.fileId !== id.fileId);
      }
    })
  }
  
  viewPfd(file:any) {
    file.uploadingId = true;
    let fileName = file.filePath.split('.com/');
    this.companyService.downloadFile({filePath:fileName[1]}).subscribe((response:any)=>{
      if(response && response.data){
        const blob = this.base64ToBlob(response.data.file, response.data.contentType);
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        file.uploadingId = false;
      }
    })
  }

  base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
}
