let frequencyOrder: any = {
    Daily: 1,
    Weekly: 2,
    Monthly: 3,
};
let fileStatusOrder: any = {
    overdue: 1,
    late: 2,
    received: 3
}
let sortDirection:number = 1
export class SortUtil {
    static sortList(list: any[], column: any) {
        switch (column) {
            case 'frequency.interval':
                list = list.sort((a, b) => {
                    const intervalA = a.frequency.interval;
                    const intervalB = b.frequency.interval;
                    return frequencyOrder[intervalA] - frequencyOrder[intervalB];
                });
                list = sortDirection == -1 ? list.reverse() : list;
                break;
            case 'status.fileStatus':
                list = list.sort((a, b) => b.status.days - a.status.days);
                list = list.sort((a, b) => {
                    const statusA = a.status.fileStatus;
                    const statusB = b.status.fileStatus;
                    return fileStatusOrder[statusA] - fileStatusOrder[statusB];
                });
                list = sortDirection == -1 ? list.reverse() : list;
                break;
            default:
                list = list.sort((a, b) => {
                    const valueA = this.getPropertyValue(a, column);
                    const valueB = this.getPropertyValue(b, column);
                    if (typeof valueA === 'object' && typeof valueB === 'object') {
                        // Both values are objects, compare their properties
                        const nestedPropA = this.getNestedPropertyValue(valueA, column);
                        const nestedPropB = this.getNestedPropertyValue(valueB, column);
                        if ((nestedPropA).toLowerCase() < (nestedPropB).toLowerCase()) {
                            return -1 * sortDirection;
                        } else if ((nestedPropA).toLowerCase() > (nestedPropB).toLowerCase()) {
                            return 1 * sortDirection;
                        } else {
                            return 0;
                        }
                    } else {
                        // At least one value is not an object, compare the values directly
                        if ((valueA).toLowerCase() < (valueB).toLowerCase()) {
                            return -1 * sortDirection;
                        } else if ((valueA).toLowerCase() > (valueB).toLowerCase()) {
                            return 1 * sortDirection;
                        } else {
                            return 0;
                        }
                    }
                });
                return {list:list, sortDirection: sortDirection}
        }
    }

    static getPropertyValue(item: any, column: string): any {
        const keys = column.split('.');

        let value = item;
        for (const key of keys) {
            value = value[key];
        }

        return value;
    }

    static getNestedPropertyValue(item: any, column: string): any {
        const nestedProp = this.getPropertyValue(item, column);
        return typeof nestedProp !== 'object' ? nestedProp : '';
    }

}