import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../services';
import { CurrentUser } from '../../utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUserInfo: any = {}
  currentModule: string = 'Dashboard';
  editModule: string = '';
  constructor(private authenticationService: AuthenticationService, private router: Router,  private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentUserInfo = CurrentUser.getCurrentUser();
  }

  ngDoCheck() {
    this.route.queryParams.subscribe(params => {
      this.editModule = params['name'];
    });
    this.getModuleName();
  }

  getModuleName() {
    if ( this.router.url.split('/')[1] === '404') {
      this.currentModule = 'Not Found'
    }
    else {
      if( this.router.url.split('/')[2]){
        if(this.router.url.split('/')[2].includes('?')) {
          let mdl =  this.router.url.split('/')[2].split('?')
          this.currentModule = mdl[0].includes('-')? mdl[0].replace(/-/g, ' ' ) : mdl[0];
          this.currentModule = this.currentModule.includes('-') ? this.currentModule.replace(/-/g, ' ' ) : this.currentModule;
        }
        else{
          this.currentModule = `${this.router.url.split('/')[2].includes('-') ? this.router.url.split('/')[2].replace(/-/g, ' '): this.router.url.split('/')[2]}`;
        }
      }
      else{
        this.currentModule = this.router.url.split('/')[1].includes('-') ? this.router.url.split('/')[1].replace(/-/g, ' ') : this.router.url.split('/')[1];
      }
    }
  }
}
