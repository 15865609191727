import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import * as moment from 'moment';

import { ActivityLogService } from '../../services';
import { ActivityLogSearchModel } from '../../model';
import { PipeFormat, TableConfig } from '../../interfaces';


@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  searchKeyword: any = '';
  searchConfig = {
    // action: { label: 'Export .xlsx', type: 'export' },
    showAdvancedSearch: true,
    showToggle: true,
    formValue: this.searchKeyword,
    btnClass: 'btn-clear',
    module: 'activityLog',
    limit : 25
  }
  tableConfig: TableConfig = {
    objectData: [
      {
        headerKey: 'Created Date', dataKey: 'createdAt',
        type: {
          format: PipeFormat.DATE,
          dateFormat: 'MM/dd/yyyy',
        },
      },
      { headerKey: 'User', dataKey: 'name' },
      { headerKey: 'Log Type', dataKey: {type:{}} },
      { headerKey: 'Module', dataKey: 'log.module' },
      { headerKey: 'Event', dataKey: 'event.type' },
      { headerKey: 'Note', dataKey: 'log.note' }
    ],
    tableClass: 'table datatable-table mt-4',
    headerClass: 'fw-bold',
    baseActions: [],
    dataType: '',
    isSearchable: true,
  };
  activityLogList: any = [];
  userList: any;
  responsive: boolean = true;
  maxSize: number = 7;
  listLoading: boolean = false;
  activityLogModel = new ActivityLogSearchModel();

  constructor(private activityLogService: ActivityLogService) { }

  ngOnInit(): void {
    this.handlePageChange();
  }

  getActivityLog(filterParams: any) {
    this.activityLogList = [];
    this.listLoading = true;
    this.activityLogService.getActivityLog(filterParams).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.listLoading = false;
        this.activityLogList = response.data.data;
        this.activityLogList.activities = this.activityLogList.activities.map((x: any) => {
          return { ...x, createdAt: new Date(x.createdAt) }
        });
      }
    })
  }


  stringify(att: any) {
    if (att) {
      let string = '';
      const data = Object.keys(att);
      data.forEach((x, index) => {
        string = string + x + ':' + att[x];
        if (index < data.length - 1) {
          string = string + ',' + ' ';
        }
      });
      return string;
    }
    else {
      return '';
    }

  }

  handlePageChange(event?: any) {
    if(event) {
      event.createdAt = event?.createdDateTo ? moment(event?.createdDateFrom).format('MM/DD/YYYY') + '-' + moment(event?.createdDateTo).format('MM/DD/YYYY') : (event?.createdDateFrom ? moment(event?.createdDateFrom).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'));
    }
    else{  
      this.activityLogModel.createdAt = moment().format('MM/DD/YYYY');
    }
    this.getActivityLog(event ? event : this.activityLogModel );
  }

}
