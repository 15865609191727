import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { ApiConstant } from '../constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClientService) { }

  getCareReportTemplateList(requestType?:any) {
    return this.http.get(`${ApiConstant.EMAIL}/templates`, null, requestType).pipe(
      map((response) => {
        return response;
      })
    );
  }
  
  getTemplateSubject(code:any, requestType?:any) {
    return this.http.get(`${ApiConstant.EMAIL}/templates/${code}`, null, requestType).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCaseFileList(caseId:any,  requestType?:any) {
    return this.http.get(`${ApiConstant.CASE}/files/${caseId}`, null, requestType).pipe(
      map((response) => {
        return response;
      })
    );
  }
}