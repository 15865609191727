<div class="p-3 raw-filter">
  <mdb-accordion>
    <mdb-accordion-item [collapsed]="false">
      <ng-template mdbAccordionItemHeader class="px-3">Report Filters</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="col-12 px-2">
          <div class="row">
            <div class="col-3 px-2">
              <label for="date" class="form-label">Created Date From</label>
              <div class="input-group input-group-lg">
                <input mdbInput [mdbDatepicker]="createdFromDatePicker" type="text" class="form-control"
                  id="createdFromDatePicker" (click)="createdFromDatePicker.open()" name="createdFrom"
                  placeholder="mm/dd/yyyy" [(ngModel)]="rawFilterModel.createdFrom" autocomplete="off" />
                <mdb-datepicker-toggle [mdbDatepicker]="createdFromDatePicker"></mdb-datepicker-toggle>
                <mdb-datepicker #createdFromDatePicker [inline]="true" [format]="'mm/dd/yyyy'"
                  (closed)="onDatePickerClosed()"></mdb-datepicker>
              </div>
            </div>
            <div class="col-3 px-2">
              <label for="date" class="form-label">Created Date To </label>
              <div class="input-group input-group-lg">
                <input mdbInput [mdbDatepicker]="createdToDatePicker" type="text" class="form-control"
                  id="createdToDatePicker" (click)="createdToDatePicker.open()" name="createdTo"
                  placeholder="mm/dd/yyyy" [(ngModel)]="rawFilterModel.createdTo" autocomplete="off" />
                <mdb-datepicker-toggle [mdbDatepicker]="createdToDatePicker"></mdb-datepicker-toggle>
                <mdb-datepicker #createdToDatePicker [inline]="true" [format]="'mm/dd/yyyy'"
                  (closed)="onDatePickerClosed()"></mdb-datepicker>
              </div>
            </div>
            <div class="col-3 px-2">
              <label for="logType" class="form-label">Closed Date From</label>
              <div class="input-group input-group-lg">
                <input mdbInput [mdbDatepicker]="closedFromDatePicker" type="text" class="form-control"
                  id="closedFromDatePicker" (click)="closedFromDatePicker.open()" name="closedFrom"
                  placeholder="mm/dd/yyyy" [(ngModel)]="rawFilterModel.closedFrom" autocomplete="off" />
                <mdb-datepicker-toggle [mdbDatepicker]="closedFromDatePicker"></mdb-datepicker-toggle>
                <mdb-datepicker #closedFromDatePicker [inline]="true" [format]="'mm/dd/yyyy'"
                  (closed)="onDatePickerClosed()"></mdb-datepicker>
              </div>
            </div>
            <div class="col-3 px-2">
              <label for="logType" class="form-label">Closed Date To</label>
              <div class="input-group input-group-lg">
                <input mdbInput [mdbDatepicker]="closedToDatePicker" type="text" class="form-control"
                  id="closedToDatePicker" (click)="closedToDatePicker.open()" name="closedTo" placeholder="mm/dd/yyyy"
                  [(ngModel)]="rawFilterModel.closedTo" autocomplete="off" />
                <mdb-datepicker-toggle [mdbDatepicker]="closedToDatePicker"></mdb-datepicker-toggle>
                <mdb-datepicker #closedToDatePicker [inline]="true" [format]="'mm/dd/yyyy'"
                  (closed)="onDatePickerClosed()"></mdb-datepicker>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-2">
          <div class="row pt-3">
            <div class="col-3 px-2">
              <label for="date" class="form-label">Select Creator</label>
              <ng-select [(ngModel)]="rawFilterModel.requestedByEmail" class="form-control p-0" [items]="userList.rows"
                name="comp" placeholder="Select your option" bindLabel="firstName" bindValue="email"
                [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                [clearSearchOnAdd]="true" notFoundText="Type to search" typeToSearchText="Type to search"
                style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{ item.firstName
                    }}{{item.middleName ? " " + item.middleName + " " : " "}}{{ item.lastName }}</label>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-3 px-2">
              <label for="date" class="form-label">Select Assignee</label>
              <ng-select [(ngModel)]="rawFilterModel.requestedToEmail" class="form-control p-0" [items]="userList.rows"
                name="comp" placeholder="Select your option" bindLabel="firstName" bindValue="email"
                [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                [clearSearchOnAdd]="true" notFoundText="Type to search" typeToSearchText="Type to search"
                style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{ item.firstName
                    }}{{item.middleName ? " " + item.middleName + " " : " "}}{{ item.lastName }}</label>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-3 px-2">
              <label for="logType" class="form-label">Select Company</label>
              <ng-select [(ngModel)]="rawFilterModel.companyId" class="form-control p-0" [items]="autocompleteCompList | sort : 'asc' : 'clientLegalName'"
                name="comp" placeholder="Select your option" bindLabel="clientLegalName" bindValue="alithiasCompanyId"
                [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                (search)="searchList('company', $event)"  (clear)="searchList('company', $event)" (remove)="searchList('company', $event)"[clearSearchOnAdd]="true" notFoundText="Type to search"
                typeToSearchText="Type to search" style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{
                    item.clientLegalName | titleCase 
                    }}</label>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-3 px-2">
              <label for="logType" class="form-label">Select Procedure</label>
              <ng-select [(ngModel)]="rawFilterModel.procedureId" class="form-control p-0" [items]="procedureList | sort : 'asc' : 'procedureName'"
                name="proc" placeholder="Select your option" bindLabel="procedureName" bindValue="procedureId"
                [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                (search)="searchList('procedure', $event)" [clearSearchOnAdd]="true" notFoundText="Type to search"
                typeToSearchText="Type to search" style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{
                    item.procedureName | titleCase
                    }}</label>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 px-2">
          <div class="row pt-3">
            <div class="col-3 px-2">
              <label for="date" class="form-label">Select Call In Type</label>
              <ng-select [(ngModel)]="rawFilterModel.caseType" class="form-control p-0"
                [items]="dropdownConstants.callInType | sort : 'asc' : 'label'" name="callInType" placeholder="Select your option"
                bindLabel="label" bindValue="value" [selectableGroupAsModel]="false" [multiple]="true"
                [closeOnSelect]="false" [searchable]="true" [clearSearchOnAdd]="true" style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{
                    item.label
                    }}</label>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-3 px-2">
              <label for="date" class="form-label">Select Call Regarding</label>
              <ng-select [(ngModel)]="rawFilterModel.callRegarding" class="form-control p-0"
                [items]="dropdownConstants.callRegarding | sort : 'asc' : 'label'" name="callRegarding" placeholder="Select your option"
                bindLabel="label" bindValue="value" [selectableGroupAsModel]="false" [multiple]="true"
                [closeOnSelect]="false" [searchable]="true" [clearSearchOnAdd]="true" style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{
                    item.label
                    }}</label>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-3 px-2">
              <label for="date" class="form-label">Select Request Status</label>
              <ng-select [(ngModel)]="rawFilterModel.status " class="form-control p-0"
                [items]="dropdownConstants.caseStatus | sort : 'asc' : 'label'" name="status" placeholder="Select your option" bindLabel="label"
                bindValue="value" [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false"
                [searchable]="true" [clearSearchOnAdd]="true" notFoundText="Type to search"
                typeToSearchText="Type to search" style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{ item.label }}</label>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-3 px-2">
              <label for="date" class="form-label">Select Medium of Contact</label>
              <ng-select [(ngModel)]="rawFilterModel.mediumOfContact" class="form-control p-0"
                [items]="dropdownConstants.mediumOfContact | sort : 'asc' : 'label'" name="moc" placeholder="Select your option"
                bindLabel="label" bindValue="value" [selectableGroupAsModel]="false" [multiple]="true"
                [closeOnSelect]="false" [searchable]="true" [clearSearchOnAdd]="true" notFoundText="Type to search"
                typeToSearchText="Type to search" style="min-height: 2.5rem">
                <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
                    class="form-check-input" />
                  <label class="ps-2 text-break" style="width: 90%">{{ item.label }}</label>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 pt-3">
          <div class="d-flex justify-content-end ps-5 pe-1">
            <div class="col-3 d-flex">
              <button class="btn btn-sm w-100 btn-secondary px-1 me-1" (click)="searchRawFilter()" [disabled]="
                  !rawFilterModel.createdFrom &&
                  !rawFilterModel.createdTo &&
                  !rawFilterModel.closedFrom &&
                  !rawFilterModel.closedTo &&
                  !rawFilterModel.requestedByEmail &&
                  !rawFilterModel.requestedToEmail &&
                  !rawFilterModel.companyId &&
                  !rawFilterModel.procedureId &&
                  !rawFilterModel.caseType &&
                  !rawFilterModel.callRegarding &&
                  !rawFilterModel.status &&
                  !rawFilterModel.mediumOfContact
                ">
                Search
              </button>
              <button class="btn btn-sm w-100 btn-clear border px-1 ms-2" (click)="reset()">
                Reset
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>