import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ServiceCaseService {
    constructor(
        private http: HttpClientService
    ) { }

    getRawFilterData(params:any) {
        const queryParams = QueryParams.addParams(params);
        return this.http.get(`${ApiConstant.CASE}?` + queryParams)
        .pipe(map(response => {
            return response;
        }))
    }
}