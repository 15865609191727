import { Injectable } from "@angular/core";

@Injectable()
export class base64Converter {
    changefileContentToBase64(file: any) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64String = reader.result?.toString().split(',')[1];
              if (base64String) {
                resolve(base64String);
              } else {
                reject(new Error('Failed to convert the file to base64.'));
              }
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
    }

    // convertBase64ToFile(file:any) {
    //     try {
    //       // Decode the Base64 string to binary data
    //       const byteCharacters = atob(file.base64);
    //       const byteArrays = [];
    
    //       for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    //         const slice = byteCharacters.slice(offset, offset + 512);
    
    //         const byteNumbers = new Array(slice.length);
    //         for (let i = 0; i < slice.length; i++) {
    //           byteNumbers[i] = slice.charCodeAt(i);
    //         }
    
    //         const byteArray = new Uint8Array(byteNumbers);
    //         byteArrays.push(byteArray);
    //       }
    
    //       const blob = new Blob(byteArrays, { type: file.type });
    
    //       // Save the file using FileSaver.js
    //       FileSaver.saveAs(blob, file.fileName); // Replace 'output_file.jpg' with the desired file name and extension
    //     } catch (error:any) {
    //       this.toastrService.showError('An error occurred:', error);
    //     }
    //   }
}




