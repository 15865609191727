import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { CompanyService, EmailService, FormInitService, PdfService, ReportService, ToastrService } from 'src/app/services';
import { EmailModel } from '../../model';
import {PhoneFormatPipe} from '../../pipe';
import { MonthList} from '../../constants';
import { CareOptionReportComponent } from 'src/app/components';

@Component({
  selector: 'app-send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.scss']
})
export class SendReportComponent {

  modalTitle: string;
  textEditorInit = { height: 300, menubar: false, font_format: 'Montserrat, sans-serif' };
  careReportTemplateList: any = [];
  sendReportForm: UntypedFormGroup;
  reportContent: any;
  loadingMessage: boolean = false;
  attachments: any = [];
  generating: boolean = false;
  sending: boolean = false;
  fileList: any = []
  caseId: any;
  userId:any;
  bccUser:any;
  //savings
  monthList: any = MonthList;
  reportFilter:any;
  recipientList:any = [];
  dateLabel:any ='';
  currentUser:any;
  savingsAttachment = {incentive:true, savingsDetailed:false};  
  sendFax:boolean = false;
  clinicFax:any;
  selectedClinic :any;

  constructor(public modalRef: MdbModalRef<SendReportComponent>,
    public emailService: EmailService,
    private formInit: FormInitService,
    private pdfService: PdfService,
    private reportService: ReportService,
    private toastrService: ToastrService,
    private phoneFormatPipe: PhoneFormatPipe,
    // public parentComp : CareOptionReportComponent
  ) {
    this.sendReportForm = this.formInit.createFormGroup(EmailModel.setSendEmailModel())
  }

  ngOnInit() {
    this.handleReportType();
  }

  selectTemplateType(event: any) {
    this.loadingMessage = true;
    if (event) {
      this.emailService.getTemplateSubject(event, this.modalTitle == 'Care Option' ?'standalone' : '').pipe(finalize(() => {
        this.loadingMessage = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          let content = response.data.content;
          content = content.replace(/@firstname/g, this.reportContent?.patientInfo?.patientName);
          content = content.replace(/@useremail/g, 'askme@careadvocacycenter.com');
          content = content.replace(/@userphone/g,  this.phoneFormatPipe.transform(this.reportContent?.phone ? this.reportContent?.phone: '8558438783'));
          content = content.replace(/@Number/g, this.phoneFormatPipe.transform(this.reportContent?.phone ? this.reportContent?.phone: '8558438783'));
          content = content.replace(/@Email/g, 'askme@careadvocacycenter.com');
          content = content.replace(/@username/g, `${this.reportContent?.employerInfo?.completedBy || this.reportContent?.createdBy?.name}`);
          content = content.replace(/@Company/g, this.reportFilter?.company?.clientLegalName);
          content = content.replace(/@date/g, this.dateLabel);
          content = content.replace(/@Username/g, `${this.currentUser?.firstName} ${this.currentUser?.lastName}`);
          this.sendReportForm.controls["message"].setValue(content);
        }
      })
    }
  }

  onFileSelect(file: any) {
    file.selected = !file.selected;
  }

 

  async viewCareOptionReport() {
    this.generating = true;
    let careOptionPdf: any = await this.pdfService.captureCareReportScreen(this.reportContent.providerOptions, '');
    window.open(careOptionPdf.output('bloburl'), '_blank');
    this.generating = false;
  }

  handleReportType() {
    switch(this.modalTitle) {
      case 'Savings':
        this.setSavingsFormValue();
        break;
      case 'Care Option':
        this.sendReportForm.controls["subject"].setValue(`Your requested Care Option Report for case id [ ${this.reportContent.caseId} ]`);
        this.sendReportForm.controls["to"].setValue(this.reportContent.patientInfo.email);
        this.sendReportForm.controls["bcc"].setValue(this.bccUser);
        if(!this.reportContent.patientInfo.tel) {
          this.sendReportForm.controls["notifyByText"].disable();
        }
        this.selectedClinic = this.reportContent.employerInfo.corporateClinicInfo.length == 1 ? this.reportContent.employerInfo.corporateClinicInfo[0] : null;
        this.clinicFax = this.reportContent.employerInfo.corporateClinicInfo.length == 1 ? this.reportContent.employerInfo.corporateClinicInfo[0].primaryCareId : null;
        break;
    }

  }

  setSavingsFormValue() {
    let startMonth = this.monthList.find((x:any )=> x.value === (new Date(this.reportFilter.from).getMonth() + 1)).name; // when getMonth() Jan starts from 0
    let endMonth = this.monthList.find((x:any) => x.value === (new Date(this.reportFilter.to).getMonth() + 1)).name;
    const displayMonth = startMonth === endMonth ? startMonth : startMonth + ' - ' + endMonth;
    this.sendReportForm.controls["subject"].setValue(`${displayMonth} Alithias Savings and Utilization Report`);
    this.sendReportForm.controls["to"].setValue(this.recipientList);
    this.sendReportForm.controls["bcc"].setValue(this.bccUser);
  }

  handleSend() {
    switch(this.modalTitle) {
      case 'Savings':
        this.modalRef.close({...this.sendReportForm.value, attachmentList: this.savingsAttachment });
        this.recipientList = [];
        break;
      case 'Care Option' :
        this.modalRef.close({...this.sendReportForm.value, phone:this.reportContent.phone, faxNumber:this.sendFax ? this.selectedClinic?.addressDetail[0]?.fax : null});
        break;
    }
  }

  async onClinicSelect(event:any) {
    this.selectedClinic = event;    
  }

  notifyByTextChange(event:any) {
    this.sendReportForm.controls["notifyByText"].setValue(event.checked);
  }

} 
