<div class="px-3">
  <app-report-header (baseAction)="handleBaseAction($event)"></app-report-header>
  <div class="pt-5 mt-5" *ngIf="loading">
    <loader></loader>
  </div>

  <div class="pt-4" *ngIf="!loading && savingDetailedReportData">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fw-bold ps-1">Saving Detailed Report Preview</p>
        <div class="dropdown" mdbDropdown *ngIf="savingDetailedReportData?.data?.length">
          <button class="btn btn-clear border dropdown-toggle" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle>
            <i-bs name="download" class="pe-2" *ngIf="!exporting"></i-bs>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="exporting"></span>
            Export
          </button>
          <ul mdbDropdownMenu class="dropdown-menu">
            <li>
              <button class="dropdown-item" type="button" (click)="exportExcel()">
                <i-bs name="file-spreadsheet" class="pe-2"></i-bs>
                Excel
              </button>
              <button class="dropdown-item" type="button" (click)="exportPdf()">
                <i-bs name="file-pdf" class="pe-2"></i-bs>
                PDF
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 pt-3">
      <table class="table table-striped table-report table-detailed">
        <thead>
          <tr class="fs-2 bordered">
            <th scope="col col-3" class="fw-bold pointer" (click)="sort({sortBy: 'caseId'})">
              <div class="d-flex align-items-center justify-content-between">
                Case Id
                <i-bs name="chevron-expand" *ngIf="order === 'caseId'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'closedDate'})">
              <div class="d-flex align-items-center justify-content-between">
                Closed Date
                <i-bs name="chevron-expand" *ngIf="order === 'closedDate'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'procedureName'})">
              <div class="d-flex align-items-center justify-content-between">
                Procedure
                <i-bs name="chevron-expand" *ngIf="order === 'procedureName'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'providerStateAverage'})">
              <div class="d-flex align-items-center justify-content-between">
                State Average
                <i-bs name="chevron-expand" *ngIf="order === 'providerStateAverage'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'ppoSavings'})">
              <div class="d-flex align-items-center justify-content-between">
                Saving From <br> PPO Cost
                <i-bs name="chevron-expand" *ngIf="order === 'ppoSavings'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'dcSavings'})">
              <div class="d-flex align-items-center justify-content-between">
                Saving From <br> DC Cost
                <i-bs name="chevron-expand" *ngIf="order === 'dcSavings'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'actualSavings'})">
              <div class="d-flex align-items-center justify-content-between">
                Actual Savings
                <i-bs name="chevron-expand" *ngIf="order === 'actualSavings'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'incentive'})">
              <div class="d-flex align-items-center justify-content-between">
                Incentive Paid
                <i-bs name="chevron-expand" *ngIf="order === 'incentive'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'lostOpportunity'})">
              <div class="d-flex align-items-center justify-content-between">
                Lost Opportunity
                <i-bs name="chevron-expand" *ngIf="order === 'lostOpportunity'"></i-bs>
              </div>
            </th>
          </tr>
        </thead>
        <tbody id="">
          <ng-container *ngIf="savingDetailedReportData?.data?.length">
            <tr class="fs-2 bordered" *ngFor="let item of savingDetailedReportData.data">
              <td>
                {{ item.caseId }}
              </td>
              <td>{{ item.closedDate | date : 'MM/dd/yyyy'}}</td>
              <td>{{ item.procedureName }}</td>
              <td>{{ item.providerStateAverage | currency }}</td>
              <td>{{ item.ppoSavings | currency }}</td>
              <td>{{ item.dcSavings | currency}}</td>
              <td>{{ item.actualSavings| currency }}</td>
              <td>{{ item.incentive | currency}}</td>
              <td>{{ item.lostOpportunity | currency }}</td>
            </tr>
            <tr class="fs-2">
              <td></td>
              <td></td>
              <td class="fw-bold text-center">Total</td>
              <td class="fw-bold">{{savingDetailedReportData.totalProviderStateAverage |currency}}</td>
              <td class="fw-bold">{{savingDetailedReportData.totalPpoSavings |currency}}</td>
              <td class="fw-bold">{{savingDetailedReportData.totalDcSavings |currency}}</td>
              <td class="fw-bold">{{savingDetailedReportData.totalActualSavings |currency}}</td>
              <td class="fw-bold">{{savingDetailedReportData.totalIncentive |currency}}</td>
              <td class="fw-bold">{{savingDetailedReportData.totalLlostOpportunity |currency}}</td>
            </tr>
          </ng-container>
          <tr *ngIf="!savingDetailedReportData?.data?.length">
            <td colspan="9" class="text-center">No records found.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="pdf" class="d-none">
  <app-report-pdf-format [id]="'savings-detailed'" [reportConstants]="reportConstants"
  [reportData]="savingDetailedReportData" [filterParams]="filterParams"></app-report-pdf-format>
</div>
