import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from './toastr.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(
        private http: HttpClientService,
        private router: Router,
        private toasterService: ToastrService
    ) { }

    login(userCredentials: any) {
        return this.http.post(`${ApiConstant.LOGIN}`, userCredentials)
            .pipe(map(response => {
                return response;
            }))
    }

    logout(userId?: any) {
        this.userLogout().subscribe((response: any) => {
            if (response && response.success) {
                this.router.navigate(['/login']);
                this.toasterService.showSuccess(response.message);
                localStorage.removeItem('currentUser');
                localStorage.removeItem('token');
            }
        })

    }

    getDynamicMenu() {
        return this.http.get(`${ApiConstant.MENU}`)
            .pipe(map(response => {
                return response;
            }))
    }

    userLogout() {
        return this.http.post(`/logout`, localStorage.getItem('token'))
            .pipe(map(response => {
                return response;
            }))
    }

    getMenuListFromUser() {
        let menuList: any;
        return new Promise(resolve => {
            this.getDynamicMenu().pipe(finalize(() => {
            })).subscribe((response: any) => {
                if (response && response.success) {
                    menuList = response.data.resources;
                    menuList = menuList.map((x: any) => {
                        return {
                            ...x, submenu: menuList.filter((y: any) => x._id == y.parentId)
                        }
                    })
                    menuList = menuList.filter((x: any) => !x.parentId);
                    resolve(menuList);
                }
            })

        })
    }

    validateToken(token: any) {
        return this.http.post(`/token/validate`, { token: token })
            .pipe(map(response => {
                return response;
            }))
    }

    validate(token: any) {
        return new Promise(resolve => {
            this.validateToken(token).pipe(finalize(() => {
            })).subscribe((response: any) => {
                if (response && response.success) {
                    resolve(response);
                }
            })
        })
    }
}