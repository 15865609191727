export class MemberSearchModel { 
    firstName: string = '';
    tpa: string = '';
    lastName: string ='';
    phone : any = null;
    dob: any = null;
    subscriberId: any = null;
    groupCode: any = null;
    alithiasGroupId:any = null;
    // limit : any = 0;
    keywords: any;
    middleName:any = null;
}