<div class="alert text-white bg-primary-dark p-3 rounded-0 col-12 fs-2" role="alert" *ngIf="env.staging">
    QA Environment Alert. Please note that any actions or changes made here are for testing purposes only and will not affect the production environment.
</div>
<div class="d-flex justify-content-center flex-coulmn welcome-page mt-5 pt-5">
    <div class="flex-columm text-center mt-5 pt-5">
        <i class="fas fa-tools"></i>
        <div *ngIf="!defaultMessage ">
            <div class="text-center p-5">
                <h4><strong>Welcome to Alithias Portal</strong></h4>
                <label>Our team is currently developing awesome features for this application </label>
            </div>
            <!-- <button type="button" class="btn btn-lg btn-block btn-gradient">
                If you have questions, Contact us
            </button> -->
        </div>
        <div *ngIf="defaultMessage">
            <h4><strong>Welcome to Alithias Portal</strong></h4>
            <label>You are not authorized for this page. Please make sure you have the right access.</label>
        </div>

    </div>
   
</div>