import { Component, OnInit } from '@angular/core';
import { ExcelService, CompanyService } from '../../../services';
import { ExcelHeaders } from '../../../constants'
import { finalize } from 'rxjs';

@Component({
  selector: 'app-etl-monitoring',
  templateUrl: './etl-monitoring.component.html',
  styleUrls: ['./etl-monitoring.component.scss']
})
export class EtlMonitoringComponent implements OnInit {

  constructor(private companyService: CompanyService, private excelExportService: ExcelService) { }
  searchKeyword: any = '';
  searchConfig = {
    showAdvancedSearch: true,
    showToggle: false,
    formValue: this.searchKeyword,
    module: 'monitoring',
    limit: 25,
    btnClass: 'btn-clear',
    action: { label: 'Export', type: 'export', icon: 'download' }
  }
  listLoading: boolean = false;
  companyStatusList: any = [];
  exporting: boolean = false;
  filterParams: any;
  sortColumn: string = 'variance';
  sortDirection: number = -1;

  ngOnInit(): void {
    this.getMonitoringList({ companyCount: false });
  }

  handleSearchEmit(event: any) {
    if (event == 'reset') {
      this.filterParams = null;
      this.companyStatusList = [];
      this.getMonitoringList({ companyCount: false });
    }
    else {
      this.filterParams = event;
      this.companyStatusList = [];
      this.listLoading = true;
      this.getMonitoringList({ ...event, companyCount: true })
    }
  }

  handleExport(event: any) {
    switch (event) {
      case 'current':
        this.handleExportReport(this.filterParams, this.companyStatusList.rows);
        break;
      case 'all':
        if (!this.filterParams.companyId) {
          this.handleExportReport(this.filterParams, this.companyStatusList.rows);
        }
        else {
          this.filterParams.companyId = null;
          this.exporting = true;
          this.getMonitoringList({ ...this.filterParams, eventType: 'all', companyCount: true });
        }
        break;
    }
  }

  getMonitoringList(filterParams: any) {
    this.listLoading= true;
    this.companyService.getCompanyEmployeeStatus({...filterParams, companyStatus:true, includeNull:true}).pipe(finalize(() => {
      this.listLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        response.data.rows = response.data.rows ? response.data.rows : [response.data];
        if (filterParams.eventType == 'all') {
          this.handleExportReport(filterParams, response.data.rows);
          this.exporting = false;
        }
        else {
          this.companyStatusList = response.data;
          // this.sort('companyName');
        }
      }
    })

  }

  handleExportReport(params: any, report: any) {
    if (this.companyStatusList.rows?.length) {
      const content = {
        header: params ? ExcelHeaders.CompanyCount : ExcelHeaders.CompanyVariance,
        data: report,
        reportTitle: params ? 'Member Count by Company' : 'Employee Variance by Company',
        fileName: params ? 'Employee-Count_Report' : 'Employee-Variance_Report',
        subTitle: params ? `Reporting Period: ${params.start} ${params.end ? 'To:' + params.end : ''}` : '',
      }
      this.excelExportService.exportExcel(content);
    }
  }


  sort(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = -this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = 1;
    }
    switch (column) {
      case ('companyName'):
      case('source'):
        this.companyStatusList.rows = this.companyStatusList.rows.sort((a: any, b: any) => {
          if ((a[column]).toLowerCase() < (b[column]).toLowerCase()) {
            return -1 * this.sortDirection;
          } else if ((a[column]).toLowerCase() > (b[column]).toLowerCase()) {
            return 1 * this.sortDirection;
          } else {
            return 0;
          }
        })
        break;
      case 'variance':
        this.companyStatusList.rows = this.companyStatusList.rows.reverse();
        break;
      default:
        this.companyStatusList.rows = this.companyStatusList.rows.sort((a: any, b: any) => a[column] - b[column]);
        this.companyStatusList.rows = this.sortDirection == -1 ? this.companyStatusList.rows.reverse() : this.companyStatusList.rows;
        break;
    }
  }
}
