export class RolesModel {
    name: string;
    code: string;
    permissions: IPermissions[] =[];
    createdBy: string;
    updatedBy: string;
    status: string;
    roleId:string;
}
export class IPermissions {
    resource: string;
    accessLevel: string;
    name:string
}
