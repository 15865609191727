import { Injectable } from "@angular/core";

@Injectable()
export class TPAFileModel {
    static setTpaFileModel(value:any){
        return {
            name:value.name,
            base64: value.base64,
            contentType: value.type
        }
    }
}
        