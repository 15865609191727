<div class="px-3">
  <app-report-header (baseAction)="handleBaseAction($event)"></app-report-header>
  <div class="pt-5 mt-5" *ngIf="incentiveLoading">
    <loader></loader>
  </div>
  <div class="pt-4" *ngIf="!incentiveLoading && incentiveList">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
        <p class="fw-bold ps-1">Incentive Report Preview</p>
        <div class="dropdown" mdbDropdown *ngIf="incentiveList?.length">
          <button class="btn btn-clear border dropdown-toggle" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle>
            <i-bs name="download" class="pe-2" *ngIf="!exporting"></i-bs>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="exporting"></span>
            Export
          </button>
          <ul mdbDropdownMenu class="dropdown-menu">
            <li>
              <button class="dropdown-item" type="button" (click)="exportExcel()">
                <i-bs name="file-spreadsheet" class="pe-2"></i-bs>
                Excel
              </button>
              <button class="dropdown-item" type="button" (click)="exportPdf()">
                <i-bs name="file-pdf" class="pe-2"></i-bs>
                PDF
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-12 pt-3">
      <table class="table table-report table-incentive">
        <thead>
          <tr class="fs-2 bordered text-center">
            <th scope="col col-3" class="fw-bold pointer" (click)="sort({sortBy: 'closedDate'})">
              <div class="d-flex align-items-center justify-content-between ">
                Closed Date
                <i-bs name="chevron-expand" *ngIf="order === 'closedDate'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'employeeId'})">
              <div class="d-flex align-items-center justify-content-between ">
                Member ID
                <i-bs name="chevron-expand" *ngIf="order === 'employeeId'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'employeeFirstName'})">
              <div class="d-flex align-items-center justify-content-between">
                Employee Name
                <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'employeeFirstName'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'employeeDob'})">
              <div class="d-flex align-items-center justify-content-between">
                Employee DOB
                <i-bs name="chevron-expand" *ngIf="order === 'employeeDob'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'patientFirstName'})">
              <div class="d-flex align-items-center justify-content-between">
                Dependant
                <i-bs name="chevron-expand" *ngIf="order === 'patientFirstName'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'providerServiceOption'})">
              <div class="d-flex align-items-center justify-content-between">
                Service <br />
                Option
                <i-bs name="chevron-expand" *ngIf="order === 'providerServiceOption'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'dateOfService'})">
              <div class="d-flex align-items-center justify-content-between">
                Service<br />
                Date
                <i-bs name="chevron-expand" *ngIf="order === 'dateOfService'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'providerStateAverage'})">
              <div class="d-flex align-items-center justify-content-between">
                State Avg
                <i-bs name="chevron-expand" *ngIf="order === 'providerStateAverage'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'providerEstCost'})">
              <div class="d-flex align-items-center justify-content-between">
                Est Cost
                <i-bs name="chevron-expand" *ngIf="order === 'providerEstCost'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'providerIncentives'})">
              <div class="d-flex align-items-center justify-content-between">
                Incentive <br> To
                Be Paid
                <i-bs name="chevron-expand" *ngIf="order === 'providerIncentives'"></i-bs>
              </div>
            </th>
            <th scope="col" class="fw-bold pointer" (click)="sort({sortBy: 'providerCostSaving'})">
              <div class="d-flex align-items-center justify-content-between ">
                Est Cost Savings
                <i-bs name="chevron-expand" *ngIf="order === 'providerCostSaving'"></i-bs>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="12" *ngIf="!incentiveList.length" class="text-center">
              No records found.
            </td>
          </tr>
          <ng-container *ngFor="let incentive of incentiveList; let i = index">
            <tr class="fs-2 bordered" [ngClass]="{ 'row-striped': i % 2 !== 0 }">
              <td>{{ incentive.closedDate | date : "MM/dd/yyyy" }}</td>
              <td>{{ incentive.employeeId }}</td>
              <td>
                {{incentive.employeeName ? incentive.employeeName : (incentive.employeeFirstName + ' ' +
                incentive.employeeLastName )}}
              </td>
              <td>{{ incentive.employeeDob | date : "MM/dd/yyyy" }}</td>
              <td>
                {{ incentive.patientFirstName }} {{ incentive.patientLastName }}
              </td>
              <td>{{ incentive.providerServiceOption }}</td>
              <td>{{ incentive.dateOfService | date : "MM/dd/yyyy" }}</td>
              <td>{{ incentive.providerStateAverage ? "$" + (incentive.providerStateAverage | number : "1.0-0") : "$0"}}
              </td>
              <td>{{ incentive.providerEstCost ? "$" + (incentive.providerEstCost | number : "1.0-0") : "$0"}}</td>
              <td>{{ incentive.providerIncentives ? "$" + (incentive.providerIncentives | number : "1.0-0") : "$0"}}
              </td>
              <td>{{incentive.providerCostSaving ? "$" + (incentive.providerCostSaving | number : "1.0-0"): "$0"}}</td>
            </tr>
          </ng-container>
          <tr class="fs-2" *ngIf="incentiveList.length && incentiveList.totals">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="fw-bold">Total</td>
            <td class="fw-bold">
              {{incentiveList.totals.totalStateAvg ? "$" + (incentiveList.totals.totalStateAvg | number : "1.0-0"):
              "$0"}}
            </td>
            <td class="fw-bold">
              {{incentiveList.totals.totalEstCost ? "$" + (incentiveList.totals.totalEstCost | number : "1.0-0") :
              "$0"}}
            </td>
            <td class="fw-bold"> {{incentiveList.totals.totalIncentivesToBePaid ? "$" +
              (incentiveList.totals.totalIncentivesToBePaid | number : "1.0-0") : "$0" }}</td>
            <td class="fw-bold"> {{incentiveList.totals.totalEstCostSavings ? "$" +
              (incentiveList.totals.totalEstCostSavings | number : "1.0-0") : "$0"}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div id="pdf" class="d-none">
  <app-report-pdf-format [id]="'incentive'" [reportConstants]="reportConstants" [reportData]="incentiveList"
    [filterParams]="filterParams"></app-report-pdf-format>
</div>