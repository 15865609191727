<div class="general pt-3" [formGroup]="billingsForm">
    <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Invoicing Information </p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="col-12 d-flex">
        <div class="col-6">
            <div class="d-flex align-items-center col-12 ">
                <label for="name" class="pt-0 col-5 text-end pe-3">Invoice To</label>
                <div class="col-7">
                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"  formControlName="invoiceTo"
                    name="invoiceTo">
                        <ng-option *ngFor="let option of dropdownConstants.invoiceToList | sort:'asc':'name'" [value]="option.value">
                            {{option.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div clas="">
                <div class="col-12 d-flex align-items-center pt-3">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Billing Email</label>
                    <div class="col-7">
                        <input type="text" class="form-control" id="billingEmail" formControlName="billingEmail"
                            name="billingEmail" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Billing Address</label>
                <input type="text" class="form-control" id="billingAddress" formControlName="billingAddress"
                    name="billingAddress" />
            </div>
            <div class="d-flex pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3"></label>
                <div class="col-7 d-flex">
                    <div class="col-5 ">
                        <input type="text" class="form-control" formControlName="city" name="city" />
                    </div>
                    <div class="col-4 ps-2">
                        <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                            formControlName="state" name="state">
                            <ng-option *ngFor="let option of parentComponent.statesList| sort:'asc':'name'"
                                value="{{option.value}}">
                                {{option.value}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-3 ps-2">
                        <input type="text" class="form-control" formControlName="zip" name="zip" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-2">
        <table class="table table-reponsive">
            <thead>
                <tr>
                    <th scope="col" class="fs-2 fw-bold" style="width: 400px !important;">Contact Name</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Phone 1</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Ext</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Phone 2</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 500px !important;">Email</th>
                    <th></th>
                </tr>
            </thead>
            <tbody formArrayName="contactInfo">
                <tr *ngIf="!getFormArray('contactInfo').length">
                    <th colspan="6" class="fw-bold text-center">No client contacts</th>
                </tr>
                <tr *ngFor="let item of getFormArray('contactInfo').controls; let i = index" [formGroupName]="i">
                    <td> <input type="text" class="form-control" formControlName="contactName" name="contactName" />
                    </td>
                    <td> <input type="text" class="form-control" formControlName="phone1" name="phone1"
                            mask='(000) 000-0000' /></td>
                    <td> <input type="number" class="form-control" formControlName="extension" name="extension"
                            maxlength="6" minlength="4"/>
                    </td>
                    <td> <input type="text" class="form-control" formControlName="phone2" name="phone2"
                            mask='(000) 000-0000' /></td>
                    <td> <input type="text" class="form-control" formControlName="email" name="email"
                            [pattern]="parentComponent.mailformat" /></td>
                    <td>
                        <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                            (click)="delete(i, item.value, 'contactInfo')"></i-bs>
                        <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                            *ngIf="item.value.loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <a class="text-primary pointer" type="button" (click)="addContactForm()"><i-bs name="plus"></i-bs>Add
            More</a>
        <hr>

        <div class="d-flex align-items-center pt-2">
            <p class="fw-bold pt-3 ps-2 pe-2">Pricing Information </p>
        </div>
        <div class="col-12 d-flex">
            <table class="table table-reponsive">
                <thead>
                    <tr>
                        <th scope="col" class="fs-2 fw-bold">Service Type</th>
                        <th scope="col" class="fs-2 fw-bold">Pricing Type</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;"> Pricing Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody formArrayName="pricingInfo">
                    <tr *ngIf="!getFormArray('pricingInfo').length">
                        <th colspan="6" class="fw-bold text-center">No Pricing Info</th>
                    </tr>
                    <tr *ngFor="let item of getFormArray('pricingInfo').controls; let i = index" [formGroupName]="i">
                        <td>
                            <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                                formControlName="serviceType" name="serviceType">
                                <ng-option *ngFor="
                                let option of dropdownConstants.pricingServiceTypes | sort:'asc':'name'
                              " [value]="option.value">
                                    {{ option.name }}</ng-option>
                            </ng-select>
                        </td>
                        <td>
                            <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                                formControlName="pricingType" name="pricingType">
                                <ng-option *ngFor="
                                let option of dropdownConstants.pricingType | sort:'asc':'name'
                              " [value]="option.value">
                                    {{ option.name }}</ng-option>
                            </ng-select>
                        </td>
                        <td> <input type="text" class="form-control" formControlName="pricingAmount"
                                name="pricingAmount" [prefix]="item.get('pricingAmount')?.value ? '$ ' : ''" mask="separator.2" thousandSeparator="," />
                        </td>
                        <td>
                            <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                                (click)="delete(i, item.value, 'pricingInfo')"></i-bs>
                            <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                *ngIf="item.value.loading">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <a class="text-primary pointer" type="button" (click)="addPricingForm('pricingInfo')"><i-bs
                name="plus"></i-bs>Add
            More</a>
        <hr>

        <div class="d-flex align-items-center pt-2">
            <p class="fw-bold pt-3 ps-2 pe-2">Add-On to Alithias Service</p>
        </div>
        <div class="col-12 d-flex">
            <table class="table table-reponsive">
                <thead>
                    <tr>
                        <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Vendor Name</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 400px !important;">Service Type</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Pricing Type</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Pricing Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody formArrayName="addOnAlithiasServices">
                    <tr *ngIf="!getFormArray('addOnAlithiasServices').length">
                        <th colspan="6" class="fw-bold text-center">No Pricing Info</th>
                    </tr>
                    <tr *ngFor="let item of getFormArray('addOnAlithiasServices').controls; let i = index"
                        [formGroupName]="i">
                        <td> <input type="text" class="form-control" formControlName="vendorName" name="vendorName" />
                        </td>
                        <td>
                            <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                                formControlName="serviceType" name="serviceType">
                                <ng-option *ngFor="
                                let option of dropdownConstants.addOnAlithiasServiceType | sort:'asc':'name'
                              " [value]="option.value">
                                    {{ option.name }}</ng-option>
                            </ng-select>
                        </td>
                        <td>
                            <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                                formControlName="pricingType" name="pricingType">
                                <ng-option *ngFor="
                                let option of dropdownConstants.pricingType | sort:'asc':'name'
                              " [value]="option.value">
                                    {{ option.name }}</ng-option>
                            </ng-select>
                        </td>
                        <td> <input type="text" class="form-control" formControlName="pricingAmount"
                                name="pricingAmount"  [prefix]="item.get('pricingAmount')?.value ? '$ ' : ''" mask="separator.2" thousandSeparator="," decimalMarker="."  />
                        </td>
                        <td>
                            <!-- <i-bs name="trash-fill" (click)="delete(i, item.value, 'addOnAlithiasServices')"></i-bs> -->
                            <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                                (click)="delete(i, item.value, 'addOnAlithiasServices')"></i-bs>
                            <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                *ngIf="item.value.loading">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <a class="text-primary pointer" type="button"
            (click)="addPricingForm('addOnAlithiasServices', 'vendorName')"><i-bs name="plus"></i-bs>Add
            More</a>
        <hr>
    </div>
    <div class="d-flex justify-content-end pb-3">
        <button type="button" class="btn btn-primary " [disabled]="!billingsForm.valid" (click)="saveBillingsInfo()">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
                <span class="visually-hidden">Loading...</span>
            </div>
            Save
        </button>
    </div>
</div>