import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_BASE_URL = environment.API_URL;

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  private iframeAuthToken: string | null = null;
  httpHeaderModel = {
    name: '',
    value: '',
  };
  enterpriseSecretKey = "FEFB5624F3932A919128F38392682";
  constructor(private http: HttpClient,) { }

  createHeader(requestType?: string) {
    let headers = new HttpHeaders();
    if (requestType === 'enterprise') {
      headers = headers.append(
        'Authorization',
        `${this.enterpriseSecretKey}`
      );
    } else if (requestType === 'standalone') {
      headers = headers.append(
        'Authorization', `${this.getIframeToken()}`
      );
    }
    else {
      if ((localStorage.getItem('token'))) {
        headers = headers.append(
          'Authorization',
          `Bearer ${localStorage.getItem('token')}`
        );
      }
    }
    return headers;
  }

  get(url: string, headers?: any, requestType?: string) {
    let header: any;
    if (requestType === 'enterprise') {
      header = this.createHeader(requestType);
    }
    else if (requestType === 'standalone') {
      header = this.createHeader(requestType);
    } else {
      header = this.createHeader();
      if (headers) {
        headers.forEach((head: any) => {
          header = header.append(head.name, head.value);
        });
      }
    }
    return this.http.get(API_BASE_URL + url, { headers: header });
  }
  // get(url: string, params?: any): any {
  //   return this.http.get(API_BASE_URL + url, {params});
  // }

  post(url: string, data: any, isFile: boolean = false, requestType?: any): any {
    let authToken: any;
    if (requestType === 'standalone') {
      authToken = {
        headers: authToken = this.createHeader(requestType),
        responseType: isFile ? 'blob' : 'json',
      };
    }
    else {
      authToken = {
        headers: this.createHeader(),
        responseType: isFile ? 'blob' : 'json',
      };
    }

    return this.http.post(API_BASE_URL + url, data, authToken);
  }

  put(url: string, data: any, headers?: any): any {
    let header = this.createHeader();
    if (headers) {
      headers.forEach((head: any) => {
        header = header.append(head.name, head.value);
      });
    }
    return this.http.put(API_BASE_URL + url, data, { headers: header });
  }

  delete(url: string, data: any): any {
    return this.http.put(API_BASE_URL + url, data, {
      headers: this.createHeader(),
    });
  }

  remove(url: string): any {
    return this.http.delete(API_BASE_URL + url, {
      headers: this.createHeader(),
    });
  }

  patch(url: string, data: any, headers?: any): any {
    let header = this.createHeader();
    if (headers) {
      headers.forEach((head: any) => {
        header = header.append(head.name, head.value);
      });
    }
    return this.http.patch(API_BASE_URL + url, data, { headers: header });
  }

  setIframeToken(token: string): void {
    this.iframeAuthToken = token;
  }

  getIframeToken(): string | null {
    return this.iframeAuthToken;
  }
}
