export const MetaDataDropdownConstant = {
    caseStatus : [
        {label:'Awaiting Survey', value:'AS'},
        {label:'Awaiting Documents', value:'AWDOC'},
        {label:'Awaiting Order', value:'AWO'},
        {label:'Awaiting DC', value:'AWDC'},
        {label:'Closed - No response', value:'CNR'},
        {label:'Multiple Request', value:'MR'},
        {label:'Care Report Sent', value:'CRS'},
        {label:'Follow up 1', value:'FU1'},
        {label:'Follow up 2', value:'FU2'},
        {label:'Monitoring', value:'MONITORING'},
        {label:'New Assignment', value:'NA'},
        {label:'Phone Outreach', value:'PO'},
        {label:'Response Hold', value:'RH'},
        {label:'Pending Review', value:'PR'},
        {label:'PPO Search', value:'PPO'},
        {label:'Researching', value:'RESEARCHING'},
        {label:'Survey Complete', value:'Survey Complete'},
        {label:'Closed', value:'CLOSED'},
        {label:'Deleted', value:'DELETED'},
        {label:'New', value:'REQUESTED'},
    ],

    mediumOfContact : [
        {label:'Chat', value:'Chat'},
        {label:'Mail', value:'Mail'},
        {label:'Voicemail', value:'Voicemail'},
        {label:'Enrollment', value:'Enrollment'},
        {label:'In Person', value:'In Person'},
        {label:'Email', value:'Email'},
        {label:'HCMT', value:'HCMT'},
        {label:'Transfer From - Employer', value:'Transfer From - Employer'},
        {label:'Transfer From - Partners', value:'Transfer From - Partners'},
        {label:'Transfer From - Broker', value:'Transfer From - Broker'},
        {label:'Transfer From - TPA', value:'Transfer From - TPA'},
        {label:'Transfer From - KT Call Center', value:'Transfer From - KT Call Center'},
        {label:'Fax', value:'Fax'},
        {label:'Phone', value:'Phone'},
    ],

    callRegarding: [
        {label:'Ancillary Policies', value:'AP'},
        {label:'Appointment Scheduling', value:'APS'},
        {label:'Balance Billing', value:'BALANCE-BILLING'},
        {label:'Benefits', value:'BE'},
        {label:'Billing', value:'BILLING'},
        {label:'Claim Status', value:'CS'},
        {label:'CN Program', value:'CNPROGRAM'},
        {label:'Cobra', value:'COBRA'},
        {label:'Consult', value:'CONSULT'},
        {label:'Coordination of Benefits (COB)', value:'COB'},
        {label:'Dental', value:'DENTAL'},
        {label:'Diagnostic/Imaging', value:'DI'},
        {label:'EAP', value:'EAP'},
        {label:'Eligibility', value:'EL'},
        {label:'Enrollment', value:'ENROLLMENT'},
        {label:'EOB Request', value:'ER'},
        {label:'FMLA/Life Insurance', value:'FLI'},
        {label:'HSA/HRA/FSA', value:'HHF'},
        {label:'ID Cards', value:'IC'},
        {label:'Kiio', value:'Kiio'},
        {label:'Log-In Issue', value:'LII'},
        {label:'Mbr Portal Issues', value:'MPI'},
        {label:'Medical Benefits', value:'MEDICAL'},
        {label:'Medical Pre-Cert', value:'PC'},
        {label:'Medicare', value:'MEDICARE'},
        {label:'Network Provider', value:'NP'},
        {label:'Nurse Line', value:'NL'},
        {label:'Other', value:'OTHER'},
        {label:'RBP Providers', value:'RBP'},
        {label:'RX Benefits', value:'RB'},
        {label:'RX Override Request', value:'ROR'},
        {label:'RX Pre-Cert', value:'RPC'},
        {label:'Single Case Agreement (SCA)', value:'SCA'},
        {label:'Surgery', value:'SURGERY'},
        {label:'Telemedicine', value:'TELEDOC'},
        {label:'Therapy', value:'THERAPY'},
        {label:'Vision Benefits', value:'VB'},
        {label:'Wellness', value:'WELLNESS'},
    ],

    callInType: [
        {label:'Benefit Support', value:'Benefit Support'},
        {label: 'Care Navigation', value:'Care Navigation'},
        {label: 'Provider Call', value:'Provider Call'}
    ],

    radiusConstants : [
        30, 60, 120, 200, 240
    ]
}