<nav class="navbar navbar-expand-lg navbar-light bg-white p-0">
  <div class="container-fluid">
    <p class="text-capitalize mb-0 p-3" style="padding-left: 32px !important;"><strong>{{editModule || currentModule}}</strong></p>
    <div class="collapse navbar-collapse" id="navbarNav" mdbCollapse #basicNav="mdbCollapse">
      <ul class="ms-auto navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link text-primary" aria-current="page" href="#"><i class="fas fa-cog"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-primary" aria-current="page" href="#"><i class="fas fa-bell"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-primary position-relative" href="#">
            <i class="fas fa-envelope"></i>
            <span class="badge bg-danger rounded-circle notification p-0 font-weight-normal">10</span>
          </a>
        </li> -->
        <li class="nav-item align-self-center px-0">
          <div mdbDropdown class="btn-group shadow-0">
            <div class="avatar"><span
                class="av-initial">{{currentUserInfo.firstName.charAt(0)}}{{currentUserInfo.lastName.charAt(0)}}</span>
            </div>
            <button class="btn btn-clear dropdown-toggle p-2 dropstart" mdbDropdownToggle aria-expanded="false">
              <strong class="px-1">{{currentUserInfo.firstName}} {{currentUserInfo.lastName}}</strong>
            </button>
            <ul mdbDropdownMenu class="dropdown-menu">
              <li><button class="dropdown-item" type="button" [routerLink]="['/logout']">
                  <i-bs name="box-arrow-right"></i-bs>
                  Log Out
                </button></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Sidenav -->