<div class="general pt-3" [formGroup]="clientServiceTypeForm">
    <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Client Service Type </p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="d-flex justify-content-between pt-3">
        <div class="col-6 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Service Type</label>
            <div class="col-7">
                <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                    formControlName="serviceType" name="serviceType">
                    <ng-option *ngFor="let option of  dropdownConstants.clientServiceType| sort:'asc':'name'"
                        value="{{option.value}}">
                        {{option.name}}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-6 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Identified as</label>
            <div class="col-7">
                <input type="text" class="form-control" id="identifiedAs" formControlName="identifiedAs"
                    name="identifiedAs" />
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between pt-3">
        <div class="col-6 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Member Service Phone</label>
            <div class="col-7">
                <input type="text" class="form-control" id="memberServicePhone" formControlName="memberServicePhone"
                    name="memberServicePhone"  mask='(000) 000-0000'/>
            </div>
        </div>
        <div class="col-6 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Member Service Email</label>
            <div class="col-7">
                <input type="text" class="form-control" id="serviceEmail" formControlName="serviceEmail"
                    name="serviceEmail" [pattern]="parentComponent.mailformat"/>
            </div>
        </div>
    </div>
    <div class="col-6 d-flex align-items-center pt-3">
        <label for="name" class="pt-0 col-5 text-end pe-3"></label>
        <input mdbCheckbox class="form-check-input me-4 col-2" type="checkbox" id="whiteLabel" (change)="whiteLabelChecked()"
            formControlName="whiteLabel" name="whiteLabel" /> 
        <label class="form-check-label col-8">White Label</label>
    </div>
    <div class="pt-4 ">
        <table class="table table-reponsive">
            <thead>
                <tr>
                    <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">White Label <br> Contact Name</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Contact Title</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Phone 1</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 150px !important;">Ext</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Phone 2</th>
                    <th scope="col" class="fs-2 fw-bold" style="width: 350px !important;">Email</th>
                    <th></th>
                </tr>
            </thead>
            <tbody formArrayName="clientServiceReportInfo">
                <tr *ngIf="!getFormArray(clientServiceTypeForm, 'clientServiceReportInfo').length">
                    <th colspan="6" class="fw-bold text-center">No contact info</th>
                </tr>
                <tr *ngFor="let item of getFormArray(clientServiceTypeForm, 'clientServiceReportInfo').controls; let i = index"
                    [formGroupName]="i">
                    <td> <input type="text" class="form-control" id="contactName" formControlName="contactName"
                            name="contactName" /></td>
                    <td>
                        <input type="text" class="form-control" id="contactTitle" formControlName="contactTitle"
                            name="contactTitle" />
                    </td>
                    <td> <input type="text" class="form-control" id="phone1" formControlName="phone1" name="phone1"
                            mask='(000) 000-0000' />
                    </td>
                    <td> <input type="text" class="form-control" id="extension" formControlName="extension"
                            name="extension" maxlength="6" minlength="4" /></td>
                    <td> <input type="text" class="form-control" id="phone2" formControlName="phone2" name="phone2"
                            mask='(000) 000-0000' />
                    </td>
                    <td> <input type="text" class="form-control" id="group" formControlName="email" name="email"
                            [pattern]="parentComponent.mailformat" />
                    </td>
                    <td>
                        <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                            (click)="delete(i, item.value, 'clientServiceReportInfo')"></i-bs>
                        <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                            *ngIf="item.value.loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <a class="text-primary pointer" type="button"
            (click)="addForms(clientServiceTypeForm, 'clientServiceReportInfo')"><i-bs name="plus"></i-bs>Add
            More</a>
    </div>
    <hr class="mt-5">
    <div [formGroup]="getControl(clientServiceTypeForm, 'phoneSetup')">
        <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Phone Setup </p>
        </div>
        <div class="d-flex justify-content-between pt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Assigned DID</label>
                <div class="col-7">
                    <input type="text" class="form-control" id="assignedDid" formControlName="assignedDid"
                        name="assignedDid"  mask='(000) 000-0000' />
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 d-flex justify-content-end">
                    <input mdbCheckbox class="form-check-input me-4 col-2" type="checkbox" id="seperatePhoneNeeded"
                        formControlName="seperatePhoneNeeded" name="seperatePhoneNeeded" />
                    <label class="form-check-label col-8">Seperate Phone # Needed</label>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <table class="table table-reponsive">
                <thead>
                    <tr>
                        <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Contact Name</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 1</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 100px !important;">Ext</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 2</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 400px !important;">Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody formArrayName="contactInfo">
                    <tr *ngIf="!getFormArray(getControl(clientServiceTypeForm, 'phoneSetup'), 'contactInfo').length">
                        <th colspan="6" class="fw-bold text-center">No contact info</th>
                    </tr>
                    <tr *ngFor="let item of getFormArray(getControl(clientServiceTypeForm, 'phoneSetup'), 'contactInfo').controls; let i = index"
                        [formGroupName]="i">
                        <td> <input type="text" class="form-control" id="contactName" formControlName="contactName"
                                name="contactName" /></td>
                        <td> <input type="text" class="form-control" id="phone1" formControlName="phone1"
                                mask='(000) 000-0000' name="phone1" />
                        </td>
                        <td> <input type="text" class="form-control" id="extension" formControlName="extension"
                                name="extension" maxlength="6" minlength="4" /></td>
                        <td> <input type="text" class="form-control" id="phone2" formControlName="phone2"
                                mask='(000) 000-0000' name="phone2" />
                        </td>
                        <td> <input type="text" class="form-control" id="group" formControlName="email" name="email"
                                [pattern]="parentComponent.mailformat" />
                        </td>
                        <td>
                            <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                                (click)="delete(i, item.value, 'contactInfo')"></i-bs>
                            <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                *ngIf="item.value.loading">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a class="text-primary pointer" type="button"
                (click)="addForms(getControl(clientServiceTypeForm, 'phoneSetup'), 'contactInfo')"><i-bs
                    name="plus"></i-bs>Add
                More</a>
        </div>
    </div>
    <hr class="mt-5">
    <div [formGroup]="getControl(clientServiceTypeForm, 'emailSetup')">
        <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Email Setup </p>
        </div>
        <div class="d-flex justify-content-between pt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Pass Thru Email Account</label>
                <div class="col-7">
                    <input type="text" class="form-control" id="passThroughEmailAccount"
                        formControlName="passThroughEmailAccount" name="passThroughEmailAccount" />
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 d-flex justify-content-end">
                    <input mdbCheckbox class="form-check-input me-4 col-2" type="checkbox" id="requiresSeparateEmail"
                        formControlName="requiresSeparateEmail" name="requiresSeparateEmail" />
                    <label class="form-check-label col-8">Seperate Email Needed</label>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <table class="table table-reponsive">
                <thead>
                    <tr>
                        <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Contact Name</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 1</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 100px !important;">Ext</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 2</th>
                        <th scope="col" class="fs-2 fw-bold" style="width: 400px !important;">Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody formArrayName="contactInfo">
                    <tr *ngIf="!getFormArray(getControl(clientServiceTypeForm, 'emailSetup'), 'contactInfo').length">
                        <th colspan="6" class="fw-bold text-center">No contact info</th>
                    </tr>
                    <tr *ngFor="let item of getFormArray(getControl(clientServiceTypeForm, 'emailSetup'), 'contactInfo').controls; let i = index"
                        [formGroupName]="i">
                        <td> <input type="text" class="form-control" id="contactName" formControlName="contactName"
                                name="contactName" /></td>
                        <td> <input type="text" class="form-control" id="phone1" formControlName="phone1"
                                mask='(000) 000-0000' name="phone1" />
                        </td>
                        <td> <input type="text" class="form-control" id="extension" formControlName="extension"
                                name="extension" maxlength="6" minlength="4" /></td>
                        <td> <input type="text" class="form-control" id="phone2" formControlName="phone2"
                                mask='(000) 000-0000' name="phone2" />
                        </td>
                        <td> <input type="text" class="form-control" id="group" formControlName="email" name="email"
                                [pattern]="parentComponent.mailformat" />
                        </td>
                        <td>
                            <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                                (click)="delete(i, item.value, 'emailSetup')"></i-bs>
                            <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                *ngIf="item.value.loading">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a class="text-primary pointer" type="button"
                (click)="addForms(getControl(clientServiceTypeForm, 'emailSetup'), 'contactInfo')"><i-bs
                    name="plus"></i-bs>Add
                More</a>
        </div>
    </div>
    <hr class="mt-5">
    <div [formGroup]="getControl(clientServiceTypeForm, 'incentiveProgram')">
        <p class="fw-bold pt-3 ps-2 pe-2">MoneyTwenty Incentive Program </p>
        <div class="d-flex justify-content-between pt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Incentive Payment Method</label>
                <div class="col-7">
                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                        formControlName="incentivePaymentMethod" name="incentivePaymentMethod">
                        <ng-option *ngFor="let option of  dropdownConstants.incentivePaymentMethods| sort:'asc':'name'"
                            value="{{option.value}}">
                            {{option.name}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 d-flex justify-content-end">
                    <input mdbCheckbox class="form-check-input me-4 col-2" type="checkbox" id="customIncentiveProgram"
                        formControlName="customIncentiveProgram" name="customIncentiveProgram" />
                    <label class="form-check-label col-8">Custom Incentive Program</label>
                </div>
                <div class="col-12 d-flex justify-content-end pt-3">
                    <input mdbCheckbox class="form-check-input me-4 col-2" type="checkbox" id="standardIncentiveProgram"
                        formControlName="standardIncentiveProgram" name="standardIncentiveProgram" />
                    <label class="form-check-label col-8">Standard Incentive Program</label>
                </div>

            </div>
        </div>
    </div>

    <hr class="mt-5">
    <div class="d-flex justify-content-end pb-3">
        <button type="button" class="btn btn-primary " (click)="saveClientServiceType()">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
                <span class="visually-hidden">Loading...</span>
            </div>
            Save
        </button>
    </div>
</div>