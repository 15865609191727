import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { ConfirmationModalComponent } from '../../../modal';
import { ExcelHeaders } from 'src/app/constants';
import { CompanyService, HttpCancelService, ToastrService, ExcelService } from '../../../services';
import * as moment from 'moment';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  searchKeyword: any = null;
  searchConfig = {
    action: [
      // { label: 'Export', type: 'export', icon: 'download', btnClass: 'btn-clear px-4 pt-2 border'},
      { label: 'Add Company', type: 'addCompany', icon:'plus', btnClass: 'btn-gradient' }], 
    showAdvancedSearch: false,
    showToggle: true,
    formValue: this.searchKeyword,
    module: 'company',
    limit:25,
    sourceType: 'Active',
    exportToggle: true
  }
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;
  companyLoading: boolean = true;
  companyList: any = [];
  order = 'clientLegalName';
  reverse: boolean = false;
  getRequest: any;

  constructor(private modalService: MdbModalService,
    private toastrService: ToastrService,
    private companyService: CompanyService,
    private router: Router,
    private cancelService: HttpCancelService,
    private excelExportService: ExcelService) { }

  ngOnInit(): void {
    this.getCompanyList({sortBy: 'clientLegalName', sortType: 'asc', limit:25, companyStatus: true});
  }

  manageCompany(event: any) {
    switch(event) {
      case 'addCompany' :
        this.router.navigate([`/admin/company/create`]);
        break;
      case 'current' :
        this.handleExport(); 
        break;
        case 'all' :
          this.getCompanyList({limit:0, companyStatus: true}, 'all'); 
          break;
      default : 
      const queryParams = { name: event.clientLegalName };
      this.router.navigate([`/admin/company/edit/${event.id}`], { queryParams: queryParams});
    }
  }

  openConfirmationModal(company: any) {
    this.modalRef = this.modalService.open(ConfirmationModalComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        modalTitle: 'Confirmation',
        modalBody: `Are you sure you want to delete <strong> ${company.clientLegalName}</strong> ?`,
      }
    });
    this.modalRef.onClose.subscribe((message: any) => {
      if (message == 'confirmDeactivate') {
        this.deleteCompany(company);
      }
    })
  }

  getCompanyList(params? :any, action?:any) {
    this.companyLoading = true;
    this.getRequest = this.companyService.getCompanyList(params).pipe(finalize(() => {
      this.companyLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        if(action === 'all'){
          this.handleExport(response.data.rows);
        }
        else{
          this.companyList = response.data;
        }
      }
    })
  }

  deleteCompany(company:any) {
    this.companyService.deleteCompany(company).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.searchConfig.formValue = null;
        this.getCompanyList({sortBy: 'clientLegalName', sortType: 'asc'});
      }
    })
  }

  searchCompany(event:any) {
    if (!this.getRequest) {
      this.getCompanyList({...event, sortBy: 'clientLegalName', sortType: 'asc'});
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getCompanyList({...event, sortBy: 'clientLegalName', sortType: 'asc'});
    }
  }

  handleExport(list?:any) {
    if (list || this.companyList.rows?.length) {
      let finalList = list || this.companyList.rows;
      let reportData =finalList.map((x: any) => {
        return {
          ...x, status: x.companyStatus ? 'Active' : 'Inactive',
          tpa: x.tpas[0]?.tpaName,
          createdAt : moment(x.createdAt).format('MM/DD/YYYY')
        }
      })
      const content = {
        header: ExcelHeaders.AdminCompany,
        data: reportData,
        reportTitle: 'Company',
        fileName: 'Company_Report'
      }
      this.excelExportService.exportExcel(content);
    }
  }
}
