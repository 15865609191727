<div class="modal-header p-3">
    <p class="modal-title mb-0 fw-bold" id="exampleModalLabel">{{ modalTitle }}</p>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="">
        <label for="user" class="form-label">Name</label>
        <div class="input-group input-group-lg mb-3">
          <input
            mdbInput
            type="text"
            class="form-control"
            id="fileName"
            aria-describedby="basic-addon3"
            [(ngModel)]="fieldValue"
            name="fileName"
            autocomplete="off"
          />
        </div>
      </div>
  </div>
  <div class="modal-footer d-flex justify-content-between p-1">
    <button type="button" class="btn btn-clear text-primary" (click)="modalRef.close()">
      Cancel
    </button>
    <button type="button btn-sm" class="btn btn-primary" (click)="modalRef.close(fieldValue)">Rename</button>
  </div>