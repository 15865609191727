import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Observable, Subject, catchError, concat, debounceTime, distinctUntilChanged, finalize, of, switchMap, tap } from 'rxjs';

import { CommonListService, NetworkService, ProviderService } from 'src/app/services';
import { CompanyDropdownConstant } from '../../constants'
import { CustomMessagingModel } from 'src/app/model';

@Component({
  selector: 'app-custom-messaging-report-modal',
  templateUrl: './custom-messaging-report-modal.component.html',
  styleUrls: ['./custom-messaging-report-modal.component.scss']
})
export class CustomMessagingReportModalComponent {

  constructor(public modalRef: MdbModalRef<CustomMessagingReportModalComponent>,
    private commonListService: CommonListService,
    private networkService: NetworkService,
    private providerService: ProviderService) { }

  modalTitle: any = '';
  companyList: any = [];
  networkList: any = [];
  procedureList: any = [];
  listConstant = CompanyDropdownConstant;
  providerList: Observable<any>;
  providerLoading = false;
  providerInput = new Subject<string>();
  notFound = false;
  crmModel: CustomMessagingModel = new CustomMessagingModel();
  mentionConfig = {
    items: ['CompanyName', 'MemberServicePhone', 'PatientName', 'PatientPhoneNumber', 'WhiteLabelPhone'],
    triggerChar: "@",
  }

  selectedProvider:any 

  async ngOnInit() {
    await this.getNetwork(); 
    this.companyList = await this.commonListService.getCompanyList({
      limit: 0,
      companyStatus: true,
    });
    this.loadProvider();
    this.procedureList = await this.commonListService.getProcedureList({ limit: 0 });
  }

  getNetwork() {
    this.networkService
      .getNetworkList({ limit: 0 })
      .pipe(
        finalize(() => {
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.networkList = response.data.rows;
        }
      });
  }

  loadProvider() {
    this.providerList = concat(
      of([]), // default items
      this.providerInput.pipe(
        distinctUntilChanged(),
        tap(() => this.providerLoading = true),
        switchMap((term) =>
          this.providerService.getProvider({ keyword: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.providerLoading = false)))
      )
    )
  }
}
