<div class="modal-header">
    <h6 class="modal-title fw-bold" id="exampleModalLabel">{{modalTitle}}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close('close')"></button>
</div>
<div class="modal-body p- 2">
    <form #rolesForm="ngForm">
        <div class="md-form" *ngIf="event !== 'viewPermission' &&  event !=='managePermission'">
            <label for="username" class="form-label">Role Name</label>
            <div class="input-group mb-3">
                <input mdbInput type="text" class="form-control" id="username" placeholder="eg. Admin" required
                    aria-describedby="basic-addon3" name="search" [(ngModel)]="rolesModel.name" [disabled]="rolesModel.roleId"/>
            </div>
        </div>
        <div class="d-flex justify-content-center pt-5" *ngIf="permissionsLoading">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <table class="table" *ngIf="!permissionsLoading">
            <thead>
                <tr>
                    <th scope="">
                        <div class="d-flex">
                            <div class="col-8">Name</div>
                            <div class="col-2">Read</div>
                            <div class="col-2">Write</div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let permission of permissionList; let i = index">
                    <td class="px-0">
                        <div class="col-12 d-flex" *ngIf="!permission.submenu.length">
                            <div class="col-8">
                                <div class="form-check ps-5">
                                    <input mdbCheckbox [disabled]="event == 'viewPermission'"
                                        class="form-check-input me-4" type="checkbox" value="" id="permission.resource"
                                        [checked]="permission.hasPermission"
                                        (change)="onCheck($event, i, permission)" />
                                    <label class="form-check-label"> {{permission.name}}</label>
                                </div>
                            </div>
                            <div class="col-2">
                                <input mdbRadio [disabled]="event == 'viewPermission'" class="form-check-input"
                                    type="radio" name="{{permission.resource}}" id="read{{permission.resource}}"
                                    [checked]="permission.accessLevel == 'R'" (change)="onSelect('R', i, permission)" />
                            </div>
                            <div class="col-2">
                                <input mdbRadio [disabled]="event == 'viewPermission'" class="form-check-input"
                                    type="radio" name="{{permission.resource}}" id="write{{permission.resource}}"
                                    [checked]="permission.accessLevel == 'W'" (change)="onSelect('W', i, permission)" />
                            </div>
                        </div>

                        <div class="p-0 col-12" *ngIf="permission.submenu.length">
                            <div class="d-flex">
                                <div class="col-8 d-flex">
                                    <a class="pe-2 ps-1 btn-clear" (click)="basicCollapse.toggle()" role="button"
                                        [attr.aria-expanded]="!basicCollapse.collapsed" aria-controls="collapseExample">
                                        <i-bs [name]="basicCollapse.collapsed ? 'chevron-right' : 'chevron-down'">
                                        </i-bs>
                                    </a>
                                    <div class="form-check">
                                        <input mdbCheckbox [disabled]="event == 'viewPermission'"
                                            class="form-check-input me-3" type="checkbox" value=""
                                            id="permission.resource" [checked]="permission.hasPermission"
                                            (change)="onCheck($event, i, permission)" />
                                        <label class="form-check-label">
                                            {{permission.name}}</label>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <input mdbRadio [disabled]="event == 'viewPermission'" class="form-check-input"
                                        type="radio" name="{{permission.resource}}" id="read{{permission.resource}}"
                                        [checked]="permission.accessLevel == 'R'"
                                        (change)="onSelect('R', i, permission)" value="R" />

                                </div>
                                <div class="col-2">
                                    <input mdbRadio [disabled]="event == 'viewPermission'" class="form-check-input"
                                        type="radio" name="{{permission.resource}}" id="write{{permission.resource}}"
                                        [checked]="permission.accessLevel == 'W'"
                                        (change)="onSelect('W', i, permission)" value="W" />
                                </div>
                            </div>

                            <div class="mt-2 col-lg-12" id="collapseExample" mdbCollapse #basicCollapse="mdbCollapse">
                                <div class="d-flex py-1" *ngFor="let menu of permission.submenu; let index = index">
                                    <div class="col-lg-8 ps-5">
                                        <div class="form-check">
                                            <input mdbCheckbox [disabled]="event == 'viewPermission'"
                                                class="form-check-input m-0 me-3" type="checkbox" value=""
                                                id="{{menu.resource}}" [checked]="menu.hasPermission"
                                                (change)="onCheck($event,index, menu, i)" />
                                            <label class="form-check-label">
                                                {{menu.name}}</label>
                                        </div>

                                    </div>
                                    <div class="col-2">
                                        <input mdbRadio [disabled]="event == 'viewPermission'" [checked]="menu.accessLevel == 'R'"
                                            class="form-check-input m-0 col-lg-2" type="radio" name="{{menu.resource}}"
                                            id="read{{menu.resource}}" (change)="onSelect('R', index, menu, i)" value="R" />

                                    </div>
                                    <div class="col-2">
                                        <input mdbRadio [disabled]="event == 'viewPermission'"
                                            class="form-check-input m-0 col-lg-2" type="radio" name="{{menu.resource}}"
                                            [checked]="menu.accessLevel == 'W'"
                                            id="write{{menu.resource}}" (change)="onSelect('W', index, menu, i)" value="W" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>

</div>
<div class="modal-footer" *ngIf="event !== 'viewPermission' && event !=='managePermission'" style="border-top: none;">
    <button type="button" class="btn btn-gradient" (click)="handleSend()" [disabled]="rolesForm.invalid">
        Save</button>
</div>