import { Component, OnInit, Input } from '@angular/core';

import { AuthenticationService, TokenService } from '../../services';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() slim: any;

  menuList: any = [];
  authenticationToken = '';

  constructor(
    private authService: AuthenticationService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  async openSmartSearchPage() {
    this.tokenService
      .getAuthenticationToken()
      .pipe(finalize(() => {}))
      .subscribe((response: any) => {
        if (response && response.success) {
          this.authenticationToken = response.data;
        }
        this.router.navigate(['/service-case/smart-search'], {
          queryParams: {
            token: this.authenticationToken,
            isAlithiasRequest: true,
          },
        });
      });
  }

  async ngOnInit() {
    this.menuList = await this.authService.getMenuListFromUser();
  }
}
