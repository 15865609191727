<div>
  <div
    class="mt-2 mb-2 advanced-collapse member-profile"
    id="collapseExample"
    mdbCollapse
    #basicCollapse="mdbCollapse"
    [collapsed]="false"
  >
    <form #memberSearchForm="ngForm" autocomplete="off">
      <div class="p-3">
        <div class="">
          <div class="row pb-3">
            <div class="col-12 d-flex">
              <div class="col-4 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="user" class="form-label mb-0 text-center col-4"
                    >Member ID</label
                  >
                  <div class="col-8">
                    <input
                      mdbInput
                      type=""
                      class="form-control"
                      id="username"
                      aria-describedby="basic-addon3"
                      [(ngModel)]="memberSearchModel.subscriberId"
                      autocomplete="off"
                      name="alithiasUID"
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="logType" class="form-label mb-0 text-center col-4"
                    >Phone Number</label
                  >
                  <div class="col-8">
                    <input
                      mdbInput
                      class="form-control"
                      id="phone"
                      aria-describedby="basic-addon3"
                      [(ngModel)]="memberSearchModel.phone"
                      name="phone"
                      autocomplete="off"
                      mask="(000) 000-0000"
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="date" class="form-label mb-0 text-center col-4"
                    >Date of Birth</label
                  >
                  <div class="col-8">
                    <input
                      mdbInput
                      [mdbDatepicker]="dobPicker"
                      type="text"
                      class="form-control"
                      id="exampleDatepicker"
                      name="from"
                      [(ngModel)]="memberSearchModel.dob"
                      autocomplete="off"
                      (click)="dobPicker.open()"
                    />
                    <i-bs
                      name="x"
                      class="position-absolute"
                      style="right: 25px; top: 7px; cursor: pointer"
                      *ngIf="memberSearchModel.dob"
                    ></i-bs>
                    <mdb-datepicker-toggle
                      [mdbDatepicker]="dobPicker"
                    ></mdb-datepicker-toggle>
                    <mdb-datepicker
                      #dobPicker
                      [inline]="true"
                      [format]="'mm/dd/yyyy'"
                    ></mdb-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-3">
            <div class="col-12 d-flex">
              <div class="col-4 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="user" class="form-label mb-0 text-center col-4"
                    >First Name</label
                  >
                  <div class="col-8">
                    <input
                      mdbInput
                      type="text"
                      class="form-control"
                      id="username"
                      aria-describedby="basic-addon3"
                      [(ngModel)]="memberSearchModel.firstName"
                      name="memFirstName"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="user" class="form-label mb-0 text-center col-4"
                    >Middle Name</label
                  >
                  <div class="col-8">
                    <input
                      mdbInput
                      type="text"
                      class="form-control"
                      id="memMiddleName"
                      aria-describedby="basic-addon3"
                      [(ngModel)]="memberSearchModel.middleName"
                      name="memMiddleName"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="user" class="form-label mb-0 text-center col-4"
                    >Last Name</label
                  >
                  <div class="col-8">
                    <input
                      mdbInput
                      type="text"
                      class="form-control"
                      id="memLastName"
                      aria-describedby="basic-addon3"
                      [(ngModel)]="memberSearchModel.lastName"
                      name="memLastName"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-12 d-flex">
              <div class="col-8 d-flex">
                <div class="col-12 d-flex align-items-center">
                  <label for="date" class="form-label mb-0 text-center col-2"
                    >Company</label
                  >
                  <div class="col-10">
                    <input
                      mdbInput
                      [mdbAutocomplete]="autocomplete"
                      [(ngModel)]="companySearchText"
                      (ngModelChange)="filterList($event)"
                      (blur)="checkCompanyChange($event)"
                      type="text"
                      id="autocomplete"
                      class="form-control"
                      name="company"
                    />
                    <mdb-autocomplete
                      #autocomplete="mdbAutocomplete"
                      [displayValue]="displayValue"
                      (selected)="onCompanyChange($event)"
                    >
                      <ng-container>
                        <mdb-option
                          *ngFor="let option of keywordSearchList"
                          [value]="option"
                        >
                          {{ option.clientLegalName }}
                        </mdb-option>
                      </ng-container>
                    </mdb-autocomplete>
                  </div>
                </div>
              </div>
              <!-- <div class="col-1"></div> -->
              <div class="col-4">
                <div class="col-12 d-flex justify-content-center ps-4">
                  <div class="col-6 pe-2">
                    <button
                      class="btn w-100 btn-clear border"
                      (click)="reset()"
                    >
                      Reset
                    </button>
                  </div>
                  <div class="col-6 ps-2">
                    <button
                      class="btn w-100 btn-secondary"
                      (click)="searchMember()"
                      [disabled]="
                        !memberSearchModel.subscriberId &&
                        !memberSearchModel.phone &&
                        !memberSearchModel.dob &&
                        !memberSearchModel.firstName &&
                        !memberSearchModel.lastName &&
                        !memberSearchModel.middleName &&
                        !memberSearchModel.alithiasGroupId
                      "
                    >
                      <span
                        class="spinner-grow spinner-grow-sm me-2"
                        role="status"
                        aria-hidden="true"
                        *ngIf="loading"
                      ></span>
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="memberDropdownList.rows?.length">
            <label>{{ memberDropdownList.count }} results found</label>
            <div class="col-12 text-center d-flex align-items-center">
              <label for="date" class="form-label mb-0 text-end pe-4 col-3"
                >Select Member</label
              >
              <div class="col-7 text-start">
                <ng-select
                  [selectOnTab]="true"
                  class="form-control p-0"
                  notFoundText="No Result"
                  name="member"
                  (change)="memberSelected($event)"
                >
                  <ng-option
                    *ngFor="let option of memberDropdownList.rows"
                    [value]="option"
                  >
                    {{ option.lastName | titleCase }},
                    {{ option.firstName | titleCase }} ({{
                      option.dob | date : "MM/dd/yyyy"
                    }}), {{ option.subscription.groupName }}</ng-option
                  >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
