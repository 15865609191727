export class ReportFilter {
    company: any = [];
    from:any = null;
    to: any = null;
}


export class TagReportModel{
    company:any[] = [];
    from: null;
    to: null;
    tag:any[] = []
}