<div class="upload">
    <div class="modal-header">
        <h6 class="modal-title fw-bold" id="exampleModalLabel">{{modalTitle}}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
    </div>
    <div class="modal-body">
        <mdb-file-upload [multiple]="modalTitle == 'File Upload' ? true : false" (fileAdded)="fileAdded($event)" (fileRemoved)="fileRemoved($event)"></mdb-file-upload>
        <div class="pt-3" *ngIf="tpaInfo?.tpaFileList.length">
            <table class="w-100">
                <tbody>
                    <tr *ngFor="let file of tpaInfo.tpaFileList">
                        <td><a class="text-primary pointer" (click)="downloadFile(file)">
                                <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="file.downloading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                {{file.fileName}}
                            </a></td>
                        <td class="fs-2 text-gray-main"><span class="fs-3">{{file.uploadDate | date: 'dd MMMM, yyyy'}}</span></td>
                        <td class="text-gray-main text-end">
                            <i-bs name="trash-fill" class="text-gray-fill pointer" (click)="deleteFile(file)"
                                *ngIf="!file.deleting"></i-bs>
                            <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                *ngIf="file.deleting">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="modalRef.close()">
            Close
        </button>
        <button type="button" class="btn btn-primary" (click)="saveFiles()">Save</button>
    </div>
</div>