<div class="p-3">
  <app-member-profile-filter (emitSelectedMember)="getMemberDetail($event)"></app-member-profile-filter>
  <div class="pt-5 mt-5" *ngIf="detailLoading">
    <loader></loader>
  </div>
  <div class="light-bg" *ngIf="!detailLoading && memberDetail">
    <div class="pt-2">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex">
          <div class="tabSet fw-bold-2" *ngFor="let t of profileTabs; let index = index"
            [ngClass]="{ active: activeTab === index }">
            <a href="javascript:void(0)" class="" (click)="selectTab(index)">{{
              t
              }}</a>
          </div>
        </div>
      </div>
      <hr class="m-0" style="color: #b3b3b3" />
    </div>
    <div class="p-2 border border-selected-bg">
      <div class="col-12 d-flex" *ngIf="activeTab === 0">
        <div class="col-9">
          <p class="fw-bold-2">Member Information</p>
          <div class="" *ngIf="tabLoader">
            <loader></loader>
          </div>
          <div class="row col-12 d-flex fs-1" *ngIf="!tabLoader">
            <div class="col-6">
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Name:</p>
                </div>
                <div class="col-8">
                  <p>
                    {{ memberDetail.firstName | titleCase }}
                    {{ memberDetail.middleName | titleCase }}
                    {{ memberDetail.lastName | titleCase }}
                  </p>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Email:</p>
                </div>
                <div class="col-8">
                  <p>
                    {{
                    memberDetail.email[0]?.value ||
                    memberDetail.email[1]?.value ||
                    "-"
                    }}
                  </p>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Phone:</p>
                </div>
                <div class="col-8">
                  <p>{{ memberDetail.phone[0]?.value | phone }}</p>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Date of Birth:</p>
                </div>
                <div class="col-8">
                  <p>{{ memberDetail.dob | date : "MM/dd/yyyy" }}</p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Address:</p>
                </div>
                <div class="col-8">
                  <p>
                    {{ memberDetail.address.addressLine1 | titleCase }}
                    <br />
                    {{ memberDetail.address.city | titleCase }},{{
                    memberDetail.address.state
                    }}
                    {{ memberDetail.address.zip }}
                  </p>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Company:</p>
                </div>
                <div class="col-8">
                  <p>{{ memberDetail.subscription.groupName | titleCase }}</p>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Network:</p>
                </div>
                <div class="col-8">
                  <p>
                    {{(memberDetail.networkInfo | titleCase) || "-"}}
                  </p>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="col-4">
                  <p>Benefit Date:</p>
                </div>
                <div class="col-8">
                  <p>
                    {{ "Start" + " - " + memberDetail.benefitStartDate }}
                    {{
                    memberDetail.benefitEndDate
                    ? " | End - " + memberDetail.benefitEndDate
                    : ""
                    }}
                  </p>
                </div>
              </div>
              <!-- <div class="col-12 d-flex">
                                <div class="col-4">
                                    <p>Registered Date:</p>
                                </div>
                                <div class="col-8">
                                    <p>Not Registered</p>
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="ps-3" style="height: 100%; border-left: 1px solid #e1e2e2">
            <p class="fw-bold">Dependent List</p>
            <div class="d-flex pb-2 align-items-center" *ngFor="let d of dependentlist | sort : 'asc' : 'firstName'">
              <input mdbRadio class="form-check-input m-0 col-lg-2" type="radio" estid="dependent"
                [checked]="d.selected" (change)="changeDependant(d)" value="" />
              <p class="ps-3 mb-0">
                {{ d.firstName | titleCase }} {{ d.middleName | titleCase }}
                {{ d.lastName | titleCase }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--History Tab-->
      <div class="col-12" *ngIf="activeTab === 1">
        <div>
          <div class="col-12 d-flex justify-content-between pb-3">
            <p class="fw-bold mb-0 col-6">Case History</p>
            <div class="col-6">
              <div class="col-12 d-flex align-items-center justify-content-end">
                <label class="pe-3 col-1">View</label>
                <div class="col-5">
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result" name="member"
                    (change)="getCaseHistory($event)" [(ngModel)]="dropdownInput">
                    <ng-option *ngFor="
                        let d of dependentlist | sort : 'asc' : 'firstName'
                      " [value]="d.alithiasUID">
                      {{ d.firstName | titleCase }}
                      {{ d.middleName | titleCase }}
                      {{ d.lastName | titleCase }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-member mb-0">
          <thead>
            <tr class="table-min-row fs-2">
              <th scope="col" class="fw-bold">Case ID</th>
              <th scope="col" class="fw-bold">Request Status</th>
              <th scope="col" class="fw-bold">Patient Name</th>
              <th scope="col" class="fw-bold">Procedure</th>
              <th scope="col" class="fw-bold">Call Type</th>
              <th scope="col" class="fw-bold">Call Regarding</th>
              <th scope="col" class="fw-bold">Assigned To</th>
              <th scope="col" class="fw-bold">Created Date</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngIf="!caseHistoryList.length && !tabLoader">
              <td colspan="5">Member case history not found.</td>
            </tr>
            <tr *ngIf="tabLoader">
              <td colspan="8">
                <loader></loader>
              </td>
            </tr>
            <ng-container *ngIf="caseHistoryList.length">
              <tr class="table-min-row fs-2" *ngFor="let r of caseHistoryList">
                <td scope="row">{{ r.caseId }}</td>
                <td>
                  {{ r.status === "CNR" ? "Closed-No Response" : r.status }}
                </td>
                <td>
                  {{ r.patient.firstName | titleCase }}
                  {{ r.patient.middleName | titleCase }}
                  {{ r.patient.lastName | titleCase }}
                </td>
                <td>{{ r.service[0].name }}</td>
                <td>{{ r?.caseType ? r.caseType[0].split(" | ")[0] : "" }}</td>
                <td>
                  {{
                  r?.caseType
                  ? r.caseType[0].split(" | ").length === 2
                  ? r.caseType[0].split(" | ")[1]
                  : ""
                  : ""
                  }}
                </td>
                <td>{{ r.to.name }}</td>
                <td>{{ r.createdDate | date : "MM/dd/yyyy" }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <!--Files Tab-->
      <div class="col-12" *ngIf="activeTab === 2">
        <div>
          <div class="col-12 d-flex justify-content-between pb-3">
            <p class="fw-bold mb-0 col-6">Files</p>
            <div class="col-6">
              <div class="col-12 d-flex align-items-center justify-content-end">
                <label class="pe-3 col-1">View</label>
                <div class="col-5">
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result" name="member"
                    (change)="getFileList($event)" [(ngModel)]="dropdownInput">
                    <ng-option *ngFor="
                        let d of dependentlist | sort : 'asc' : 'firstName'
                      " [value]="d">
                      {{ d.firstName | titleCase }}
                      {{ d.middleName | titleCase }}
                      {{ d.lastName | titleCase }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-member mb-0">
          <thead>
            <tr class="table-min-row fs-2">
              <th scope="col" class="fw-bold text-nowrap">Case ID</th>
              <th scope="col" class="fw-bold text-nowrap">Uploaded Date</th>
              <th scope="col" class="fw-bold text-nowrap">Uploaded By</th>
              <th scope="col" class="fw-bold">Name</th>
              <th scope="col" class="fw-bold text-center">Action</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngIf="!fileList.length && !tabLoader">
              <td colspan="5">Member file list not found.</td>
            </tr>
            <tr *ngIf="tabLoader">
              <td colspan="8">
                <loader></loader>
              </td>
            </tr>
            <ng-container *ngIf="fileList.length">
              <tr class="table-min-row fs-2" *ngFor="let r of fileList">
                <td class="text-nowrap" scope="row">{{ r.caseId }}</td>
                <td class="text-nowrap">
                  {{ r.createdAt | date : "MM/dd/yyyy" }}
                </td>
                <td class="text-nowrap">{{ r.createdBy?.name }}</td>
                <td>{{ r.name }}</td>
                <td class="text-center" (click)="downlodFile(r)">
                  <div class="spinner-border spinner-border-sm text-primary" role="status" *ngIf="r.downloading">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <a download class="text-gray-main" *ngIf="!r.downloading"><i-bs name="download"></i-bs></a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <!-- ID card Tab -->
      <div class="col-12" *ngIf="activeTab === 3">
        <div class="d-flex p-2 pb-3 align-items-center">
          <p class="fw-bold mb-0 pe-2">Member Insurance ID Card</p>
          <div class="spinner-border spinner-border-sm" role="status" *ngIf="addingFile">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="upload" *ngIf="!memberDetail.insuranceCardInfo?.fileInfo?.length">
          <mdb-file-upload [multiple]="true" (fileAdded)="fileAdded($event)" #fileUpload
            [acceptedExtensions]="'image/*, .pdf'" [defaultMsg]="'Drag and Drop or Browse files'"></mdb-file-upload>
        </div>
        <div *ngIf="memberDetail.insuranceCardInfo?.fileInfo?.length">
          <div class="col-12 d-flex">
            <div class="upload col-3">
              <mdb-file-upload [multiple]="true" (fileAdded)="fileAdded($event)" [height]="300" #fileUpload
                [acceptedExtensions]="'image/*, .pdf'" [defaultMsg]="'Drag and Drop or Browse files'"></mdb-file-upload>
            </div>
            <div class="ps-2 col-3" *ngFor="let x of memberDetail.insuranceCardInfo?.fileInfo.slice(0,3)">
              <div class="bg-white" style="height:300px">
                <div class="d-flex justify-content-between p-2 align-items-center">
                  <h6 class="fw-bold pb-0 text-truncate" style="max-width: 200px">{{x.fileName}}</h6>
                  <div class="spinner-border spinner-border-sm" role="status" *ngIf="x.uploadingId">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div mdbDropdown class="" *ngIf="!x.uploadingId">
                    <button class="btn btn-clear btn-floating dropdown-toggle shadow-none pe-0" mdbDropdownToggle
                      aria-expanded="false">
                      <i-bs name="three-dots-vertical"></i-bs>
                    </button>
                    <ul autoClose="true" mdbDropdownMenu class="dropdown-menu shadow"
                      style="min-width: 120px; position: absolute; left: -72px;">
                      <li>
                        <button class="dropdown-item" type="button" (click)="renameIdCard(x)">
                          <i-bs name="pencil-square" class="me-2"></i-bs> Rename
                        </button>
                      </li>
                      <!-- <li>
                        <button class="dropdown-item" type="button" (click)="updateFile()">
                          <i-bs name="upload" class="me-2"></i-bs> Update
                        </button>
                      </li> -->
                      <li>
                        <button class="dropdown-item" type="button" (click)="deleteIDCard(x)">
                          <i-bs name="trash-fill" class="me-2"></i-bs> Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <img class="col-12 pointer" [src]="sanitizer.bypassSecurityTrustResourceUrl(x?.filePath)" alt="" height="200"
                  width="300" *ngIf="x.contentType !== 'application/pdf'" (click)="viewPfd(x)"/>
                <div class="d-flex justify-content-center pointer bg-selected-bg pt-5" (click)="viewPfd(x)"
                  *ngIf="x.contentType=='application/pdf'" style="height:244px">
                  <i-bs name="file-pdf" height="100px" width="100px" class="text-gray-main mt-4"></i-bs>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex align-content-start flex-wrap pt-2" 
            *ngIf="memberDetail.insuranceCardInfo?.fileInfo?.length > 3">
            <div class="ps-2 col-3" *ngFor="let x of memberDetail.insuranceCardInfo?.fileInfo.slice(3)">
              <div class="bg-white" style="height:300px">
                <div class="d-flex justify-content-between p-2 align-items-center">
                  <h6 class="fw-bold pb-0  text-truncate" style="max-width: 200px" >{{x.fileName}}</h6>
                  <div class="spinner-border spinner-border-sm" role="status" *ngIf="x.uploadingId">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div mdbDropdown class="" *ngIf="!x.uploadingId">
                    <button class="btn btn-clear btn-floating dropdown-toggle shadow-none pe-0" mdbDropdownToggle
                      aria-expanded="false">
                      <i-bs name="three-dots-vertical"></i-bs>
                    </button>
                    <ul autoClose="true" mdbDropdownMenu class="dropdown-menu shadow"
                      style="min-width: 120px; position: absolute; left: -72px;">
                      <li>
                        <button class="dropdown-item" type="button" (click)="renameIdCard(x)">
                          <i-bs name="pencil-square" class="me-2"></i-bs> Rename
                        </button>
                      </li>
                      <!-- <li>
                        <button class="dropdown-item" type="button" (click)="updateFile()">
                          <i-bs name="upload" class="me-2"></i-bs> Update
                        </button>
                      </li> -->
                      <li>
                        <button class="dropdown-item" type="button" (click)="deleteIDCard(x)">
                          <i-bs name="trash-fill" class="me-2"></i-bs> Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <img class="col-12 pointer" *ngIf="x.contentType !== 'application/pdf'" (click)="viewPfd(x)"
                  [src]="sanitizer.bypassSecurityTrustResourceUrl(x?.filePath)" alt="" height="200" width="300" />
                <div class="d-flex justify-content-center pointer bg-selected-bg pt-5" (click)="viewPfd(x)"
                  *ngIf="x.contentType=='application/pdf'" style="height:244px">
                  <i-bs name="file-pdf" height="100px" width="100px" class="text-gray-main mt-4"></i-bs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>