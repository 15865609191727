import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class AddOnServiceModel {
    static setAddOnServiceModel(value? :any){
        let model : FormFields[] = [
            
            {
                name: "addOnServiceId",
                id: "addOnServiceId",
                controlType: 'input',
                controlName: "addOnServiceId",
                required: true,
                value: value?.addOnServiceId || null
            },
            {
                name: "vendorName",
                id: "vendorName",
                controlType: 'input',
                controlName: "vendorName",
                required: true,
                value: value?.vendorName || ''
            },
            {
                name: 'vendorPortalUrl',
                id: 'vendorPortalUrl',
                controlType: 'input',
                controlName: 'vendorPortalUrl',
                required: true,
                value: value?.vendorPortalUrl || ''
            },
            {
                name: 'vendorPhone',
                id: 'vendorPhone',
                controlType: 'input',
                controlName: 'vendorPhone',
                value: value?.vendorPhone || null
            },
            {
                name: 'vendorEmail',
                id: 'vendorEmail',
                controlType: 'input',
                controlName: 'vendorEmail',
                value: value?.vendorEmail || ''
            },
            {
                name: 'startDate',
                id: 'startDate',
                controlType: 'input',
                controlName: 'startDate',
                value: value?.startDate ? new Date(value?.startDate) : null
            },
            {
                name: 'endDate',
                id: 'endDate',
                controlType: 'input',
                controlName: 'endDate',
                value: value?.startDate ? new Date(value?.endDate) : null
            },
            {
                controlType: 'vendorDetails',
                controlName: 'vendorDetails',
                name: 'vendorDetails',
                id: 'vendorDetails',
                value: value?.vendorDetails || [],
                formArray:[]
            },
            {
                controlType: 'addOnAccountInformation',
                controlName: 'addOnAccountInformation',
                subFields: [{
                    name: 'pepmCost',
                    id: 'pepmCost',
                    controlType: 'input',
                    controlName: 'pepmCost',
                    value: value?.addOnAccountInformation?.pepmCost || null
                },
                {
                    name: 'perServiceCost',
                    id: 'perServiceCost',
                    controlType: 'input',
                    controlName: 'perServiceCost',
                    value: value?.addOnAccountInformation?.perServiceCost || null
                },
                {
                    name: 'accountNotes',
                    id: 'accountNotes',
                    controlType: 'input',
                    controlName: 'accountNotes',
                    value: value?.addOnAccountInformation?.accountNotes || '',
                }
                ]
            }

        ]
        return model;
    } 
}