<div class="px-3">
  <div class="pt-5 mt-5" *ngIf="loading">
    <loader></loader>
  </div>
  <div class="dashboard" *ngIf="!loading">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="fw-bold px-2 pt-4 pb-1">{{displayMsg}}</h6>
    </div>
    <div class="col-12 d-flex py-2" *ngIf="summaryStats">
      <div class="col-3 px-2" *ngFor="let stat of summaryStats">
        <div class="card bg-gray-lighter db-sec">
          <div class="card-body p-3">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <i-bs [name]="stat.icon" class="text-primary"></i-bs> <span
                  class="fs-2 fw-bold ps-2">{{stat.label}}</span>
              </div>
              <a href="/active-employees" target="_blank">
                <i-bs name="globe-americas" *ngIf="stat.label.toLowerCase() == 'employees'" title="View Member Heatmap"
                  class="pointer text-primary"></i-bs>
              </a>
            </div>
            <div class="d-flex justify-content-between py-2">
              <div>
                <h6 class="py-2 fw-bold">{{stat.total |number}}</h6>
                <p class="fs-3 pt-1 mb-0">Total {{stat.label}}</p>
              </div>
              <div>
                <h6 class="py-2 fw-bold">{{stat.active |number}}</h6>
                <p class="fs-3 pt-1 mb-0">Active {{stat.label}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex pt-4">
      <div class="col-6 px-2" *ngIf="chartConfig.datasets.length">
        <div class="bg-gray-lighter stats db-sec p-3">
          <div class="d-flex py-3 pt-0">
            <a class="m-0 a-hover pointer" title="View More" href="/etl/by-company" target="_blank">Count Variance (By
              Company)</a>
          </div>
          <canvas mdbChart [type]="'bar'" [datasets]="chartConfig.datasets" [labels]="chartConfig.labels"
            [backgroundColor]="chartConfig.colors" [options]="commonChartOption" [legend]="true"></canvas>
        </div>
      </div>
      <div class="col-6 px-2" *ngIf="dataSourceList.rows?.length">
        <div class="bg-gray-lighter db-sec stats p-3">
          <div class="d-flex py-3 pt-0 justify-content-between">
            <p class="m-0">Files Missing (By Source)</p>
            <div class="d-flex justify-content-end p-1">
              <a class="clickable pointer" href="/etl/by-source" target="_blank">View More</a>
            </div>
          </div>
          <table class="table fs-2 mb-2">
            <thead>
              <tr>
                <th>Name</th>
                <th>Frequency</th>
                <th>Last File</th>
                <th style="width:10px !important;">Days</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of dataSourceList.rows?.slice(0, 7)">
                <td>{{data.sourceCode}}</td>
                <td>{{data.frequency.interval}}</td>
                <td>
                  <div *ngIf="data.fileName.length > 20">
                    {{data.fileName.slice(0, 20)}}<span class="pointer" mdbPopover="{{data.fileName}}"
                      trigger="hover">...</span>
                    <!-- <br>({{data.lastRunDate| date :'MM/dd'}}) -->
                  </div>
                  <div *ngIf="data.fileName.length < 20">
                    {{data.fileName}}
                    <!-- <br>({{data.lastRunDate| date :'MM/dd'}}) -->
                  </div>
                </td>
                <td>{{data.status.days}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>