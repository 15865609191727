<div class="px-3">
  <p class="fs-1 fw-bold mb-0 pt-4">Search Pricing</p>
  <app-search-bar [searchConfig]="searchConfig" (searchLogEmit)="handleLogEmit($event)">
  </app-search-bar>

  <div class="pt-5 mt-5" *ngIf="pricingLoading">
    <loader></loader>
  </div>
  <div class="text-center pt-4" *ngIf="!pricingList.rows?.length && hasSearched">
    <p class="fw-bold">Pricing not found.</p>
  </div>
  <div class="pt-3" *ngIf="pricingList.rows?.length">
    <div class="d-flex align-items-center justify-content-between">
      <p class="fw-bold fs-2">Showing {{pricingList.count}} Result</p>
      <div class="d-flex">
        <div class="pe-3">
          <div class="mb-3 limit-input">
            <ng-select class="form-control p-1" style="background: transparent !important"
              [(ngModel)]="searchConfig.limit" name="itemsPerPage" [searchable]="false" (change)="searchPricing()">
              <ng-option [value]="10" selected>Show 10</ng-option>
              <ng-option [value]="25">Show 25</ng-option>
              <ng-option [value]="50">Show 50</ng-option>
              <ng-option [value]="100">Show 100</ng-option>
              <ng-option [value]="0">Show All</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="pe-3">
          <div class="mb-3 limit-input">
            <ng-select class="form-control p-1" style="background: transparent !important"
              [(ngModel)]="searchConfig.distance" name="itemsPerPage" [searchable]="false" (change)="searchPricing()">
              <ng-option [value]="30" selected>30mi</ng-option>
              <ng-option [value]="60">60mi</ng-option>
              <ng-option [value]="120">120mi</ng-option>
              <ng-option [value]="200">200mi</ng-option>
              <ng-option [value]="240">240mi</ng-option>
            </ng-select>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-clear pt-2 border" (click)="exportPricing()">
            <i-bs name="download" class="pe-2"></i-bs>Export
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="pricingList.rows?.length">
    <table class="table mb-0">
      <thead>
        <tr class="fs-2 table-min-row">
          <th class="fw-bold px-1">Provider NPI</th>
          <th class="fw-bold pointer" style="width: 300px;" (click)="sort({sortBy: 'providerName'})">
            <div class="d-flex align-items-center justify-content-between">
              Provider Name
              <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'providerName'"></i-bs>
            </div>
          </th>
          <th class="fw-bold pointer" style="width: 200px;" (click)="sort({sortBy: 'networkName'})">
            <div class="d-flex align-items-center justify-content-between">
              Network
              <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'networkName'"></i-bs>
            </div>
          </th>
          <th class="fw-bold pointer" (click)="sort({sortBy: 'practiceAddressCity'})">
            <div class="d-flex align-items-center justify-content-between">
              Address
              <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'practiceAddressCity'"></i-bs>
            </div>
          </th>
          <th class="fw-bold">Timeline</th>
          <th class="fw-bold text-nowrap pointer" (click)="sort({sortBy: 'likelyCost'})">
            <div class="d-flex align-items-center justify-content-between">
              Likely <br>Cost
              <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'likelyCost'"></i-bs>
            </div>
          </th>
          <th class="fw-bold text-nowrap pointer" (click)="sort({sortBy: 'retailPrice'})">
            <div class="d-flex align-items-center justify-content-between">
              Retail <br> Price
              <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'retailPrice'"></i-bs>
            </div>
          </th>
          <th class="fw-bold text-nowrap pointer" (click)="sort({sortBy: 'discount'})">
            <div class="d-flex align-items-center justify-content-between">
              Discount
              <i-bs name="chevron-expand" class="pointer" *ngIf="order === 'discount'"></i-bs>
            </div>
          </th>
          <th class="fw-bold text-nowrap">Fee & <br> Retail <br> Episode</th>
          <th class="fw-bold text-nowrap">Fee & <br> Pricing <br> Source</th>
          <th class="fw-bold">Included</th>
          <th class=""></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let pricing of pricingList.rows; let i = index">
          <tr [ngClass]="{ 'row-striped': i % 2 === 0 }" class="fs-2 table-min-row">
            <td class="px-1">
              <span class="text-decoration-underline pointer" (click)="getEpisodeDetail(pricing)">{{ pricing.providerNPI
                }}</span>
            </td>
            <td>{{ pricing.providerName | titleCase}}</td>
            <td>{{ pricing.networkName | titleCase}}</td>
            <td><span [mdbPopover]="addressPopover" trigger="hover" placement="left" class="pointer">
                {{ pricing.practiceAddressCity | titleCase}}
                <ng-template #addressPopover>
                  <p class="mb-2">
                    <i-bs name="geo-alt-fill" class="me-1"></i-bs>
                    {{pricing.practiceAddressLine1 | titleCase}} <br>
                    <span class="ps-4">{{pricing.practiceAddressCity | titleCase}} {{pricing.practiceAddressState}},
                      {{pricing.providerZip}}</span>
                  </p>
                  <p class="mb-0">
                    <i-bs name="phone-fill" class="me-1"></i-bs> {{pricing.practiceAddressPhone}}
                  </p>
                </ng-template>
              </span></td>
            <td>{{ pricing.likeLyCostTimeLine}}</td>
            <td>${{ pricing.likelyCost | number : "1.1-1" }}</td>
            <td>${{ pricing.retailPrice | number : "1.1-1"}}</td>
            <td>{{pricing.discount  | number : "1.1-1"}}%</td>
            <td>{{ pricing.feeScheduleEpisodes }} <br>{{ pricing.retailPriceEpisodes }} </td>
            <td>{{ pricing.feeScheduleSourceID }} <br> {{pricing.pricingSource}}</td>
            <td><span [mdbPopover]="includedPopOver" trigger="hover" placement="left"
                class="pointer">{{pricing.included}}</span>
              <ng-template #includedPopOver>
                <p class="mb-2" *ngIf="pricing.included.includes('A')">A: Anesthesia</p>
                <p class="mb-2" *ngIf="pricing.included.includes('F')">F: Facility</p>
                <p class="mb-0" *ngIf="pricing.included.includes('P')">P: Physician</p>
              </ng-template>
            </td>
            <td>
              <div class="d-flex">
                <a href="javascript:void(0)" *ngIf="!pricing.loading"
                  [ngClass]="{'text-secondary': pricing.activeFlag, 'text-danger': !pricing.activeFlag}"
                  title="{{pricing.activeFlag ? 'Active' : 'Inactive'}}">
                  <i-bs [name]="pricing.activeFlag ? 'eye-fill' : 'eye-slash-fill'"
                    (click)="updatePricing(pricing, pricing.activeFlag)"></i-bs>
                </a>
                <div class="text-center" *ngIf="pricing.loading">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>