import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Observable, Subject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { NetworkModel } from '../../model/network.model';
import { CompanyDropdownConstant } from 'src/app/constants';

@Component({
  selector: 'app-network-management-modal',
  templateUrl: './network-management-modal.component.html',
  styleUrls: ['./network-management-modal.component.scss']
})
export class NetworkManagementModalComponent implements OnInit {

  modalTitle: string = '';
  networkModel: NetworkModel =  new NetworkModel();
  networkGroupList:any;
  searchText = new Subject<string>();
  results: Observable<any[]>;
  notFound = false;
  selectedGroupNetwork :any;
  dropdownConstants = CompanyDropdownConstant;

  constructor(public modalRef: MdbModalRef<NetworkManagementModalComponent>) { 
    this.results = this.searchText.pipe(
      startWith(''),
      map((value: any) => this.filter(value)),
      tap((results: any) =>
        results.length > 0 ? (this.notFound = false) : (this.notFound = true)
      )
    );
  }

  ngOnInit(): void {
    this.selectedGroupNetwork = (this.networkModel.groupNetworkName && this.networkModel.groupNetworkId) ? this.networkModel.groupNetworkName + ' - ' + this.networkModel.groupNetworkId : '';
  }
  
  saveNetwork() {
    this.modalRef.close(this.networkModel);
  }
  
  filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.networkGroupList.filter((item: any) =>
      (item.groupNetworkName.toLowerCase() + ' - ' + item.groupNetworkId).includes(filterValue)
    );
  }

  groupNetworkblur(value:any) {
    if(value) {
      if(value.includes(' - ')){
        this.networkModel.groupNetworkName = value.split(' - ')[0];
        this.networkModel.groupNetworkId = value.split(' - ')[1];
      }
      else{
        this.networkModel.groupNetworkName = value;
        this.networkModel.groupNetworkId = 0;
      }
    }
  }
}
