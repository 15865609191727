<mdb-tabs class="general pt-2" #medicalPlanDetailTabs [justified]="true" (activeTabChange)="onTabChange($event)">
  <!-- ---------------Plan Type Details-------------------- -->
  <mdb-tab title="Plan Type Details" [formGroup]="medicalPlanTypeForm">
    <div class="">
      <div class="col-12 p-2">
        <div class="pb-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <p class="fw-bold pt-3 pe-2">Plan Type</p>
              <div class="spinner-border spinner-border-sm" role="status"
                *ngIf="parentComponent.loadingMedicalPlantypeDetail">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="
                addMedicalPlanTypeDetail(
                  medicalPlanTypeForm,
                  'medicalPlantypeInfo'
                )
              ">
              <span>
                <i-bs name="plus"></i-bs>
              </span>
              Add New Plan
            </button>
          </div>
        </div>
        <div class="" formArrayName="medicalPlantypeInfo">
          <div class="col-12 p-3" *ngIf="
              !getFormArray(medicalPlanTypeForm, 'medicalPlantypeInfo').length
            ">
            <p class="text-center">No Plan Types</p>
          </div>
          <div class="col-12 p-0 pb-3" *ngFor="
              let item of getFormArray(
                medicalPlanTypeForm,
                'medicalPlantypeInfo'
              ).controls;
              let i = index
            " [formGroupName]="i">
            <div class="border">
              <div class="collapse-btn">
                <button type="button"
                  class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
                  (click)="medicalPlantypeInfo.toggle()" [attr.aria-expanded]="!medicalPlantypeInfo.collapsed"
                  aria-controls="collapseExample">
                  <span class="text-capitalize">
                    <i-bs [name]="
                        !medicalPlantypeInfo.collapsed
                          ? 'chevron-down'
                          : 'chevron-up'
                      " class="pe-2"></i-bs>
                    {{ item.get("preferrdProgramType")?.value || "New Plan" }}
                  </span>
                  <span>
                    <i-bs name="trash-fill" class="pull-right text-gray-main" (click)="
                        openPlanTypeConfirmationModel(
                          i,
                          item,
                          medicalPlanTypeForm,
                          'medicalPlantypeInfo'
                        )
                      "></i-bs>
                  </span>
                </button>
              </div>
              <div class="collapse-body px-4" id="collapseExample" mdbCollapse #medicalPlantypeInfo="mdbCollapse"
                [collapsed]="false">
                <div class="d-flex align-items-center justify-content-end col-6 pt-3">
                  <label for="network-type" class="pe-2 fw-bold">Plan Type</label>
                  <div class="col-7">
                    <input type="text" class="form-control" name="preferrdProgramType"
                      formControlName="preferrdProgramType" />
                  </div>
                </div>
                <hr class="mt-3" />
                <div class="d-flex align-items-center justify-content-between">
                  <p class="fs-2 fw-bold">Network Details</p>
                  <a class="text-primary pointer" type="button" (click)="addNetworkDetails(item, 'networkDetails')">
                    <i-bs name="plus"></i-bs>
                    Add New Network
                  </a>
                </div>
                <div class="collapse-network-detail pb-3" formArrayName="networkDetails">
                  <div *ngIf="!getFormArray(item, 'networkDetails').length">
                    <p class="text-center">No Network Details</p>
                  </div>
                  <div class="collapse-network-body pb-3" *ngFor="
                      let networkInfo of getFormArray(item, 'networkDetails')
                        .controls;
                      let j = index
                    " [formGroupName]="j">
                    <button type="button"
                      class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
                      (click)="networkDetails.toggle()" [attr.aria-expanded]="!networkDetails.collapsed"
                      aria-controls="collapseExample">
                      <span  class="text-capitalize">
                        <i-bs [name]="
                            !networkDetails.collapsed
                              ? 'chevron-down'
                              : 'chevron-up'
                          " class="pe-2"></i-bs>
                        {{
                          getNetworDisplayName(networkInfo.get("networkType")?.value) || "New Plan"
                        }}
                      </span>
                      <span>
                        <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                          *ngIf="networkInfo.value.loading">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <i-bs name="trash-fill" *ngIf="!networkInfo.value.loading" class="pull-right text-gray-main"
                          (click)="
                            openDeleteConfirmationModel(
                              j,
                              networkInfo,
                              item,
                              'networkDetails'
                            )
                          "></i-bs>
                      </span>
                    </button>
                    <div id="collapseExample" mdbCollapse #networkDetails="mdbCollapse" [collapsed]="false">
                      <div class="d-flex align-items-center justify-content-end col-6 pt-3 pb-3">
                        <label for="network-type" class="pe-2 fw-bold">Network Type</label>
                        <div class="col-7">
                          <ng-select [selectOnTab]="true" class="form-control p-0" name="networkType"
                            formControlName="networkType">
                            <ng-option *ngFor="
                              let option of dropdownConstants.medicalPlanNetworkType  | sort:'asc':'name'
                            " [value]="option.value">{{ option.name }}</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <table class="table table-reponsive" formArrayName="networkInfos">
                        <thead>
                          <tr>
                            <th scope="col" class="fs-2 fw-bold" style="width: 230px !important">
                              Type
                            </th>
                            <th scope="col" class="fs-2 fw-bold" style="width: 150px !important">
                              Individual
                            </th>
                            <th scope="col" class="fs-2 fw-bold" style="width: 150px !important">
                              Family
                            </th>
                            <th scope="col" class="fs-2 fw-bold" style="width: 230px !important">
                              Accumulation Method
                            </th>
                            <th scope="col" class="fs-2 fw-bold" style="width: 278px !important">
                              Note
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="
                              !getFormArray(networkInfo, 'networkInfos').length
                            ">
                            <th colspan="6" class="fw-bold text-center">
                              No contact info
                            </th>
                          </tr>
                          <tr *ngFor="
                              let info of getFormArray(
                                networkInfo,
                                'networkInfos'
                              ).controls;
                              let k = index
                            " [formGroupName]="k">
                            <td>
                              <div>
                                <ng-select [selectOnTab]="true" class="form-control p-0" name="type"
                                  formControlName="type">
                                  <ng-option *ngFor="
                                      let option of dropdownConstants.medicalPlanDetailType
                                    " [value]="option.value">{{ option.name }}</ng-option>
                                </ng-select>
                              </div>
                            </td>
                            <td>
                              <input type="number" class="form-control" name="individual"
                                formControlName="individual" />
                            </td>
                            <td>
                              <input type="number" class="form-control" name="family" formControlName="family" />
                            </td>
                            <td>
                              <div>
                                <ng-select [selectOnTab]="true" class="form-control p-0" name="accumulateMethod"
                                  formControlName="accumulateMethod">
                                  <ng-option *ngFor="
                                      let option of dropdownConstants.medicalPlanAccumulatedMethod
                                    " [value]="option.value">{{ option.name }}</ng-option>
                                </ng-select>
                              </div>
                            </td>
                            <td>
                              <input type="text" class="form-control" name="note" formControlName="note" />
                            </td>

                            <td>
                              <span>
                                <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                  *ngIf="info.value.loading">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                                <i-bs name="trash-fill" class="pull-right text-gray-main" *ngIf="!info.value.loading"
                                  (click)="
                                    delete(k, info, networkInfo, 'networkInfos', item.value)
                                  ">
                                </i-bs>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <a class="test-primary pointer" type="button"
                        (click)="addNetworkInfo(networkInfo, 'networkInfos')">
                        <i-bs name="plus"></i-bs>
                        Add More
                      </a>
                      <hr class="mt-4 mb-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="col-12">
            <div class="col d-flex justify-content-end" *ngIf="
                getFormArray(medicalPlanTypeForm, 'medicalPlantypeInfo').length
              ">
              <button type="button" class="btn btn-primary" (click)="savePlanTypeDetail()">
                <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mdb-tab>
  <!-- ---------------Pre Certification-------------------- -->
  <mdb-tab title="Pre-Certification" [formGroup]="preCertificationForm">
    <div class="p-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <p class="fw-bold pt-3 ps-2 pe-2">Pre-Certification</p>
          <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th class="fs-2 fw-bold" style="width: 360px !important">
                Pre-Certification Vendor Name
              </th>
              <th class="fs-2 fw-bold" style="width: 344px !important">
                Pre-Certification Vendor Phone
              </th>
              <th class="fs-2 fw-bold" style="width: 360px !important">
                Pre-Certification Website
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="preCertificationInfo">
            <tr *ngIf="
                !getFormArray(preCertificationForm, 'preCertificationInfo')
                  .length
              ">
              <th colspan="4" class="fw-bold text-center">
                No Pre-Certifications
              </th>
            </tr>
            <tr *ngFor="
                let item of getFormArray(
                  preCertificationForm,
                  'preCertificationInfo'
                ).controls;
                let i = index
              " [formGroupName]="i">
              <td>
                <input type="text" class="form-control" formControlName="preCertificationVendorName"
                  name="preCertificationVendorName" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="preCertificationVendorPhone"
                  name="preCertificationVendorPhone" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="preCertificationWebsite"
                  name="preCertificationWebsite" />
              </td>
              <td>
                <i-bs name="trash-fill" class="pointer text-gray-main" *ngIf="!item.value.loading" (click)="
                    deletePreCertification(
                      i,
                      item,
                      preCertificationForm,
                      'preCertificationInfo'
                    )
                  "></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 pb-3">
        <a class="text-primary pointer" type="button" (click)="
            addPreCertificationForm(
              preCertificationForm,
              'preCertificationInfo'
            )
          "><i-bs name="plus" class="pe-2"></i-bs>Add More</a>
      </div>
      <div class="col d-flex justify-content-end" *ngIf="
          getFormArray(preCertificationForm, 'preCertificationInfo').length
        ">
        <button type="button" class="btn btn-primary" (click)="savePreCertification()">
          <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
            <span class="visually-hidden">Loading...</span>
          </div>
          Save
        </button>
      </div>
    </div>
  </mdb-tab>
  <!-- ---------------Include Routine Exams-------------------- -->
  <mdb-tab title="Included Routine Exams" [formGroup]="routineExamForm">
    <div class="p-3">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <p class="fw-bold pt-3 pe-2">Included Routine Exams</p>
          <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="col-12">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th class="fs-2 fw-bold" style="width: 541px !important">
                Exam Types
              </th>
              <th class="fs-2 fw-bold" style="width: 541px !important">
                Routine Exam Notes
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="routineExamInfo">
            <tr *ngIf="!getFormArray(routineExamForm, 'routineExamInfo').length">
              <th colspan="4" class="fw-bold text-center">
                No Pre-Certifications
              </th>
            </tr>
            <tr *ngFor="
                let item of getFormArray(routineExamForm, 'routineExamInfo')
                  .controls;
                let i = index
              " [formGroupName]="i">
              <td>
                <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result" name="examTypes"
                  formControlName="examTypes">
                  <ng-option *ngFor="
                    let option of dropdownConstants.medicalExamTypes | sort:'asc':'name'
                  " [value]="option.value">
                    {{ option.name }}</ng-option>
                </ng-select>
              </td>
              <td>
                <textarea mdbInput class="form-control" id="routineExamNotes" rows="3"
                  formControlName="routineExamNotes" name="routineExamNotes"></textarea>
              </td>
              <td>
                <i-bs name="trash-fill" class="pointer text-gray-main" *ngIf="!item.value.loading" (click)="
                    deleteRoutineExams(
                      i,
                      item,
                      routineExamForm,
                      'routineExamInfo'
                    )
                  "></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 pb-3">
        <a class="text-primary pointer" type="button"
          (click)="addRoutineExams(routineExamForm, 'routineExamInfo')"><i-bs name="plus" class="pe-2"></i-bs>Add
          More</a>
      </div>
      <div class="col d-flex justify-content-end" *ngIf="getFormArray(routineExamForm, 'routineExamInfo').length">
        <button type="button" class="btn btn-primary" (click)="saveRoutineExams()">
          <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
            <span class="visually-hidden">Loading...</span>
          </div>
          Save
        </button>
      </div>
    </div>
  </mdb-tab>
  <!-- ---------------Networks-------------------- -->
  <mdb-tab title="Networks" [formGroup]="medicalNetworksForm">
    <div class="p-3">
      <div class="col-12 pb-3">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Networks</p>
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <button type="button" class="btn btn-primary" (click)="
              addMedicalNetworks(medicalNetworksForm, 'medicalNetworksInfo')
            ">
            <span>
              <i-bs name="plus"></i-bs>
              Add New Network
            </span>
          </button>
        </div>
      </div>
      <div formArrayName="medicalNetworksInfo">
        <div *ngIf="
            !getFormArray(medicalNetworksForm, 'medicalNetworksInfo').length
          ">
          <p class="text-center">No Network Information</p>
        </div>
        <div class="col-12 pb-3" *ngFor="
            let item of getFormArray(medicalNetworksForm, 'medicalNetworksInfo')
              .controls;
            let i = index
          " [formGroupName]="i">
          <button
            class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
            type="button" (click)="medicalNetworksInfo.toggle()" [attr.aria-expanded]="!medicalNetworksInfo.collapsed"
            aria-controls="collapseExample">
            <span>
              <i-bs [name]="
                  !medicalNetworksInfo.collapsed ? 'chevron-down' : 'chevron-up'
                "></i-bs>
              {{ item.get("name")?.value || "New Network" }}
            </span>
            <span>
              <i-bs name="trash-fill" class="pull-right text-gray-main" (click)="
                  openDeleteConfirmationModel(
                    i,
                    item,
                    medicalNetworksForm,
                    'medicalNetworksInfo'
                  )
                "></i-bs>
            </span>
          </button>
          <div class="network-body pt-3" id="collapseExample" mdbCollapse #medicalNetworksInfo="mdbCollapse"
            [collapsed]="false">
            <div class="col-12 d-flex">
              <div class="col-6">
                <div class="d-flex pb-3 align-items-center justify-content-end">
                  <label for="type" class="pe-3">Type</label>
                  <div class="col-7">
                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                      formControlName="type">
                      <ng-option *ngFor="
                          let option of dropdownConstants.medicalNetworkType | sort:'asc':'name'
                        " [value]="option.value">
                        {{ option.name }}</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="d-flex pb-3 align-items-center justify-content-end">
                  <label for="type" class="pe-3">Name</label>
                  <div class="col-7">
                    <input type="text" class="form-control" name="name" formControlName="name" />
                  </div>
                </div>
                <div class="d-flex pb-3 align-items-center justify-content-end">
                  <label for="type" class="pe-3">Phone</label>
                  <div class="col-7">
                    <input type="text" class="form-control" name="phone" formControlName="phone"
                      mask='(000) 000-0000' />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex pb-3 align-items-center justify-content-end">
                  <label for="type" class="pe-3">Website</label>
                  <div class="col-7">
                    <input type="text" class="form-control" name="website" formControlName="website" />
                  </div>
                </div>
                <div class="d-flex pb-3 align-items-center justify-content-end">
                  <label for="type" class="pe-3">Notes</label>
                  <div class="col-7">
                    <textarea mdbInput class="form-control" id="textAreaExample" name="notes" formControlName="notes"
                      rows="2" name="accountNotes"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-5" />
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-end" *ngIf="
              getFormArray(medicalNetworksForm, 'medicalNetworksInfo').length
            ">
            <button type="button" class="btn btn-primary" (click)="saveMedicalNetworks()">
              <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
                <span class="visually-hidden">Loading...</span>
              </div>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </mdb-tab>
  <!-- ---------------Plan Type Details-------------------- -->
  <mdb-tab title="Reference Base Pricing Program" [formGroup]="referencePricingProgramForm">
    <div class="p-3">
      <div class="col-12 pb-3">
        <div class="d-flex align-items-center">
          <p class="fw-bold pt-3 ps-2 pe-2">Reference Base Pricing Program</p>
          <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex">
        <div class="col-6">
          <div class="col-12 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">RBP Vendor Name</label>
            <div class="col-7">
              <input type="text" class="form-control" id="legalName" formControlName="rbpVendorName"
                name="rbpVendorName" />
            </div>
          </div>
          <div class="col-12 d-flex align-items-center pt-3">
            <label for="name" class="pt-0 col-5 text-end pe-3">Portal</label>
            <div class="col-7">
              <input type="text" class="form-control" id="group" formControlName="portal" name="portal" />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="col-12 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Customer Service Phone</label>
            <div class="col-7">
              <input type="text" class="form-control" name="customerServicePhone" formControlName="customerServicePhone"
                mask="(000) 000-0000" />
            </div>
          </div>
          <div class="col-12 d-flex align-items-center pt-3">
            <label for="name" class="pt-0 col-5 text-end pe-3">Customer Service Email</label>
            <div class="col-7">
              <input type="text" class="form-control" name="customerServiceEmail" formControlName="customerServiceEmail"
                [pattern]="parentComponent.mailformat" />
            </div>
          </div>
          <div class="col-12 d-flex pt-3">
            <label for="name" class="pt-0 col-5 text-end pe-3">RBP Notes</label>
            <div class="col-7">
              <textarea mdbInput class="form-control" id="rbpNotes" rows="3" formControlName="rbpNotes"
                name="rbpNotes"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-2">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                Portal Access Contact
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 1
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                Ext
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 2
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Email
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="refereceBasedContactInfo">
            <tr *ngIf="!getFormArray(referencePricingProgramForm, 'refereceBasedContactInfo').length">
              <th colspan="6" class="fw-bold text-center">
                No contact info
              </th>
            </tr>
            <tr *ngFor="
                  let item of getFormArray(referencePricingProgramForm, 'refereceBasedContactInfo')
                    .controls;
                  let i = index
                " [formGroupName]="i">
              <td>
                <input type="text" class="form-control" formControlName="portalAccessContact" name="portalAccessContact" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="phone1" name="phone1" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="extension" name="extension" maxlength="6"
                  minlength="4" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="phone2" name="phone2" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="email" name="email"
                  [pattern]="parentComponent.mailformat" />
              </td>
              <td>
                <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main" (click)="deletePortalContactInfo(i, item.value, 'refereceBasedContactInfo', referencePricingProgramForm)"
                 ></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <a class="text-primary pointer" type="button" (click)="addContactForms(referencePricingProgramForm, 'refereceBasedContactInfo')"><i-bs
            name="plus"></i-bs>Add More</a>
      </div>
    </div>
    <hr class="mt-3" />
    <div class="d-flex justify-content-end pb-3">
      <button type="button" class="btn btn-primary" (click)="saveReferencePricingProgram()"
        [disabled]="!referencePricingProgramForm.valid">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </mdb-tab>
</mdb-tabs>