import { Injectable } from "@angular/core";
import { FormFields } from "../interfaces";

@Injectable()
export class EmailModel {
    static setSendEmailModel(value?:any){
        let model : FormFields[] = [
            {
                name: 'to',
                id: 'to',
                controlType: 'input',
                controlName: 'to',
                required: true,
                value: value?.to || null
            },
            {
                name: 'cc',
                id: 'cc',
                controlType: 'input',
                controlName: 'cc',
                required: true,
                value: value?.cc || null
            },
            {
                name: 'bcc',
                id: 'bcc',
                controlType: 'input',
                controlName: 'bcc',
                value: null,
                disabled : value?.bcc || null
            },
            {
                name: 'subject',
                id: 'subject',
                controlType: 'input',
                controlName: 'subject',
                value: value?.subject || null
            },
            {
                name: 'template',
                id: 'template',
                controlType: 'input',
                controlName: 'template',
                value: ''
            },
            {
                name: 'message',
                id: 'message',
                controlType: 'input',
                controlName: 'message',
                value: value?.message || null
            },
            {
                name: 'notifyByText',
                id: 'notifyByText',
                controlType: 'input',
                controlName: 'notifyByText',
                value: null
            }
        ]
        return model
    }
}
        