import { Component } from '@angular/core';
import { finalize } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

import { ChartConstants, MonthList, PDFReportConstant } from 'src/app/constants';
import { ReportService, PdfService, EmailService, CompanyService, ToastrService } from '../../../services';
import { SendReportComponent } from 'src/app/modal';
import { CurrentUser, DateCalc } from 'src/app/utils';

@Component({
  selector: 'app-saving-report',
  templateUrl: './saving-report.component.html',
  styleUrls: ['./saving-report.component.scss']
})
export class SavingReportComponent {

  printing: boolean = false;
  sending: boolean = false;
  loading: boolean = false;
  chartConst = ChartConstants.ChartColors;
  savingChartOption = ChartConstants.savingsReportOption;
  tatChartConst = ChartConstants.TATChartConstant;
  tatOptConst = ChartConstants.TATOption;
  reportContent: any = null;
  filterParams: any;
  recipientList: any = [];
  modalRef: MdbModalRef<SendReportComponent> | null = null;
  templateList: any = [];
  currentUserInfo: any;
  monthList = MonthList;
  labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  thisYear = new Date().getFullYear();
  today = moment().format('MM/DD/YYYY');
  planPeriod: any = null;

  //for savings-detailed and incentive  
  incentiveReportConfig: any;
  savingsDetailedReportConfig: any;

  emailBody: any;


  constructor(private reportService: ReportService,
    private pdfService: PdfService,
    private emailService: EmailService,
    private modalService: MdbModalService,
    private companyService: CompanyService,
    public sanitizer: DomSanitizer,
    public toasterService: ToastrService) { }

  ngOnInit() {
    this.currentUserInfo = CurrentUser.getCurrentUser();
    // this.getSavingsReport({
    //   companyId: 10704,
    //   planMonth: 1,
    //   createdFrom: '03/01/2024',
    //   createdTo: '03/31/2024',
    // })
  }

  getSavingsReport(params?: any) {
    this.reportService.getSavingReport(params).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.reportContent = response.data;
        //Call chart
        this.reportContent.callCanvasData = {
          data: [{
            label: 'Care Navigation', data: this.reportContent.callsData.callChart.map((y: any) => y.cnCall),
            backgroundColor: this.chartConst.tealColor.backgroundColor
          }, {
            label: 'Benefit Support', data: this.reportContent.callsData.callChart.map((y: any) => y.bsCall),
            backgroundColor: this.chartConst.primaryColor.backgroundColor
          }], months: this.reportContent.callsData.callChart.map((a: any) => this.monthList.find((y: any) => y.value == a.month)?.name)
        };
        //YTD chart
        this.reportContent.ytdCanvasData = {
          data: [{
            label: 'Total Offered Savings', data: this.reportContent.ytdData.ytdSavingsChart.map((y: any) => y.offeredSavingsYtd),
            backgroundColor: this.chartConst.primaryColor.backgroundColor
          }, {
            label: 'Total Lost Opportunities', data: this.reportContent.ytdData.ytdSavingsChart.map((y: any) => y.lostOpportunity),
            backgroundColor: this.chartConst.lightColor.backgroundColor
          },
          {
            label: 'Total Net Savings', data: this.reportContent.ytdData.ytdSavingsChart.map((y: any) => y.actualSavingsYtd),
            backgroundColor: this.chartConst.secondaryColor.backgroundColor
          }], months: this.reportContent.ytdData.ytdSavingsChart.map((a: any) => this.monthList.find((y: any) => y.value == a.month)?.name)
        };
      }
    })
  }

  printPDF(type: string,) {
    this.printing = (type == 'print');
    return new Promise(async resolve => {
      let savingsReport: any = await this.pdfService.convertToImageFromHtml('savings', false);
      if (type == 'send') {
        let fileName = `${this.filterParams.company.clientLegalName}-Savings Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}` + '.pdf';
        let pdfString = savingsReport.output('datauristring');
        resolve({
          filename: fileName,
          content: pdfString ? pdfString.split(',')[1] : "",
          encoding: 'base64',
          isNewCareReport: true
        });
      }
      else {
        window.open(savingsReport.output('bloburl'), '_blank');
        this.printing = false;
        resolve(1)
      }
    })
  }

  async openSendReportModal() {
    this.modalRef = this.modalService.open(SendReportComponent, {
      modalClass: 'modal-lg modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        careReportTemplateList: this.templateList,
        modalTitle: 'Savings',
        reportFilter: this.filterParams,
        recipientList: this.recipientList,
        dateLabel: this.getDateLabel(this.filterParams.dateType),
        currentUser: this.currentUserInfo,
        bccUser: `client_reports@alithias.com`
      }
    });
    this.modalRef.onClose.subscribe((event: any) => {
      if (event && event.to) {
        this.sendSavingsMail(event);
      }
    });
  }

  async handleBaseAction(event: any) {
    if (event !== 'reset') {
      this.filterParams = event;
      if (this.filterParams.company?.logo?.filePath) {
        this.loading = true;
        this.filterParams.base64 = `data:image/png;base64,${await this.getLogoBase64(this.filterParams.company?.logo?.filePath)}`
      }
      this.loading = true;
      this.planPeriod = `Plan Period: ${moment(this.generatePlanStartDate())?.format('MM/DD')} to  ${moment(moment(this.generatePlanStartDate()).add(1, 'year')).subtract(1, 'days').format('MM/DD')}`
      this.getSavingsReport({ companyId: event?.company?.alithiasCompanyId, planMonth: event?.company?.planPeriodStartDate, createdFrom: moment(event.from).format('MM/DD/YYYY'), createdTo: moment(event.to).format('MM/DD/YYYY') })
    }
    else {
      this.reportContent = false;
    }
  }

  handleSendReport() {
    this.sending = true;
    this.emailService.getCareReportTemplateList().pipe(finalize(() => {
    })).subscribe(async (response: any) => {
      if (response && response.success) {
        this.templateList = response.data.rows;
        this.templateList = this.templateList.filter((x: any) => x.ICode == 'SR');
        await this.getCompanyRecipientList();
        this.openSendReportModal();
      }
    })

  }

  getCompanyRecipientList() {
    if (this.filterParams.company.id) {
      return new Promise(async resolve => {
        this.companyService.getClientReportingByCompany(this.filterParams.company.id).pipe(finalize(() => {
          this.sending = false;
        })).subscribe((response: any) => {
          if (response && response.success) {
            if (response.data.length) {
              this.recipientList = response.data.reduce((acc:any, obj:any) => acc.concat(obj.reportReceipentInfo), []);
              this.recipientList = this.recipientList?.map((x:any)=> x.reportRecipientEmail);
              this.recipientList = [...new Set(this.recipientList)];
              this.recipientList = this.recipientList?.map((x:any)=> x).join(',');
            }
          }
          resolve(true);
        })
      })
    }
  }

  getDateLabel(value: any) {
    switch (value) {
      case 'thisMonth': return 'this month';
      case 'lastMonth': return 'last month';
      case 'lastYear': return 'last year';
      case "lastQuarter": return 'last quarter';
      case 'ytd': return 'year to date';
      case 'dateRange': return `(${moment(this.filterParams.from).format('MM/DD/YYYY')} - ${moment(this.filterParams.to).format('MM/DD/YYYY')})`;
    }
  }

  getYTDDate() {
    return this.filterParams.dateType == 'lastYear' ? this.filterParams.from : DateCalc.convertPlandDateToYTD(this.filterParams.to, this.generatePlanStartDate())
  }

  generatePlanStartDate() {
    const pm = this.filterParams.company?.planPeriodStartDate - 1;
    const pd = 1;
    const py = new Date().getFullYear();
    return this.filterParams.company?.planPeriodStartDate ? moment(new Date(py, pm, pd)).format('MM/DD/YYYY') : null;
  }

  async sendSavingsMail(body: any) {
    this.sending = true;
    this.emailBody = body;
    let savingsContent = await this.printPDF('send');
    this.emailBody.attachments = [savingsContent];
    if (body.attachmentList.incentive) {
      await this.generateIncentive();
    }
    if (body.attachmentList.savingsDetailed) {
      await this.generateSavingsDetailed();
    }
    this.sendReport();
  }

  exportReports(reportConfig: any, pdfHeaderBody: any) {
    return new Promise(async resolve => {
      let incentivePdf: any = await this.pdfService.captureTable(reportConfig.id, pdfHeaderBody);
      let pdfString = incentivePdf.output('datauristring');
      let pdfFile = {
        // filename: `${reportConfig.id}Report` + '-' + new Date().getTime() + '.pdf',
        filename: `${this.filterParams.company.clientLegalName}-${reportConfig.id == 'incentive' ? 'Incentive' : 'Savings Detailed'} Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}` + '.pdf',
        content: pdfString ? pdfString.split(',')[1] : "",
        encoding: 'base64',
      };
      this.emailBody.attachments.push(pdfFile);
      resolve(pdfFile);
    })
  }

  generateIncentive() {
    return new Promise(async resolve => {
      this.reportService.getIncentiveReport({ companyId: this.filterParams?.company?.alithiasCompanyId, createdFrom: moment(this.filterParams.from).format('MM/DD/YYYY'), createdTo: moment(this.filterParams.to).format('MM/DD/YYYY') }).
        subscribe(async (response: any) => {
          if (response && response.success) {
            response.data = response.data.map((x: any) => {
              return {
                ...x, providerStateAverage: x.providerStateAverage?.amount,
                providerEstCost: x.providerEstCost?.amount, providerIncentives: x.providerIncentives?.amount

              }
            })
            response.data.totals = { totalEstCost: 0, totalEstCostSavings: 0, totalIncentivesToBePaid: 0, totalStateAvg: 0 };
            response.data.map((data: any) => {
              response.data.totals.totalEstCost = (response.data.totals.totalEstCost + (parseInt(data.providerEstCost || 0)));
              response.data.totals.totalEstCostSavings = (response.data.totals.totalEstCostSavings + (parseInt(data.providerCostSaving || 0)));
              response.data.totals.totalIncentivesToBePaid = (response.data.totals.totalIncentivesToBePaid + (parseInt(data.providerIncentives || 0)));
              response.data.totals.totalStateAvg = (response.data.totals.totalStateAvg + (parseInt(data.providerStateAverage || 0)));
            })
            this.incentiveReportConfig = { id: 'incentive', reportConstants: PDFReportConstant.incentiveReport, dataList: response.data };
            const pdfHeaderBody = {
              header: `Incentive Report`,
              companyName: this.filterParams?.company?.clientLegalName,
              imgPath: "assets/images/login-logo.png",
              date: `Report Period: ${moment(this.filterParams?.from).format('MM/DD/YYYY')} to ${moment(this.filterParams?.to).format('MM/DD/YYYY')}`,
              reportPlanDate: this.planPeriod,
              // isBase64  : this.filterParams.imgPath.base64
            }
            let section: any = document.getElementById("pdf");
            section.style.display = "block";
            await this.exportReports(this.incentiveReportConfig, pdfHeaderBody);
            section.style.display = "none";
            resolve(true);
          }
        })
    })

  }

  generateSavingsDetailed() {
    return new Promise(async resolve => {
      this.reportService.getSavingDetailedReport({ companyId: this.filterParams?.company?.alithiasCompanyId, createdFrom: moment(this.filterParams.from).format('MM/DD/YYYY'), createdTo: moment(this.filterParams.to).format('MM/DD/YYYY') }).
        subscribe(async (response: any) => {
          if (response && response.success) {
            this.savingsDetailedReportConfig = { id: 'savings-detailed', reportConstants: PDFReportConstant.savingsReport, dataList: response.data };
            const pdfHeaderBody = {
              header: `Savings Detailed Report`,
              companyName: this.filterParams?.company?.clientLegalName,
              imgPath: "assets/images/login-logo.png",
              date: `Report Period: ${moment(this.filterParams?.from).format('MM/DD/YYYY')} to ${moment(this.filterParams?.to).format('MM/DD/YYYY')}`,
              reportPlanDate: this.planPeriod,
              // isBase64  : this.filterParams.imgPath.base64
            }
            let section: any = document.getElementById("pdf");
            section.style.display = "block";
            await this.exportReports(this.savingsDetailedReportConfig, pdfHeaderBody);
            section.style.display = "none";
            resolve(true);
          }
        })
    })

  }

  sendReport() {
    let body = {
      to: this.emailBody.to,
      cc: this.emailBody.cc,
      bcc: this.emailBody.bcc,
      subject: this.emailBody.subject,
      Template: this.emailBody.template,
      html: this.emailBody.message,
      savingsPdf: true,
      incentivePdf: this.emailBody.attachmentList.incentive,
      detailedPdf: this.emailBody.attachmentList.savingsDetailed,
      attachments: this.emailBody.attachments
    }
    this.reportService.sendSavingsReport(body).pipe(finalize(() => {
      this.sending = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toasterService.showSuccess(response.message);
      }
    })
  }

  format(date: any) {
    return moment(date).format('MM/DD/YYYY')
  }

  getEligibilityMonth(dateString: any) {
    if (dateString) {
      const date = new Date(dateString);
      const month = date.getMonth() + 1; // Adding 1 because getUTCMonth() returns zero-based month index
      const year = date.getFullYear().toString().slice(-2); // Getting last two digits of the year
      return `${month.toString().padStart(2, '0')}${year}`;
    }
    else return;

  }

  getLogoBase64(filePath: any) {
    return new Promise(async resolve => {
      this.companyService.getFileBase64(encodeURIComponent(filePath.split('.com/')[1])).pipe(finalize(() => {
        this.sending = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          resolve(response?.data?.file);
        }
        resolve(true);
      })
    })
  }
}
