import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class PharmacyPlanDetailModel {
    static setPharmacyPlanDetailModel(value?: any) {
        let model: FormFields[] = [
            {
                name: "planAdministratorName",
                id: "planAdministratorName",
                controlType: 'input',
                controlName: "planAdministratorName",
                required: true,
                value: value?.planAdministratorName || ''
            },
            {
                name: 'customerServicePhone',
                id: 'customerServicePhone',
                controlType: 'input',
                controlName: 'customerServicePhone',
                required: true,
                value: value?.customerServicePhone || ''
            },
            {
                name: 'pharmacyHelpLine',
                id: 'pharmacyHelpLine',
                controlType: 'input',
                controlName: 'pharmacyHelpLine',
                required: true,
                value: value?.pharmacyHelpLine || ''
            },
            {
                name: 'website',
                id: 'website',
                controlType: 'input',
                controlName: 'website',
                required: true,
                value: value?.website || ''
            }, {
                name: 'pharmacyPlanNotes',
                id: 'pharmacyPlanNotes',
                controlType: 'input',
                controlName: 'pharmacyPlanNotes',
                required: true,
                value: value?.pharmacyPlanNotes || ''
            }, 
            {
                controlType: 'pharmacyPrograms',
                controlName: 'pharmacyPrograms',
                name: 'pharmacyPrograms',
                id: 'pharmacyPrograms',
                value: value?.pharmacyPrograms || [],
                formArray: []
            },
            {
                controlType: 'pharmacyPlanContactInfo',
                controlName: 'pharmacyPlanContactInfo',
                name: 'pharmacyPlanContactInfo',
                id: 'pharmacyPlanContactInfo',
                value: value?.pharmacyPlanContactInfo || [],
                formArray: []
            }
        ]
        return model;
    }
}