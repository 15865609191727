import { Component, OnInit, Input } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import {
  CompanyService,
  FormInitService,
  ToastrService,
} from '../../../../../services';
import { PharmacyPlanDetailModel } from '../../../../../model';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { finalize, fromEventPattern } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmationModalComponent } from 'src/app/modal';

@Component({
  selector: 'app-pharmacy-plan-detail',
  templateUrl: './pharmacy-plan-detail.component.html',
  styleUrls: ['./pharmacy-plan-detail.component.scss'],
})
export class PharmacyPlanDetailComponent implements OnInit {
  @Input() pharmacyPlanDetails: any;
  pharmacyPlanDetailForm: UntypedFormGroup;
  saving: boolean = false;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;

  constructor(
    private formInit: FormInitService,
    public parentComponent: CompanyAddEditComponent,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private modalService: MdbModalService
  ) {
    this.pharmacyPlanDetailForm = this.formInit.createFormGroup(
      PharmacyPlanDetailModel.setPharmacyPlanDetailModel()
    );
  }

  ngOnInit(): void { }

  ngOnChanges() {
    this.resetForm();
    if (this.pharmacyPlanDetails) {
      this.pharmacyPlanDetailForm.patchValue(this.pharmacyPlanDetails);
      if (this.pharmacyPlanDetails.pharmacyPrograms.length) {
        this.pharmacyPlanDetails.pharmacyPrograms.forEach((obj: any) => {
          this.addPharmacyPrograms(
            this.pharmacyPlanDetailForm,
            'pharmacyPrograms',
            obj
          );
        });
      }
      if (this.pharmacyPlanDetails.pharmacyPlanContactInfo.length) {
        let arr = this.getFormArray(this.pharmacyPlanDetailForm, 'pharmacyPlanContactInfo');
        arr.clear();
        this.pharmacyPlanDetails.pharmacyPlanContactInfo.forEach((obj: any) => {
          this.addContactInfo(
            this.pharmacyPlanDetailForm,
            'pharmacyPlanContactInfo',
            obj, 'contactType'
          );
        });
      }
    }
  }

  resetForm() {
    let arr = this.getFormArray(
      this.pharmacyPlanDetailForm,
      'pharmacyPrograms'
    );
    arr.clear();
  }

  addPharmacyPrograms(formData: any, arrayName: any, data: any) {
    let formArray: any;
    const newPharmacyProgram = this.initPharmacyProgram(data);
    formArray = this.getFormArray(formData, arrayName);
    data ? formArray.push(newPharmacyProgram) :  formArray.insert(0, newPharmacyProgram);
    if (data.portalContactDetail.length) {
      data.portalContactDetail.forEach((obj: any) => {
        this.addContactInfo(newPharmacyProgram, 'portalContactDetail', obj);
      });
    }
  }
  // addPortalContactDetail(formData:any, arrayName:any, data:any){
  //   let formArray:any;
  //   // const newPortalContactDetail = this.addContactInfo(data)
  // }

  getControl(controlName: string): UntypedFormGroup {
    return this.pharmacyPlanDetailForm.controls[controlName] as UntypedFormGroup;
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newService = this.initPharmacyProgram(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) : formArray.insert(0, newService);
  }

  addContactInfo(formName: any, arrayName: any, value?: any, additionalControlName?: any) {
    let formArray: any;
    const newService = this.initContactDetails(value, additionalControlName);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) :  formArray.insert(0, newService);
  }

  initContactDetails(value?: any, additionalControlName?: any,) {
    const formGroupConfig: any = {
      contactName: [value?.contactName],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
      contactId: [value?.contactId]
    }
    if (additionalControlName) {
      formGroupConfig[additionalControlName] = value ? value[additionalControlName] : '';
    }
    return this.formBuilder.group(formGroupConfig);
  }

  initPharmacyProgram(value?: any) {
    return this.formBuilder.group({
      programId: [value?.programId],
      programType: [value?.programType],
      phone: [value?.phone],
      programName: [value?.programName],
      websitePortal: [value?.websitePortal],
      programNotes: [value?.programNotes],
      portalContactDetail: this.formBuilder.array([]),
    });
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }

  delete(index: number, item: any, arrayName: any, formControlName: any) {
    item.loading = true;
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    switch (arrayName) {
      case 'portalContactDetail':
        if (item.contactId) {
          this.companyService.deletePharmacyPlanProgram(this.parentComponent.currentCompanyId, item, formControlName.controls['programId'].value).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'pharmacyPlanContactInfo':
        if (item.contactId) {
          this.companyService.deletePharmacyPlanContactDetail(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          });
        }
        else {
          control.removeAt(index);

        }
      break;
    }
  }

  openConfirmationModel(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if (item.value.programId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.programName}</strong> ?`,
        },
      });
      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          control.removeAt(index);
          this.saveForms();
          // this.delete(index, item, arrayName, formControlName);
        }
      });
    }
    else {
      control.removeAt(index);
    }
  }

  saveForms() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService
        .updatePharmacyPlanDetails(
          this.parentComponent.currentCompanyId,
          this.pharmacyPlanDetailForm.value
        )
        .pipe(
          finalize(() => {
            this.saving = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            this.toastrService.showSuccess(response.message);
            this.parentComponent.getPharmacyPlanDetails();
          }
        });
    }
  }
}
