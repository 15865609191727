import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { map } from 'rxjs/operators';
import { QueryParams } from '../utils';


@Injectable({
    providedIn: 'root'
})
export class CustomMessageService {
    constructor(
        private http: HttpClientService
    ) { }

    getCrmList(event?:any) {
        const queryParams = QueryParams.addParams(event);
        return this.http.get(`${ApiConstant.CRM}?` + queryParams)
        .pipe(map(response => {
            return response;
        }))

    }
    
    getCrmById(id:any) {
        return this.http.get(`${ApiConstant.CRM}/${id}`)
        .pipe(map(response => {
            return response;
        }))

    }

    saveNewCrm(params:any) {
        return this.http.post(`${ApiConstant.CRM}`, params)
        .pipe(map(response => {
            return response;
        }))
    }

    editCrm(params:any) {
        return this.http.put(`${ApiConstant.CRM}/${params.id}`, params)
        .pipe(map(response => {
            return response;
        }))
    }

    
    deleteCrm(params:any) {
        return this.http.remove(`${ApiConstant.CRM}/${params.id}`)
        .pipe(map(response => {
            return response;
        }))
    }
}