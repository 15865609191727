import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { finalize, mergeMap } from 'rxjs';
import { PricingService, ToastrService } from 'src/app/services';

@Component({
  selector: 'app-episodes',
  templateUrl: './episodes.component.html',
  styleUrls: ['./episodes.component.scss'],
})
export class EpisodesComponent {
  getRequest: any;
  episodeList: any = [];
  searchParams: any = {
    providerNPI: '',
    procedureID: '',
    networkName: '',
    networkID: '',
    procedureName: '',
  };
  episodeDetail: any = [];
  loading: boolean = false;
  episodeUpdating: boolean = true;

  searchConfig = {
    action: [],
    module: 'episode',
    limit: 25,
    distance: 30,
    prefillFilter: null
  };

  constructor(
    private route: ActivatedRoute,
    private pricingService: PricingService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngDoCheck() {
    this.route.queryParams.subscribe((params) => {
      if (
        params['procedureID'] &&
        params['networkID'] &&
        !this.getRequest
      ) {
        this.searchParams = params;
        this.getPricingEpisode({
          procedureID: params['procedureID'],
          groupNetworkID: params['networkID'],
          providerNPI: params['providerNPI']
        }, 'query');
      }
    });
    this.searchConfig.prefillFilter = this.searchParams;
  }

  async getPricingEpisode(params: any, action: any) {
    if (params == 'reset') {
      this.episodeList = null;
      this.loading = false;
      await this.changeRouteParams('procedureName', null);
      this.router.navigate([], { queryParams: {} });
    }
    else {
      if (action == 'emitter') {
        await this.changeRouteParams('providerNPI', params.providerNPI);
        await this.changeRouteParams('networkID', params.networkID);
        await this.changeRouteParams('procedureID', params.procedureID);
        await this.changeRouteParams('networkName', params.networkName);
        await this.changeRouteParams('procedureName', params.procedureName);
      }
      this.episodeList = [];
      this.loading = true;
      this.getRequest = this.pricingService
        .getProcedureEpisodes(action == 'emitter' ? { providerNPI: params.providerNPI, groupNetworkID: params.networkID, procedureID: params.procedureID } : params)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(async (response: any) => {
          if (response && response.data && response.data?.rows?.length) {
            this.episodeList = {
              count: response.data.count,
              rows: response.data.rows.map((row: any) => {
                const episodeDetails = {
                  ...row.episodeDetails,
                  categoryDetails: row.episodeDetails.categoryDetails.map(
                    (catetgory: any) => {
                      const updatedEpisodes = catetgory.episodes.map(
                        (episode: any) => ({
                          ...episode,
                          isEpisodeUpating: false,
                        })
                      );
                      return {
                        ...catetgory,
                        episodes: updatedEpisodes,
                      };
                    }
                  ).sort((a: any, b: any) => a.category.localeCompare(b.category)),
                };

                return {
                  ...row,
                  episodeDetails: episodeDetails,
                };
              }),
            };
          }
        });
    }
  }

  getEpisodesMappedData(episodeKey: any) {
    this.getRequest = this.pricingService
      .getEpisodeMappedDetail({
        episodeKey: episodeKey,
        facilityNPI: this.route.snapshot.params['id'],
      })
      .pipe(finalize(() => { }))
      .subscribe(async (response: any) => {
        if (response.success && response.data) {
          let changedEp = this.episodeList.rows.find((x: any) => x.episodeKey === episodeKey)
          changedEp.episodeDetails.totalMappedActualRetailAmount = response.data.categoryMappedTotalActualRetailAmount;
          changedEp.episodeDetails.totalMappedAdjustedRetailAmount = response.data.categoryMappedTotalAdjustedRetailAmount;
          changedEp.episodeDetails.totalMappedActualAllowedAmount = response.data.categoryTotalMappedActualAllowedAmount;
          changedEp.episodeDetails.totalMappedAdjustedAllowedAmount = response.data.categoryTotalMappedAdjustedAllowedAmount;
        }
      });
  }

  updatePricingEpisode(episode: any, episodeKey: string) {
    episode.isEpisodeUpating = episode.isEpisodeUpating ? false : true;
    const body = {
      includeFlag: episode.includeFlag === true ? false : true,
    };
    this.getRequest = this.pricingService
      .updateEpisodeDetail(
        {
          ...episode,
          episodeKey: episodeKey,
          facilityNPI: this.searchParams.providerNPI,
        },
        body
      )
      .pipe(
        finalize(() => {
          episode.isEpisodeUpating = episode.isEpisodeUpating ? false : true;
        })
      )
      .subscribe(async (response: any) => {
        if (response.data) {
          episode.includeFlag = body.includeFlag;
          this.toastrService.showSuccess(response.message);
          this.getEpisodeById({ ...episode, ...this.searchParams, episodeKey: episodeKey })
        }
      });
  }

  updateEpisodeInclude(episode: any) {
    episode.episodeIncludeFlag = !episode.episodeIncludeFlag;
    episode.updating = true;
    let params = { ...episode, ...this.searchParams };
    this.pricingService.updateEpisode(params, { episodeIncludeFlag: episode.episodeIncludeFlag }).pipe(finalize(() => {
      episode.updating = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.getEpisodeById(params);
        this.toastrService.showSuccess(response.message);
      }
    })

  }

  getEpisodeById(params: any) {
    this.pricingService.getEpisodeById(params).pipe(finalize(() => {
    })).subscribe((response: any) => {
      const index = this.episodeList.rows.findIndex((obj: any) => obj['episodeKey'] === response.data['episodeKey']);
      if (index !== -1) {
        this.episodeList.rows[index] = { ...this.episodeList.rows[index], ...response.data };
      }
      this.episodeList.rows = this.episodeList.rows.map((x: any) => {
        const episodeDetails = {
          ...x.episodeDetails,
          categoryDetails: x.episodeDetails.categoryDetails.sort((a: any, b: any) => a.category.localeCompare(b.category)),
        };
        return {
          ...x,
          episodeDetails: episodeDetails,
        };
      })
    })
  }

  changeRouteParams(paramsName: string, value: any) {
    return new Promise(async (resolve: any) => {
      this.route.queryParams
        .pipe(
          mergeMap((params: Params) => {
            const queryParams = { ...params };
            queryParams[paramsName] = value;
            return this.router.navigate([], {
              relativeTo: this.route,
              queryParams: queryParams,
              queryParamsHandling: 'merge',
            });
          })
        )
        .subscribe();
      resolve(true);
    })
  }
}
