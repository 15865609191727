export class ClaimsSearchModel {
  procedureID: any = null;
  procedureName: any = null
  networkID: any
  networkName: string;
  distance: number = 30;
  providerZip: any = null
  location:string = "";
  state:string | null  = null
  stateCode:string | null  = null;
  [key: string]: any;
  providerNPI: any = null;
}
