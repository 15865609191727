import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment} from '../../../environments/environment';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  defaultMessage:any = '';
  env = environment;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.defaultMessage = this.router.url.split('/')[1]=='404'; 
  }

}
