<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (baseAction)="handleBaseAction()"
        (searchLogEmit)="getDataSourceList($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="loading">
        <loader></loader>
    </div>
    <div class="px-0 table-responsive" *ngIf="!loading">
        <table class="table pt-5 mb-0 table-striped">
            <thead>
                <tr>
                    <th scope="col" class="fw-bold pointer ps-2" (click)="sort('status.fileStatus')"
                        style="width: 110px !important;">Status
                        <i-bs [name]="sortDirection !== 1 ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'status.fileStatus'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer pointer" (click)="sort('sourceName')"
                        style="width: 150px !important;">Source
                        <i-bs [name]="sortDirection !== 1  ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'sourceName'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer" (click)="sort('ftp.username')"
                        style="width: 250px !important;">FTP
                        <i-bs [name]="sortDirection !== 1  ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'ftp.username'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer" (click)="sort('contact.contactPerson')"
                        style="width: 250px !important;">Contact
                        <i-bs [name]="sortDirection !== 1  ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'contact.contactPerson'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer" (click)="sort('frequency.interval')"
                        style="width: 150px !important;">Frequency
                        <i-bs [name]="sortDirection !== 1  ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'frequency.interval'"></i-bs>
                    </th>
                    <th scope="col" class="fw-bold pointer" (click)="sort('lastRunDate')"
                        style="width: 200px !important;">Last Run Date
                        <i-bs [name]="sortDirection !== 1  ? 'caret-up-fill' : 'caret-down-fill'"
                            *ngIf=" sortColumn === 'lastRunDate'"></i-bs>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!dataSourceList.rows.length">
                    <th colspan="6" class="text-center fw-bold">No sources available.</th>
                </tr>
                <tr *ngFor="let status of dataSourceList.rows | filterKeyword:searchConfig.formValue">
                    <td><i-bs *ngIf="status.status.fileStatus == 'received'" name="circle-fill" class="text-secondary"
                            mdbPopover="Received" placement="right" trigger="hover"></i-bs>
                        <i-bs *ngIf="status.status.fileStatus == 'late' && status.lastRunDate" name="circle-fill"
                            class="text-primary"
                            mdbPopover="{{status.status.days}} {{status.status.days > 1 ? 'days' : 'day'}} late"
                            placement="right" trigger="hover"></i-bs>
                        <i-bs *ngIf="status.status.fileStatus == 'overdue'" name="circle-fill" class="text-red"
                            mdbPopover="{{status.status.days}} {{status.status.days > 1 ? 'days' : 'day'}} late"
                            placement="right" trigger="hover"></i-bs>
                        <i-bs *ngIf="searchConfig.sourceType === 'Inactive' && !status.lastRunDate " name="circle-fill"
                            class="text-gray-main" mdbPopover="Inactive" placement="right" trigger="hover"></i-bs>
                    </td>

                    <td>{{status.sourceName}}</td>
                    <td mdbPopover="{{status.ftp.location}}" placement="top" trigger="click"><span
                            class="pointer">{{status.ftp.username}}</span></td>
                    <td [mdbPopover]="(status.contact.contactEmail || status.contact.contactPhone)  ? contactPopover : ''"
                        placement="top" trigger="click"><span class="pointer"> {{status.contact.contactPerson}}</span>
                        <ng-template
                            #contactPopover>{{status.contact.contactEmail}}<br>{{status.contact.contactPhone}}</ng-template>
                    </td>
                    <td>{{status.frequency.interval}}</td>
                    <td mdbPopover="{{status.fileName }}" placement="top" trigger="click"> <span
                            class="pointer">{{status.lastRunDate | date:'MM/dd/yyyy hh:mm a'}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>