import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CurrentUser } from '../utils';
import { LoginModel } from '../model';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard  {


    currentUser = new LoginModel();
    constructor(
        private _router: Router,
    ) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = CurrentUser.getCurrentUser();
        let valid = (user && user.exp) > Date.now() / 1000; // check if token is expired by comparing exp in user token with current time
        if (!valid) {
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }
}
