import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterKeyword'
})

export class filterKeyword implements PipeTransform {
    transform(itemList: any, searchKeyword: any, propList?: any[]) {
        if (!itemList)
            return [];
        if (!searchKeyword)
            return itemList;
        let filteredList: any[] = [];
        if (itemList.length > 0) {
            searchKeyword = searchKeyword.toLowerCase();
            // IF THERE IS PROPERTY LIST, FILTER USING ONLY THE PROPERTIES ON THE LIST
            if (propList && propList.length > 0) {
                itemList.forEach((item: any) => {
                    for (let key in item)//LOOP THROUGH THE PROPERTY TYPES OF EACH ITEM
                    {
                        if (propList.indexOf(key) > -1) {
                            if (item[key] && item[key].toString().toLowerCase().indexOf(searchKeyword) > -1) {
                                filteredList.push(item);
                                break;
                            }
                        }
                    }
                })
            }
            //ELSE FILTER BY ALL TYPES
            else {
                itemList.forEach((item: any) => {
                    //Object.values(item) => gives the list of all the property values of the 'item' object
                    let propValueList: any = Object.values(item);
                    for (let i = 0; i < propValueList.length; i++) {
                        if (propValueList[i]) {
                            if (propValueList[i].toString().toLowerCase().indexOf(searchKeyword) > -1) {
                                filteredList.push(item);
                            }
                        }
                    }
                })
            }


        }
        return this.filterDuplicates(filteredList)
    }

    filterDuplicates(array: any[]) {
        const uniqueObjects = new Set();
        const filteredArray = array.filter(obj => {
            const objString = JSON.stringify(obj);
            if (uniqueObjects.has(objString)) {
                return false;
            }
            uniqueObjects.add(objString);
            return true;
        });
        return filteredArray;
    }
}