<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title fw-bold" id="exampleModalLabel">{{ modalTitle }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close('close')"></button>
    </div>
    <div class="model-body">
        <form class="px-2" #tilesForm="ngForm">
            <div class="md-form p-2">
                <label for="" class="form-label">Title</label>
                <div class="">
                    <div class="">
                        <input type="text" class="form-control" name="title" [(ngModel)]="tilesModel.title" />
                    </div>
                </div>
            </div>
            <div class="md-form p-2">
                <label for="" class="form-label">Caption</label>
                <div class="">
                    <div class="">
                        <input type="text" class="form-control" name="cap" [(ngModel)]="tilesModel.caption" />
                    </div>
                </div>
            </div>
            <div class="md-form p-2">
                <label for="" class="form-label">Action Type</label>
                <div class="">
                    <ng-select [(ngModel)]="tilesModel.action.type" class="form-control p-0" [items]="actionTypeList"
                        name="alerts" bindLabel="label" bindValue="value" [selectableGroupAsModel]="false"
                        [closeOnSelect]="true" [searchable]="true" (change)="actionTypeChange($event)">
                        <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                            let-index="index">
                            <label>{{ item.label }}</label>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="md-form p-2" *ngIf="tilesModel.action?.type">
                <label for="" class="form-label">{{tilesModel.action?.type == 'Phone' ? 'Phone' : "Link"}}</label>
                <div class="">
                    <div class="">
                        <input *ngIf="tilesModel.action?.type == 'Phone'" type="text" class="form-control" name="phone" [(ngModel)]="tilesModel.action.value" mask='(000) 000-0000'/>
                        <input *ngIf="tilesModel.action?.type == 'Link'" type="text" class="form-control" name="link" [(ngModel)]="tilesModel.action.value"/>
                    </div>
                </div>
            </div>
            <!-- <div class="md-form p-2">
                <label for="" class="form-label">Description</label>
                <div class="input-group mb-3">
                    <textarea class="form-control h-auto" [(ngModel)]="tilesModel.description" name="description"
                        aria-label="With textarea" rows="3" #description="ngModel"></textarea>
                </div>
            </div> -->
            <div class="md-form p-2 d-flex align-items-center">
                <label for="" class="form-label pe-3 m-0">Tile Visibility</label>
                <a href="javascript:void(0)">
                  <i-bs [name]="!tileVisibility ? 'toggle-off': 'toggle-on'" [ngClass]="{
                  'text-success': tileVisibility,
                  'text-gray-main': !tileVisibility
                }" (click)="changeVisibility()" width="32" height="32"></i-bs>
                </a>
              </div>
        </form>
    </div>
    <div class="model-footer d-flex justify-content-between p-2 bg-gray-lighter">
        <button type="button" class="btn btn-light" data-mdb-ripple-color="dark" (click)="modalRef.close('close')">
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="tilesForm.invalid">Save</button>
    </div>
</div>