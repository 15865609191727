import {Injectable} from '@angular/core';
import {MdbNotificationRef, MdbNotificationService} from 'mdb-angular-ui-kit/notification';
import {ToastrComponent} from '../common/toastr/toastr.component';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
    // notificationRef: MdbNotificationRef<ToastrComponent> | null = null;
  constructor(private notificationService: MdbNotificationService) {}

  showSuccess(detail: string, life?: number): void {
    this.showToast('bg-success-lighter', 'Success!', detail, life, 'text-success', 'check-circle-fill');
  }

  showInfo(detail: string, life?: number): void {
    this.showToast('bg-info-lighter', 'Info', detail, life, 'text-info', 'exclamation-circle-fill');
  }

  showWarning(detail: string, life?: number): void {
    this.showToast('bg-warning-lighter', 'Warning!', detail, life, 'text-warning', 'exclamation-circle-fill');
  }

  showError(detail: string, life?: number): void {
    this.showToast('bg-error-lighter', 'Error', detail, life, 'text-red', 'exclamation-circle-fill');
  }

  showToast(severity: string, summary: string, detail: string, life = 3000, text:string, icon:any): void {
    // this.notificationRef = 
    this.notificationService.open(ToastrComponent, {
      delay: life,
      autohide: true,
      stacking: true,
      data: {severity, summary, detail, text, icon},
    });
  }
}
