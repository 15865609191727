import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { ExcelHeaders } from 'src/app/constants';
import { ReportService, CommonListService, ExcelService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';

@Component({
  selector: 'app-dashboard-detailed-report',
  templateUrl: './dashboard-detailed-report.component.html',
  styleUrls: ['./dashboard-detailed-report.component.scss']
})
export class DashboardDetailedReportComponent {
  reportDetails: any = false;
  loading: boolean = false;
  userList: any = [];
  workingDays: any;
  filterParams:any;

  constructor(private reportService: ReportService, private commonListService: CommonListService, private excelService: ExcelService) { }

  async ngOnInit() {
    this.userList = await this.commonListService.getUserList();
  }

  handleBaseAction(event: any) {
    if (event == 'reset') {
      this.reportDetails = false;
      this.loading = false;
    }
    else {
      this.loading = true;
      this.filterParams = event;
      this.workingDays = DateCalc.workdayCount(event.from, event.to)
      this.reportService.getDashboardReport({ createdFrom: moment(event.from).format('MM/DD/YYYY'), createdTo: moment(event.to).format('MM/DD/YYYY') }).pipe(finalize(() => {
        this.loading = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.reportDetails = response.data.filter((y: any) => y.name);
          this.reportDetails = this.reportDetails.reduce((result: any, obj: any) => {
            (result[obj['name']] = result[obj['name']] || []).push(obj);
            return result;
          }, {});
          for (const key in this.reportDetails) {
            if (this.reportDetails.hasOwnProperty(key)) {
              const group = this.reportDetails[key];
              group.forEach((obj: any) => {
                if (obj?.label?.includes('totalOutboundCalls') || obj?.label?.includes('totalInboundCalls')) {
                  group.push(this.calculateDurationValue(obj, group, obj?.label == 'totalOutboundCalls' ? 'avgOutboundInMinutes' : 'avgInboundInMinutes'));
                  group.push(this.calculateDurationValue(obj, group, obj?.label == 'totalOutboundCalls' ? 'avgOutboundsPerDay' : 'avgInboundsPerDay'));
                }
              });
            }
          }
          this.reportDetails = Object.entries(this.reportDetails).map(([key, value]) => ({ user: key, value }));
        }
      })
    }

  }

  calculateDurationValue(obj: any, userArray: any, labelName: any) {
    let returnObj = { name: obj.name, label: labelName, value: 0 };
    let totalDuration = userArray.find((y: any) => y.label == (labelName.toLowerCase().includes('outbound') ? 'totalOutboundDuration' : 'totalInboundDuration')).value;
    let totalCallNumber = userArray.find((y: any) => y.label == (labelName.toLowerCase().includes('outbound') ? 'totalOutboundCalls' : 'totalInboundCalls')).value;
    switch (labelName) {
      case ('avgInboundInMinutes'):
      case ('avgOutboundInMinutes'):
        returnObj.value = totalCallNumber > 0 ? ((totalDuration / totalCallNumber) / 60000) : 0;
        break;
      case ('avgInboundsPerDay'):
      case ('avgOutboundsPerDay'):
        returnObj.value = this.workingDays > 0 ? (totalCallNumber / this.workingDays) : totalCallNumber;
        break;
      default:
        returnObj.value = 0;
    }
    return returnObj;
  }

  findValue(label: any, arr: any) {
    let obj;
    obj = arr.find((x: any) => x.label == label);
    obj = obj && obj.value > 0 ? obj.value : 0;
    return obj;
  }

  exportExcelReport() {
    let data = this.reportDetails.map((x: any) => {
      return {
        user: x.user,
        totalInboundCalls: this.findValue("totalInboundCalls", x.value),
        totalOutBoundCalls: this.findValue("totalOutboundCalls", x.value),
        totalCalls: this.findValue("totalInboundCalls", x.value) + this.findValue("totalOutboundCalls", x.value),
        totalInboundHours: (this.findValue("totalInboundDuration", x.value) / 3600000).toFixed(2).replace(/[.,]00$/, ""),
        totalOutboundHours: (this.findValue("totalOutboundDuration", x.value) / 3600000).toFixed(2).replace(/[.,]00$/, ""),
        totalCallHours: ((this.findValue("totalInboundDuration", x.value) / 3600000) + (this.findValue("totalOutboundDuration", x.value) / 3600000)).toFixed(2).replace(/[.,]00$/, ""),
        avgTimeInboundInMinutes: (this.findValue("avgInboundInMinutes", x.value)).toFixed(2).replace(/[.,]00$/, ""),
        avgTimeOutboundInMinutes: (this.findValue("avgOutboundInMinutes", x.value)).toFixed(2).replace(/[.,]00$/, ""),
        totalAvgTimeInMinutes: ((this.findValue("avgInboundInMinutes", x.value) + this.findValue('avgOutboundInMinutes', x.value)) / 2).toFixed(2).replace(/[.,]00$/, ""),
        avgInboundCallsPerDay: this.findValue("avgInboundsPerDay", x.value).toFixed(2).replace(/[.,]00$/, ""),
        avgOutboundCallsPerDay: this.findValue("avgOutboundsPerDay", x.value).toFixed(2).replace(/[.,]00$/, ""),
        totalAvgCallsPerDay: ((this.findValue("totalInboundCalls", x.value) + this.findValue("totalOutboundCalls", x.value)) / this.workingDays).toFixed(2).replace(/[.,]00$/, ""),
        benefitSupportInteraction: this.findValue("Benefit Support Interaction(s)", x.value),
        careNavigationInteraction: this.findValue("Care Navigation Interaction(s)", x.value),
        careReportSent: this.findValue("Care Report(s) Sent", x.value),
        numberOfRequestCreatedViaEmail:(this.findValue("Number of Request Created Via CCM-EMAIL", x.value) + this.findValue("Number of Request Created Via Email", x.value)),
        numberOfRequestCreatedViaPhone: (this.findValue("Number of Request Created Via CCM-PHONE", x.value) + this.findValue("Number of Request Created Via Phone", x.value)),
        totalRequestsCreated: this.findValue("Total Requests Created", x.value),
        averageWordsPerNote: this.findValue("Average Words Per Note", x.value).toFixed(1),
        // userLoginAfter8 : this.findValue("userLoginAfter8", x.value),
        totalPauseDurationInMinutes: ((this.findValue("totalPausedDuration", x.value) / 60)).toFixed(2).replace(/[.,]00$/, ""),
        totalPauseDurationInHours: ((this.findValue("totalPausedDuration", x.value) / 3600)).toFixed(2).replace(/[.,]00$/, ""),
        pauseTimePerMonth: 0,
        totalNumberOfPauses: this.findValue("totalPausedCalls", x.value)
      }
    });
    data = data.sort((a: any, b: any) => {
      return (a.user.toUpperCase() < b.user.toUpperCase()) ? -1 : (a.user.toUpperCase() > b.user.toUpperCase()) ? 1 : 0;
    });
    let body = {
      header: ExcelHeaders.DashboardDetailedReport,
      data: data,
      reportTitle: 'Dashboard Detailed Report',
      fileName: `Dashboard Detailed Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`
    };
    this.excelService.exportExcel(body);
  }

}
