import { Injectable } from '@angular/core';
import { CompanyService } from './company.service';
import { UserService } from './users.service';
import { ProcedureService } from './procedure.service';
import { NetworkService } from './network.service';
import { EnterpriseService } from './enterprise.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CommonListService {
  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private procedureService: ProcedureService,
    private networkService: NetworkService,
  ) {}

  async getCompanyList(params?: any, requestType?:any) {
    return new Promise(async (resolve: any) => {
      await this.companyService
        .getCompanyList(params, requestType)
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data.rows);
          } else resolve([]);
        });
    });
  }

  async getTpaList() {
    return new Promise(async (resolve: any) => {
      await this.companyService.getTpaList().subscribe((response: any) => {
        if (response && response.success) {
          resolve(response.data.rows);
        } else resolve([]);
      });
    });
  }

  async getUserList(keyword?: any) {
    return new Promise(async (resolve: any) => {
      await this.userService
        .getUsers(keyword)
        .subscribe(async (response: any) => {
          if (response && response.success) {
            response.data.rows = response.data.rows.sort((a: any, b: any) =>
              a.firstName > b.firstName ? 1 : -1
            );
            response.data.rows = response.data.rows.filter(
              (x: any) => x.isActive
            );
            resolve(response.data);
          } else resolve([]);
        });
    });
  }

  async getStateList() {
    return new Promise(async (resolve: any) => {
      await this.procedureService
        .getStatefromLookup()
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data.rows);
          }
        });
    });
  }
  async getProcedureList(params?: any) {
    return new Promise(async (resolve: any) => {
      await this.procedureService
        .getProcedureList(params)
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data.rows);
          } else resolve([]);
        });
    })
  }
  async getNetworkList(params?: any) {
    return new Promise(async (resolve: any) => {
      await this.networkService
        .getNetworkList(params)
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data.rows);
          } else resolve([]);
        });
    });
  }
}
