<app-raw-filter (submitAction)="handleSubmit($event)"></app-raw-filter>
<div class="pt-5 mt-5" *ngIf="reportLoader">
  <loader></loader>
</div>
<div class="text-center p-3" *ngIf="rawFilterList && !rawFilterList?.length && !reportLoader">
  <p class="mb-0">No results found.</p>
</div>
<div style="overflow: scroll" class="p-3" *ngIf="rawFilterList?.length">
  <div class="">
    <div class="d-flex align-items-center justify-content-start">
      <!-- <p class="fw-bold fs-2">Showing {{pricingList.count}} Result</p> -->
      <div class="d-flex">
        <div class="pe-3">
          <div class="mb-3 limit-input">
            <ng-select class="form-control p-1" style="background: transparent !important" name="itemsPerPage"
              [searchable]="false" (change)="changeLimit($event)" [(ngModel)]="limit">
              <ng-option [value]="10" selected>Show 10</ng-option>
              <ng-option [value]="25">Show 25</ng-option>
              <ng-option [value]="50">Show 50</ng-option>
              <ng-option [value]="100">Show 100</ng-option>
              <ng-option [value]="0">Show All</ng-option>
            </ng-select>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-clear pt-2 border" (click)="exportRawFilter()">
            <!-- <div class="spinner-grow me-2" style="width: 1rem; height: 1rem;" role="status" *ngIf="exporting">
              <span class="visually-hidden">Loading...</span>
            </div>  -->
            <i-bs name="download" class="pe-2"></i-bs>Export
          </button>
        </div>
      </div>
    </div>
  </div>
  <table class="table w-100">
    <thead>
      <tr class="bg-white fs-2 table-min-row" style="overflow: scroll">
        <th class="fw-bold text-nowrap pointer" (click)="sort('caseId')">
          <div class="d-flex align-items-center justify-content-between ">
            Case ID
            <i-bs name="chevron-expand" *ngIf="sortBy === 'caseId'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('companyName')">
          <div class="d-flex align-items-center justify-content-between ">
            Company Name
            <i-bs name="chevron-expand" *ngIf="sortBy === 'companyName'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap">TPA</th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('status')">
          <div class="d-flex align-items-center justify-content-between ">
            Case Status
            <i-bs name="chevron-expand" *ngIf="sortBy === 'status'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('lastModified')">
          <div class="d-flex align-items-center justify-content-between ">
            Last Updated
            <i-bs name="chevron-expand" *ngIf="sortBy === 'lastModified'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap">Tag</th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('callType')">
          <div class="d-flex align-items-center justify-content-between ">
            Call Type
            <i-bs name="chevron-expand" *ngIf="sortBy === 'callType'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('callRegarding')">
          <div class="d-flex align-items-center justify-content-between ">
            Call Regarding
            <i-bs name="chevron-expand" *ngIf="sortBy === 'callRegarding'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap">Contact Via</th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('createdDate')">
          <div class="d-flex align-items-center justify-content-between ">
            Created Date
            <i-bs name="chevron-expand" *ngIf="sortBy === 'createdDate'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('closeDate')">
          <div class="d-flex align-items-center justify-content-between ">
            Closed Date
            <i-bs name="chevron-expand" *ngIf="sortBy === 'closeDate'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('createdBy')">
          <div class="d-flex align-items-center justify-content-between ">
            Created By
            <i-bs name="chevron-expand" *ngIf="sortBy === 'createdBy'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('assignedTo')">
          <div class="d-flex align-items-center justify-content-between ">
            Assigned To
            <i-bs name="chevron-expand" *ngIf="sortBy === 'assignedTo'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap pointer" (click)="sort('memberId')">
          <div class="d-flex align-items-center justify-content-between ">
            Employee ID
            <i-bs name="chevron-expand" *ngIf="sortBy === 'memberId'"></i-bs>
          </div>
        </th>
        <th class="fw-bold text-nowrap">First Name</th>
        <th class="fw-bold text-nowrap">Last Name</th>
        <th class="fw-bold text-nowrap">DOB</th>
        <th class="fw-bold text-nowrap">Email</th>
        <th class="fw-bold text-nowrap">Cell Phone</th>
        <th class="fw-bold text-nowrap">Work Phone</th>
        <th class="fw-bold text-nowrap">Home Phone</th>
        <th class="fw-bold text-nowrap">Address Line 1</th>
        <th class="fw-bold text-nowrap">Address Line 2</th>
        <th class="fw-bold text-nowrap">City</th>
        <th class="fw-bold text-nowrap">State</th>
        <th class="fw-bold text-nowrap">Zip Code</th>
        <th class="fw-bold text-nowrap">Procedure</th>
        <th class="fw-bold text-nowrap">Avg State Cost</th>
        <th class="fw-bold text-nowrap">Highest DC Offering</th>
        <th class="fw-bold text-nowrap">Lowest DC Offering</th>
        <th class="fw-bold text-nowrap">DC Savings Net of Incentives</th>
        <th class="fw-bold text-nowrap">Highest PPO Offering</th>
        <th class="fw-bold text-nowrap">Lowest PPO Offering</th>
        <th class="fw-bold text-nowrap">PPO Savings Net of Incentives</th>
        <th class="fw-bold text-nowrap">Actual Savings</th>
        <th class="fw-bold text-nowrap">Incentive</th>
        <th class="fw-bold text-nowrap">TAT Start</th>
        <th class="fw-bold text-nowrap">TAT End</th>
        <th class="fw-bold text-nowrap">TAT</th>
        <th class="fw-bold text-nowrap">Total Days</th>
      </tr>
      <tr class="table-min-row fs-2" *ngFor="let r of rawFilterList; let i = index"
        [ngClass]="{ 'row-striped': i % 2 === 0 }">
        <td class="text-nowrap">{{ r.caseId }}</td>
        <td class="text-nowrap">{{ r.patient?.relationship?.grandParentName }}</td>
        <td class="text-nowrap">{{ r?.callInPerson?.tpa ?? "" }}</td>
        <td class="text-nowrap">{{ r.status }}</td>
        <td class="text-nowrap">{{r.lastModified | date : "MM/dd/yyyy"}}</td> <!--last updated-->
        <td class="text-nowrap"> <span *ngFor="let t of r.tags">
            <p class="mb-0">{{ t }}</p>
          </span></td> <!--tag-->
        <td class="text-nowrap">{{ r.caseType && r.caseType[0] ? r.caseType[0]?.split("|")[0] : "" }}</td>
        <td class="text-nowrap">{{ r.caseType && r.caseType[0] ? r.caseType[0]?.split("|")[1] : "" }}</td>
        <td class="text-nowrap">{{ r.contactVia }}</td>
        <td class="text-nowrap">{{ r.createdDate | date : "MM/dd/yyyy" }}</td>
        <td class="text-nowrap">{{ r.closeDate | date : "MM/dd/yyyy" }}</td>
        <td class="text-nowrap">{{ r?.from?.assignedTo?.name ?? "" }}</td>
        <td class="text-nowrap">{{ r.to.assignedTo?.name }}</td>
        <td class="text-nowrap">{{ r.subscriber?.subscriberId}}</td>
        <td class="text-nowrap">{{ r.patient?.firstName }}</td>
        <td class="text-nowrap">{{ r.patient?.lastName }} </td>
        <td class="text-nowrap">{{ r.patient?.dob | date : "MM/dd/yyyy"}}</td>
        <td class="text-nowrap">{{ r.patient?.email }}</td>
        <!-- <td class="text-nowrap">
          <span *ngFor="let p of r.patient?.phones">
            <p *ngIf="p.value" class="mb-0">
              <i-bs [name]="
                  p.type == 'Cell'
                    ? 'phone-fill'
                    : p.type == 'Home'
                    ? 'house-door-fill'
                    : 'telephone-fill'
                " class="text-gray-main me-2"></i-bs>
              {{ p.value | phone }}
            </p>
          </span>
        </td> -->
        <!--source-->
        <td class="text-nowrap"><span *ngFor="let p of r.patient?.phones">
            <p *ngIf="p.type=='Cell'" class="mb-0">{{p.value | phone}}</p>
          </span></td>
        <td class="text-nowrap"><span *ngFor="let p of r.patient?.phones">
            <p *ngIf="p.type=='Work'" class="mb-0">{{p.value | phone}}</p>
          </span></td>
        <td class="text-nowrap"><span *ngFor="let p of r.patient?.phones">
            <p *ngIf="p.type=='Home'" class="mb-0">{{p.value | phone}}</p>
          </span></td>
        <td class="text-nowrap"> {{r.patient?.address?.line1 }}
          <!-- <span>
            <p class="mb-0 text-nowrap">
              <i-bs name="geo-alt-fill" class="text-gray-main me-2" *ngIf="r.patient?.address?.line1"></i-bs>{{
              r.patient?.address?.line1 }}
            </p>
            <p class="mb-0 ps-4 text-nowrap">{{ r.patient?.address?.line2 }}</p>
            <p class="mb-0 ps-4 text-nowrap">
              {{
              r.patient?.address?.city ? r.patient?.address?.city + "," : ""
              }}
              {{ r.patient?.address?.state }} {{ r.patient?.address?.zip }}
            </p>
          </span> -->
        </td>
        <td class="text-nowrap"> {{r.patient?.address?.line2 }}</td>
        <td class="text-nowrap"> {{r.patient?.address?.city }}</td>
        <td class="text-nowrap"> {{r.patient?.address?.state }}</td>
        <td class="text-nowrap"> {{r.patient?.address?.zip }}</td>
        <td class="text-nowrap">{{ r?.service ? (r.service.length ? r?.service[0]?.name : "") : "" }}</td>
        <td class="text-nowrap">{{ r.averageStateCost | currency }}</td>
        <td class="text-nowrap">{{ r.highestDCOffering | currency }}</td>
        <td class="text-nowrap">{{ r.directContractOffering | currency }}</td>
        <td class="text-nowrap">{{ r.directContractSavings | currency }}</td>
        <td class="text-nowrap">{{ r.highestPPOOffering | currency }}</td>
        <td class="text-nowrap">{{ r.lowestCostPPOOffering | currency }}</td>
        <td class="text-nowrap">{{ r.lowestCostPPOSavings | currency }}</td>
        <td class="text-nowrap">{{ r.actualSavings | currency }}</td>
        <td class="text-nowrap">{{ r.incentive | currency }}</td>
        <td class="text-nowrap">{{ r.tatStart | date : "MM/dd/yyyy" }}</td>
        <td class="text-nowrap">{{ r.tatEnd | date : "MM/dd/yyyy" }}</td>
        <td class="text-nowrap">{{ r.tat }}</td>
        <td class="text-nowrap">{{ r.totalDays }}</td>
      </tr>
    </thead>
  </table>
</div>