<div [formGroup]="pharmacyPlanDetailForm" class="general">
    <div class="">
        <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Pharmacy Plan Details</p>
            <div class="spinner-border spinner-border-sm" role="status"
                *ngIf="parentComponent.loadingPharmacyPlanDetails">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="d-flex justify-content-between pt-3">
            <div class="col-6">
                <div class="col-12 d-flex align-items-center">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Plan Administartor Name</label>
                    <div class="col-7">
                        <input type="text" class="form-control" id="legalName" formControlName="planAdministratorName"
                            name="planAdministratorName" />
                    </div>
                </div>
                <div class="col-12 pt-3 d-flex align-items-center">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Pharmacy Plan Notes</label>
                    <div class="col-7">
                        <textarea mdbInput class="form-control" id="pharmacyPlanNotes" rows="3"
                            formControlName="pharmacyPlanNotes" name="pharmacyPlanNotes"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="col-12 d-flex align-items-center">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Customer Service Phone</label>
                    <div class="col-7">
                        <input type="text" class="form-control" id="legalName" formControlName="customerServicePhone"
                            name="customerServicePhone" mask='(000) 000-0000' />
                    </div>
                </div>
                <div class="col-12 pt-3 d-flex align-items-center">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Website</label>
                    <div class="col-7">
                        <input type="text" class="form-control" id="legalName" formControlName="website" name="website" />
                    </div>
                </div>
                <!-- <div class="col-12 d-flex align-items-center">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Contact Type</label>
                    <div class="col-7">
                        <input type="text" class="form-control" id="group" formControlName="contactType"
                            name="contactType" />
                    </div>
                </div> -->
                <div class="col-12 d-flex pt-3  align-items-center">
                    <label for="name" class="pt-0 col-5 text-end pe-3">Pharmacy Helpline</label>
                    <div class="col-7">
                        <input type="text" class="form-control" id="legalName" formControlName="pharmacyHelpLine"
                            name="pharmacyHelpLine" mask='(000) 000-0000' />
                    </div>
                </div>
            </div>
            <!-- <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Contact Name</label>
                <div class="col-7">
                    <input type="text" class="form-control" id="group" formControlName="contactName"
                        name="contactName" />
                </div>
            </div> -->
        </div>
        <!-- <div class="d-flex justify-content-between pt-3">
            <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Phone 1</label>
                <div class="col-7">
                    <div class="col-12 d-flex">
                        <div class="col-8 pe-2">
                            <input type="text" class="form-control pe-2" id="group" formControlName="phone1"
                                name="phone1" mask='(000) 000-0000' />
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-control" id="group" formControlName="extension"
                                name="extension" maxlength="6" minlength="4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between pt-3">

            <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Phone 2</label>
                <div class="col-7">
                    <input type="text" class="form-control" id="group" formControlName="phone2" name="phone2"
                        mask='(000) 000-0000' />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between pt-3">
            
            <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Email</label>
                <div class="col-7">
                    <input type="text" class="form-control" id="group" formControlName="email" name="email"
                        [pattern]="parentComponent.mailformat" />
                </div>
            </div>
        </div> -->
    </div>
    <div class="pt-2">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
               Contact Name
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                Contact Type
                </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 1
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                Ext
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 2
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Email
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="pharmacyPlanContactInfo">
            <tr *ngIf="!getFormArray(pharmacyPlanDetailForm, 'pharmacyPlanContactInfo').length">
              <th colspan="6" class="fw-bold text-center">
                No contact info
              </th>
            </tr>
            <tr *ngFor="
                  let item of getFormArray(pharmacyPlanDetailForm, 'pharmacyPlanContactInfo')
                    .controls;
                  let i = index
                " [formGroupName]="i">
              <td>
                <input type="text" class="form-control" formControlName="contactName" name="contactName" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="contactType" name="contactType" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="phone1" name="phone1" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="extension" name="extension" maxlength="6"
                  minlength="4" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="phone2" name="phone2" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="email" name="email"
                  [pattern]="parentComponent.mailformat" />
              </td>
              <td>
                <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main" (click)="delete(i, item.value, 'pharmacyPlanContactInfo', pharmacyPlanDetailForm)"
                 ></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <a class="text-primary pointer" type="button" (click)="addContactInfo(pharmacyPlanDetailForm, 'pharmacyPlanContactInfo', '', 'contactType')"><i-bs
            name="plus"></i-bs>Add More</a>
      </div>
    <hr class="mt-5">
    <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Pharmacy Programs</p>
            <!-- <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
                <span class="visually-hidden">Loading...</span>
            </div> -->
        </div>
        <button type="button" class="btn btn-primary"
            (click)="addInfoForms(pharmacyPlanDetailForm, 'pharmacyPrograms')">
            <i-bs name="plus" class="pe-1"></i-bs> Add New Programs
        </button>
    </div>
    <div formArrayName="pharmacyPrograms">
        <div *ngIf="!getFormArray(pharmacyPlanDetailForm, 'pharmacyPrograms').length">
            <p class="text-center">No direct primary care clinic.</p>
        </div>
        <div *ngFor="let item of getFormArray(pharmacyPlanDetailForm, 'pharmacyPrograms').controls; let i = index"
            [formGroupName]="i">
            <div class="col-12 pb-4">
                <button
                    class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
                    type="button" (click)="pharmacyCollapse.toggle()" [attr.aria-expanded]="!pharmacyCollapse.collapsed"
                    aria-controls="collapseExample">
                    <span class="text-capitalize">
                        <i-bs [name]="!pharmacyCollapse.collapsed ? 'chevron-down' : 'chevron-up'" class="pe-1"></i-bs>
                        {{item.get('programName')?.value || 'New Pharmacy Plan Details'}}
                    </span>
                    <span>
                        <i-bs name="trash-fill" class="pull-right text-gray-main"
                            (click)="openConfirmationModel(i, item, 'pharmacyPrograms', pharmacyPlanDetailForm)"></i-bs>
                    </span>
                </button>
            </div>
            <div class="pb-3" id="collapseExample" mdbCollapse #pharmacyCollapse="mdbCollapse" [collapsed]="false">
                <div class="px-2 collapse-container">
                    <div class="col-12 d-flex justify-content-between">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Program Type</label>
                                <div class="col-7">
                                    <!-- <input type="text " class="form-control" id="programType"
                                        formControlName="programType" name="programType" /> -->
                                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                                        formControlName="programType" name="programType">
                                        <ng-option
                                            *ngFor="let option of  parentComponent.dropdownConstants.pharmacyProgramType| sort:'asc':'name'"
                                            value="{{option.value}}">
                                            {{option.name}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Phone</label>
                                <div class="col-7">
                                    <input type="text " class="form-control" id="phone" formControlName="phone"
                                        name="phone" mask='(000) 000-0000' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between pt-3">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Program Name</label>
                                <div class="col-7">
                                    <input type="text " class="form-control" id="programName"
                                        formControlName="programName" name="programName" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Website Portal</label>
                                <div class="col-7">
                                    <input type="text " class="form-control" id="websitePortal"
                                        formControlName="websitePortal" name="websitePortal" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-between pt-3">
                        <div class="col-6">
                            <div class="d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Program Notes</label>
                                <div class="col-7">
                                    <textarea mdbInput class="form-control" id="textAreaExample" rows="3"
                                        formControlName="programNotes" name="programNotes"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-2">
                    <table class="table table-reponsive">
                        <thead>
                            <tr>
                                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Portal Access
                                    Contact</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 1</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 100px !important;">Ext</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 2</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody formArrayName="portalContactDetail">
                            <tr *ngIf="!getFormArray(item, 'portalContactDetail').length">
                                <th colspan="6" class="fw-bold text-center">No address info</th>
                            </tr>
                            <tr *ngFor="let contactForm of getFormArray(item, 'portalContactDetail').controls; let i = index"
                                [formGroupName]="i">
                                <td> <input type="text" class="form-control" id="contactName"
                                        formControlName="contactName" name="contactName" /></td>
                                <td> <input type="text" class="form-control" id="phone1" formControlName="phone1"
                                        name="phone1" mask='(000) 000-0000' /></td>
                                <td> <input type="text" class="form-control" id="extension" formControlName="extension"
                                        name="extension" maxlength="6" minlength="4" /></td>
                                <td> <input type="text" class="form-control" id="phone2" formControlName="phone2"
                                        name="phone2" mask='(000) 000-0000' /></td>
                                <td> <input type="text" class="form-control" id="email" formControlName="email"
                                        name="email" [pattern]="parentComponent.mailformat" /></td>
                                <td>
                                    <!-- <i-bs name="trash-fill"></i-bs> -->

                                    <i-bs *ngIf="!contactForm.value.loading" name="trash-fill"
                                        class="pointer text-gray-main"
                                        (click)="delete(i, contactForm.value, 'portalContactDetail', item)"></i-bs>
                                    <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                        *ngIf="contactForm.value.loading">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="text-primary pointer" type="button"
                        (click)="addContactInfo(item, 'portalContactDetail')"><i-bs name="plus"></i-bs>Add
                        More</a>
                    <hr class="mt-5">
                </div>
            </div>
        </div>

    </div>
</div>
<div class="d-flex justify-content-end pb-3">
    <button type="button" class="btn btn-primary " (click)="saveForms()" [disabled]="!pharmacyPlanDetailForm.valid">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
            <span class="visually-hidden">Loading...</span>
        </div>
        Save
    </button>
</div>