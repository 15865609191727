import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.heat';
import { MemberService } from 'src/app/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
})
export class HeatmapComponent implements OnInit {

  mapData: any = [];
  loadingZipData: boolean = true;
  constructor(private memberService: MemberService) {}

  ngOnInit(): void {
    this.getMemberCountByZipCode()
  }

  options = {
    layers: [
      L.tileLayer('https://api.mapbox.com/styles/v1/pramit-b/cllxbtyg200ip01qy9of34o28/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicHJhbWl0LWIiLCJhIjoiY2xseGJoaHRxMGdqYTNxczZwbmR5NWE5ZCJ9.vz5UCU2kDhi8A0nO2SBXcw', {
        maxZoom: 18,
        attribution: '&copy; Mapbox',
      })
    ],
    zoom: 5,
    center: L.latLng(39.8283, -98.5795),
  };

  cfg = {
    maxOpacity: 0.8,
    radius: 25,
    blur: 15,
    gradient: {
      0.0: 'red',    // Low values are blue
      0.4: 'red',   // Intermediate values are green
      0.8: 'red',  // Intermediate values are yellow
      1.0: 'red'      // High values are red
    },
    // scales the radius based on map zoom
    scaleRadius: true,
  };

  async onMapReady(map: any) {
    const data: any = this.mapData.map((feature: any) => {
      return [feature.lat, feature.lng, feature.count];
    });
    L.heatLayer(data, this.cfg).addTo(map);
  }

  getMemberCountByZipCode(){
    this.memberService.getMemberCountByZipCode()
      .pipe(finalize(() => {}))
      .subscribe(async (response: any) => {
        if (response && response.success) {
          this.loadingZipData = false
          this.mapData = response.data.rows;
        }
      });
  }
}
