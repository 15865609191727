import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

// import { OrderModule } from 'ngx-order-pipe';

//MDB Modules
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbTableModule } from 'mdb-angular-ui-kit/table';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbAutocompleteModule } from 'mdb-angular-ui-kit/autocomplete';
import { MdbStepperModule } from 'mdb-angular-ui-kit/stepper';
import { MdbFileUploadModule } from 'mdb-angular-file-upload';
import { MdbRatingModule } from 'mdb-angular-ui-kit/rating';
import { MdbChartModule } from 'mdb-angular-ui-kit/charts';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbDragAndDropModule } from 'mdb-angular-drag-and-drop';
/**
 * @property declarations : Array //Declares which components, directives, and pipes belong to the module.
 * @property imports : Array //Imports other modules with the components, directives, and pipes that components in the current module need.
 * @property exports : Array //Makes some of those components, directives, and pipes public so that other module's component templates can use them.
 * @property providers : Array //Provides services that other application components can use.
 */

@NgModule({
  declarations: [],
  imports: [
    NgSelectModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    MdbSidenavModule,
    MdbNotificationModule,
    MdbTableModule,
    MdbDatepickerModule,
    // OrderModule,
    MdbAutocompleteModule,
    MdbStepperModule,
    MdbFileUploadModule,
    MdbRatingModule,
    MdbChartModule,
    MdbSelectModule,
    MdbDragAndDropModule
  ],
  exports: [
    NgSelectModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    MdbSidenavModule,
    MdbNotificationModule,
    MdbTableModule,
    MdbDatepickerModule,
    // OrderModule,
    MdbAutocompleteModule,
    MdbStepperModule,
    MdbFileUploadModule,
    MdbRatingModule,
    MdbChartModule,
    MdbSelectModule,
    MdbDragAndDropModule
  ],
  providers: [],
})
export class SharedModule { }
