import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { ExcelHeaders, MetaDataDropdownConstant } from 'src/app/constants';
import { ExcelService, ServiceCaseService } from 'src/app/services';

@Component({
  selector: 'app-raw-filter-data',
  templateUrl: './raw-filter-data.component.html',
  styleUrls: ['./raw-filter-data.component.scss'],
})
export class RawFilterDataComponent {
  rawFilterList: any = false;
  dropdownConstants: any = MetaDataDropdownConstant;
  reportLoader: boolean = false;
  searchFilter: any;
  limit: number = 25;
  sortOrder: boolean = true; //for sort order
  sortBy: any = 'caseId'

  constructor(private serviceCaseService: ServiceCaseService,
    private excelExportService: ExcelService) { }

  ngOnInit() { }

  getRawFilterReport(params: any) {
    // this.rawFilterList = false;
    this.serviceCaseService
      .getRawFilterData({ ...params, sortBy: this.sortBy, sortType: this.sortOrder ? 'asc' : 'desc' })
      .pipe(
        finalize(() => {
          this.reportLoader = false;
        })
      )
      .subscribe((response: any) => {
        this.rawFilterList = true;
        if (response && response.success) {
          this.rawFilterList = response.data;
          this.rawFilterList = this.rawFilterList.map((x: any) => {
            return {
              ...x,
              status: x.status.code
                ? this.dropdownConstants.caseStatus.find(
                  (y: any) => y.value == x.status.code
                )?.label
                : null,
            };
          });
        }
      });
  }

  handleSubmit(event: any) {
    if (event == 'reset') {
      this.rawFilterList = false;
    } else {
      this.rawFilterList = true;
      this.reportLoader = true;
      this.searchFilter = event;
      this.getRawFilterReport({ ...event, limit: this.limit });
    }
  }

  changeLimit(limit: number) {
    this.reportLoader = true;
    this.getRawFilterReport({ ...this.searchFilter, limit: limit });
  }

  async exportRawFilter() {
    let reportData = this.rawFilterList.map((x: any) => {
      return {
        ...x, memberId: x.subscriber?.subscriberId,
        companyName: x.patient?.relationship?.grandParentName,
        tpa: x.callInPerson?.tpa,
        patientFirstName: x.patient?.firstName,
        patientLastName: x.patient?.lastName,
        patientDOB: x.patient?.dob ? moment(x.patient?.dob).format('MM/DD/YYYY') : '',
        patientEmail: x.patient?.email,
        patientCell: x.patient?.phones?.find((y: any) => y.type == 'Cell')?.value,
        patientHomePhone: x.patient?.phones?.find((y: any) => y.type == 'Home')?.value,
        patientWorkPhone: x.patient?.phones?.find((y: any) => y.type == 'Work')?.value,
        caseCallType: x.caseType && x.caseType[0]?.split("|")[0],
        caseCallRegarding: x.caseType && x.caseType[0]?.split("|")[1],
        createdBy: x.from?.assignedTo?.name,
        assignedTo: x.to?.assignedTo?.name,
        addressLine1: x.patient?.address?.line1,
        addressLine2: x.patient?.address?.line2,
        city: x.patient?.address?.city,
        state: x.patient?.address?.state,
        zip: x.patient?.address?.zip,
        procedure: x.service && x.service[0]?.name,
        tatStart: x.tatStart ? moment(x.tatStart).format("MM/DD/YYYY") : '',
        tatEnd: x.tatEnd ? moment(x.tatEnd).format("MM/DD/YYYY") : '',
        createdDate: x.createdDate ? moment(x.createdDate).format('MM/DD/YYYY') : '',
        closeDate: x.closeDate ? moment(x.closeDate).format('MM/DD/YYYY') : '',
        lastModified: x.lastModified ? moment(x.lastModified).format("MM/DD/YYYY") : '',
        tag: x.tags?.map((x: any) => x).join(',')
      }
    })
    const content = {
      header: ExcelHeaders.RawFilterData,
      data: reportData,
      reportTitle: 'Raw Filter Data',
      fileName: 'Raw_Filter_Data',
    };
    await this.excelExportService.exportExcel(content);
  }

  sort(sortBy: any) {
    this.sortOrder = !this.sortOrder;
    this.sortBy = sortBy;
    this.rawFilterList = [];
    this.reportLoader = true;
    this.getRawFilterReport(this.searchFilter);
  }
}
