import { Injectable } from '@angular/core';
import { ApiConstant } from '../constants/api.constants';
import { HttpClientService } from "./http-client.service";
import { filter, map } from 'rxjs/operators';
import { QueryParams } from '../utils';


@Injectable({
    providedIn: 'root'
})

export class ActivityLogService {
    constructor(
        private http: HttpClientService,
    ) { }

    getActivityLog(filterParams:any) {
        const keyValues = QueryParams.addParams(filterParams);
        return this.http.get(`${ApiConstant.ACTIVITYLOG}?` + keyValues)
        .pipe(map(response => {
            return response;
        }))
    }
}