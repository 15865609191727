import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    constructor(
        private http: HttpClientService
    ) { }

    getNetworkList(keyword? :any) {
        const keyValues = QueryParams.addParams(keyword);
        return this.http.get(`${ApiConstant.NETWORK}?` + keyValues)
        .pipe(map(response => {
            return response;
        }))
    }
    
    addNewNetwork(networkInfo:any) {
        return this.http.post(`${ApiConstant.NETWORK}`, networkInfo)
        .pipe(map(response => {
            return response;
        }))
    }

    editNetwork(networkInfo:any) {
        return this.http.put(`${ApiConstant.NETWORK}/${networkInfo.id}`, networkInfo)
        .pipe(map(response => {
            return response;
        }))
    }

    deleteNetwork(networkInfo:any) {
        return this.http.remove(`${ApiConstant.NETWORK}/${networkInfo.id}`)
        .pipe(map(response => {
            return response;
        }))
    }
    
    getNetworkGroup() {
        return this.http.get(`${ApiConstant.NETWORK}/group-name`)
        .pipe(map(response => {
            return response;
        }))
    }

    getNetworkById(networkInfo:any) {
        return this.http.get(`${ApiConstant.NETWORK}/${networkInfo.id}`)
        .pipe(map(response => {
            return response;
        }))

    }

    getGroupNetworkList(params:any) {
        return this.http.get(`${ApiConstant.NETWORK}/group-name?groupNetworkName=${params}`)
        .pipe(map(response => {
            return response;
        }))
    }
}