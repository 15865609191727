import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { UserModalComponent, ConfirmationModalComponent, RolesPermissionModalComponent } from '../../../modal';
import { LoginModel, RolesModel } from '../../../model';
import { UserService, ToastrService, HttpCancelService, CommonListService } from '../../../services';
import { CurrentUser } from '../../../utils';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  modalRef: MdbModalRef<UserModalComponent | ConfirmationModalComponent | RolesPermissionModalComponent> | null = null;

  usersLoading: boolean = true;
  userList: any = [];
  searchKeyword: any = null;
  searchConfig = {
    action: { label: 'Add User', type: 'addUser', icon: 'plus' },
    showAdvancedSearch: false,
    showToggle: true,
    formValue: this.searchKeyword,
    module: 'user',
    limit : 25
  }
  rolesList: any = [];
  updating: boolean = false;
  roleModel: RolesModel = new RolesModel();
  order = 'firstName';
  reverse: boolean = false;
  getRequest: any;
  currentUser:any;

  constructor(private modalService: MdbModalService, private userService: UserService, private toastrService: ToastrService, private cancelService: HttpCancelService, 
    private commonService: CommonListService) { }


  ngOnInit(): void {
    this.currentUser = CurrentUser.getCurrentUser();
    this.getUserList();
    this.getRolesList();
  }


  openUserModal(action: any, user: any) {
    this.modalRef = this.modalService.open(UserModalComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        action: action,
        modalTitle: action === 'addUser' ? 'Add User'  : (action ===  'assignCompany' ? `Assign Company to ${user.firstName}` : `Edit User Details of ${user.firstName}`),
        userModel: JSON.parse(JSON.stringify(user)) || new LoginModel(),
        roles: this.rolesList
      }
    })
    this.modalRef.onClose.subscribe((event: any) => {
      if (action === 'addUser' && event !== 'close') {
        this.addNewuser(event);
      }
      else if (action === 'editUser' && event !== 'close') {
        this.editUser(event);
      }
      else if (action === 'assignCompany' && event !== 'close') {
          if(event) {
            this.assignCompanyToUser(user.id, event.companies);
          } 
      }
    })
  }

  openConfirmationModal(user: any) {
    this.modalRef = this.modalService.open(ConfirmationModalComponent, {
      modalClass: 'modal-md ',
      ignoreBackdropClick: true,
      data: {
        modalTitle: 'Confirmation',
        modalBody: `Are you sure you want to deactivate <strong>${user.firstName} ${user.middlName ? user.middleName : ''} ${user.lastName}</strong>?`,
        userModel: JSON.parse(JSON.stringify(user)) || new LoginModel(),
      }
    });
    this.modalRef.onClose.subscribe((message: any) => {
      if (message == 'confirmDeactivate') {
        this.deactivateUser(user);
      }
    })
  }

  openRolesModal(event: any, user: any) {
    this.getUserPermission(event, user);

  }

  async getUserList(keyword?: any) {
    this.usersLoading = true;
    this.userList.rows =[];
    this.getRequest = this.commonService.getUserList(keyword).then((response) => {
      this.usersLoading = false;
      this.userList = response;
    })
  }

  deactivateUser(userInfo: any) {
    this.updating = true;
    this.userService.deactivateUser(userInfo).pipe(finalize(() => {
      this.updating = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess('User deactivated sucessfully.');
        this.getUserList();
      }
    })
  }

  addNewuser(newUser: any) {
    this.usersLoading = true;
    newUser.roles = this.assignRoleName(newUser.roles);
    this.userService.addNewUser(newUser).pipe(finalize(() => {
      this.usersLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getUserList();
      }
    })
  }

  editUser(userInfo: any) {
    this.updating = true;
    userInfo.roles = this.assignRoleName(userInfo.roles);
    this.userService.editUser(userInfo).pipe(finalize(() => {
      this.updating = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        userInfo.loading = true;
        this.toastrService.showSuccess('User updated successfully.');
        this.getUserList();
      }
    })
  }

  getRolesList() {
    this.userService.getRolesList().pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.rolesList = response.data.data.roles;
      }
    })
  }

  assignRoleName(roles: any) {
    roles = roles.map((x: any) => {
      return {
        roleId: x, name: this.rolesList.find((y: any) => y.roleId == x).name
      }
    })
    return roles;
  }

  getUserPermission(event: any, user: any) {
    this.userService.getPermissionByUserId(user).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.roleModel.permissions = response.data.data.permissions;
        this.modalRef = this.modalService.open(RolesPermissionModalComponent, {
          modalClass: 'modal-md modal-dialog-centered',
          ignoreBackdropClick: true,
          data: {
            event: event,
            modalTitle: `Manage Permission for ${user.firstName}`,
            userPermission: user.permissions,
            userId: user.id,
            rolesModel: this.roleModel
          }
        })
      }
    })
  }

  setOrder(value: string) {
    if (this.order == value) {
      this.reverse = !this.reverse;
    }
    else {
      this.order = value;
      this.reverse = false;
    }
  }

  searchUser(event: any) {
    if (!this.getRequest) {
      this.getUserList(event);
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getUserList(event);
    }
  }

  sendInvitation(user: any) {
    if (!user.isEmailVerified) {
      this.userService.sendInvitation(user.id).pipe(finalize(() => {
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message)
        }
      })
    }
    else {
      this.userService.forgotPassword(user.email).pipe(finalize(()=>{
      })).subscribe((response:any)=>{
        if(response && response.success) {
          this.toastrService.showSuccess('An email has been sent to this account. Please reset your password through the email.');
        }
      })
    }
  }

  assignCompanyToUser(user:any, company:any=[]) {
      this.userService.assignCompany(user, {"companies":company}).pipe(finalize(()=>{
      })).subscribe((response:any)=>{
        if(response && response.success) {
          this.toastrService.showSuccess(response.message);
          this.getUserList();
        }
      })
  }
}
