import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

import {LoginModel} from '../../model'
import { CommonListService } from 'src/app/services';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {

  userModel : LoginModel = new LoginModel();
  showPassword: boolean = false;
  modalTitle: string = 'Add User';
  roles : Array<any>;
  action:any;
  mailformat = /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  textFormat = '[A-Za-z]+';
  userFullName:string = '';
  companyList:any = [];
  companyToggleList:any = [];
  loading:boolean = true;

  constructor(public modalRef: MdbModalRef<UserModalComponent>, public commonListService: CommonListService) {}

  async ngOnInit() {
    if(this.userModel.roles?.length) {
      this.userModel.roles=this.userModel.roles.map((x:any)=>{
        return x.roleId || x._id;
      })
    }
    this.companyList = await this.commonListService.getCompanyList({
      limit: 0,
      companyStatus: true,
    });
    this.companyList = this.companyList.length ? [{alithiasCompanyId: 0, clientLegalName:'ALL'}, ...this.companyList] : [];
    this.companyToggleList = this.companyList;
    this.userFullName = `${this.userModel?.firstName} ${this.userModel.middleName  ? this.userModel.middleName : ''} ${this.userModel?.lastName}` ;
    this.loading = false;
  }
  
  showPasswordToggle() {
    this.showPassword = !this.showPassword;
  }

  handleSend() {
    this.modalRef.close(this.userModel);
  }

  toggleParent(i:number) {
    this.roles[i].toggle = !this.roles[i].toggle
  }

  searchCompany(event:any) {
    if (event.term && event.term.length) {
      this.companyToggleList = this.companyList.filter((selectedItem:any) => selectedItem.clientLegalName.toLowerCase().includes(event.term.toLowerCase()));
    }
    else {
      this.companyToggleList = this.companyList;
    }
  }

  selectCompany(event:any) {
    if(!event.alithiasCompanyId) {
      this.userModel.companies = this.userModel.companies.filter((x:any)=>!x.alithiasCompanyId)
    }
    else {
      this.userModel.companies = this.userModel.companies.filter((x:any)=>x.alithiasCompanyId).map((item:any) => {
        return {
          alithiasCompanyId: item.alithiasCompanyId,
          clientLegalName: item.clientLegalName,
          tpas: item.tpas.length > 0 ? item.tpas[0].tpaName: ""
        }
      })
    }
  }
}
