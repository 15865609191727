import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class AcillaryBenefitModel {
    static setAcillaryBenefitModel(value? :any){
        let model : FormFields[] = [
            {
                name: "ancillaryId",
                id: "ancillaryId",
                controlType: 'input',
                controlName: "ancillaryId",
                required: true,
                value: value?.ancillaryId || null
            },
            {
                name: "vendorName",
                id: "vendorName",
                controlType: 'input',
                controlName: "vendorName",
                required: true,
                value: value?.vendorName || ''
            },
            {
                name: 'vendorPortalUrl',
                id: 'vendorPortalUrl',
                controlType: 'input',
                controlName: 'vendorPortalUrl',
                required: true,
                value: value?.vendorPortalUrl || ''
            },
            {
                name: 'vendorPhone',
                id: 'vendorPhone',
                controlType: 'input',
                controlName: 'vendorPhone',
                value: value?.vendorPhone || null
            },
            {
                name: 'programType',
                id: 'programType',
                controlType: 'input',
                controlName: 'programType',
                value: value?.programType || ''
            },
            {
                name: 'vendorEmail',
                id: 'vendorEmail',
                controlType: 'input',
                controlName: 'vendorEmail',
                value: value?.vendorEmail || ''
            },
            {
                name: 'programNotes',
                id: 'programNotes',
                controlType: 'input',
                controlName: 'programNotes',
                value: value?.programNotes || ''
            },
            {
                controlType: 'vendorDetails',
                controlName: 'vendorDetails',
                name: 'vendorDetails',
                id: 'vendorDetails',
                value: value?.vendorDetails || [],
                formArray:[]
            }
        ]
        return model;
    } 
}