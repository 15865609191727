<div class="modal-header">
  <h6 class="modal-title fw-bold" id="exampleModalLabel">{{ modalTitle }}</h6>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close('close')"></button>
</div>
<div class="modal-body">
  <form #networkForm="ngForm">
    <div class="md-form px-2">
      <label for="networkName" class="form-label">Network Name*</label>
      <div class="input-group mb-3">
        <input mdbInput type="text" class="form-control px-3" id="networkName" aria-describedby="basic-addon3"
          [(ngModel)]="networkModel.networkName" name="networkName" #networkName="ngModel" required />
      </div>
    </div>
    <div class="d-flex form-inline">
      <div class="col-6 px-2">
        <label for="networkCode" class="form-label">Network Code*</label>
        <div class="input-group mb-3">
          <input mdbInput type="text" class="form-control px-3" id="networkCode" aria-describedby="basic-addon3"
            [(ngModel)]="networkModel.networkCode" name="networkCode" #networkCode="ngModel" required />
        </div>
      </div>
      <div class="col-6 px-2">
        <label for="source" class="form-label">Source*</label>
        <div class="input-group mb-3">
          <input mdbInput type="text" class="form-control px-3" id="source" aria-describedby="basic-addon3"
            [(ngModel)]="networkModel.source" name="source" #source="ngModel" disabled placeholder="MANUAL" />
        </div>
      </div>
    </div>
    <div class="md-form px-2">
      <label for="source" class="form-label">Network Type</label>
      <div class="input-group mb-3">
        <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
        [(ngModel)]="networkModel.networkType" name="networkType">
        <ng-option *ngFor="let option of dropdownConstants.contractBenefitsType | sort:'asc':'name'"
          [value]="option.value">
          {{ option.name }}</ng-option>
      </ng-select>
      </div>
    </div>
    <div class="md-form px-2">
      <label for="groupNetworkName" class="form-label">Group Network Name*</label>
      <div class="input-group mb-3">
        <input mdbInput [ngModel]="searchText | async" (ngModelChange)="searchText.next($event)"
          [mdbAutocomplete]="autocomplete" type="text" required [(ngModel)]="selectedGroupNetwork" id="autocomplete"
          class="form-control" name="group" (blur)="groupNetworkblur(selectedGroupNetwork)" />
        <mdb-autocomplete #autocomplete="mdbAutocomplete">
          <mdb-option *ngFor="let option of results | async"
            value="{{ option.groupNetworkName }} - {{ option.groupNetworkId }}">
            {{ option.groupNetworkName }} - {{ option.groupNetworkId }}
          </mdb-option>
          <div *ngIf="notFound" class="autocomplete-no-results">
            No results found
          </div>
        </mdb-autocomplete>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="saveNetwork()" [disabled]="networkForm.invalid">
    Save
  </button>
</div>