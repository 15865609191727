<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title fw-bold" id="exampleModalLabel">{{ modalTitle }}</h6>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close('close')"></button>
    </div>
    <div class="model-body">
        <form class="p-2" #crmForm="ngForm">
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-2 ">Company</label>
                <div class="col-10">
                    <ng-select class="form-control p-0" [items]="companyList" name="comp" placeholder="Select Company"
                        bindLabel="clientLegalName" [selectableGroupAsModel]="false" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" [(ngModel)]="crmModel.companies"
                       [clearSearchOnAdd]="true" notFoundText="Type to search"
                        typeToSearchText="Type to search" style="min-height: 2.5rem;">
                        <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                            let-index="index">
                            <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox"
                                [ngModel]="item$.selected" class="form-check-input" />
                            <label class="ps-2 text-break">{{ item.clientLegalName |
                                titleCase}}</label>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-2">Network</label>
                <div class="col-10">
                    <ng-select class="form-control p-0" [items]="networkList" name="net" placeholder="Select Network"
                        bindLabel="networkName" [selectableGroupAsModel]="false" [multiple]="true"
                        [closeOnSelect]="false" [searchable]="true" [(ngModel)]="crmModel.networks"
                       [clearSearchOnAdd]="true" notFoundText="Type to search"
                        typeToSearchText="Type to search" style="min-height: 2.5rem;">
                        <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                            let-index="index">
                            <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox"
                                [ngModel]="item$.selected" class="form-check-input" />
                            <label class="ps-2 text-break">{{ item.networkName |
                                titleCase}}</label>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-2">Procedure</label>
                <div class="col-10">
                    <ng-select class="form-control p-0" [items]="procedureList" name="proc"
                        placeholder="Select Procedure" bindLabel="procedureName" [(ngModel)]="crmModel.procedures"
                        [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        [clearSearchOnAdd]="true" notFoundText="Type to search"
                        typeToSearchText="Type to search" style="min-height: 2.5rem;">
                        <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                            let-index="index">
                            <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox"
                                [ngModel]="item$.selected" class="form-check-input" />
                            <label class="ps-2 text-break">{{ item.procedureName |
                                titleCase}}</label>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-2">Service Options</label>
                <div class="col-10">
                    <ng-select class="form-control p-0" [items]="listConstant.serviceOptionList" name="serviceOpt"
                        placeholder="Select Service Option" bindLabel="label" [(ngModel)]="crmModel.serviceOptions"
                        [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
                        [clearSearchOnAdd]="true" notFoundText="Type to search"
                        typeToSearchText="Type to search" style="min-height: 2.5rem;">
                        <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$"
                            let-index="index">
                            <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox"
                                [ngModel]="item$.selected" class="form-check-input" />
                            <label class="ps-2 text-break">{{ item.label |
                                titleCase}}</label>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-2">Provider</label>
                <div class="col-10">
                    <ng-select class="form-control p-0" [items]="providerList | async" bindLabel="displayName"  placeholder="Search Providers"
                       [multiple]="true" [hideSelected]="true" [minTermLength]="2"
                        [loading]="providerLoading" [(ngModel)]="crmModel.providers" name="prov" 
                        typeToSearchText="Please enter 2 or more characters" [typeahead]="providerInput">
                    </ng-select>
                </div>
            </div>
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-2">Effective Date</label>
                <div class="col-10">
                    <div class="col-12 d-flex">
                        <div class="col-6 pe-2">
                            <div class="input-group">
                                <input mdbInput [mdbDatepicker]="effStartDate" type="text" class="form-control" required
                                    id="effStartDate" (click)="effStartDate.open()" name="effSD"
                                    [(ngModel)]="crmModel.effectiveStartDate" placeholder="Start Date"
                                    autocomplete="off" />
                                <mdb-datepicker-toggle [mdbDatepicker]="effStartDate"></mdb-datepicker-toggle>
                                <mdb-datepicker #effStartDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                            </div>
                        </div>
                        <div class="col-6 ps-2">
                            <div class="input-group">
                                <input mdbInput [mdbDatepicker]="effEndDate" type="text" class="form-control"
                                    id="effEndDate" (click)="effEndDate.open()" name="effED"
                                    [(ngModel)]="crmModel.effectiveEndDate" placeholder="End Date" autocomplete="off" />
                                <mdb-datepicker-toggle [mdbDatepicker]="effEndDate"></mdb-datepicker-toggle>
                                <mdb-datepicker #effEndDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-form d-flex p-2">
                <label class="col-2 pt-3">Message</label>
                <div class="input-group">
                    <textarea class="form-control h-auto" name="message" aria-label="With textarea" required
                        [(ngModel)]="crmModel.message" placeholder="Type your message here..." rows="2"
                        [mention]="mentionConfig.items" [mentionConfig]="mentionConfig"></textarea>
                </div>
            </div>
        </form>
    </div>
    <div class="model-footer d-flex justify-content-between p-2 bg-gray-lighter">
        <button type="button" class="btn btn-clear text-primary" data-mdb-ripple-color="dark"
            (click)="modalRef.close('close')">
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="modalRef.close(crmModel)" 
        [disabled]="(!crmModel.companies?.length && !crmModel.networks?.length && !crmModel.procedures?.length 
        && !crmModel.serviceOptions?.length && !crmModel.providers.length) || crmForm.invalid">Save</button>
    </div>
</div>