import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';

import { CompanyService, FileStatusService } from '../../../services';
import { ChartConstants } from 'src/app/constants';
import { CurrentUser } from 'src/app/utils';

@Component({
  selector: 'app-etl-dashboard',
  templateUrl: './etl-dashboard.component.html',
  styleUrls: ['./etl-dashboard.component.scss']
})
export class EtlDashboardComponent implements OnInit {

  chartConst = ChartConstants.ChartColors;
  commonChartOption = ChartConstants.commonOption;
  chartConfig: any = {
    datasets: [],
    labels: []
  }
  dataSourceList: any = [];
  fileStatusOrder: any = {
    overdue: 1,
    late: 2,
    received: 3
  }
  summaryStats: any = [];
  displayMsg: string = '';
  loading: boolean = true;

  constructor(private companyService: CompanyService, private dataSourceService: FileStatusService) { }

  ngOnInit(): void {
    this.getWelcomeMessage();
    Promise.all([this.getDashboardSummary(), this.getVarianceList({ companyCount: false }), this.getDataSourceList()]).then(() => {
      this.loading = false;
    })
  }

  getVarianceList(filterParams: any) {
    this.companyService.getCompanyEmployeeStatus({ ...filterParams, companyStatus: true }).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        response.data.rows = response.data.rows ? response.data.rows : [response.data];
        this.chartConfig.datasets = [
          { data: (response.data.rows.map((x: any) => x.previousCount)).slice(0, 5), label: 'Previous Count', backgroundColor: this.chartConst.primaryColor.backgroundColor, variance: (response.data.rows.map((x: any) => x.variance)).slice(0, 5)},
          { data: (response.data.rows.map((x: any) => x.currentCount)).slice(0, 5), label: 'Current Count ', backgroundColor: this.chartConst.secondaryColor.backgroundColor, variance:  (response.data.rows.map((x: any) => x.variance)).slice(0, 5) },
        ]
        this.chartConfig.labels = (response.data.rows.map((x: any) => x.clientLegalName)).slice(0,5)
      }
    })
  }

  getDataSourceList() {
    this.dataSourceService.getDataSource({ status: 'Active' }).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        response.data.rows = response.data.rows.filter((x: any) => x.status.fileStatus !== 'received')
        this.dataSourceList = response.data;
        this.dataSourceList.rows = this.dataSourceList.rows.map((x: any) => {
          return {
            ...x, fileName: x.fileName ? x.fileName.split('/')[x.fileName.split('/').length - 1] : ''
          }
        })
        this.dataSourceList.rows = this.dataSourceList.rows.sort((a: any, b: any) => b.status.days - a.status.days);
        this.dataSourceList.rows = this.dataSourceList.rows.sort((a: any, b: any) => {
          const statusA = a.status.fileStatus;
          const statusB = b.status.fileStatus;
          return this.fileStatusOrder[statusA] - this.fileStatusOrder[statusB];
        });
      }
    })
  }

  getDashboardSummary() {
    this.dataSourceService.getDashboardStats().pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.summaryStats.push(Object.values(response.data.rows[0]))
        this.summaryStats = this.summaryStats[0];
        this.summaryStats = this.summaryStats.filter((x:any)=>typeof x === 'object');

      }
    })
  }

  getWelcomeMessage() {
    const currentUserInfo = CurrentUser.getCurrentUser();
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      this.displayMsg = `Good Morning, ${currentUserInfo.firstName}`;
    } else if (currentHour >= 12 && currentHour < 18) {
      this.displayMsg = `Good Afternoon, ${currentUserInfo.firstName}`;
    } else {
      this.displayMsg = `Good Evening, ${currentUserInfo.firstName}`;
    }
  }

}
