export const ApiConstant = {
  LOGIN: '/login',
  MENU: '/menu',
  USER: '/users',
  ROLES: '/roles',
  RESOURCE: '/resource',
  ACTIVITYLOG: '/activity-log',
  PROCEDURE: '/procedures',
  NETWORK: '/network',
  COMPANY: '/companies',
  // COMPANY : '/companyOnBoardings',
  LOOKUP: '/lookup',
  ACCOUNT: '/account',
  MEMBER: '/members',
  FILES: '/file-status',
  TPA: '/tpas',
  SOURCE: '/datasources',
  DASHBOARD: '/dashboard',
  CLAIMS: '/claims',
  LOCATION: '/location',
  REPORTS: '/reports',
  ENTERPRISE: '/enterprise',
  EMAIL: '/email',
  CASE: '/service-requests',
  PROVIDER: '/providers',
  CRM: '/report-messaging',
  SURVEY: '/survey-monkey',
  TOKEN: '/token',
};
