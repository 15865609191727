import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class Sanitizer {
    constructor(private domSanitizer: DomSanitizer) {
    }

    sanitizeParams(params: any) {
        const sanitizedParam: any = this.domSanitizer.sanitize(SecurityContext.HTML, params);
        return sanitizedParam;
    }
}