import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CodeInputModule } from 'angular-code-input';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MentionModule } from 'angular-mentions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import { AppIcons } from './constants';
import * as common from './common';
import * as component from './components';
import * as services from './services';
import * as modal from './modal';
import * as pipe from './pipe';
import * as guard from './guards';
import * as util from './utils';
import { LeafletModule } from '@asymmetrik/ngx-leaflet'; 
const icons = AppIcons;
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    common.HeaderComponent,
    common.WelcomeComponent,
    common.SidebarComponent,
    common.ToastrComponent,
    common.SearchBarComponent,
    common.LoaderComponent,
    common.HeatmapComponent,
    common.TableComponent,
    common.InvalidComponent,
    component.LayoutLoginComponent,
    component.LayoutMainComponent,
    component.LoginComponent,
    component.ActivityLogComponent,
    component.UsersComponent,
    component.RolesPermissionComponent,
    component.ProcedureComponent,
    component.NetworkComponent,
    component.CompanyComponent,
    component.PricingComponent,
    component.CompanyAddEditComponent,
    component.ProcedureAddEditComponent,
    component.AccountActivationComponent,
    component.MemberComponent,
    component.FilesStatusComponent,
    component.EtlMonitoringComponent,
    component.MonitoringBySourceComponent,
    component.EtlDashboardComponent,
    component.AccountDetailsComponent,
    component.BillingsComponent,
    component.PlanDetails,
    component.ClientServiceTypeComponent,
    component.AddOnServicesComponent,
    component.ValueBasedBenefitsComponent,
    component.AncillaryBenefitProgramComponent,
    component.PreferredProviderComponent,
    component.DirectPrimaryCareComponent,
    component.PharmacyPlanDetailComponent,
    component.MedicalPlanDetailsComponent,
    component.Alerts,
    component.TilesComponent,
    component.EpisodesComponent,
    component.ReportHeaderComponent,
    component.IncentiveReportComponent,
    component.AccountStatusUpdateComponent,
    component.SavingDetailedReportComponent,
    component.SavingReportComponent,
    component.SmartSearchComponent,
    component.MemberProfileFilterComponent,
    component.MemberProfileComponent,
    component.CareOptionReportComponent,
    component.RawFilterComponent,
    component.RawFilterDataComponent,
    component.CustomReportMessagingComponent,
    component.SurveyResponseComponent,
    component.DashboardDetailedReportComponent,
    component.LuckyReportComponent,
    component.TagReportComponent,
    component.ReportPdfFormatComponent,
    component.AdvocacySatisfactionReportComponent,
    component.ReasonCodeReportComponent,
    modal.UserModalComponent,
    modal.RolesPermissionModalComponent,
    modal.ConfirmationModalComponent,
    modal.NetworkManagementModalComponent,
    modal.UploadFileModalComponent,
    modal.SendReportComponent,
    modal.CustomMessagingReportModalComponent,
    modal.AlertsManagementModelComponent,
    modal.TilesManagementModelComponent,
    modal.SingleFieldModalComponent,
    pipe.filterKeyword,
    pipe.sortPipe,
    pipe.TitleCasePipe,
    pipe.FormatPipe,
    pipe.PhoneFormatPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBootstrapIconsModule.pick(icons),
    HttpClientModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    CodeInputModule.forRoot({
      codeLength: 4,
      isCharsCode: false,
    }),
    LeafletModule,
    EditorModule,
    MentionModule,
  ],
  providers: [
    services.HttpClientService,
    services.AuthenticationService,
    services.UserService,
    services.NetworkService,
    services.ProcedureService,
    services.CompanyService,
    services.HttpCancelService,
    services.CommonListService,
    services.FormInitService,
    services.FormInitService,
    services.PricingService,
    services.EnterpriseService,
    services.ReportService,
    services.PdfService,
    services.ReportService,
    services.EmailService,
    services.ServiceCaseService,
    services.ProviderService,
    services.CustomMessageService,
    services.SurveyService,
    services.TokenService,
    pipe.PhoneFormatPipe,
    pipe.TitleCasePipe,
    util.Sanitizer,
    util.base64Converter,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: services.ServerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: services.ManageHttpInterceptor,
      multi: true,
    },
    guard.RoleGuard,
    guard.AuthGuard,
    guard.ValidTokenGuard,
    guard.IframeGuard,
    DatePipe,
    CurrencyPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
