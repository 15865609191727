<app-search-bar [searchConfig]="searchConfig" (searchLogEmit)="handlePageChange($event)" [searchList]="activityLogList">
</app-search-bar>
<div class="pt-5 mt-5" *ngIf="listLoading">
    <loader></loader>
</div>
<div class="text-center pt-4" *ngIf="!listLoading && !activityLogList.activities?.length">
    <p class="fw-bold">Logs not found.</p>
</div>
<div class="px-0" *ngIf="activityLogList.activities?.length">
    <table class="table table-striped pt-5 mb-0">
        <thead>
            <tr>
                <th scope="col" class="fw-bold" style="width:200px;">Created Date</th>
                <th scope="col" class="fw-bold">User</th>
                <th scope="col" class="fw-bold">Log Type</th>
                <th scope="col" class="fw-bold">Module</th>
                <th scope="col" class="fw-bold">Event</th>
                <th scope="col" class="fw-bold">Note</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let log of activityLogList.activities">
                <td style="width:200px;">{{log.createdAt | date: 'MM/dd/yyyy hh:mm a '}}</td>
                <td>{{log.user.name}}</td>
                <td>{{log.log.type}}</td>
                <td>{{log.log.module}}</td>
                <td>{{log.event.type}}</td>
                <td>{{log.log.note}} </td>
            </tr>
        </tbody>
    </table>
    <!-- <app-table [tableConfig]="tableConfig" [dataSource]="activityLogList.activities">
    </app-table> -->
    <p class="p-3 mb-0 fw-bold fs-" style="font-size: 14px;" *ngIf="!listLoading && activityLogList.activities.length">
        Showing {{activityLogList.count >= searchConfig.limit ? searchConfig.limit : activityLogList.count}} of
        {{activityLogList.count}} {{activityLogList.count > 1 ? 'results' : 'result'}}</p>
</div>