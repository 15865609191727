<div class="col-12 px-3">
    <div class="col">
        <div class="col-12">
            <app-report-header (baseAction)="handleBaseAction($event)"></app-report-header>
        </div>
        <div class="pt-5 mt-5" *ngIf="loading">
            <loader></loader>
        </div>
    </div>
    <div *ngIf="!loading && reasonCodeList">
        <div class="col-12 py-3">
            <div class="col-12 py-3">
                <div class="d-flex align-items-center justify-content-between">
                    <p class="fw-bold mb-0 ps-1">Reason Code Report Preview</p>
                    <button type="button" class="btn btn-clear px-4 py-2 border" (click)="exportExcelReport()" *ngIf="reasonCodeList?.serviceRequests?.length">
                        <i-bs name="download" class="pe-2"></i-bs>Export
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12" style="height: 180px">
            <div class="d-flex justify-content-evenly">
                <div class="col-2 px-2 ps-0">
                    <div style="height: 180px"
                        class="text-center bg-gray-light border-top border-4 border-primary rounded-1 p-3">
                        <p class="fw-bold">Total Calls Received</p>
                        <h1 class="text-primary fw-bold">
                            {{ reasonCodeList.totals[0]?.totalCalls }}
                        </h1>
                    </div>
                </div>

                <div class="col-2 px-2">
                    <div style="height: 180px"
                        class="text-center bg-gray-light border-top border-4 border-primary-dark rounded-1 p-3">
                        <p class="fw-bold">Care Navigator Calls</p>
                        <h1 class="text-primary fw-bold">
                            {{ reasonCodeList.totals[0]?.totalCn }}
                        </h1>
                    </div>
                </div>
                <div class="col-2 px-2">
                    <div style="height: 180px"
                        class="text-center bg-gray-light border-top border-4 border-secondary rounded-1 p-3">
                        <p class="fw-bold">
                            Benefit Support Calls
                        </p>
                        <h1 class="text-primary fw-bold">
                            {{ reasonCodeList.totals[0]?.totalBs }}
                        </h1>
                    </div>
                </div>
                <div class="col-3 px-2">
                    <div style="height: 180px"
                        class="bg-gray-light border-top border-4 border-primary-dark rounded-1 p-3">
                        <p class="fw-bold text-center ">
                            Top Reason Codes CN
                        </p>
                        <ul class="">
                            <li class="m-0 fw-bold  fs-2" *ngFor="let c of reasonCodeList.careNavCalls">{{(c.callRegarding |
                                titleCase) + ' (' + c.percentage + '%' + ')'}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-3 px-2 pe-0">
                    <div style="height: 180px"
                        class=" bg-gray-light border-top border-4 border-secondary rounded-1 p-3">
                        <p class="fw-bold text-center">
                            Top Reason Codes BS
                        </p>
                        <ul class="">
                            <li class="m-0 fw-bold fs-2" *ngFor="let c of reasonCodeList.benefitCalls">{{(c.callRegarding |
                                titleCase) + ' (' + c.percentage + '%' + ')'}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="pt-3">
                <table class="table table-report table-lucky" id="incentive">
                    <thead>
                        <tr class="fs-2 bordered">
                            <th scope="col" class="fw-bold">Case ID</th>
                            <th scope="col" class="fw-bold">Created Date</th>
                            <th scope="col" class="fw-bold">Closed Date</th>
                            <th scope="col" class="fw-bold">Call In Type</th>
                            <th scope="col" class="fw-bold">
                                Call Regarding
                            </th>
                        </tr>
                    </thead>
                    <tbody id="incentive">
                        <tr>
                            <td colspan="12" *ngIf="!reasonCodeList?.serviceRequests?.length" class="text-center">
                                No records found.
                            </td>
                        </tr>
                        <ng-container *ngFor="let l of reasonCodeList?.serviceRequests; let i = index">
                            <tr class="fs-2 bordered" [ngClass]="{ 'row-striped': i % 2 !== 0 }">
                                <td>{{ l.caseId }}</td>
                                <td>{{ l.createdDate | date :'MM/dd/yyyy' }}</td>
                                <td>{{ l.date | date : "MM/dd/yyyy" }}</td>
                                <td>{{ l.callInType }}</td>
                                <td>{{ l.callRegarding }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>