import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { MdbTabChange } from 'mdb-angular-ui-kit/tabs/tabs.component';
import { finalize } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { DomSanitizer } from '@angular/platform-browser';


import { CompanyAddEditComponent } from '../company-add-edit.component';
import { CompanyDropdownConstant } from '../../../../../constants';
import { CompanyModel } from '../../../../../model';
import { FormInitService, CompanyService, ToastrService } from '../../../../../services';
import { ConfirmationModalComponent, UploadFileModalComponent } from '../../../../../modal';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  @Input() accountGeneralDetails!: UntypedFormGroup;
  @ViewChild('accountDetailsTabs') accountDetailsTabs: MdbTabsComponent;
  @Input() statesList: any;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;
  uploadModalRef: MdbModalRef<UploadFileModalComponent> | null = null

  dropdownConstants = CompanyDropdownConstant;
  electronicProviderForm: UntypedFormGroup;
  tpaInformationForm: UntypedFormGroup;
  brokerDetailForm: UntypedFormGroup;
  clientReportingForm: UntypedFormGroup;
  assignedAccounManagerForm: UntypedFormGroup;
  infoLoading: boolean = true;
  infoSaving: boolean = false;
  uploadingLogo: boolean = false;
  dbaName: string = '';


  constructor(private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private cd: ChangeDetectorRef,
    private formInit: FormInitService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private modalService: MdbModalService,
    public sanitizer: DomSanitizer) {
    this.electronicProviderForm = this.formInit.createFormGroup(CompanyModel.setElectronicFilesProvider()),
      this.tpaInformationForm = this.formBuilder.group({
        tpaInformation: this.formBuilder.array([])
      }),
      this.brokerDetailForm = this.formBuilder.group({
        brokerInfo: this.formBuilder.array([])
      }),
      this.clientReportingForm = this.formBuilder.group({
        clientReportingInfo: this.formBuilder.array([])
      }),
      this.assignedAccounManagerForm = this.formInit.createFormGroup(CompanyModel.setAssignedAccountManager())
  }

  ngAfterViewInit() {
    this.accountDetailsTabs.setActiveTab(2);
    this.infoLoading = false;
    this.cd.detectChanges();
  }

  ngOnInit(): void {
  }

  onTabChange(event: MdbTabChange): void {
    if (this.accountGeneralDetails.value.id) {
      this.infoLoading = true;
      switch (event.index) {
        case 0:
          this.parentComponent.getCompanyById(this.accountGeneralDetails.value.id);
          break;
        case 1:
          this.getElectronicFilesProvider();
          break;
        case 2:
          this.getTPAInformation();
          break;
        case 3:
          this.getBrokerDetails();
          break;
        case 4:
          this.getClientReports();
          break;
        case 5:
          this.getAccountManager();
          break;
      }
    }
  }

  getElectronicFilesProvider() {
    this.companyService.getElectronicFilesProviderByCompany(this.accountGeneralDetails.value.id).pipe(finalize(() => {
      this.infoLoading = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        this.electronicProviderForm.patchValue(response.data);
        if (response.data.contactInfo?.length) {
          let formArray = this.getFormArray(this.electronicProviderForm, 'contactInfo');
          formArray.clear();
          response.data.contactInfo.forEach((x: any) => {
            this.addContactForms(this.electronicProviderForm, 'contactInfo', x)
          });
        }
      }
    })
  }

  getTPAInformation() {
    this.companyService.getTPAInformationByCompany(this.accountGeneralDetails.value.id).pipe(finalize(() => {
      this.infoLoading = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        if (response.data.length) {
          let formArray = this.getFormArray(this.tpaInformationForm, 'tpaInformation');
          formArray.clear();
          response.data.forEach((x: any) => {
            this.addInfoForms(this.tpaInformationForm, 'tpaInformation', x)
          });
        }
      }
    })
  }

  getBrokerDetails() {
    this.companyService.getBrokerDetailsByCompany(this.accountGeneralDetails.value.id).pipe(finalize(() => {
      this.infoLoading = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        if (response.data.length) {
          let formArray = this.getFormArray(this.brokerDetailForm, 'brokerInfo');
          formArray.clear();
          response.data.forEach((x: any) => {
            this.addBrokerForm(this.brokerDetailForm, 'brokerInfo', x)
          });
        }
      }
    })
  }

  getClientReports() {
    this.companyService.getClientReportingByCompany(this.accountGeneralDetails.value.id).pipe(finalize(() => {
      this.infoLoading = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        if (response.data.length) {
          let formArray = this.getFormArray(this.clientReportingForm, 'clientReportingInfo');
          formArray.clear();
          response.data.forEach((obj: any) => {
            this.addClientReportForm(this.clientReportingForm, 'clientReportingInfo', obj);
          });
        }
      }
    })
  }

  getAccountManager() {
    this.companyService.getAccountManagerByCompany(this.accountGeneralDetails.value.id).pipe(finalize(() => {
      this.infoLoading = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        if (response.data?.assignedAccountManager?.length) {
          let formArray = this.getFormArray(this.assignedAccounManagerForm, 'assignedAccountManager');
          formArray.clear();
          response.data.assignedAccountManager.forEach((x: any) => {
            this.addContactForms(this.assignedAccounManagerForm, 'assignedAccountManager', x)
          });
        }
        if (response.data?.assignedAdvocate?.length) {
          let formArray = this.getFormArray(this.assignedAccounManagerForm, 'assignedAdvocate');
          formArray.clear();
          response.data.assignedAdvocate.forEach((x: any) => {
            this.addContactForms(this.assignedAccounManagerForm, 'assignedAdvocate', x)
          });
        }
      }
    })
  }

  saveElectronicFileProvider() {
    this.companyService.editElectronicFilesProviderByCompany(this.accountGeneralDetails.value.id, this.electronicProviderForm.value).pipe(finalize(() => {
      this.infoSaving = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        this.toastrService.showSuccess(response.message);
        this.electronicProviderForm.patchValue(response.data);
      }
    })
  }

  saveTPAInformation() {
    const formArray = this.getFormArray(this.tpaInformationForm, 'tpaInformation');
    for (let i = 0; i < formArray.length; i++) {
      const formControl = formArray.controls[i] as UntypedFormGroup;
      const webContactInfoArray: any = this.getFormArray(formControl, 'webContactInfo')
      for (let j = 0; j < webContactInfoArray.length; j++) {
        let webControls = webContactInfoArray.controls[j] as UntypedFormGroup;
        if (!webControls.controls['file'].value?.length) {
          webControls.controls['file'].setValue([])
        }
      }

    }
    this.companyService.editTPAInformationByCompany(this.accountGeneralDetails.value.id, this.tpaInformationForm.value.tpaInformation).pipe(finalize(() => {
      this.infoSaving = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        this.toastrService.showSuccess(response.message);
        if (response.data.length) {
          let formArray = this.getFormArray(this.tpaInformationForm, 'tpaInformation');
          formArray.clear();
          response.data.forEach((x: any) => {
            this.addInfoForms(this.tpaInformationForm, 'tpaInformation', x)
          });
        }
      }
    })
  }

  saveBrokerDetails() {
    this.companyService.editBrokersInformationByCompany(this.accountGeneralDetails.value.id, this.brokerDetailForm.value.brokerInfo).pipe(finalize(() => {
      this.infoSaving = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        this.toastrService.showSuccess(response.message);
        this.brokerDetailForm.patchValue(response.data);
      }
    })
  }

  saveClientReportDetails() {
    this.companyService.editClientReportingByCompany(this.accountGeneralDetails.value.id, this.clientReportingForm.value.clientReportingInfo).pipe(finalize(() => {
      this.infoSaving = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        this.toastrService.showSuccess(response.message);
        let formArray = this.getFormArray(this.clientReportingForm, 'clientReportingInfo');
        formArray.clear();
        if (response.data) {
          response.data.forEach((obj: any) => {
            this.addClientReportForm(this.clientReportingForm, 'clientReportingInfo', obj);
          });
        }
      }
    })
  }

  saveAccountManagerDetails() {
    this.companyService.editAccountManageByCompany(this.accountGeneralDetails.value.id, this.assignedAccounManagerForm.value).pipe(finalize(() => {
      this.infoSaving = false;
    })).subscribe((response: any) => {
      if (response && response.data) {
        this.toastrService.showSuccess(response.message);
        // this.add.patchValue(response.data);
      }
    })
  }

  getControl(formControl: any, controlName: string): UntypedFormGroup {
    return formControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(formControl: any, arrayName: string): UntypedFormArray {
    return formControl.controls[arrayName] as UntypedFormArray;
  }

  initContactForms(value?: any, additionalControlName?: any) {
    const formGroupConfig: any = {
      contactName: [value?.contactName],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
      contactId: [value?.contactId]
    }
    if (additionalControlName) {
      formGroupConfig[additionalControlName] = (value ? value[additionalControlName] : (additionalControlName !== 'file' ? '' : []));
    }
    return this.formBuilder.group(formGroupConfig);
  }

  initAccountManagerForm(value?: any) {
    return this.formBuilder.group({
      name: [value?.name],
      role: [value?.role],
      phone: [value?.phone],
      extension: [value?.extension],
      email: [value?.email],
      assignedId: [value?.assignedId]
    });
  }

  initReportReceiptInfo(value?: any) {
    return this.formBuilder.group({
      // reportName: [value?.reportName],
      reportRecipientName: [value?.reportRecipientName],
      reportRecipientEmail: [value?.reportRecipientEmail],
      recipientId: [value?.recipientId]

    });
  }

  initTPAInformation(value?: any) {
    return this.formInit.createFormGroup(CompanyModel.setTPAInformation(value));
  }

  initBrokerDetailForm(value?: any) {
    return this.formInit.createFormGroup(CompanyModel.setBrokerDetailnformation(value));
  }

  initClientReportingForm(value?: any) {
    return this.formInit.createFormGroup(CompanyModel.setClientReporting(value));
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newTPA = this.initTPAInformation(value);
    this.datePickerClosed(newTPA.controls['tpaEndDate'].value, newTPA, 'tpaEndDate');
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newTPA) : formArray.insert(0, newTPA);
    if (value?.customerServiceContactInfo.length) {
      value?.customerServiceContactInfo.forEach((x: any) => {
        this.addContactForms(newTPA, 'customerServiceContactInfo', x)
      });
    }
    if (value?.webContactInfo.length) {
      value?.webContactInfo.forEach((x: any) => {
        this.addContactForms(newTPA, 'webContactInfo', x)
      });
    }
  }

  addBrokerForm(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newBroker = this.initBrokerDetailForm(value);
    this.datePickerClosed(newBroker.controls['endDate'].value, newBroker, 'endDate');
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newBroker) : formArray.insert(0, newBroker);
    if (value?.brokerDetails.length) {
      value?.brokerDetails.forEach((x: any) => {
        this.addContactForms(newBroker, 'brokerDetails', x)
      });
    }
  }

  addClientReportForm(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newClientReport = this.initClientReportingForm(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newClientReport) : formArray.insert(0, newClientReport);
    if (value?.reportReceipentInfo.length) {
      value?.reportReceipentInfo.forEach((x: any) => {
        this.addContactForms(newClientReport, 'reportReceipentInfo', x)
      });
    }
  }

  async addContactForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    let newContactInfo = await this.getNewFormControl(arrayName, value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newContactInfo) : formArray.insert(0, newContactInfo);
  }

  getNewFormControl(arrayName: any, value?: any) {
    switch (arrayName) {
      case 'webContactInfo':
        return this.initContactForms(value, 'file');
      case 'brokerDetails':
        return this.initContactForms(value, 'title');
      case 'assignedAccountManager':
      case 'assignedAdvocate':
        return this.initAccountManagerForm(value);
      case 'reportReceipentInfo':
        return this.initReportReceiptInfo(value);
      case 'customerServiceContactInfo':
        return this.initContactForms(value);
      default  :
        return this.initContactForms(value, 'fileType');
    }
  }

  delete(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    item.loading = true;
    switch (arrayName) {
      case 'contactInfo':
        if (item.contactId) {
          this.companyService.deleteFileContactDetails(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'tpaInformation':
      case 'brokerInfo':
      case 'clientReportingInfo':
        if (item.tpaId || item.brokerId || item.clientReportId) {
          this.openConfirmationModal(index, arrayName, item, formControlName);
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'customerServiceContactInfo':
        if (item.contactId) {
          this.companyService.deleteTPACustomerService(this.parentComponent.currentCompanyId, item, formControlName.controls['tpaId'].value).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'webContactInfo':
        if (item.contactId) {
          this.companyService.deleteTpaWebPortal(this.parentComponent.currentCompanyId, item, formControlName.controls['tpaId'].value).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'brokerDetails':
        if (item.contactId) {
          this.companyService.deleteBrokerContact(this.parentComponent.currentCompanyId, item, formControlName.controls['brokerId'].value).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'reportReceipentInfo':
        if (item.recipientId) {
          this.companyService.deleteReportReceipient(this.parentComponent.currentCompanyId, item, formControlName.controls['clientReportId'].value).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'assignedAccountManager':
        if (item.assignedId) {
          this.companyService.deleteAssignedAccountManager(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'assignedAdvocate':
        if (item.assignedId) {
          this.companyService.deleteAssignedAdvocateManager(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
    }
  }

  saveForms(form: any) {
    if (this.accountGeneralDetails.value.id) {
      this.infoSaving = true;
      switch (form) {
        case 'fileProvider':
          this.saveElectronicFileProvider();
          break;
        case 'tpaInformation':
          this.saveTPAInformation();
          break;
        case 'brokerInfo':
          this.saveBrokerDetails();
          break;
        case 'clientReportingInfo':
          this.saveClientReportDetails();
          break
        case 'assignedAccountManager':
          this.saveAccountManagerDetails();
      }
    }
  }

  openConfirmationModal(index: number, arrayName: any, item: any, formControlName: any) {
    this.modalRef = this.modalService.open(ConfirmationModalComponent, {
      modalClass: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        modalTitle: 'Delete Confirmation',
        modalBody: `Are you sure you want to delete <strong> ${item.tpaName || item.brokerAgencyName || item.frequency || 'this item'} </strong>?`,
      }
    });
    this.modalRef.onClose.subscribe((message: any) => {
      if (message == 'confirmDeactivate') {
        let control = <UntypedFormArray>formControlName.controls[arrayName];
        control.removeAt(index);
        this.saveForms(arrayName);
      }
    })
  }

  async openUploadFileModal(contactForm?: any, parentForm?: any) {
    let fileArray: any = []
    if (contactForm && parentForm) {
      contactForm.uploading = true;
      fileArray = await this.getTpaFileList(contactForm, parentForm);
      this.uploadModalRef = this.modalService.open(UploadFileModalComponent, {
        modalClass: 'modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'File Upload',
          tpaInfo: {
            tpaFileList: fileArray,
            companyId: this.parentComponent.currentCompanyId,
            contactForm: contactForm,
            parentForm: parentForm,
          }
        }
      });
      this.uploadModalRef.onClose.subscribe((filesArray: any) => {
        if (filesArray.length) {
          contactForm.uploading = true;
          this.uploadFiles(filesArray, contactForm, parentForm);
        }
      })
    }
    else {
      this.uploadModalRef = this.modalService.open(UploadFileModalComponent, {
        modalClass: 'modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Upload Logo',
        }
      })
      this.uploadModalRef.onClose.subscribe((filesArray: any) => {
        if (filesArray?.length && filesArray[0].contentType.includes('image')) {
          this.uploadingLogo = true;
          this.uploadLogo(filesArray[0]);
        }
      })
    }
  }

  handleAkaEnter(event: any) {
    if (event.target.value) {
      if (this.accountGeneralDetails.value.akaName.length) {
        if (!this.accountGeneralDetails.value.akaName?.find(((x: string) => ((x).toLowerCase() === (event.target.value).toLowerCase())))) {
          this.accountGeneralDetails.value.akaName.push(event.target.value);
        }
      }
      else {
        this.accountGeneralDetails.value.akaName.push(event.target.value);
      }
    }
    this.dbaName = '';
  }

  deleteAKAName(akaName: string) {
    if (akaName) {
      this.accountGeneralDetails.value.akaName = this.accountGeneralDetails.value.akaName.filter((x: string) => (x).toLowerCase() !== (akaName).toLowerCase());
      this.accountGeneralDetails.controls['akaName'].setValue(this.accountGeneralDetails.value.akaName.filter((x: string) => (x).toLowerCase() !== (akaName).toLowerCase()));
    }
  }

  datePickerClosed(date: any, fromValue: any, controlName: string) {
    if ((date && date.target) ? (date.target.value instanceof Date) : date instanceof Date) {
      fromValue.controls[controlName].setValue(date);
    }
    else {
      fromValue.controls[controlName].setValue('');

    }
  }

  checkDate(event: any, item: any, controlName: string) {
    if (!event.target.value) {
      item.controls[controlName].setValue('');
      this.datePickerClosed('', item, controlName);
    }
    else {
      this.datePickerClosed(event.target.value, item, controlName);
    }
  }

  uploadFiles(filesArray: any = [], contactForm: any, parentForm: any) {
    if (this.parentComponent.currentCompanyId) {
      this.companyService.uploadTpaFile(this.parentComponent.currentCompanyId, contactForm, filesArray, parentForm.tpaId).pipe(finalize(() => {
        contactForm.uploading = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
        }
      })
    }
  }

  uploadLogo(file: any) {
    if (this.parentComponent.currentCompanyId) {
      this.companyService.uploadClientLogo(this.parentComponent.currentCompanyId, file).pipe(finalize(() => {
        this.uploadingLogo = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.parentComponent.getControl('logo').controls['filePath']?.setValue(response.data.filePath);
          this.toastrService.showSuccess(response.message);
        }

      })
    }
  }

  async getTpaFileList(contactForm: any, parentForm: any): Promise<string> {
    return new Promise(async (resolve: any) => {
      if (this.parentComponent.currentCompanyId && parentForm.tpaId) {
        await this.companyService.getTpaFileList(this.parentComponent.currentCompanyId, contactForm, parentForm.tpaId).pipe(finalize(() => {
          contactForm.uploading = false;
        })).subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data);
          }
          else resolve([])
        });
      }
    })
  }
}
