export const ExcelHeaders = {
  CompanyCount: new Map()
    .set('clientLegalName', 'Company Name')
    .set('source', 'Source')
    .set('totalMembers', 'Employees')
    .set('dependencies', 'Dependents')
    .set('total', 'Total'),

  CompanyVariance: new Map()
    .set('clientLegalName', 'Company Name')
    .set('source', 'Source')
    .set('currentCount', 'Current Count')
    .set('previousCount', 'Previous Count')
    .set('variance', 'Variance(%)'),

  BySource: new Map()
    .set('sourceName', 'Source')
    .set('status', 'Status')
    .set('interval', 'Frequency')
    .set('ftpUsername', 'FTP Username')
    .set('ftpLocation', 'FTP Location')
    .set('contactPerson', 'Contact Person')
    .set('contactEmail', 'Contact Email')
    .set('contactPhone', 'Contact Phone')
    .set('lastRunDate', 'Last Run Date')
    .set('fileName', 'File Name'),

  AdminCompany: new Map()
    .set('alithiasCompanyId', 'Alithias Company Id')
    .set('clientLegalName', 'Company Name')
    .set('groupNumber', 'Company Code')
    .set('tpa', 'TPA')
    .set('createdBy', 'Created By')
    .set('createdAt', 'Created At')
    .set('status', 'Status'),

  AdminMember: new Map()
    .set('memberId', 'Member Id')
    .set('firstName', 'FirstName')
    .set('lastName', 'LastName')
    .set('ssn', 'SSN')
    .set('company', 'Company')
    .set('dependent', 'Dependent')
    .set('tpa', 'TPA')
    .set('lastModified', 'Last Modified'),

  AdminNetwork: new Map()
    .set('networkCode', 'Network Code')
    .set('networkName', 'Network Name')
    .set('displayTypeName', 'Network Type')
    .set('source', 'Source')
    .set('groupNetworkName', 'Group Network Name'),

  AdminProcedure: new Map()
    .set('procedureId', 'Procedure ID')
    .set('procedureName', 'Procedure Name')
    .set('category', 'Procedure Type')
    .set('primaryCptCodes', 'CPT Codes')
    .set('createdBy', 'Created By'),

  AdminPricing: new Map()
    .set('providerNPI', 'Provider NPI')
    .set('providerName', 'Provider Name')
    .set('networkName', 'Network')
    .set('practiceAddressLine1', 'Provider Address')
    .set('practiceAddressCity', 'Provider City')
    .set('practiceAddressState', 'Provider State')
    .set('providerZip', 'Provider Zip')
    .set('likeLyCostTimeLine', 'Cost Timeline')
    .set('likelyCost', 'Likely Cost')
    .set('retailPrice', 'Retail Price')
    .set('discount', 'Discount')
    .set('retailPriceEpisodes', 'Retail Price Episode')
    .set('feeScheduleEpisodes', 'Fee Schedule Episode')
    .set('feeScheduleSourceID', 'Fee Schedule Source')
    .set('included', 'Whats Included'),

  IncentiveReport: new Map()
    .set('closedDate', 'Closed Date')
    .set('employeeId', 'Member ID')
    .set('employeeName', 'Employee Name')
    .set('employeeDob', 'Employee DOB')
    .set('patientName', 'Dependent Name')
    .set('providerServiceOption', 'Service Option')
    .set('dateOfService', 'Service Date')
    .set('stateAvg', 'State Average')
    .set('estCost', 'Estimated Cost')
    .set('incentive', 'Incentive To Be Paid')
    .set('providerCostSaving', 'Estimated Cost Savings'),

  LuckyReport: new Map()
    .set('caseId', 'Case Id')
    .set('employeeId', 'Employee Id')
    .set('patientName', 'Patient Name')
    .set('procedure', 'Procedure')
    .set('providerName', 'Provider Name')
    .set('stateAvg', 'State Average')
    .set('estCost', 'Estimated Cost')
    .set('dateOfService', 'Date Of Service')
    .set('providerValue', 'Provider Value')
    .set('totalPeopleCallForService', 'Total Calls')
    .set('totalPeopleUsedService', 'Total Used Service')
    .set('totalPeopleUsedHighValueProvider', 'Total Used High Value Provider')
    .set('totalPeopleUsedLowValueProvider', 'Total Used Low Value Provider'),

  DashboardDetailedReport: new Map()
    .set('user', 'Care Navigator')
    .set('totalInboundCalls', 'Total Inbound Calls')
    .set('totalOutBoundCalls', 'Total Outbound Calls')
    .set('totalCalls', 'Total Calls')
    .set('totalInboundHours', 'Total Inbound Hours')
    .set('totalOutboundHours', 'Total Outbound Hours')
    .set('totalCallHours', 'Total Hours')
    .set('avgTimeInboundInMinutes', 'Avg Time Inbound in minutes')
    .set('avgTimeOutboundInMinutes', 'Avg Time Outbound in minutes')
    .set('totalAvgTimeInMinutes', 'Total Avg Time in Minutes')
    .set('avgInboundCallsPerDay', 'Avg Inbound Calls per day')
    .set('avgOutboundCallsPerDay', 'Avg Outbound Calls per day')
    .set('totalAvgCallsPerDay', 'Total Avg Calls per day')
    .set('benefitSupportInteraction', 'Benefit Support Interaction')
    .set('careNavigationInteraction', 'Care Navigation Interaction')
    .set('careReportSent', 'Care Report(s) Sent')
    .set(
      'numberOfRequestCreatedViaEmail',
      'Number of Request Created Via Email'
    )
    .set(
      'numberOfRequestCreatedViaPhone',
      'Number of Request Created Via Phone'
    )
    .set('totalRequestsCreated', 'Total Requests Created')
    .set('averageWordsPerNote', 'Average Word per Note')
    // .set('userLoginAfter8', 'User Login After 8')
    .set('totalPauseDurationInMinutes', 'Total Pause Duration (in Minutes)')
    .set('totalPauseDurationInHours', 'Total Pause Duration(in Hour)')
    .set('pauseTimePerMonth', 'Pause Time per Month (in %)')
    .set('totalNumberOfPauses', 'Total Number of Pauses'),

  RawFilterData: new Map()
    .set('caseId', 'Case ID')
    .set('companyName', 'Company Name')
    .set('tpa', 'TPA')
    .set('status', 'Case Status')
    .set('lastModified', 'Last Updated')
    .set('tag', 'Tag')
    .set('caseCallType', 'Call Type')
    .set('caseCallRegarding', 'Call Regarding')
    .set('contactVia', 'Contact Via')
    .set('createdDate', 'Created Date')
    .set('closeDate', 'Closed Date')
    .set('createdBy', 'Created By')
    .set('assignedTo', 'Assigned To')
    .set('memberId', 'Employee ID')
    .set('patientFirstName', 'First Name')
    .set('patientLastName', 'Last Name')
    .set('patientDOB', 'DOB')
    .set('patientEmail', 'Email')
    .set('patientCell', 'Cell Phone')
    .set('patientWorkPhone', 'Work Phone')
    .set('patientHomePhone', 'Home Phone')
    .set('addressLine1', 'Address Line 1')
    .set('addressLine2', 'Address Line 2')
    .set('city', 'City')
    .set('state', 'State')
    .set('zip', 'Zip Code')
    .set('procedure', 'Procedure')
    .set('averageStateCost', 'Avg State Cost')
    .set('highestDCOffering', 'Highest DC Offering')
    .set('directContractOffering', 'Lowest DC Offering')
    .set('directContractSavings', 'DC Savings Net of Incentives')
    .set('highestPPOOffering', 'Highest PPO Offering')
    .set('lowestCostPPOOffering', 'Lowest PPO Offering')
    .set('lowestCostPPOSavings', 'PPO Savings Net of Incentives')
    .set('actualSavings', 'Actual Savings')
    .set('incentive', 'Incentive')
    .set('tatStart', 'TAT Start')
    .set('tatEnd', 'TAT End')
    .set('tat', 'TAT')
    .set('totalDays', 'Today Days'),

  SavingsDetailedReport: new Map()
    .set('caseId', 'Case Id')
    .set('closedDate', 'Closed Date')
    .set('procedureName', 'Procedure Name')
    .set('providerStateAverage', 'Average State Cost')
    .set('ppoSavings', 'Savings from Lowest PPO Cost')
    .set('dcSavings', 'Savings from Lowest DC Cost')
    .set('actualSavings', 'Actual Savings')
    .set('incentive', 'Incentive Paid')
    .set('lostOpportunity', 'Lost Opportunity'),

  TagReport : new Map() 
    .set('caseId', 'Case Id')
    .set('patientName', 'Patient Name')
    .set('patientEmail', 'Patient Email')
    .set('patientCellPhone', 'Patient Cell Phone')
    .set('patientAddressLine1', 'Patient Address Line 1')
    .set('patientAddressLine2', 'Patient Address Line 2')
    .set('city', 'City')
    .set('state', 'State')
    .set('zip', 'Zip')
    .set('tag', 'Tags')
    .set('createdDate', 'Created Date'),
  
  AdvocateSatisfactionReport : new Map()
  .set('name', 'Care Advocate')
  .set('noOfServiceRequest', 'Number of Cases')
  .set('avarageTotalScorePercentage', 'Satisfaction Score')
  .set('nps', 'Net Promoter Score'),
  
  ReasonCodeReport: new Map()
    .set('caseId', 'Case Id')
    .set('createdDate', 'Created Date')
    .set('date', 'Closed Date')
    .set('callInType', 'Call In Type')
    .set('callRegarding', 'Call Regarding'),
};

export const ExcelTemplate = {
  GREY_COLUMN: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'd0cece' },
  },
};
