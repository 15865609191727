<div class="px-3">
  <app-report-header (baseAction)="handleBaseAction($event)"></app-report-header>
  <div class="pt-5 mt-5" *ngIf="tagReportLoading">
    <loader></loader>
  </div>
  <div class="pt-4" *ngIf="!tagReportLoading && tagReportList">
    <div class="d-flex align-items-center justify-content-between">
      <p class="fw-bold mb-0 ps-1">Tag Report Preview</p>
      <button type="button" class="btn btn-clear px-4 py-2 border" (click)="exportExcelReport()">
          <i-bs name="download" class="pe-2"></i-bs>Export
      </button>
  </div>
    <div class="col-12 pt-3">
      <table class="table table-report">
        <thead>
          <tr class="fs-2 bordered">
            <th scope="col col-3" class="fw-bold">
              <div class="d-flex align-items-center justify-content-between">
                Case ID
                <!-- <i-bs name="chevron-expand"></i-bs> -->
              </div>
            </th>
            <th scope="col" class="fw-bold">Patient Name</th>
            <th scope="col" class="fw-bold">Email</th>
            <th scope="col" class="fw-bold">Cell</th>
            <th scope="col" class="fw-bold">Address Line 1</th>
            <th scope="col" class="fw-bold">Address Line 2</th>
            <th scope="col" class="fw-bold">Tags</th>
            <th scope="col" class="fw-bold">Created Date</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!tagReportList.length">
            <td colspan="8" class="text-center">No records found.</td>
          </tr>
          <ng-container *ngFor="let item of tagReportList; let i = index">
            <tr class="fs-2">
              <td>{{ item.caseId }}</td>
              <td>{{ item.patientFirstName | titleCase}} {{ item.patientMiddleName| titleCase }} {{item.patientLastName| titleCase}}</td>
              <td><div style="width : 150px !important;" class="text-break">{{ item.patientEmail }}</div></td>
              <td><div class="text-nowrap">{{ item.patientCellPhone | phone}}</div></td>
              <td>{{ item.patientAddressLine1 | titleCase}} 
              <br>{{ item.city | titleCase }} {{ item.city ? ',' : ''}} {{ item.state }} {{ item.zip }}  </td>
              <td>{{ item.patientAddressLine2 | titleCase}}</td>
              <td><div style="width : 150px !important;" class="text-break">{{item.tag}}</div></td>
              <td>{{ item.createdDate | date : 'MM/dd/yyyy' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>