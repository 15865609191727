import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, } from '@angular/forms';

import { FormInitService, CompanyService, ToastrService } from '../../../../../services';
import { ClientServiceTypeModel } from '../../../../../model';
import { CompanyDropdownConstant } from '../../../../../constants';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { finalize } from 'rxjs';


@Component({
  selector: 'app-client-service-type',
  templateUrl: './client-service-type.component.html',
  styleUrls: ['./client-service-type.component.scss']
})
export class ClientServiceTypeComponent implements OnInit {

  @Input() clientServiceType!: any;

  clientServiceTypeForm: UntypedFormGroup;
  dropdownConstants = CompanyDropdownConstant;
  saving: boolean = false;

  constructor(
    private formInit: FormInitService,
    private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService
  ) {
    this.clientServiceTypeForm = this.formInit.createFormGroup(ClientServiceTypeModel.setClientServiceTypeModel())
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.clientServiceType) {
      this.clientServiceTypeForm.patchValue(this.clientServiceType);
      if (this.clientServiceType.clientServiceReportInfo.length) {
        this.clientServiceType.clientServiceReportInfo.forEach((x: any) => {
          let formArray = this.getFormArray(this.clientServiceTypeForm, 'clientServiceReportInfo');
          formArray.clear();
          this.addForms(this.clientServiceTypeForm, 'clientServiceReportInfo', x);
        });
      }
      this.clientServiceType.emailSetup?.contactInfo?.forEach((x: any) => {
        let formArray = this.getFormArray(this.getControl(this.clientServiceTypeForm, 'emailSetup'), 'contactInfo');
        formArray.clear();
        this.addForms(this.getControl(this.clientServiceTypeForm, 'emailSetup'), 'contactInfo', x);
      });
      this.clientServiceType.phoneSetup?.contactInfo?.forEach((x: any) => {
        let formArray = this.getFormArray(this.getControl(this.clientServiceTypeForm, 'phoneSetup'), 'contactInfo');
        formArray.clear();
        this.addForms(this.getControl(this.clientServiceTypeForm, 'phoneSetup'), 'contactInfo', x);
      });
      this.whiteLabelChecked();
    }
  }

  getControl(fromControl: any, controlName: string): UntypedFormGroup {
    return fromControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }

  delete(index: number, item: any, arrayName: any) {
    item.loading = true;
    switch (arrayName) {
      case 'clientServiceReportInfo':
        let control = <UntypedFormArray>this.clientServiceTypeForm.controls[arrayName];
        if (item.contactId) {
          this.companyService.deleteClientContact(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          control.removeAt(index);
        }
        break;
      case 'contactInfo':
        let arr = <UntypedFormArray>this.clientServiceTypeForm.controls['phoneSetup'];
        if (item.contactId) {
          this.companyService.deletePhoneSetup(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              (arr.get('contactInfo') as UntypedFormArray).removeAt(index)
              this.toastrService.showSuccess(response.message);
            }
          })
        }
        else {
          (arr.get('contactInfo') as UntypedFormArray).removeAt(index)
        }
        break;
      case 'emailSetup':
        let arr2 = <UntypedFormArray>this.clientServiceTypeForm.controls['emailSetup'];
        if (item.contactId) {
          this.companyService.deleteEmailSetup(this.parentComponent.currentCompanyId, item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              (arr2.get('contactInfo') as UntypedFormArray).removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
        } else {
          (arr2.get('contactInfo') as UntypedFormArray).removeAt(index);
        }
        break;
    }
  }

  addForms(controlName: any, formArrayName: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initContactDetails(value);
    formArray = this.getFormArray(controlName, formArrayName);
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails)
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      contactName: [value?.contactName],
      contactTitle: [value?.contactTitle],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email],
      contactId: [value?.contactId]
    })
  }

  saveClientServiceType() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService.editClientServiceTypeByCompany(this.parentComponent.currentCompanyId, this.clientServiceTypeForm.value).pipe(finalize(() => {
        this.saving = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          if (response.data.clientServiceReportInfo.length) {
            let formArray = this.getFormArray(this.clientServiceTypeForm, 'clientServiceReportInfo');
            formArray.clear();
            response.data.clientServiceReportInfo.forEach((x: any) => {
              this.addForms(this.clientServiceTypeForm, 'clientServiceReportInfo', x);
            });
          }
          if (response.data.emailSetup?.contactInfo.length) {
            let formArray = this.getFormArray(this.getControl(this.clientServiceTypeForm, 'emailSetup'), 'contactInfo');
            formArray.clear();
            response.data.emailSetup?.contactInfo.forEach((x: any) => {
              this.addForms(this.getControl(this.clientServiceTypeForm, 'emailSetup'), 'contactInfo', x);
            });
          }
          if (response.data.phoneSetup?.contactInfo.length) {
            let formArray = this.getFormArray(this.getControl(this.clientServiceTypeForm, 'phoneSetup'), 'contactInfo');
            formArray.clear();
            response.data.phoneSetup?.contactInfo.forEach((x: any) => {
              this.addForms(this.getControl(this.clientServiceTypeForm, 'phoneSetup'), 'contactInfo', x);
            });
          }
        }
      })
    }
  }

  whiteLabelChecked() {
    if(!this.clientServiceTypeForm.controls['whiteLabel'].value) {
      this.clientServiceTypeForm.controls['identifiedAs'].setValue('Alithias');
      this.clientServiceTypeForm.controls['memberServicePhone'].setValue('8558438783');
      this.clientServiceTypeForm.controls['serviceEmail'].setValue('Ask@MyCareAdvocate.com');
      this.clientServiceTypeForm.controls['identifiedAs'].disable();
      this.clientServiceTypeForm.controls['memberServicePhone'].disable();
      this.clientServiceTypeForm.controls['serviceEmail'].disable();

    }
    else{
      this.clientServiceTypeForm.controls['identifiedAs'].enable();
      this.clientServiceTypeForm.controls['memberServicePhone'].enable();
      this.clientServiceTypeForm.controls['serviceEmail'].enable();

    }
  }
}
