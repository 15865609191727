import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class ValueBasedContractModel {
    static setValueBasedContractModel(value? :any){
        let model : FormFields[] = [
            {
                name: "valueBasedId",
                id: "valueBasedId",
                controlType: 'input',                        
                controlName: "valueBasedId",
                value: value?.valueBasedId || null
            },
            {
                name: "contractBenefitsType",
                id: "contractBenefitsType",
                controlType: 'input',                        
                controlName: "contractBenefitsType",
                value: value?.contractBenefitsType || ''
            },
            {
                name: 'vendorPortalUrl',
                id: 'vendorPortalUrl',
                controlType: 'input',
                controlName: 'vendorPortalUrl',
                required: true,
                value: value?.vendorPortalUrl || ''
            },
            {
                name: "vendorName",
                id: "vendorName",
                controlType: 'input',                        
                controlName: "vendorName",
                required: true,
                value: value?.vendorName || ''
            },
            {
                controlType: 'network',
                controlName: 'network',
                subFields: [{
                    name: 'id',
                    id: 'id',
                    controlType: 'input',
                    controlName: 'id',
                    value: value?.network?.id || null
                },
                {
                    name: 'name',
                    id: 'name',
                    controlType: 'input',
                    controlName: 'name',
                    value: value?.network?.name || null
                },
                {
                    name: 'code',
                    id: 'code',
                    controlType: 'input',
                    controlName: 'code',
                    value: value?.network?.code || null
                },]
            },
            {
                name: "networkLimitation",
                id: "networkLimitation",
                controlType: 'input',                        
                controlName: "networkLimitation",
                required: true,
                value: value?.networkLimitation || ''
            },
            {
                name: 'vendorPhone',
                id: 'vendorPhone',
                controlType: 'input',
                controlName: 'vendorPhone',
                value: value?.vendorPhone || null
            },
            {
                name: 'vendorEmail',
                id: 'vendorEmail',
                controlType: 'input',
                controlName: 'vendorEmail',
                value: value?.vendorEmail || ''
            },
            {
                name: 'startDate',
                id: 'startDate',
                controlType: 'input',
                controlName: 'startDate',
                value: value?.startDate ? new Date(value?.startDate) : null
            },
            {
                name: 'endDate',
                id: 'endDate',
                controlType: 'input',
                controlName: 'endDate',
                value: value?.endDate ? new Date(value?.endDate) : null
            },
            {
                controlType: 'vendorDetails',
                controlName: 'vendorDetails',
                name: 'vendorDetails',
                id: 'vendorDetails',
                value: value?.vendorDetails || [],
                formArray:[]
            },
            {
                controlType: 'valueBasedContractBenefitsIncentive',
                controlName: 'valueBasedContractBenefitsIncentive',
                subFields: [{
                    name: 'deductibleWaived',
                    id: 'deductibleWaived',
                    controlType: 'input',
                    controlName: 'deductibleWaived',
                    value: value?.valueBasedContractBenefitsIncentive?.deductibleWaived || false
                },
                {
                    name: 'coInsuranceWaived',
                    id: 'coInsuranceWaived',
                    controlType: 'input',
                    controlName: 'coInsuranceWaived',
                    value: value?.valueBasedContractBenefitsIncentive?.coInsuranceWaived || false
                },
                {
                    name: 'cashIncentive',
                    id: 'cashIncentive',
                    controlType: 'input',
                    controlName: 'cashIncentive',
                    value: value?.valueBasedContractBenefitsIncentive?.cashIncentive || false
                },
                {
                    name: 'hsaPlanMinimum',
                    id: 'hsaPlanMinimum',
                    controlType: 'input',
                    controlName: 'hsaPlanMinimum',
                    value: value?.valueBasedContractBenefitsIncentive?.hsaPlanMinimum || false
                },
                {
                    name: 'accountNotes',
                    id: 'accountNotes',
                    controlType: 'input',
                    controlName: 'accountNotes',
                    value: value?.valueBasedContractBenefitsIncentive?.accountNotes || '',
                },
                {
                    name: 'individualMinimum',
                    id: 'individualMinimum',
                    controlType: 'input',
                    controlName: 'individualMinimum',
                    value: value?.valueBasedContractBenefitsIncentive?.individualMinimum || '',
                },
                {
                    name: 'familyMinimum',
                    id: 'familyMinimum',
                    controlType: 'input',
                    controlName: 'familyMinimum',
                    value: value?.valueBasedContractBenefitsIncentive?.familyMinimum || '',
                }
                ]
            }

        ]
        return model;
    } 
}