<div class="modal-header">
  <h6 class="modal-title fw-bold" id="exampleModalLabel">{{ modalTitle }}</h6>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close('close')"></button>
</div>
<div class="modal-body px-2" *ngIf="action !=='assignCompany'">
  <form #userForm="ngForm">
    <div class="md-form">
      <div class="d-flex form-inline">
        <div class="col-4 px-2">
          <label for="firstName" class="form-label">First Name*</label>
          <div class="input-group mb-3">
            <input mdbInput type="text" [pattern]="textFormat" class="form-control px-3" id="firstName"
              aria-describedby="basic-addon3" [(ngModel)]="userModel.firstName" name="firstName" #firstName="ngModel"
              required />
          </div>
        </div>
        <div class="col-4 px-2">
          <label for="middleName" class="form-label">Middle Name</label>
          <div class="input-group mb-3">
            <input mdbInput type="text" [pattern]="textFormat" class="form-control px-3" id="middleName"
              aria-describedby="basic-addon3" [(ngModel)]="userModel.middleName" name="middleName"
              #middleName="ngModel" />
          </div>
        </div>
        <div class="col-4 px-2">
          <label for="lastName" class="form-label">Last Name*</label>
          <div class="input-group mb-3">
            <input mdbInput type="text" [pattern]="textFormat" class="form-control px-3" id="lastName"
              aria-describedby="basic-addon3" [(ngModel)]="userModel.lastName" name="lastName" #lastName="ngModel"
              required />
          </div>
        </div>
      </div>
    </div>
    <div class="md-form px-2">
      <label for="email" class="form-label">Email*</label>
      <div class="input-group input-group-lg mb-3">
        <input mdbInput type="text" [pattern]="mailformat" class="form-control px-3" id="email"
          aria-describedby="basic-addon3" [disabled]="action == 'editUser'" required [(ngModel)]="userModel.email"
          name="email" #email="ngModel" />
      </div>
    </div>
    <div class="md-form px-2">
      <label for="phoneNumber" class="form-label">Phone Number</label>
      <div class="input-group input-group-lg mb-3">
        <input mdbInput type="text" class="form-control px-3" id="phoneNumber" aria-describedby="basic-addon3"
          mask="(000) 000-0000" [(ngModel)]="userModel.phoneNumber" name="phoneNumber" #phoneNumber="ngModel" />
      </div>
    </div>
    <div class="md-form px-2">
      <label for="password" class="form-label">Roles</label>
      <div class="mb-3">
        <ng-select [(ngModel)]="userModel.roles" class="form-control p-0" [items]="roles" name="roles" required
          bindLabel="name" bindValue="roleId" [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false"
          [searchable]="true">
          <!-- <ng-option *ngFor="let role of roles" [value]="role.value">{{role.label}}</ng-option> -->
          <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
              class="form-check-input" />
            <label class="ps-2">{{ item.name }}</label>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </form>
  <div class="modal-footer pb-0">
    <button type="button" class="btn btn-gradient text-white" (click)="handleSend()" [disabled]="userForm.invalid">
      Save
    </button>
  </div>
</div>
<div class="modal-body px-2" *ngIf="action =='assignCompany'">
  <div class="md-form px-2">
    <label for="name" class="form-label pe-1">User</label> <div *ngIf="loading" class="spinner-border spinner-border-sm text-gray-main" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="input-group input-group-lg mb-3">
      <input mdbInput type="text" [pattern]="mailformat" class="form-control px-3" id="name"
        aria-describedby="basic-addon3" disabled required [(ngModel)]="userFullName" name="name" #name="ngModel" />
    </div>
  </div>
  <div class="md-form px-2 assign-company-dropdown">
    <label for="password" class="form-label">Company</label>
    <div class="mb-3">
      <ng-select [(ngModel)]="userModel.companies" class="form-control p-0" [items]="companyToggleList" name="comp" required
        bindLabel="clientLegalName" [selectableGroupAsModel]="false" [multiple]="true" [closeOnSelect]="false"
        [searchable]="true" (search)="searchCompany($event)" (add)="selectCompany($event)" [clearSearchOnAdd]="true" notFoundText="Type to search" typeToSearchText="Type to search"> 
        <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{ index }}" name="tag-{{ index }}" type="checkbox" [ngModel]="item$.selected"
            class="form-check-input" />
          <label class="ps-2">{{ item.clientLegalName }}</label>
        </ng-template>
      </ng-select>
    </div>
    <div class="modal-footer pb-0">
      <button type="button" class="btn btn-gradient text-white" (click)="handleSend()" [disabled]="!userModel.companies?.length">
        Save
      </button>
    </div>
  </div>
</div>