<div class="general pt-3">
  <div class="d-flex align-items-center pt-3">
    <p class="fw-bold mb-0 ps-2 pe-2">Alerts</p>
    <!-- <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
      <span class="visually-hidden">Loading...</span>
    </div> -->
  </div>
  <app-search-bar [searchConfig]="searchConfig" [searchList]="alerts"
    (baseAction)="openAlertManagemententModel(null, 'addAlerts')">
  </app-search-bar>
  <div class="total-div pt-3 pe-3">
    <p>Total Results: {{alerts.length || 0}}</p>
  </div>
  <div class="pt-5 mt-5" *ngIf="parentComponent.loadingGeneralDetails">
    <loader></loader>
  </div>
  <div class="table-responsive"  *ngIf="!parentComponent.loadingGeneralDetails">
    <table class="table table-striped table-reponsive mb-0">
      <thead>
        <tr>
          <th class="fw-bold">Alert Type</th>
          <th class="fw-bold" style="width: 400px;" >Benefit Detail</th>
          <th class="fw-bold" style="width: 50px;">Alert</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="table-alert">
        <tr class="text-center" *ngIf="!alerts.length && !parentComponent.loadingGeneralDetails">
          <td colspan="9" class="fw-bold">Alerts not found.</td>
        </tr>
        <ng-container *ngIf="alerts.length">
          <tr class="text-left" *ngFor="let alert of alerts | filterKeyword:searchConfig.formValue; let i = index">
            <td style="padding:16px !important;"><span class="">{{ alert.alertTypeName }}</span> </td>
            <td style="padding:16px !important;"><span class=" text-break" >{{ alert.benefitDetail }}</span></td>
            <td style="padding:16px !important;">
              <div>
                <span class="">
                  <a href="javascript:void(0)">
                    <i-bs [name]="!alert.alert ? 'toggle-off' : 'toggle-on'" [ngClass]="{
                        'text-success': alert.alert,
                        'text-gray-main': !alert.alert
                      }" width="32" height="32"></i-bs>
                  </a>
                </span>
              </div>
            </td>
            <td style="padding:16px !important;">
              <div mdbDropdown class="dropdown">
                <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                  aria-expanded="false">
                  <i-bs name="three-dots-vertical"></i-bs>
                </button>
                <ul autoClose="true" mdbDropdownMenu class="dropdown-menu dropdown-menu-end shadow">
                  <li>
                    <button class="dropdown-item" type="button"
                      (click)="openAlertManagemententModel(alert, 'editAlert')">
                      Edit
                    </button>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button" (click) ="openConfirmationModel(i, alert)">Delete</button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>