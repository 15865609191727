import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { finalize } from 'rxjs';
import { ExcelHeaders } from 'src/app/constants';
import { PhoneFormatPipe, TitleCasePipe } from 'src/app/pipe';
import { CommonListService, ExcelService, ReportService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';

@Component({
  selector: 'app-tag-report',
  templateUrl: './tag-report.component.html',
  styleUrls: ['./tag-report.component.scss'],
})
export class TagReportComponent implements OnInit {
  tagReportList: any = false;
  tagReportLoading: boolean = false;
  filterParams:any;

  constructor(
    private reportService: ReportService,
    public commonListService: CommonListService,
    private excelService :  ExcelService,
    private phonePipe : PhoneFormatPipe,
    private  titleCasePipe : TitleCasePipe
  ) { }

  async ngOnInit() {
  }

  getTagReport(params:any) {
    this.reportService
      .getTagReport(params)
      .pipe(
        finalize(() => {
          this.tagReportLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.tagReportList = response.data;
        }
      });
  }


  handleBaseAction(event: any) {
    if (event !== 'reset') {
      this.tagReportLoading = true;
      let company = event.company.map((x: any) => x.alithiasCompanyId).join(',');
      // let tag = event.tag.map((x: any) => {
      //   return x.name.includes('#') ? x.name.replace('#', '').trim() : x.name.trim();
      // }).join(',');
      let tag = event.tag.map((x: any) => x.name)
      this.filterParams= event;
      this.getTagReport({companyId: company, createdFrom: moment(event.from).format('MM/DD/YYYY'), createdTo: moment(event.to).format('MM/DD/YYYY'), tags: encodeURIComponent(tag)});
    }
    else {
      this.tagReportList = false;
    }
  }

  exportExcelReport() {
    let data = this.tagReportList.map((x:any)=>{
      return {
        ...x, patientName : `${this.titleCasePipe.transform(x.patientFirstName) } ${x.patientMiddleName ? this.titleCasePipe.transform(x.patientMiddleName)  : ''} ${this.titleCasePipe.transform(x.patientLastName) }`,
        createdDate : x.createdDate ? moment(x.createdDate).format('MM/DD/YYYY') : null,
        patientCellPhone : x.patientCellPhone ?  this.phonePipe.transform(x.patientCellPhone) :  x.patientCellPhone,
      }
    })
    let body = {
      header: ExcelHeaders.TagReport,
      data: data,
      reportTitle: 'Tag Report',
      fileName: `Tag Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`,
    };
    this.excelService.exportExcel(body);
  }

}
