import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-pdf-format',
  templateUrl: './report-pdf-format.component.html',
  styleUrls: ['./report-pdf-format.component.scss']
})
export class ReportPdfFormatComponent {
  @Input() reportConstants : any;
  @Input() id:any;
  @Input() reportData:any;
  @Input() filterParams:any;
}
