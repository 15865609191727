import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { FileStatusService, ActivityLogService } from '../../../services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-files-status',
  templateUrl: './files-status.component.html',
  styleUrls: ['./files-status.component.scss']
})
export class FilesStatusComponent implements OnInit {

  searchKeyword: any = null;
  listLoading: boolean = true;
  searchConfig = {
    showAdvancedSearch: false,
    showToggle: false,
    formValue: this.searchKeyword,
    module: 'fileStatus',
    limit : 0
  }
  fileStatusList:any = [];

  constructor(
    private fileStatusService: FileStatusService,
    private activityLogService: ActivityLogService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getFilesStatusList();

  }

  getFilesStatusList(query?:any) {
    this.listLoading= true;
    this.fileStatusService.getFileStatusList(query).pipe(finalize(()=>{
        this.listLoading= false;
    })).subscribe((response:any)=>{
      if(response && response.success) {
        this.fileStatusList = response.data;
        this.fileStatusList.rows = this.fileStatusList.rows.map((x:any)=> {
          return {...x, collapsed: true, filesize: +x.filesize, fileDisplayName : x.filename.split('/')[x.filename.split('/').length-1]}
        })
      }
    })
  }

  toggleCollapse(data:any) {
    data.collapsed = !data.collapsed;
    if(!data.collapsed && !data.fileLogs?.length) {
      data.logsLoading = true;
      this.activityLogService.getActivityLog({module:'etl 2.0', tags: data.filename}).pipe(finalize(()=>{
        data.logsLoading = false;
      })).subscribe((response:any)=>{
        if(response && response.success) {
          data.fileLogs = response.data.data.activities;
        }
      })
    }

  }

  viewRawFile(status:any) {
    status.fileLoading = true;
    const splitValue = status.filename.split("/");
    const newValue = splitValue.slice(3).join("/");
    const bom = '\uFEFF';
    this.fileStatusService.getRawFile(newValue).pipe(finalize(()=>{
      status.fileLoading = false;
    })).subscribe((response:any)=>{
      if(response && response.success) {
        const blob = new Blob([bom +response.data], {type: 'text/csv'});
        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = newValue;
        link.click();
        window.URL.revokeObjectURL(fileURL);
        link.remove();
      }
    })

  }
}
 