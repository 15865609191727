import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';


@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Output() confrimation: EventEmitter<any> = new EventEmitter();

  modalTitle: any = 'Confirmation';
  modalBody: any = '';

  constructor(public modalRef: MdbModalRef<ConfirmationModalComponent>) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.modalRef.close('confirmDeactivate');
  }

}
