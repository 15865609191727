<!-- <div class="upload"> -->
<div class="modal-header">
    <h6 class="modal-title fw-bold" id="exampleModalLabel">Send {{modalTitle}} Report</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <div class="" [formGroup]="sendReportForm">
        <div class="col-12 d-flex">
            <div class="col-2">
                To:
            </div>
            <div class="col-10">
                <div class="input-group mb-3">
                    <input mdbInput type="text" class="form-control" id="to" required aria-describedby="basic-addon3"
                        name="to" formControlName="to" />
                </div>
            </div>
        </div>
        <div class="col-12 d-flex">
            <div class="col-2">
                CC:
            </div>
            <div class="col-10">
                <div class="input-group mb-3">
                    <input mdbInput type="text" class="form-control" id="cc" aria-describedby="basic-addon3" name="cc"
                        formControlName="cc" />
                </div>
            </div>
        </div>
        <div class="col-12 d-flex">
            <div class="col-2">
                BCC:
            </div>
            <div class="col-10">
                <div class="input-group mb-3">
                    <input mdbInput type="text" class="form-control" id="bcc" aria-describedby="basic-addon3"
                        formControlName="bcc" name="bcc" />
                </div>
            </div>
        </div>
        <div class="col-12 d-flex">
            <div class="col-2">
                Subject:
            </div>
            <div class="col-10">
                <div class="input-group mb-3">
                    <input mdbInput type="text" class="form-control" id="subject" formControlName="subject"
                        aria-describedby="basic-addon3" name="subject" />
                </div>
            </div>
        </div>
        <div class="col-12 d-flex">
            <div class="col-2">
                Template:
            </div>
            <div class="col-10 mb-3">
                <ng-select class="form-control p-0" notFoundText="No Result" name="template" required
                    (change)="selectTemplateType($event)" formControlName="template">
                    <ng-option value='' disabled>Select Template</ng-option>
                    <ng-option *ngFor="let option of careReportTemplateList | sort:'asc':'code'"
                        value="{{ option._id }}">
                        {{ option.value }}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-12 d-flex">
            <div class="col-2">
                Message:
            </div>
            <div class="col-10">
                <div class="py-5" *ngIf="loadingMessage">
                    <loader></loader>
                </div>
                <editor [init]="textEditorInit" apiKey="w70x5rbov2iqzridbekyouww5rn4o5p0cbkzgutdb7chk7yb" name="msg"
                    formControlName="message" *ngIf="!loadingMessage">
                </editor>
            </div>
        </div>
        <div *ngIf="modalTitle == 'Savings'">
            <div class="pt-3">
                <!-- <div class="d-flex">
                        <span class="spinner-grow spinner-grow-sm me-2 mt-1" role="status" aria-hidden="true"
                            *ngIf="generating"></span>
                        <p class="mb-2 text-decoration-underline pointer" (click)="viewCareOptionReport()">View New High
                            Value Service Option Report</p>
                    </div> -->
                <div class="d-flex">
                    <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="" name="savings" disabled
                        checked />
                    <label class="form-check-label fs-1">Attach Utilization and Savings Report to the
                        request</label>
                </div>
                <div class="d-flex">
                    <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="" name="incentive"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="savingsAttachment.incentive" />
                    <label class="form-check-label fs-1">Attach Incentive Report to the request</label>
                </div>
                <div class="d-flex">
                    <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="" name="detailed"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="savingsAttachment.savingsDetailed" />
                    <label class="form-check-label fs-1">Attach Detailed Savings Report to the request</label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="modalTitle == 'Care Option'">
        <div class="py-3">
            <div class="d-flex">
                <span class="spinner-grow spinner-grow-sm me-2 mt-1" role="status" aria-hidden="true"
                    *ngIf="generating"></span>
                <p class="mb-2 text-decoration-underline pointer" (click)="viewCareOptionReport()">View New High
                    Value Service Option Report</p>
            </div>
            <div class="d-flex mb-2">
                <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="notifyByText"
                    name="notifyByText" formControlName="notifyByText" (checkboxChange)="notifyByTextChange($event)"/>
                <label class="form-check-label fs-1" for="notifyByText">Notify by text
                    <span *ngIf="reportContent.preferredCommunication != 'Email & Text' ">(Text is not selected in
                        preferred communication.)</span>
                    <span
                        *ngIf="reportContent.preferredCommunication == 'Email & Text' && !reportContent.patientInfo.tel">(Patients
                        contact information not available.)</span></label>
            </div>
            <div class="d-flex mb-2">
                <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="notifyByText" name="" checked
                    disabled />
                <label class="form-check-label fs-1">Attach High Value Service Option Report to the
                    request</label>
            </div>
            <div class="d-flex" *ngIf="reportContent?.employerInfo?.corporateClinicInfo?.length">
                <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="fax" [(ngModel)]="sendFax"
                    name="fax" [disabled]="!reportContent?.employerInfo?.corporateClinicInfo?.length" />
                <label class="form-check-label fs-1" for="fax">Send Clinic Fax</label>
            </div>
        </div>
        <div class="p-3 mb-3 border border-gray-light" *ngIf="sendFax">
            <div class="pb-3">
                <label for="date" class="form-label">Corporate Clinic</label>
                <div class="input-group input-group-lg">
                    <ng-select class="form-control bg-white p-0 border border-gray-light"
                        [items]="reportContent?.employerInfo?.corporateClinicInfo" name="clinic"
                        placeholder="Select Clinic" bindLabel="clinicName" bindValue="primaryCareId"
                        [dropdownPosition]="'bottom'" [(ngModel)]="clinicFax" [selectableGroupAsModel]="false"
                        [closeOnSelect]="true" [searchable]="true" [clearSearchOnAdd]="true"
                        notFoundText="Type to search" typeToSearchText="Type to search"
                        (change)="onClinicSelect($event)" style="min-height: 2.5rem">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-item$="item$"
                            let-index="index" >
                            <div>
                                <label class="pt-3">{{ item.clinicName}}</label>
                            </div>
                            <label class="fs-3">{{item.addressDetail[0]?.city}}, {{item.addressDetail[0]?.state}}
                                {{item.addressDetail[0]?.zip}}</label>
                            <hr class="mb-0 mt-2 text-selected-bg opacity-100">
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="selectedClinic">
                <p class="fw-bold mb-2">{{selectedClinic?.clinicName}}</p>
                <div class="d-flex align-items-center">
                    <i-bs name="geo-alt-fill" class="text-default fs-1"></i-bs>
                    <div class="pb-2">
                        <p class="ps-2 mb-1">{{selectedClinic.addressDetail[0]?.addressLine }}</p>
                        <label class="text-default ps-2 fs-1">{{selectedClinic.addressDetail[0]?.city| titleCase }},
                            {{selectedClinic.addressDetail[0]?.state }}
                            {{selectedClinic.addressDetail[0]?.zip }}
                        </label>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <i-bs name="phone-fill" class="text-default fs-1"></i-bs>
                    <label class="text-default ps-2 fs-1">{{selectedClinic.addressDetail[0]?.fax | phone }}</label>
                </div>
            </div>
        </div>
        <div class="" *ngIf="fileList?.length">
            <p class="fw-bold">Attach Additional Files in Email</p>
            <div class="d-flex" *ngFor="let f of fileList, let i = index">
                <input mdbCheckbox class="form-check-input me-3 col-2" type="checkbox" id="files{{i}}" name="files"
                    (checkboxChange)="onFileSelect(f)" />
                <label class="form-check-label fs-1 pb-3" for="files{{i}}">{{f.name}}</label>
            </div>
        </div>

    </div>
</div>
<!-- </div> -->
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-secondary" (click)="modalRef.close()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="handleSend()" [disabled]="sendReportForm.invalid">
        <span class="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"
            *ngIf="sending"></span>Send</button>
</div>