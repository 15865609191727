<div class="row">
  <div class="col-12">
    <div
      class="spinner d-flex align-items-center justify-content-center text-center"
      *ngIf="loadingZipData"
    >
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="leaflet-map" *ngIf="!loadingZipData">
      <div
        style="height: 100vh"
        leaflet
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
      ></div>
    </div>
  </div>
</div>
