import { Component } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthenticationService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'alithias-portal-UI';

  constructor(private router: Router, private authService: AuthenticationService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd && event.url === '/logout') {
        this.authService.logout();
      }
    })

  }
}
