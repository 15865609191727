<form autocomplete="off" class="onboarding">
  <mdb-tabs
    [vertical]="true"
    #tabs
    class="d-flex align-items-stretch vertical-tab h-100"
    (activeTabChange)="onTabChange($event)"
  >
    <mdb-tab>
      <ng-template mdbTabTitle class="pb-3">
        <span class="d-block" [routerLink]="['/admin/company']">
          <i-bs name="chevron-left"></i-bs> Go back
        </span>
      </ng-template>
    </mdb-tab>
    <!-- account details tab -->
    <mdb-tab title="Account Details" >
      <app-account-details
        [accountGeneralDetails]="companyOnboardingForm"
        [statesList]="statesList"
      ></app-account-details>
    </mdb-tab>
    <!-- billings tab -->
    <mdb-tab title="Billing Information"  [disabled]="!currentCompanyId">
      <app-billings [billings]="billings"></app-billings>
    </mdb-tab>
    <!-- client service type -->
    <mdb-tab title="Client Service Type"  [disabled]="!currentCompanyId">
      <app-client-service-type
        [clientServiceType]="clientServiceType"
      ></app-client-service-type>
    </mdb-tab>
    <!--plan Details tab -->
    <mdb-tab title="Plan Details"  [disabled]="!currentCompanyId">
      <app-plan-details [planDetails]="planDetails"></app-plan-details>
    </mdb-tab>
    <!-- add on services tab -->
    <mdb-tab title="Add on Services" [disabled]="!currentCompanyId">
      <app-add-on-services
        [addOnServices]="addOnServices"
      ></app-add-on-services>
    </mdb-tab>
    <!-- Value Based Benefit tab -->
    <mdb-tab title="Value Based Benefit"  [disabled]="!currentCompanyId" >
      <app-value-based-benefits 
        [valueBasedBenifits]="valueBasedBenifits"
      ></app-value-based-benefits>
    </mdb-tab>
    <!-- Ancillay Benefit Programs tab -->
    <mdb-tab title="Ancillary Benefit Programs"  [disabled]="!currentCompanyId">
      <app-ancillary-benefit-program
        [ancillaryBenifitProgram]="ancillaryBenifitProgram"
      ></app-ancillary-benefit-program>
    </mdb-tab>
    <!-- ----------Preferred Provider----------- -->
    <mdb-tab title="Preferred Provider"  [disabled]="!currentCompanyId">
      <app-preferred-provider [preferredProvider]="preferredProvider">
      </app-preferred-provider>
    </mdb-tab>
    <!-- ----------Medical Plan Detail----------- -->
    <mdb-tab title="Medical Plan Detail" [disabled]="!currentCompanyId">
      <app-medical-plan-details [medicalPlanTypeDetail]="medicalPlanTypeDetail">
      </app-medical-plan-details>
    </mdb-tab>
    <!-- ----------Direct Priamry Care----------- -->
    <mdb-tab title="Direct Primary Care"  [disabled]="!currentCompanyId">
      <app-direct-primary-care [directPrimaryCare]="directPrimaryCare">
      </app-direct-primary-care>
    </mdb-tab>
    <!-- ----------Pharmacy Plan Details----------- -->
    <mdb-tab title="Pharmacy Plan Details"  [disabled]="!currentCompanyId">
      <app-pharmacy-plan-detail [pharmacyPlanDetails]="pharmacyPlanDetails">
      </app-pharmacy-plan-detail>
    </mdb-tab>
    <!-- -----alerts -->
    <mdb-tab title="Alerts"  [disabled]="!currentCompanyId">
      <app-alerts [alerts]="alerts"></app-alerts>
    </mdb-tab>
    <!-- -----tiles -->
    <mdb-tab title="Tiles"  [disabled]="!currentCompanyId">
      <app-tiles [tiles]="tiles"></app-tiles>
    </mdb-tab>
  </mdb-tabs>
</form>
