import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';


import { CompanyService, FormInitService, ToastrService } from '../../../../../services';
import { AddOnServiceModel } from '../../../../../model';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { ConfirmationModalComponent } from 'src/app/modal';



@Component({
  selector: 'app-add-on-services',
  templateUrl: './add-on-services.component.html',
  styleUrls: ['./add-on-services.component.scss']
})
export class AddOnServicesComponent implements OnInit {
  @Input() addOnServices!: any;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;


  addOnServiceForm: UntypedFormGroup;
  saving: boolean = false;

  constructor(private formInit: FormInitService,
    private formBuilder: UntypedFormBuilder,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private modalService: MdbModalService) {
    this.addOnServiceForm = this.formBuilder.group({
      addOnServiceInfo: this.formBuilder.array([])
    })
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.addOnServices?.length) {
      let formArray = this.getFormArray(this.addOnServiceForm, 'addOnServiceInfo');
      formArray.clear();
      this.addOnServices.forEach((x: any) => {
        this.addInfoForms(this.addOnServiceForm, 'addOnServiceInfo', x)
      });
    }
  }

  getControl(fromControl: any, controlName: string): UntypedFormGroup {
    return fromControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }


  delete(index: number, item: any, arrayName: any, formControlName: any) {
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    item.loading = true;
    if(item.contactId){
      switch (arrayName) {
        case 'vendorDetails':
          this.companyService.deleteAddOnServiceContact(this.parentComponent.currentCompanyId,formControlName.controls['addOnServiceId'].value,item).pipe(finalize(() => {
            item.loading = false;
          })).subscribe((response: any) => {
            if (response && response.success) {
              control.removeAt(index);
              this.toastrService.showSuccess(response.message);
            }
          })
          break;
        case 'addOnServiceInfo':
          this.modalRef = this.modalService.open(ConfirmationModalComponent, {
            modalClass: 'modal-dialog-centered',
            ignoreBackdropClick: true,
            data: {
              modalTitle: 'Delete Confirmation',
              modalBody: `Are you sure you want to delete <strong>${item.vendorName}</strong>?`,
            }
          });
          this.modalRef.onClose.subscribe((message: any) => {
            if (message == 'confirmDeactivate') {
              this.saveForms();
              let control = <UntypedFormArray>formControlName.controls[arrayName];
              control.removeAt(index);
            }
          })
          break;
      }
    }
    else{
      control.removeAt(index);
    }

  }


  async addContactForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initContactDetails(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newContactDetails) : formArray.insert(0, newContactDetails);
  }

  saveForms() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService.editAddOnServiceByCompany(this.parentComponent.currentCompanyId, this.addOnServiceForm.value.addOnServiceInfo).pipe(finalize(() => {
        this.saving = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
        }
      })
    }
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newService = this.initAddOnService(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) : formArray.insert(0, newService);
    if (value?.vendorDetails.length) {
      value?.vendorDetails.forEach((x: any) => {
        this.addContactForms(newService, 'vendorDetails', x)
      });
    }
  }

  initContactDetails(value?: any) {
    return this.formBuilder.group({
      contactId: [value?.contactId],
      contactName: [value?.contactName],
      contactType: [value?.contactType],
      phone1: [value?.phone1],
      extension: [value?.extension],
      phone2: [value?.phone2],
      email: [value?.email]
    })
  }

  initAddOnService(value?: any) {
    return this.formInit.createFormGroup(AddOnServiceModel.setAddOnServiceModel(value));
  }

}
