<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (searchLogEmit)="getFilesStatusList($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="listLoading">
        <loader></loader>
    </div>
    <div class="px-0 table-responsive" *ngIf="!listLoading">
        <table class="table pt-5 mb-0">
            <thead>
                <tr>
                    <th scope="col" class="fw-bold ps-3" style="width:250px;">Timestamp</th>
                    <th scope="col" class="fw-bold ps-3" style="width:200px;">TPA Source</th>
                    <th scope="col" class="fw-bold ps-3" style="width:400px;">File Name</th>
                    <th scope="col" class="fw-bold ps-3">File Count</th>
                    <th scope="col" class="fw-bold ps-3">File Size</th>
                    <th scope="col" class="fw-bold ps-3">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!fileStatusList.rows.length && !listLoading" class="text-center">
                    <td colspan="6">File status list not found.</td>
                </tr>
                <ng-container *ngFor="let status of fileStatusList.rows, let i = index">
                    <tr [ngClass]="{'row-striped': i % 2 === 0}">
                        <td class="px-1">
                            <div class="d-flex">
                                <a href="javascript:void(0)" class="pe-2 text-gray-darker align-self-center"
                                    (click)="toggleCollapse(status)" role="button"
                                    [attr.aria-expanded]="!status.collapsed" aria-controls="collapseFileStatus">
                                    <i-bs [name]="status.collapsed ? 'plus-circle' : 'dash-circle'"></i-bs>
                                </a>
                                <span>{{status.created | date: 'MM/dd/yyyy hh:mm a'}}
                                    <!-- <br>{{status.created | date: 'hh:mm a'}} -->
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="text-break">
                                {{status.tpa}}
                            </div>
                        </td>
                        <td>
                            <span class="text-primary" *ngIf="status.fileLoading"><span
                                    class="spinner-border spinner-border-sm me-2" role="status"
                                    aria-hidden="true"></span>Downloading...</span>
                            <a class="text-break pointer" (click)="viewRawFile(status)" title="View"
                                *ngIf="!status.fileLoading">
                                {{status.fileDisplayName}}
                            </a>
                        </td>
                        <td>{{status.totalrecords | number}}</td>
                        <td>{{((status.filesize) * 0.000001) | number : '1.2-2'}} MB</td>
                        <td>
                            <span class="badge badge-success" *ngIf="status.status == 'Completed'">Completed</span>
                            <span class="badge badge-warning" *ngIf="status.status == 'In Progress'">In
                                Progess</span>
                            <span class="badge badge-danger" *ngIf="status.status == 'Error'">Error</span>
                        </td>
                    </tr>
                    <tr style="border:transparent" id="collapseFileStatus" mdbCollapse #fileStatus="mdbCollapse"
                        [collapsed]="status.collapsed">
                        <td colspan="9" style="padding: 0 !important;">
                            <div class="row light-bg p-3" style="max-height:275px !important;">
                                <div style="max-height:240px !important; overflow-y: scroll !important;">
                                    <table class="table table-member table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="fw-bold">Created Date</th>
                                                <th scope="col" class="fw-bold">Log Type</th>
                                                <th scope="col" class="fw-bold">Module</th>
                                                <th scope="col" class="fw-bold">Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="status.logsLoading">
                                                <td colspan="5">
                                                    <loader></loader>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!status.fileLogs?.length && !status.logsLoading">
                                                <td colspan="5">File logs not found.</td>
                                            </tr>
                                            <tr *ngFor="let log of status.fileLogs">
                                                <th scope="row">{{log.createdAt | date: 'MM/dd/yyyy hh:mm a '}}</th>
                                                <td>{{log.log.type}}</td>
                                                <td>{{log.log.module}}</td>
                                                <td>{{log.log.note}}</td>
                                            </tr>
                                            <!-- </ng-template> -->
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </td>

                    </tr>
                </ng-container>

            </tbody>

        </table>
        <p class="py-3 mb-0 fw-bold fs-2" *ngIf="fileStatusList.rows.length">Showing
            {{fileStatusList.count}} of
            {{fileStatusList.count}} {{fileStatusList.count > 1 ? 'results' : 'result'}}</p>

    </div>
</div>