import { Injectable } from "@angular/core";

@Injectable()
export class CustomMessagingModel {
    companies: any = [];
    networks:any = [];
    procedures:any =[];
    procedure:any = [];
    providers:any =[];
    serviceOptions:any =[];
    createdAt: any;
    createdBy: any;
    effectiveEndDate:any = null;
    effectiveStartDate:any =null;
    id:any = null;
    isActive:any;
    message :any =''

}