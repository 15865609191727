import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class ClientServiceTypeModel {
    static setClientServiceTypeModel(){
        let model : FormFields[] = [
            {
                name: "serviceType",
                id: "serviceType",
                controlType: 'input',
                controlName: "serviceType",
                required: true,
                value: ''
            },
            {
                name: 'identifiedAs',
                id: 'identifiedAs',
                controlType: 'input',
                controlName: 'identifiedAs',
                required: true,
                value: ''
            },
            {
                name: 'memberServicePhone',
                id: 'memberServicePhone',
                controlType: 'input',
                controlName: 'memberServicePhone',
                value: null
            },
            {
                name: 'serviceEmail',
                id: 'serviceEmail',
                controlType: 'input',
                controlName: 'serviceEmail',
                value: ''
            },
            {
                controlType: 'clientServiceReportInfo',
                controlName: 'clientServiceReportInfo',
                name: 'clientServiceReportInfo',
                id: 'clientServiceReportInfo',
                value: [],
                formArray:[]
            },
            {
                controlType: 'whiteLabel',
                controlName: 'whiteLabel',
                name: 'whiteLabel',
                id: 'whiteLabel',
                value: false,
            },
            {
                controlType: 'phoneSetup',
                controlName: 'phoneSetup',
                subFields: [{
                    name: 'assignedDid',
                    id: 'assignedDid',
                    controlType: 'input',
                    controlName: 'assignedDid',
                    value: null
                },
                {
                    name: 'seperatePhoneNeeded',
                    id: 'seperatePhoneNeeded',
                    controlType: 'input',
                    controlName: 'seperatePhoneNeeded',
                    value: false
                },
                {
                    name: 'contactInfo',
                    id: 'contactInfo',
                    controlType: 'input',
                    controlName: 'contactInfo',
                    value: [],
                    formArray:[]
                }
                ]
            },
            {
                controlType: 'emailSetup',
                controlName: 'emailSetup',
                subFields: [{
                    name: 'requiresSeparateEmail',
                    id: 'requiresSeparateEmail',
                    controlType: 'input',
                    controlName: 'requiresSeparateEmail',
                    value: false
                },
                {
                    name: 'passThroughEmailAccount',
                    id: 'passThroughEmailAccount',
                    controlType: 'input',
                    controlName: 'passThroughEmailAccount',
                    value: ''
                },
                {
                    name: 'contactInfo',
                    id: 'contactInfo',
                    controlType: 'input',
                    controlName: 'contactInfo',
                    value: [],
                    formArray:[]
                }
                ]
           
            },
            {
                controlType: 'incentiveProgram',
                controlName: 'incentiveProgram',
                subFields: [{
                    name: 'incentivePaymentMethod',
                    id: 'incentivePaymentMethod',
                    controlType: 'input',
                    controlName: 'incentivePaymentMethod',
                    value: ''
                },
                {
                    name: 'customIncentiveProgram',
                    id: 'customIncentiveProgram',
                    controlType: 'input',
                    controlName: 'customIncentiveProgram',
                    value: false
                },
                {
                    name: 'standardIncentiveProgram',
                    id: 'standardIncentiveProgram',
                    controlType: 'input',
                    controlName: 'standardIncentiveProgram',
                    value: false
                }
                ]
           
            }

        ]
        return model;
    } 
}