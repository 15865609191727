export const ReportDropdownConstant = {
  tagDropDown: [
    { name: 'Alliance QP', value: 'Alliance QP' },
    {
      name: 'missing data created record',
      value: 'missing data created record',
    },
    {
      name: 'aurora',
      value: 'aurora',
    },
    {
      name: 'COBRA',
      value: 'COBRA',
    },
    {
      name: 'Covid 19',
      value: 'Covid 19',
    },
  ],
};
