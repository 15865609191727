import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as component from './components'
import { RouteSecure, RoutePublic } from './routes';
import { AuthGuard } from './guards';


const routes: Routes = [
  { path: '', component: component.LayoutLoginComponent, children: RoutePublic },
  { path: '', component: component.LayoutMainComponent, children: RouteSecure, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
