<div class="modal-header">
  <h6 class="modal-title fw-bold" id="exampleModalLabel">{{modalTitle}}</h6>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body" [innerHtml]="modalBody"></div>
<div class="modal-footer" *ngIf="modalTitle !== 'Delete Confirmation'">
  <button type="button" class="btn btn-secondary" (click)="modalRef.close()">
    Cancel
  </button>
  <button type="button" class="btn btn-danger" (click)="onConfirm()">Confirm</button>
</div>

<div class="modal-footer d-flex justify-content-between" *ngIf="modalTitle == 'Delete Confirmation'">
  <div>
    <button type="button" class="btn btn-clear text-primary" (click)="modalRef.close()">
      Cancel
    </button>
  </div>
  <div>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">Delete</button>
  </div>
</div>