import jwt_decode from "jwt-decode";
import { Injectable } from "@angular/core";

@Injectable()
export class DecodeTokenUtil {
  static decodeToken(token:any) {
    try {
      var decoded = jwt_decode(token);
      return decoded;
    }
    catch (ex) {
      return null;
    }
  }
}