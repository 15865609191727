<div class="col-12 px-3">
    <div class="col">
        <div class="col-12">
            <app-report-header  (baseAction)="handleBaseAction($event)"></app-report-header>
        </div>
        <div class="pt-5 mt-5" *ngIf="loading">
            <loader></loader>
        </div>
    </div>
    <div *ngIf="!loading && reportDetails">
        <div class="col-12 py-3">
            <div class="d-flex align-items-center justify-content-between">
                <p class="fw-bold mb-0 ps-1">Dashboard Detailed Report Preview</p>
                <button type="button" class="btn btn-clear px-4 py-2 border" (click)="exportExcelReport()">
                    <i-bs name="download" class="pe-2"></i-bs>Export
                </button>
            </div>
        </div>
        <div class="table-responsive ep-table">
            <ng-container>
                <table class="table table-report table-ddr w-100" style="overflow: scroll"
                    style="max-height: 240px !important; overflow-y: scroll !important">
                    <thead>
                        <tr class="fs-2 bordered">
                            <th scope="col" class="fw-bold text-nowrap">
                                Care Navigator
                            </th>
                            <th scope="col" colspan="3" class="fw-bold text-center text-nowrap">Total Calls</th>
                            <th scope="col" colspan="3" class="fw-bold text-center text-nowrap">Total Time In Hours
                                (Calculated)</th>
                            <th scope="col" colspan="3" class="fw-bold text-center text-nowrap">Average Time All Calls
                                in
                                Minutes</th>
                            <th scope="col" colspan="3" class="fw-bold text-center text-nowrap">Average Calls Per Days
                            </th>
                            <th scope="col" class="fw-bold text-center text-nowrap">Benefit Support Interaction</th>
                            <th scope="col" class="fw-bold text-center text-nowrap">Care Navigation Interaction</th>
                            <th scope="col" class="fw-bold text-center text-nowrap">Care Report(s) Sent</th>
                            <th scope="col" colspan="2" class="fw-bold text-center text-nowrap">Number of Request
                                Created </th>
                            <th scope="col" class="fw-bold text-center text-nowrap">Total Requests Created</th>
                            <th scope="col" class="fw-bold text-center text-nowrap">Average Word per Note</th>
                            <!-- <th scope="col" class="fw-bold text-center text-nowrap">User Login After 8</th> -->
                            <th scope="col" colspan="2" class="fw-bold text-center text-nowrap">Total Pause Duration
                            </th>
                            <th scope="col" class="fw-bold text-center text-nowrap">Pause Time per Month (in %)</th>
                            <th scope="col" class="fw-bold text-center text-nowrap"> Total Number of Pauses</th>
                        </tr>
                        <tr class="fs-2 bordered table-min-row" >
                            <td></td>
                            <td class="fw-bold text-center"> Inbound </td>
                            <td class="fw-bold text-center"> Outbound </td>
                            <td class="fw-bold text-center"> Total </td>
                            <td class="fw-bold text-center"> Inbound </td>
                            <td class="fw-bold text-center"> Outbound </td>
                            <td class="fw-bold text-center"> Total </td>
                            <td class="fw-bold text-center"> Inbound </td>
                            <td class="fw-bold text-center"> Outbound </td>
                            <td class="fw-bold text-center"> Total Avg </td>
                            <td class="fw-bold text-center"> Inbound </td>
                            <td class="fw-bold text-center"> Outbound </td>
                            <td class="fw-bold text-center text-nowrap"> Total Avg </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="fw-bold text-center"> Via Email </td>
                            <td class="fw-bold text-center"> Via Phone </td>
                            <td></td>
                            <td></td>
                            <!-- <td></td> -->
                            <td class="fw-bold text-nowrap text-center"> (in Minutes) </td>
                            <td class="fw-bold text-nowrap text-center"> (in Hour) </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let data of reportDetails | sort : 'asc':'user'; let i = index">
                            <tr class="fs-2 bordered text-nowrap  text-center"  [ngClass]="{ 'row-striped': i % 2 !== 0 }">
                                <td class="text-start">{{data.user}}</td>
    
                                <td>{{findValue("totalInboundCalls", data.value)|| 0}}</td>
                                <td>{{findValue("totalOutboundCalls", data.value) || 0}}</td>
                                <td>{{findValue("totalInboundCalls", data.value) + findValue("totalOutboundCalls", data.value) }}</td>
    
                                <td>{{(findValue('totalInboundDuration', data.value))/3600000 | number: '1.0-2'}}</td>
                                <td>{{(findValue('totalOutboundDuration', data.value))/3600000 | number : '1.0-2'}}</td>
                                <td>{{((findValue('totalInboundDuration', data.value))/3600000 +  (findValue('totalOutboundDuration', data.value))/3600000) | number: '1.0-2'}}</td>
                                <td>{{findValue("avgInboundInMinutes", data.value) | number: '1.0-2'}}</td>
                                <td>{{findValue('avgOutboundInMinutes', data.value) | number: '1.0-2'}}</td>
                                <td>{{((findValue("avgInboundInMinutes", data.value) + findValue('avgOutboundInMinutes', data.value))/2) |  number: '1.0-2'}}</td>
    
                                <td>{{findValue('avgInboundsPerDay', data.value) | number: '1.0-2'}}</td>
                                <td>{{findValue('avgOutboundsPerDay', data.value) | number: '1.0-2'}}</td>
                                <td>{{((findValue("totalInboundCalls", data.value) + findValue("totalOutboundCalls", data.value))/workingDays) | number: '1.0-2'}}</td>
    
                                <td>{{findValue("Benefit Support Interaction(s)", data.value)}}</td>
                                <td>{{findValue("Care Navigation Interaction(s)", data.value)}}</td>
                                <td>{{findValue("Care Report(s) Sent", data.value)}}</td>
                                <td>{{findValue("Number of Request Created Via CCM-EMAIL", data.value) + findValue("Number of Request Created Via Email", data.value)}}</td>
                                <td>{{findValue("Number of Request Created Via CCM-PHONE", data.value)  + findValue("Number of Request Created Via Phone", data.value)}}</td>
                                <td>{{findValue("Total Requests Created", data.value)}}</td>
                                <td>{{findValue("Average Words Per Note", data.value) |  number: '1.0-2'}}</td>
                                <!-- <td>{{findValue("userLoginAfter8", data.value)}}</td> -->
                                <td>{{((findValue("totalPausedDuration", data.value))/60) | number: '1.0-2' }}</td>
                                <td>{{((findValue("totalPausedDuration", data.value))/3600) | number: '1.0-2'}}</td>
                                <td>0</td>
                                <td>{{findValue("totalPausedCalls", data.value)}}</td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </ng-container>
        </div>
    </div>

</div>