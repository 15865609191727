<div class="d-flex justify-content-center flex-coulmn welcome-page mt-5 pt-5">
    <div class="flex-columm text-center mt-5 pt-5">
        <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
        <div>
            <div class="text-center p-5 pt-3">
                <h4><strong>Not Authorized</strong></h4>
            </div>
        </div>
    </div>

</div>