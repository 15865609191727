import { OnInit, Component } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { TilesModel } from "../../model";
import { CompanyDropdownConstant } from "src/app/constants";

@Component({
    selector: "app-tiles-management-model",
    templateUrl: 'tiles-management-modal.component.html',
    styleUrls: ['tiles-management-modal.component.scss']
})
export class TilesManagementModelComponent implements OnInit {

    tiles: Array<any>;
    tilesModel: TilesModel = new TilesModel()
    modalTitle: string = ''
    actionTypeList = CompanyDropdownConstant.tilesActionType;
    tileVisibility: boolean;
    constructor(public modalRef: MdbModalRef<TilesManagementModelComponent>) { }

    ngOnInit(): void {
        this.tileVisibility = this.tilesModel?.isActive;
    }


    onSave() {
        this.modalRef.close(this.tilesModel)
    }

    actionTypeChange(event: any) {
        if (event?.value) {
            this.tilesModel.action.value = null;
        }
    }

    changeVisibility() {
        this.tileVisibility = !this.tileVisibility;
        this.tilesModel.isActive = this.tileVisibility;
    }
}