import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { AuthenticationService, ToastrService } from '../../services';
import { Router } from '@angular/router';

@Injectable()
export class ServerInterceptor implements HttpInterceptor {
  constructor(    
    private authService: AuthenticationService,
    private toastrService : ToastrService,
    private router: Router) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest)     
      .pipe(
        catchError((error: HttpErrorResponse) => {
        this.toastrService.showError(error.error.message)
        if(error.status == 401){
          this.authService.logout()
        }
        if(error && error.status ==500) {
          this.router.navigate(['/login']);
        }
          return throwError(error);
        })
      )
  }
}
