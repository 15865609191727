<div class="iframe-header bg-primary-lighter">
    <div class="w-100">
        <img src="../../../../assets/images/Alithias Logo with Power Slogan Transparent.png" alt=""
            class="img-fluid pe-3" alt="" style="height: 60px; width: 170px;" />
    </div>
</div>
<div class="pt-5 mt-5" *ngIf="reportLoading">
    <loader></loader>
</div>
<div *ngIf="!reportLoading">
    <div class="d-flex justify-content-center pt-3">
        <div style="width: 1073px;">
            <div class="d-flex justify-content-end px-0 pt-2">
                <button class="btn me-2 btn-clear border" type="button" aria-expanded="false" mdbDropdownToggle
                    [disabled]="printing" (click)="printReport('print')">
                    <i-bs name="printer-fill" class="pe-2 text-gray-dark" *ngIf="!printing"></i-bs>
                    <span class="spinner-grow spinner-grow-sm text-gray-main" role="status" aria-hidden="true"
                        *ngIf="printing"></span>
                    Print
                </button>
                <button class="btn btn-primary border ps-2" type="button" aria-expanded="false" mdbDropdownToggle [disabled]="generating"
                    (click)="handleSendReport()">
                    <i-bs name="send" class="pe-2" *ngIf="!generating"></i-bs>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                        *ngIf="generating"></span>
                    Send
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center pb-5 pt-3">
        <div class="care-report" id="care-option">
            <div class="col-12 p-3">
                <div id="care-option-header">
                    <div class="d-flex align-items-center justify-content-between pt-0 pb-2">
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/images/Alithias Logo with Power Slogan Transparent.png" alt=""
                                class="img-fluid pe-3" alt="" style="height: 60px; width: 170px;" />
                            <div class="vertical-line ps-3 d-flex align-items-center"
                                *ngIf="reportContent.companyLogo?.filePath">
                                <img [src]="sanitizer.bypassSecurityTrustResourceUrl(reportContent.companyLogo.filePath)"
                                    alt="" class="img-fluid pe-3" alt="" style="height: 30px" />
                            </div>
                        </div>
                        <div class="">
                            <h6 class="fw-bold m-0">High Value Option Report</h6>
                        </div>
                    </div>
                    <hr class="m-0 pb-3" />
                </div>
                <div id="info">
                    <div class="bg-gray-lighter">
                        <p class="p-3 mb-0 fw-bold">
                            This option below has been choosen based on your unique healthcare needs. My goal is to
                            assist
                            you
                            in finding high value,
                            high quality healthcare. Please call me at {{reportContent.phone | phone}} if you are not
                            completely
                            satisfied
                            with
                            these providers.
                        </p>
                    </div>
                </div>
                <div id="general-info" class="d-flex justify-content-between py-3">
                    <div class=" p-3 w-50 me-2" [ngClass]="{'bg-primary-lighter': !faxView, 'border border-lighter': faxView}">
                        <div class="d-flex">
                            <div>
                                <i-bs name='people-fill' width="32" height="32" class="pe-2" [ngClass]="{'text-primary': !faxView, 'text-gray-dark': faxView}"></i-bs>
                            </div>
                            <div>
                                <h6 class="fw-bold d-flex align-items-center pt-1">Employer Information</h6>
                                <h6 class="fw-bold d-flex align-items-center pt-2">
                                    {{reportContent.employerInfo?.clientLegalName | titleCase}}</h6>
                                <p class="pb-0 mb-2">Inquiry Date: <label
                                        class="fw-bold-2 fs-1">{{reportContent.employerInfo?.inquiryDate | date:
                                        'MM/dd/yyyy'}}</label></p>
                                <p class="pb-0 mb-2">Completed Date: <label
                                        class="fw-bold-2 fs-1">{{reportContent.employerInfo?.completedDate | date:
                                        'MM/dd/yyyy'}}</label></p>
                                <p class="pb-0 mb-2">Completed By: <label
                                        class="fw-bold-2 fs-1">{{reportContent.employerInfo?.completedBy}}</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="p-3 w-50 ms-2"  [ngClass]="{'bg-primary-lighter': !faxView, 'border border-lighter': faxView}">
                        <div class="d-flex">
                            <div>
                                <i-bs name='person-fill' width="32" height="32" class="pe-2" [ngClass]="{'text-primary': !faxView, 'text-gray-dark': faxView}"></i-bs>
                            </div>
                            <div>
                                <h6 class="fw-bold d-flex align-items-center pt-1">Patient Information</h6>
                                <h6 class="fw-bold d-flex align-items-center pt-2">
                                    {{reportContent.patientInfo?.patientName
                                    | titleCase}}</h6>
                                <p class="pb-0 mb-2">DOB: <label class="fw-bold-2 fs-1">{{reportContent.patientInfo?.DOB
                                        | date: 'MM/dd/yyyy'}}</label></p>
                                <p class="pb-0 mb-2">Tel: <label class="fw-bold-2 fs-1">{{(reportContent.patientInfo?.cell | phone) 
                                || (reportContent.patientInfo?.homePhone | phone)
                                || (reportContent.patientInfo?.workPhone | phone)}}</label></p>
                                <p class="pb-0 mb-2">Email: <label
                                        class="fw-bold-2 fs-1">{{reportContent.patientInfo?.email}}</label></p>
                                <p class="pb-0 mb-2">Distance willing to travel: <label
                                        class="fw-bold-2 fs-1">{{reportContent.travelDistance ?
                                        reportContent.travelDistance.split('mi')[0] + ' miles' : ''}}
                                    </label></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="service-info" class="bg-gray-lighter">
                    <h6 class="p-3 mb-0 fw-bold">
                        SERVICE INFORMATION - {{reportContent.serviceInfo?.name}}
                    </h6>
                </div>
                <div id="whats-included">
                    <div class="p-3">
                        <p class="fw-bold"> What's Included:</p>
                        <p class="mb-0">
                            {{(reportContent.serviceInfo?.whatsIncluded) || '--'}}
                        </p>
                    </div>
                    <hr class="text-selected-bg m-0" />
                    <div class="p-3">
                        <p class="fw-bold"> Service Description:</p>
                        <p class="mb-0">
                            {{(reportContent.serviceInfo?.description) || '--'}}
                        </p>
                    </div>
                    <hr class="text-selected-bg m-0 pb-3" />
                </div>

                <div id="procedure-info" class="bg-gray-lighter d-flex justify-content-between">
                    <h6 class="p-3 mb-0 fw-bold">
                        PROVIDER SERVICE OPTIONS

                    </h6>
                    <h6 class="p-3 mb-0 fw-bold">
                        State Average: {{reportContent.stateAverage | currency}}
                    </h6>
                </div>
                <div class="p-3" id="provider-{{i}}" *ngFor="let n of reportContent.providerOptions, let i = index">
                    <h6 class="mb-0 fw-bold pb-3">{{n.name | titleCase}} {{n.serviceOption.includes(' | ') ?
                        (n.serviceOption.split(' | ')[1] ? '(' + n.serviceOption.split(' | ')[1] + ')': '') : ''}}</h6>
                    <div class="col-12 d-flex p-3">
                        <div class="col-4 p">
                            <div class="d-flex pb-2">
                                <i-bs name="geo-alt-fill" class="align-self-center"></i-bs>
                                <div class="ps-2">
                                    <p class="mb-0">{{n.address.line1}}</p>
                                    <!-- <p class="mb-0" *ngIf="n.address.line2">{{n.address.line2}}</p> -->
                                    <p class="mb-0">{{n.address.city}}, {{n.address.state}} {{n.address.zip}}</p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <i-bs name="phone-fill"></i-bs>
                                <div class="ps-2">
                                    <p class="mb-0">{{(n.serviceOption ==  'TPA/Network Bundle' || n.serviceOption == 'Direct Contract' ||  n.serviceOption == 'Episode of Care')  ? (this.reportContent.phone | phone): ( n.phone | phone)}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <p class="fw-bold-2 mb-2">Quality Rating</p>
                            <mdb-rating [readonly]="true" *ngIf="n.providerRating > 0">
                                <mdb-rating-icon class="text-primary"></mdb-rating-icon>
                                <mdb-rating-icon class="text-primary"></mdb-rating-icon>
                                <mdb-rating-icon class="text-primary"></mdb-rating-icon>
                                <mdb-rating-icon class="text-primary"></mdb-rating-icon>
                                <mdb-rating-icon class="text-primary"></mdb-rating-icon>
                            </mdb-rating>
                            <p class="text-gray-main ps-5" *ngIf="n.providerRating <= 0">n/a</p>
                        </div>
                        <div class="col-2 text-center">
                            <p class="fw-bold-2 mb-2">{{n.costlabel}}</p>
                            <h6 class="fw-bold" 
                            [ngClass]="{'text-secondary': !faxView, 'text-gray-dark': faxView}"
                            >{{(n.costValue | currency) || '-'}}</h6>
                        </div>
                        <div class="col-2 text-center">
                            <p class="fw-bold-2 mb-2">Estimated Savings</p>
                            <h6 class="fw-bold" [ngClass]="{'text-secondary': !faxView, 'text-gray-dark': faxView}">{{((reportContent.stateAverage - n.costValue -
                                (n.incentive?.amount || 0)) | currency) || '-'}}</h6>
                            <span class="badge d-inline " [ngClass]="{'badge-secondary text-gray-darker': faxView, 'badge-primary bg-primary' : !faxView}" *ngIf="(reportContent.stateAverage- n.costValue -
                                (n.incentive?.amount || 0)) > 0"><i-bs name="gem" class="pe-2"></i-bs>High
                                Value</span>
                        </div>
                        <div class="col-2 text-center">
                            <p class="fw-bold-2 mb-2">Incentive</p>
                            <h6 class="fw-bold"  [ngClass]="{'text-secondary': !faxView, 'text-gray-dark': faxView}" *ngIf="n.incentive?.amount">{{(n.incentive?.amount| currency) || '-'}}</h6>
                            <h6 class="fw-bold"  [ngClass]="{'text-secondary': !faxView, 'text-gray-dark': faxView}" *ngIf="!n.incentive?.amount">n/a</h6>
                            <label class="text-gray-main fw-bold fs-3"
                                *ngIf="n.incentive?.type">{{n.incentive?.type}}</label>
                        </div>
                    </div>
                    <p class="text-gray-main" *ngFor="let c of n.customMessage">Note: {{c.message}}</p>
                    <hr class="text-selected-bg m-0" />
                </div>
                <div id="report-footer">
                    <hr class="m-0 pt-3" />
                    <div class="footer d-flex justify-content-between pb-3">
                        <p class="mb-0">Copyright © {{currentYear}}. All Rights Reserved.</p>
                        <div class="d-flex">
                            <p class="pe-4 mb-0">Powered by</p>
                            <img src="../../../../assets/images/Alithias Logo with Power Slogan Transparent.png" alt=""
                                class="img-fluid pe-3" alt="" style="height: 40px; width:130px" />
                        </div>
                    </div>
                </div>
                <div id="blank-space"></div>
            </div>
        </div>
    </div>
</div>