import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';


@Injectable()
export class RoleGuard  {
  menuList: any;
  constructor(private router: Router, private authService: AuthenticationService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.menuList= await this.authService.getMenuListFromUser()
    const currentPath = route.url.length == 1 ? route.url[0].path: `${ route.url[0].path}/${ route.url[1].path}`;
    if (this.menuList.find((x:any)=>x.routePath == `/${currentPath}` ||(x.submenu.length && x.submenu.find((y:any)=>y.routePath == `/${currentPath}`))) ) {
      return true;
    } else {
      this.router.navigate(['/404']);
      return false;
    }
  }

}
