import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { ExcelHeaders, PDFReportConstant } from 'src/app/constants';

import { ExcelService, PdfService, ReportService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';
@Component({
  selector: 'app-incentive-report',
  templateUrl: './incentive-report.component.html',
  styleUrls: ['./incentive-report.component.scss']
})
export class IncentiveReportComponent {

  incentiveList: any = false;
  incentiveLoading: boolean = false;
  exporting: boolean = false;
  reportConstants = PDFReportConstant.incentiveReport;
  filterParams: any;
  order = 'closedDate';
  reverse: boolean = true;

  constructor(private reportService: ReportService,
    private excelExportService: ExcelService,
    private pdfService: PdfService
  ) { }

  ngOnInit() {
    // this.getIncentives();
  }

  getIncentives(params: any) {
    this.reportService.getIncentiveReport(params).pipe(finalize(() => {
      this.incentiveLoading = false;
    })).subscribe((response: any) => {

      if (response && response.success) {
        this.reverse = true;
        this.incentiveList = response.data;
        this.incentiveList = this.incentiveList.map((x: any) => {
          return {
            ...x, providerStateAverage: x.providerStateAverage?.amount,
            providerEstCost: x.providerEstCost?.amount, providerIncentives: x.providerIncentives?.amount

          }
        })
        this.incentiveList.totals = { totalEstCost: 0, totalEstCostSavings: 0, totalIncentivesToBePaid: 0, totalStateAvg: 0 };
        this.incentiveList.map((data: any) => {
          this.incentiveList.totals.totalEstCost = (this.incentiveList.totals.totalEstCost + (parseInt(data.providerEstCost || 0)));
          this.incentiveList.totals.totalEstCostSavings = (this.incentiveList.totals.totalEstCostSavings + (parseInt(data.providerCostSaving || 0)));
          this.incentiveList.totals.totalIncentivesToBePaid = (this.incentiveList.totals.totalIncentivesToBePaid + (parseInt(data.providerIncentives || 0)));
          this.incentiveList.totals.totalStateAvg = (this.incentiveList.totals.totalStateAvg + (parseInt(data.providerStateAverage || 0)));
        })
        this.sort({sortBy:'closedDate'});
      }
    })
  }

  handleBaseAction(event: any) {
    if (event !== 'reset') {
      this.filterParams = event;
      this.incentiveLoading = true;
      this.getIncentives({ companyId: event?.company?.alithiasCompanyId, createdFrom: moment(event.from).format('MM/DD/YYYY'), createdTo: moment(event.to).format('MM/DD/YYYY') });
    } else {
      this.incentiveLoading = false;
      this.incentiveList = false;
    }
  }

  generatePlanStartDate() {
    const pm = this.filterParams.company?.planPeriodStartDate - 1;
    const pd = 1;
    const py = new Date().getFullYear();
    return this.filterParams.company?.planPeriodStartDate ? moment(new Date(py, pm, pd)).format('MM/DD/YYYY') : null;
  }

  exportExcel() {
    let reportList = this.incentiveList.map((x: any) => {
      return {
        ...x, closedDate: x.closedDate ? moment(x.closedDate).format('MM/DD/YYYY') : null,
        employeeDob: x.employeeDob ?  moment(x.employeeDob).format('MM/DD/YYYY') : null,
        stateAvg: x.providerStateAverage ? parseInt(x.providerStateAverage) : 0,
        estCost: x.providerEstCost ? parseInt(x.providerEstCost) : 0,
        providerCostSaving: x.providerCostSaving ? parseInt(x.providerCostSaving) : 0,
        incentive: x.providerIncentives ? parseInt(x.providerIncentives) : 0,
        dateOfService: x.dateOfService ?  moment(x.dateOfService).format('MM/DD/YYYY') : null,
        employeeName: `${x.employeeName ? x.employeeName : (x.employeeFirstName + ' ' + x.employeeLastName)}`,
        patientName: `${x.patientFirstName + ' ' + x.patientLastName}`
      }
    })
    let totals = {
      dateOfService: `Total`,
      incentive: this.incentiveList.totals.totalIncentivesToBePaid ?  parseInt(this.incentiveList.totals.totalIncentivesToBePaid) : 0,
      stateAvg: this.incentiveList.totals.totalStateAvg ? parseInt(this.incentiveList.totals.totalStateAvg) : 0,
      estCost: this.incentiveList.totals.totalStateAvg ? parseInt(this.incentiveList.totals.totalEstCost) : 0,
      providerCostSaving: this.incentiveList.totals.totalEstCostSavings ? parseInt(this.incentiveList.totals.totalEstCostSavings) : 0,
    }
    reportList.push(totals)
    const content = {
      header: ExcelHeaders.IncentiveReport,
      data: reportList,
      reportTitle: 'Incentive Report',
      fileName: `${this.filterParams.company.clientLegalName}-Incentive Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      hasFooter: true,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`
    }
    this.excelExportService.exportExcel(content);
  }

  exportPdf() {
    return new Promise(async resolve => {
      this.exporting = true;
      let section:any = document.getElementById("pdf");
      section.style.display = "block"; 
      const pdfHeaderBody = {
        header: 'Incentive Report',
        companyName: this.filterParams?.company?.clientLegalName,
        imgPath: "assets/images/login-logo.png",
        date: `Report Period: ${moment(this.filterParams?.from).format('MM/DD/YYYY')} to ${moment(this.filterParams?.to).format('MM/DD/YYYY')}`,
        reportPlanDate: `Plan Period: ${moment(this.generatePlanStartDate())?.format('MM/DD')} to  ${moment(moment(this.generatePlanStartDate()).add(1, 'year')).subtract(1, 'days').format('MM/DD')}`
        // isBase64  : this.filterParams.imgPath.base64
      }
      let incentivePdf: any = await this.pdfService.captureTable('incentive', pdfHeaderBody);
      let pdfString = incentivePdf.output('datauristring');
      resolve({
        filename:  `${this.filterParams.company.clientLegalName}-Incentive Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}` + '.pdf',
        content: pdfString ? pdfString.split(',')[1] : "",
        encoding: 'base64'
      });
      // this.generating = false;
      window.open(incentivePdf.output('bloburl'), '_blank');
      this.exporting = false;
      section.style.display = "none";
      resolve(1)
    })
  }

  sort(columnObj: any) {
    if (columnObj.sortBy && !this.reverse) {
      this.order = columnObj.sortBy;
      this.incentiveList = this.incentiveList.sort((a: any, b: any) => a[columnObj['sortBy']] > b[columnObj['sortBy']] ? 1 : -1);
      this.reverse = !this.reverse;
    } else {
      this.incentiveList = this.incentiveList.sort((a: any, b: any) => a[columnObj['sortBy']] < b[columnObj['sortBy']] ? 1 : -1);
      this.order = columnObj.sortBy;
      this.reverse = false
    }
  }
}
