import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';

import {
  MemberService,
  HttpCancelService,
  ExcelService,
  CompanyService,
  ToastrService,
} from '../../../services';
import * as moment from 'moment';
import { ExcelHeaders } from 'src/app/constants';
import { DateCalc } from 'src/app/utils';
import { ToastrComponent } from 'src/app/common';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
})
export class MemberComponent implements OnInit {
  searchKeyword: any = null;
  searchConfig = {
    action: [
      // {
      //   label: 'Export',
      //   type: 'export',
      //   icon: 'download',
      //   btnClass: 'btn-clear px-4 pt-2 border',
      // },
    ],
    showAdvancedSearch: true,
    showToggle: true,
    formValue: this.searchKeyword,
    module: 'member',
    exportToggle: true
  };
  memberTabs = [
    'Enrollment',
    'Member Details',
    'History',
    'Accumulators',
    'JSON Data',
  ];
  activeTab = 0;
  memberList: any = [];
  loading: boolean = false;
  getRequest: any;
  totalMemberCount: any;
  currentFilter: any
  checkingMemberCount:any

  constructor(
    private memberService: MemberService,
    private cancelService: HttpCancelService,
    private excelExportService: ExcelService,
    private companyService: CompanyService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  selectTab(memberData: any, index: any) {
    memberData.activeTab = index;
    if (index == 2 && memberData.alithiasUID && !memberData.history?.length) {
      memberData.historyLoading = true;
      this.memberService
        .getMemberHistory(memberData.alithiasUID)
        .pipe(
          finalize(() => {
            memberData.historyLoading = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            memberData.history = response.data.rows;
            if (memberData.history?.length) {
              memberData.history.forEach((y: any) => {
                y.fileName =
                  y.sourceFile.split('/')[y.sourceFile.split('/').length - 1];
              });
            }
          }
        });
    }
  }

  async getMemberList(event?: any, action?:any) {
    this.loading = true;
    event = this.sanitizeMemberFilterField(event);
    this.getRequest = this.memberService
      .getMemberList({ ...event, sortBy: 'lastModified' })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) { 
          if(action === 'all') {
            this.exportMember(response.data.rows);
          }
          else{
            this.totalMemberCount = response.data.count;
            this.memberList = response.data.rows.length ? response.data.rows : false;
            this.memberList = this.memberList.map((x: any, index: number) => {
              return {
                ...x,
                rawData: JSON.stringify(x),
                collapsed: true,
                activeTab: 0,
              };
            });
            this.memberList = this.memberList.map((x: any) => {
              return {
                ...x,
                primaryEmail: x.email.find((y: any) => y.code == 'Primary')
                  ? x.email.find((y: any) => y.code == 'Primary').value
                  : '',
                secondaryEmail: x.email.find((y: any) => y.code == 'Secondary')
                  ? x.email.find((y: any) => y.code == 'Secondary').value
                  : '',
                primaryPhone: x.phone.find((y: any) => y.code == 'Cell')
                  ? x.phone.find((y: any) => y.code == 'Cell').value
                  : x.phone.find((y: any) => y.code == 'Home')
                  ? x.phone.find((y: any) => y.code == 'Home').value
                  : '',
                secondaryPhone: x.phone.find((y: any) => y.code == 'Work')
                  ? x.phone.find((y: any) => y.code == 'Work').value
                  : '',
                gender: x.gender === 'M' ? 'Male' : 'Female',
                eligAccumulator:
                  x.enrollments?.length > 1
                    ? x.enrollments[length - 1]?.accumulators
                    : x.enrollments[0]?.accumulators,
              };
            });
            this.memberList.forEach((x: any) => {
              if (x.enrollments.length) {
                x.enrollments.forEach((y: any) => {
                  y.startDate = moment.utc(y.startDate).format('MM/DD/YYYY');
                  y.endDate =
                    new Date(y.endDate).getFullYear() == 2099
                      ? ''
                      : y.endDate
                      ? moment.utc(y.endDate).format('MM/DD/YYYY')
                      : '';
                  y.fileName =
                    y.sourceFile?.split('/')[y.sourceFile.split('/').length - 1];
                });
              }
            });
          }
        }
      });
  }

  toggleCollapse(data: any): void {
    data.collapsed = !data.collapsed;
  }

  handleLogEmit(event: any) {
    if (event !== 'reset') {
      this.currentFilter = event;
      if (!this.getRequest) {
        this.getMemberList(event);
      } else {
        this.cancelService.cancelPendingRequests();
        this.getMemberList(event);
      }
    } else this.memberList = [];
  }

  sanitizeMemberFilterField(body: any) {
    Object.keys(body).forEach((key) => {
      if (body[key] == undefined || body[key] == null) {
        delete body[key];
      }
    });
    return body;
  }

  toggleMemberExport(action:any) {
    switch(action){
      case 'current' :
        this.exportMember();
        break;
      case 'all':
        if(this.currentFilter.alithiasGroupId && 
          !this.currentFilter.firstName && !this.currentFilter.lastName &&
          !this.currentFilter.tpa && !this.currentFilter.dob && 
          !this.currentFilter.phone && !this.currentFilter.subscriberId) {
          this.checkingMemberCount = true;
          this.getMemberCount();
        }
        else{
          this.getMemberList({...this.currentFilter, limit:0}, action);
        }
      break;
    }
  }

  exportMember(list?:any) {
    if (list || this.memberList) {
      let finalList = list || this.memberList;
      let reportData = finalList.map((x: any) => {
        return {
          memberId: x.subscription.subscriberId,
          firstName: x.firstName,
          lastName: x.lastName,
          ssn: x.ssn,
          company: x.subscription.groupName,
          dependent: x.subscription.relationshipName,
          tpa: x.tpa,
          lastModified: moment(x.lastModified).format('MM/DD/YYYY'),
        };
      });
      const content = {
        header: ExcelHeaders.AdminMember,
        data: reportData,
        reportTitle: 'Member',
        fileName: 'Member_Report',
      };
      this.excelExportService.exportExcel(content);
    }
  }

  getMemberCount() {
    const calculatedDate = DateCalc.calculateDate('thisMonth');
    this.companyService.getCompanyEmployeeStatus({start: calculatedDate.from, end:calculatedDate.to, companyId:this.currentFilter.alithiasGroupId, companyCount:true}).pipe(finalize(()=>{
      this.checkingMemberCount = false;
    }))
    .subscribe((response:any) => {
    if(response.data.total > 5000) {
      this.toastrService.showInfo('The total data exceeds the exportable limit of 5000. Please contact support@alithias.com.');
    }
    else{
      this.getMemberList({...this.currentFilter, limit:0}, 'all');
    }
   });
   
  }
}
