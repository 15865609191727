import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class EnterpriseService {
    constructor(
        private http: HttpClientService
    ) { }

    getLocation(filterParams : any= {}) {
        const keyValues = QueryParams.addParams(filterParams);
        return this.http.get(`${ApiConstant.LOCATION}?` + keyValues, null, 'enterprise')
        .pipe(map(response => {
            return response;
        }))
    }

    getPricing(filterParams : any= {}) {
        const keyValues = QueryParams.addParams(filterParams);
        return this.http.get(`${ApiConstant.ENTERPRISE}/procedures/pricing?` + keyValues, null, 'enterprise')
        .pipe(map(response => {
            return response;
        }))
    }

}
