import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class MemberService {
    constructor(
        private http: HttpClientService
    ) { }

    getMemberList(filterParams : any= {}, requestType?:any) {
        if(filterParams && filterParams.dob) {
            filterParams.dob = moment(filterParams.dob).format('YYYY-MM-DD')
        }
        const keyValues = QueryParams.addParams(filterParams);
        return this.http.get(`${ApiConstant.MEMBER}?` + keyValues, null, requestType)
        .pipe(map(response => {
            return response;
        }))
    }

    getMemberHistory(auid:any) {
        let query = `/member-history?alithiasUID=${auid}`
        return this.http.get(query)
        .pipe(map(response => {
            return response;
        }))
    }

    getMemberCountByZipCode(){
        return this.http.get(`${ApiConstant.MEMBER}/zips`)
        .pipe(map(response => {
            return response;
        }))
    }

    getMemberInfoById(auid:any) {
        let query = `${ApiConstant.MEMBER}/${auid}`
        return this.http.get(query)
        .pipe(map(response => {
            return response;
        }))
    }

    getMemberDependentList(auid:any) {
        let query = `${ApiConstant.MEMBER}/${auid}/dependents`
        return this.http.get(query)
        .pipe(map(response => {
            return response;
        }))
    }
    
    getMemberCaseHistory(auid:any) {
        let query = `${ApiConstant.MEMBER}/sevice-requests?memberId=${auid}`
        return this.http.get(query)
        .pipe(map(response => {
            return response;
        }))
    }
    
    getMemberFileList(memberId:any) {
        let query = `/service-requests/${memberId}/files`
        return this.http.get(query)
        .pipe(map(response => {
            return response;
        }))
    }

    postMemberInsuranceID(auid:any, body:any){
        return this.http.post(`${ApiConstant.MEMBER}/${auid}/insurance-file`, body)
        .pipe(map(response => {
            return response;
        }))
    }

    updateIdInfo(auid:any, body:any){
        return this.http.put(`${ApiConstant.MEMBER}/${auid}/insurance-file/${body.fileId}`, body)
        .pipe(map(response => {
            return response;
        }))
    }

    deleteIdInfo(auid:any,idInfo:any) {
        return this.http.remove(`${ApiConstant.MEMBER}/${auid}/insurance-file/${idInfo.fileId}`)
        .pipe(map(response => {
            return response;
        }))
    }
}