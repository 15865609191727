<!-- <div class="opacity-0 position-absolute bottom-0 start-0"> -->
<div class="">
  <table id="{{id}}" class="table table-report">
    <thead>
      <tr>
        <th id="requestId" class="fw-bolder fs-1" *ngFor="let x of reportConstants">
          {{ x.header }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="id == 'incentive'">
      <!-- <tr *ngIf="noReport" class="mt-10">There are no incentives to be paid out
          for selected date.</tr> -->
      <tr class="tr-clickable" *ngFor="let result of reportData; let i = index">
        <td>{{ result.closedDate | date : "MM/dd/yyyy" }}</td>
        <td>{{ result.employeeName ? result.employeeName : (result.employeeFirstName + ' ' + result.employeeLastName ) }}</td>
        <td>{{ result.patientFirstName }} {{ result.patientLastName }}</td>
        <td>{{ result.employeeId }}</td>
        <td>{{ result.employeeDob | date : "MM/dd/yyyy" }}</td>
        <td>{{ result.providerServiceOption }}</td>
        <td>{{ result.dateOfService | date : "MM/dd/yyyy" }}</td>
        <td>{{ result.providerStateAverage ? "$" + (result.providerStateAverage | number : "1.0-0") : "$0"}} </td>
        <td>{{ result.providerEstCost ? "$" + (result.providerEstCost | number : "1.0-0") : "$0"}} </td>
        <td>{{ result.providerIncentives ? "$" + (result.providerIncentives | number : "1.0-0") : "$0"}}</td>
        <td>{{ result.providerCostSaving ? "$" + (result.providerCostSaving | number : "1.0-0") : "$0"}} </td>
      </tr>
      <tr class="fs-2" *ngIf="reportData?.length && reportData?.totals">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="fw-bold text-center">Total</td>
        <td class="fw-bold">
          {{
          reportData?.totals?.totalStateAvg
          ? "$" + (reportData?.totals?.totalStateAvg | number : "1.0-0")
          : "$0"
          }}
        </td>
        <td class="fw-bold">
          {{
          reportData?.totals?.totalEstCost
          ? "$" + (reportData?.totals?.totalEstCost | number : "1.0-0")
          : "$0"
          }}
        </td>
        <td class="fw-bold">
          {{
          reportData?.totals?.totalIncentivesToBePaid
          ? "$" +
          (reportData?.totals?.totalIncentivesToBePaid | number : "1.0-0")
          : "$0"
          }}
        </td>
        <td class="fw-bold">
          {{
          reportData?.totals?.totalEstCostSavings
          ? "$" +
          (reportData?.totals?.totalEstCostSavings | number : "1.0-0")
          : "$0"
          }}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="id == 'savings-detailed'">
      <tr class="tr-clickable" *ngFor="let result of reportData?.data; let i = index">
        <td>{{ result.caseId }}</td>
        <td>{{ result.closedDate | date : "MM/dd/yyyy" }}</td>
        <td>{{ result.procedureName }}</td>
        <td>{{ result.providerStateAverage | currency }}</td>
        <td>{{ result.ppoSavings | currency }}</td>
        <td>{{ result.dcSavings | currency }}</td>
        <td>{{ result.actualSavings | currency }}</td>
        <td>{{ result.incentive | currency }}</td>
        <td>{{ result.lostOpportunity | currency }}</td>
      </tr>
      <tr class="fs-2" *ngIf="reportData?.data?.length">
        <td></td>
        <td></td>
        <td class="fw-bold text-center">Total</td>
        <td class="fw-bold">
          {{ reportData?.totalProviderStateAverage | currency }}
        </td>
        <td class="fw-bold">{{ reportData?.totalPpoSavings | currency }}</td>
        <td class="fw-bold">{{ reportData?.totalDcSavings | currency }}</td>
        <td class="fw-bold">{{ reportData?.totalActualSavings | currency }}</td>
        <td class="fw-bold">{{ reportData?.totalIncentive | currency }}</td>
        <td class="fw-bold">
          {{ reportData?.totalLlostOpportunity | currency }}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="id =='advocate-satisfaction'">
      <tr class="fs-2 bordered text-nowrap text-center" *ngFor="let result of reportData |  sort: 'asc':'name'; let i = index">
        <td>
          {{result.name}}
        </td>
        <td>{{result.noOfServiceRequest}}</td>
        <td>{{result.avarageTotalScorePercentage | number: '1.0-0' }}%</td>
        <td>{{result.nps | number: '1.0-0'}}</td>
      </tr>
    </tbody>
  </table>
</div>