import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { SurveyService } from 'src/app/services';
import { Sanitizer } from 'src/app/utils';

@Component({
  selector: 'app-survey-response',
  templateUrl: './survey-response.component.html',
  styleUrls: ['./survey-response.component.scss']
})
export class SurveyResponseComponent {
  surveyResponseId: any;
  responseLoading:boolean = true;
  surveyResponse:any;

  constructor(private route: ActivatedRoute,
    private sanitizerUtil : Sanitizer,
    private surveyService: SurveyService){}

  ngOnInit(){
    this.surveyResponseId = this.sanitizerUtil.sanitizeParams(this.route.snapshot.params['id']);
    this.getSurveyResponse();
  }

  getSurveyResponse() {
    if(this.surveyResponseId) {
      this.surveyService.getSurveyResponse(this.surveyResponseId).pipe(finalize(()=>{
        this.responseLoading = false;
      })).subscribe((response:any)=>{
        if(response && response.success) {
          this.surveyResponse = response.data;
        }
      })
    }
  }
}
