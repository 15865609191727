import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { ExcelHeaders, PDFReportConstant } from 'src/app/constants';
import { ExcelService, PdfService, ReportService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';

@Component({
  selector: 'app-saving-detailed-report',
  templateUrl: './saving-detailed-report.component.html',
  styleUrls: ['./saving-detailed-report.component.scss'],
})
export class SavingDetailedReportComponent {
  loading: boolean = false;
  exporting: boolean = false;
  savingDetailedReportData: any = false;
  filterParams: any;
  reportConstants = PDFReportConstant.savingsReport;
  order = 'closedDate';
  reverse: boolean = true;

  constructor(
    private reportService: ReportService,
    private excelExportService: ExcelService,
    private pdfService: PdfService
  ) { }

  ngOnInit(): void { }

  toggleCollapse(item: any) {
    item.collapsed = !item.collapsed;
  }

  getSavingDetailedReport(params: any) {
    this.reportService
      .getSavingDetailedReport(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.reverse = true;
          this.savingDetailedReportData = response.data;
          this.savingDetailedReportData =
            this.savingDetailedReportData?.data &&
              this.savingDetailedReportData?.data?.length
              ? this.savingDetailedReportData
              : { data: [] };
          this.sort({ sortBy: 'closedDate' });
        }
      });
  }

  handleBaseAction(event: any) {
    if (event !== 'reset') {
      this.filterParams = event;
      this.loading = true;
      this.getSavingDetailedReport({
        companyId: event?.company?.alithiasCompanyId,
        createdFrom: moment(event.from).format('MM/DD/YYYY'),
        createdTo: moment(event.to).format('MM/DD/YYYY'),
      });
    } else {
      this.loading = false;
      this.savingDetailedReportData = false;
    }
  }

  exportExcel() {
    let reportList = this.savingDetailedReportData.data.map((x: any) => {
      return {
        ...x,
        closedDate: x.closedDate
          ? moment(x.closedDate).format('MM/DD/YYYY')
          : null,
        providerStateAverage: x.providerStateAverage ? parseInt(x.providerStateAverage) : 0,
        ppoSavings: x.ppoSavings ? parseInt(x.ppoSavings) : 0,
        dcSavings: x.dcSavings ? parseInt(x.dcSavings) : 0,
        actualSavings: x.actualSavings ? parseInt(x.actualSavings) : 0,
        incentive:x.incentive ? parseInt(x.incentive) : 0,
        lostOpportunity: x.lostOpportunity ? parseInt(x.lostOpportunity) : 0,
      };
    });
    let totals = {
      actualSavings: this.savingDetailedReportData.totalActualSavings ? parseInt(this.savingDetailedReportData.totalActualSavings) : 0,
      closedDate: '',
      dcSavings: this.savingDetailedReportData.totalDcSavings ? parseInt(this.savingDetailedReportData.totalDcSavings) : 0,
      lostOpportunity: this.savingDetailedReportData.totalLlostOpportunity ? parseInt(this.savingDetailedReportData.totalLlostOpportunity) : 0,
      incentive: this.savingDetailedReportData.totalIncentive ? parseInt(this.savingDetailedReportData.totalIncentive) : 0,
      ppoSavings: this.savingDetailedReportData.totalPpoSavings ? parseInt(this.savingDetailedReportData.totalPpoSavings) : 0,
      procedureName: 'Total',
      providerStateAverage:this.savingDetailedReportData.totalProviderStateAverage ? parseInt(this.savingDetailedReportData.totalProviderStateAverage) : 0,
      caseId: '',
    };
    reportList.push(totals);
    const content = {
      header: ExcelHeaders.SavingsDetailedReport,
      data: reportList,
      reportTitle: 'Savings Detailed Report',
      fileName: `${this.filterParams.company.clientLegalName}-Savings Detailed Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      hasFooter: true,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`
    };
    this.excelExportService.exportExcel(content);
  }

  generatePlanStartDate() {
    const pm = this.filterParams.company?.planPeriodStartDate - 1;
    const pd = 1;
    const py = new Date().getFullYear();
    return this.filterParams.company?.planPeriodStartDate ? moment(new Date(py, pm, pd)).format('MM/DD/YYYY') : null;
  }

  exportPdf() {
    return new Promise(async (resolve) => {
      this.exporting = true;
      let section: any = document.getElementById("pdf");
      section.style.display = "block";
      const pdfHeaderBody = {
        header: 'Savings Detailed Report',
        companyName: this.filterParams?.company?.clientLegalName,
        imgPath: "assets/images/login-logo.png",
        date: `Report Period: ${moment(this.filterParams?.from).format('MM/DD/YYYY')} to ${moment(this.filterParams?.to).format('MM/DD/YYYY')}`,
        reportPlanDate: `Plan Period: ${moment(this.generatePlanStartDate())?.format('MM/DD')} to  ${moment(moment(this.generatePlanStartDate()).add(1, 'year')).subtract(1, 'days').format('MM/DD')}`,
        // isBase64  : this.filterParams.imgPath.base64
      }
      let savingsDetailedReport: any = await this.pdfService.captureTable('savings-detailed', pdfHeaderBody);
      let pdfString = savingsDetailedReport.output('datauristring');
      resolve({
        filename:  `${this.filterParams.company.clientLegalName}-Savings Detailed Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}` + '.pdf',
        content: pdfString ? pdfString.split(',')[1] : '',
        encoding: 'base64',
        isNewCareReport: true,
      });
      window.open(savingsDetailedReport.output('bloburl'), '_blank');
      this.exporting = false;
      section.style.display = "none";
      resolve(1);
    });
  }

  sort(columnObj: any) {
    if (columnObj.sortBy && !this.reverse) {
      this.order = columnObj.sortBy;
      this.savingDetailedReportData.data = this.savingDetailedReportData?.data.sort((a: any, b: any) => a[columnObj['sortBy']] > b[columnObj['sortBy']] ? 1 : -1);
      this.reverse = !this.reverse;
    } else {
      this.savingDetailedReportData.data = this.savingDetailedReportData?.data.sort((a: any, b: any) => a[columnObj['sortBy']] < b[columnObj['sortBy']] ? 1 : -1);
      this.order = columnObj.sortBy;
      this.reverse = false
    }
  }
}
