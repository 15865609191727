import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { ApiConstant } from '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileStatusService {
  constructor(private http: HttpClientService) {}

  getFileStatusList(keyword?: any) {
    const keyValues = QueryParams.addParams(keyword);
    return this.http.get(`${ApiConstant.FILES}?` + keyValues).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getRawFile(params: any) {
    return this.http
      .get(`${ApiConstant.FILES}/raw-data?filePath=${params}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDataSource(params: any) {
    const keyValues = QueryParams.addParams(params);
    return this.http.get(`${ApiConstant.SOURCE}?` + keyValues).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getDashboardStats() {
    // const keyValues = QueryParams.addParams(params);
    return this.http.get(`${ApiConstant.DASHBOARD}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMemberFile(params: any) {
    return this.http
      .get(`${ApiConstant.FILES}/vitafy-file-download?filePath=${params}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
