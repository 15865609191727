import { Component } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-single-field-modal',
  templateUrl: './single-field-modal.component.html',
  styleUrls: ['./single-field-modal.component.scss']
})
export class SingleFieldModalComponent {
  modalTitle:any= null;
  fieldName: any = null;
  fieldValue:any = null;

  constructor(public modalRef: MdbModalRef<SingleFieldModalComponent>) {

  }
}
