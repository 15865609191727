<!-- Sidenav -->
<mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" [slim]="true" position="fixed" [slimCollapsed]="false" mode="side" [hidden]="false"
    sidenavMenuItemScroll="#scroll-container"  [slimWidth]="slimWidth" [accordion]="true" [backdrop]="false" [width]="fullWidth" >
    <app-sidebar id = "content" class="app-sidenav" [slim]="sidenav.slimCollapsed"></app-sidebar>
  </mdb-sidenav>

  <mdb-sidenav-content #sidenavContent>
    <header class="sticky-top p-0">
      <button [ngStyle]="{'left': sidenav.slimCollapsed ? '-58px' :'-20px' }" #sidenavToggle
        (click)="sidenav.toggleSlim()" class="navbar-toggler btn-floating d-block shadow sticky-top btn-sm "
        type="button" aria-label="Toggle navigation">
        <i class="fas fa-bars text-white"></i>
      </button>
      <app-header></app-header>
    </header>
    <div class="container-height container-fluid position-relative p-0">
      <router-outlet></router-outlet>
    </div>
  </mdb-sidenav-content>
</mdb-sidenav-layout>