import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { AuthenticationService, UserService, ToastrService } from '../../services';
import { RolesModel } from '../../model';


@Component({
  selector: 'app-roles-permission-modal',
  templateUrl: './roles-permission-modal.component.html',
  styleUrls: ['./roles-permission-modal.component.scss']
})
export class RolesPermissionModalComponent implements OnInit {
  modalTitle: string = 'Add Roles';
  roles: any;
  permissionList: any;
  event: string = '';
  rolesModel: RolesModel = new RolesModel();
  permissionsLoading: boolean = true;
  userId: any;
  userPermissionList: any;
  editedPermissionList: any = [];
  constructor(public modalRef: MdbModalRef<RolesPermissionModalComponent>, public authService: AuthenticationService, private userService: UserService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getResourceList()
    this.userPermissionList = this.rolesModel.permissions;
  }

  getResourceList() {
    this.userService.getAllResources().pipe(finalize(() => {
      this.permissionsLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.permissionList = response.data.resources;
        this.rolesModel.permissions = this.rolesModel.permissions.filter(x => x.accessLevel !== 'D')
        this.permissionList = this.permissionList.map((x: any) => {
          return {
            ...x, submenu: this.permissionList.filter((y: any) => x.resource == y.parentId)
          }
        })
        this.permissionList = this.permissionList.filter((x: any) => !x.parentId);
        this.permissionList = this.permissionList.map((x: any) => {
          return {
            ...x, hasPermission: this.rolesModel?.permissions.find((role: any) => role.resource == x.resource) ? true : false,
            accessLevel: this.rolesModel?.permissions.find((role: any) => role.resource == x.resource) ? this.rolesModel?.permissions.find((role: any) => role.resource == x.resource)?.accessLevel : null
          }
        })
        this.permissionList = this.permissionList.map((x: any) => {
          if (x.submenu.length) {
            x.submenu = x.submenu.map((y: any) => {
              return {
                ...y, hasPermission: this.rolesModel?.permissions.find((role: any) => role.resource == y.resource) ? true : false,
                accessLevel: this.rolesModel?.permissions.find((role: any) => role.resource == y.resource) ? this.rolesModel?.permissions.find((role: any) => role.resource == y.resource)?.accessLevel : null
              }
            })
            return x
          }
          else return x
        })
      }
    })

  }


  handleSend() {
    let filteredPermissionArray = this.filterPermissionList();
    this.modalRef.close({ ...this.rolesModel, permissions: filteredPermissionArray });
  }

  async onCheck(event: any, index: number, menu: any, parentIndex?: any) {
    if (parentIndex >= 0) {
      this.permissionList[parentIndex].submenu[index].hasPermission = !this.permissionList[parentIndex].submenu[index].hasPermission;
      this.permissionList[parentIndex].hasPermission = this.permissionList[parentIndex].submenu.find((x: any) => x.hasPermission);
      this.permissionList[parentIndex].accessLevel = this.permissionList[parentIndex].submenu.find((x: any) => x.hasPermission) ? (this.permissionList[parentIndex].accessLevel ? this.permissionList[parentIndex].accessLevel : 'R') : false;
      this.permissionList[parentIndex].submenu[index].accessLevel = this.permissionList[parentIndex].submenu[index].hasPermission ? 'R' : 'D';
      if (this.rolesModel.permissions.find(x => x.resource == this.permissionList[parentIndex].resource)) {
        this.editedPermissionList.push({ accessLevel: menu.accessLevel, name: menu.name, resource: menu.resource });
      }
      else {
        this.editedPermissionList.push({ accessLevel: menu.accessLevel, name: menu.name, resource: menu.resource },
          { accessLevel: this.permissionList[parentIndex].accessLevel, name: this.permissionList[parentIndex].name, resource: this.permissionList[parentIndex].resource });
      }
    }
    else {
      this.permissionList[index].hasPermission = !this.permissionList[index].hasPermission;
      this.permissionList[index].accessLevel = this.permissionList[index].hasPermission ? 'R' : 'D';
      this.editedPermissionList.push({ accessLevel: this.permissionList[index].accessLevel, name: this.permissionList[index].name, resource: this.permissionList[index].resource })
      this.permissionList[index].submenu = this.permissionList[index].submenu.map((x: any) => {
        if (this.permissionList[index].hasPermission) {
          this.editedPermissionList.push({ accessLevel: "R", name: x.name, resource: x.resource })
          return {
            ...x, hasPermission: true, accessLevel: 'R'
          }
        } else {
          this.editedPermissionList.push({ accessLevel: "D", name: x.name, resource: x.resource })
          return {
            ...x, hasPermission: false, accessLevel: 'D'
          }
        }

      })
    }
    if (this.event == 'managePermission') {
      this.managePermission(this.editedPermissionList)
    }
  }

  async managePermission(permissionArray: any) {
    let userPermission: any = { permissions: [] };
    userPermission.permissions = permissionArray
    let deletedRole: any;
    this.rolesModel.permissions.map((x: any) => {
      userPermission.permissions.find((y: any) => {
        if (y.resource == x.resource) {
          deletedRole = x;
        }
      })
    })
    if (deletedRole) {
      userPermission.permissions = userPermission.permissions.filter((x: any) => x.resource !== deletedRole.resource)
      userPermission.permissions.push({ resource: deletedRole.resource, accessLevel: 'D', name: deletedRole.name });
      if (this.permissionList.find((y: any) => y.resource == deletedRole.resource) && this.permissionList.find((y: any) => y.resource == deletedRole.resource).submenu.length) {
        this.permissionList.find((y: any) => y.resource == deletedRole.resource).submenu.forEach((x: any) => {
          userPermission.permissions = userPermission.permissions.filter((z: any) => z.resource !== x.resource)
          userPermission.permissions.push({ resource: x.resource, accessLevel: 'D', name: x.name });
        })
      }
    };
    await this.assignPermissionToUser(userPermission);
  }

  onSelect(type: string, index: any, permission: any, parentIndex?: any) {
    if (parentIndex >= 0) {
      this.permissionList[parentIndex].hasPermission = true;
      this.permissionList[parentIndex].accessLevel = this.permissionList[parentIndex].accessLevel ? this.permissionList[parentIndex].accessLevel : type;
      this.permissionList[parentIndex].submenu[index].hasPermission = true;
      this.permissionList[parentIndex].submenu[index].accessLevel = type;
      if(this.editedPermissionList.find((x:any)=> x.resource == permission.resource)) {
        this.editedPermissionList = this.editedPermissionList.map((a: any) => {
          if (a.resource = permission.resource) {
            return {
              ...a, accessLevel: type
            }
          }
        })
      }
      else {
        this.editedPermissionList.push({ accessLevel: type, name: permission.name, resource: permission.resource },
          { accessLevel: this.permissionList[parentIndex].accessLevel, name: this.permissionList[parentIndex].name, resource: this.permissionList[parentIndex].resource });
      }
    }
    else {
      this.permissionList[index].hasPermission = true;
      this.permissionList[index].accessLevel = type;
      if(this.editedPermissionList.find((x:any)=> x.resource == permission.resource)) {
        this.editedPermissionList = this.editedPermissionList.map((a: any) => {
          if (a.resource = permission.resource) {
            return {
              ...a, accessLevel: type
            }
          }
        })
      }
      else {
        this.editedPermissionList.push({ accessLevel: type, name: permission.name, resource: permission.resource })
      }
      if (this.permissionList[index].submenu.length) {
        if (!this.permissionList[index].submenu.find((x: any) => x.hasPermission)) {
          this.permissionList[index].submenu.forEach((x: any) => {
            x.hasPermission = true;
            x.accessLevel = type;
            this.editedPermissionList.push({ accessLevel: type, name: x.name, resource: x.resource })

          })
        }
      }
    }
    if (this.event == 'managePermission') {
      let userPermission: any = { permissions: [] };
      userPermission.permissions = this.editedPermissionList;
      this.assignPermissionToUser(userPermission);
    }
  }

  filterPermissionList() {
    let newArray = this.rolesModel.permissions.map((x: any) => {
      const y = this.editedPermissionList.find((y: any) => y.resource === x.resource);
      return y ? y : x;
    }).concat(this.editedPermissionList.filter((z: any) => !this.rolesModel.permissions.find((a: any) => z.resource === a.resource)));
    newArray = newArray.reduce((accumulator, currentObject) => {
      const existingObject = accumulator.find((object:any) => object.resource === currentObject.resource);
      if (!existingObject) {
        accumulator.push(currentObject);
      }
      return accumulator;
    }, []);
    return newArray;
  }

  assignPermissionToUser(menu: any) {
    this.userService.editUsersPermission(this.userId, menu).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.editedPermissionList = [];
      }
    })
  }
}
