<div class="px-3">
  <app-search-bar [searchConfig]="searchConfig" (baseAction)="manageCrm('addCrm')" [searchList]="crmList?.rows"
    (searchLogEmit)="searchCrm($event)">
  </app-search-bar>
  <div class="pt-5 mt-5" *ngIf="listLoading">
    <loader></loader>
  </div>
  <div  *ngIf="!listLoading">
    <table class="table mb-0">
      <thead>
        <tr class="fs-2 table-min-row">
          <th class="fw-bold text-nowrap">Created Date</th>
          <th class="fw-bold">Company</th>
          <th class="fw-bold">Network</th>
          <th class="fw-bold">Procedure</th>
          <th class="fw-bold text-nowrap">Service Option</th>
          <th class="fw-bold">Provider</th>
          <th class="fw-bold text-nowrap">Effective Date</th>
          <th class="fw-bold">Message</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let c of crmList; let i = index">
          <tr [ngClass]="{ 'row-striped': i % 2 === 0 }" class="fs-2 table-min-row">
            <td>{{c.createdAt | date: 'MM/dd/yyyy'}}</td>
            <td>{{getArrayLabel(c.companies, 'clientLegalName')}}</td>
            <td>{{getArrayLabel(c.networks, 'networkName')}}</td>
            <td>{{getArrayLabel(c.procedures, 'procedureName')}}</td>
            <td>{{getArrayLabel(c.serviceOptions, 'label')}}</td>
            <td>{{getArrayLabel(c.providers, 'businessName')}}</td>
            <td>{{c.effectiveStartDate | date: 'MM/dd/yyyy'}} <br> {{c.effectiveEndDate | date: 'MM/dd/yyyy'}}</td>
            <td>{{c.message}}</td>
            <td class="p-1">
              <div class="spinner-border spinner-border-sm  d-flex justify-content-center" role="status" *ngIf="c.processing">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div mdbDropdown class="dropdown"  *ngIf="!c.processing">
                <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                  aria-expanded="false">
                  <i-bs name="three-dots-vertical"></i-bs>
                </button>
                <ul autoClose="true" mdbDropdownMenu class="dropdown-menu crm-list shadow">
                  <li>
                    <button class="dropdown-item" type="button" (click)="manageCrm('editCrm', c)">
                      Edit
                    </button>
                  </li>
                  <li>
                    <button class="dropdown-item" type="button"  (click)="deleteCrm(c)">
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>