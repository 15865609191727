<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (baseAction)="handleNetworkEvent('', $event)"
        [searchList]="networkList" (searchLogEmit)="searchNetwork($event)">
    </app-search-bar>
    <div class="pt-5 mt-5" *ngIf="networkLoading">
        <loader></loader>
    </div>
    <table class="table table-striped table-row mb-0" *ngIf="!networkLoading">
        <thead>
            <tr>
                <th class="fw-bold" style="width: 150px;">Network Code</th>
                <th class="fw-bold" style="width: 300px;">Network Name</th>
                <th class="fw-bold" style="width: 200px;">Network Type</th>
                <th class="fw-bold" style="width: 100px;">Source</th>
                <th class="fw-bold" style="width: 300px;">Group Network Name</th>
                <th style="width: 150px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngIf="!networkList.rows.length">
                <td colspan="6" class="fw-bold">Networks not found.</td>
            </tr>
            <tr class="text-left" *ngFor="let network of networkList.rows | sort:'desc'">
                <td>{{network.networkCode}}</td>
                <td>{{network.networkName}}</td>
                <td>{{network.displayTypeName}}</td>
                <td>{{network.source}}</td>
                <td>{{network.groupNetworkName}}</td>
                <td class="p-1">
                    <div mdbDropdown class="dropdown">
                        <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                            aria-expanded="false">
                            <i-bs name="three-dots-vertical"></i-bs>
                        </button>
                        <ul autoClose="true" mdbDropdownMenu class="dropdown-menu dropdown-menu-end shadow dropstart"
                            style="min-width: 120px;">
                            <li>
                                <button class="dropdown-item" type="button"
                                    (click)="openNetworkMgmtModal(network, 'editNetwork')">
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button class="dropdown-item" type="button" (click)="openConfirmationModal(network)">
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <p class="py-3 mb-0 fw-bold fs-2" *ngIf="!networkLoading && networkList.rows.length">Showing
        {{networkList.count >= searchConfig.limit ? searchConfig.limit : networkList.count}} of {{networkList.count}}
        {{networkList.count > 1 ? 'results' : 'result'}}</p>
</div>