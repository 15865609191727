<div class="px-3">
  <p class="fs-1 fw-bold mb-0 pt-4">Search Episode</p>
  <app-search-bar [searchConfig]="searchConfig" (searchLogEmit)="getPricingEpisode($event, 'emitter')">
  </app-search-bar>
</div>
<div class="pt-5 mt-5" *ngIf="loading">
  <loader></loader>
</div>
<div class="col-12 p-3 text-center" *ngIf="
    !episodeList?.rows?.length && !loading && this.searchParams.procedureID
  ">
  No episodes found.
</div>
<div class="p-3 fs-2" *ngIf="episodeList?.rows?.length">
  <div class="bg-gray-light p-3 mb-3" *ngFor="let ep of episodeList?.rows">
    <div class="d-flex justify-content-between">
      <p>
        Episode Key:
        <span class="fw-bold pointer" [mdbPopover]="episodeKeyPopOver" trigger="hover" placement="right">{{
          ep.episodeKey | slice : 0 : 20 }}...
          <ng-template #episodeKeyPopOver>
            <p class="text-nowrap mb-0">
              {{ ep.episodeKey }}
            </p>
          </ng-template>
        </span>
      </p>
      <p>
        Company Name: <span class="fw-bold"> {{ ep.companyName }}</span>
      </p>
      <p>
        Source: <span class="fw-bold">{{ ep.source }}</span>
      </p>
      <span>
        <i-bs (click)="updateEpisodeInclude(ep)" *ngIf="!ep.updating" [ngClass]="{
            'text-secondary': ep.episodeIncludeFlag,
            'text-danger': !ep.episodeIncludeFlag
          }" [name]="ep.episodeIncludeFlag ? 'eye-fill' : 'eye-slash-fill'" style="color: #616161"
          class="pointer"></i-bs></span>
      <div class="text-center" *ngIf="ep.updating">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let d of ep.details?.details"> </ng-container>

    <div class="">
      <!-- <p class="fw-bold mb-0">Cost category : Physician</p> -->
      <div class="table-responsive ep-table">
        <ng-container *ngIf="ep.episodeDetails?.categoryDetails?.length">
          <table class="table w-100" style="overflow: scroll"
            style="max-height: 240px !important; overflow-y: scroll !important">
            <ng-container *ngFor="let d of ep.episodeDetails?.categoryDetails">
              <thead>
                <tr class="table-min-row fw-bold bg-primary-lighter fs-2">
                  <td class="" colspan="3" style="position: sticky !important; left: 0 !important">
                    Cost Category : {{ d.category }}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr class="table-min-row bg-white fs-2">
                  <th class="fw-bold">NPI</th>
                  <th class="fw-bold">Provider Name</th>
                  <th class="fw-bold">Provider Type</th>
                  <th class="fw-bold">Network</th>
                  <th class="fw-bold">Procedure Code</th>
                  <th class="fw-bold text-nowrap">Mod 1</th>
                  <th class="fw-bold text-nowrap">Mod 2</th>
                  <th class="fw-bold">Revenue Code</th>
                  <th class="fw-bold">Mapping</th>
                  <th class="fw-bold">Units</th>
                  <th class="fw-bold">Actual Retail</th>
                  <th class="fw-bold">Adjusted Retail</th>
                  <th class="fw-bold">Actual Allowed</th>
                  <th class="fw-bold">Adjusted Allowed</th>
                  <th></th>
                </tr>
                <tr class="table-min-row fs-2 bg-white" *ngFor="let lineItem of d.episodes">
                  <td class="text-nowrap">{{ lineItem.providerNPI }}</td>
                  <td class="text-nowrap">
                    {{ lineItem.provider | titleCase }}
                  </td>
                  <td class="text-nowrap">
                    {{ lineItem.providerType | titleCase }}
                  </td>
                  <td class="text-nowrap">
                    {{ lineItem.networkName | titleCase }}
                  </td>
                  <td class="text-nowrap">{{ lineItem.procedureCode }}</td>
                  <td class="text-nowrap">
                    {{
                    lineItem.modifier1 === "null" ? "-" : lineItem.modifier1
                    }}
                  </td>
                  <td class="text-nowrap">
                    {{
                    lineItem.modifier2 === "null" ? "-" : lineItem.modifier2
                    }}
                  </td>
                  <td class="text-nowrap">{{ lineItem.revenueCode }}</td>
                  <td class="text-nowrap">{{ lineItem.mapping }}</td>
                  <td class="text-nowrap">{{ lineItem.units }}</td>
                  <td class="text-nowrap">
                    ${{ lineItem.actualRetailAmount | number : "1.2-2" }}
                  </td>
                  <td class="text-nowrap">
                    ${{ lineItem.adjustedRetailAmount.toFixed(2) }}
                  </td>
                  <td class="text-nowrap">
                    ${{ lineItem.actualAllowedAmount | number : "1.2-2" }}
                  </td>
                  <td class="text-nowrap">
                    ${{ lineItem.adjustedAllowedAmount | number : "1.2-2" }}
                  </td>
                  <td class="text-nowrap">
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="lineItem.isEpisodeUpating">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <i-bs [ngClass]="{
                        'text-secondary':
                          lineItem.includeFlag && ep.episodeIncludeFlag,
                        'text-danger': !(
                          lineItem.includeFlag && ep.episodeIncludeFlag
                        )
                      }" *ngIf="!lineItem.isEpisodeUpating" [name]="
                        lineItem.includeFlag && ep.episodeIncludeFlag
                          ? 'eye-fill'
                          : 'eye-slash-fill'
                      " (click)="
                        ep.episodeIncludeFlag
                          ? updatePricingEpisode(lineItem, ep.episodeKey)
                          : null
                      " class="pointer" [mdbPopover]="
                        !ep.episodeIncludeFlag ? episodeFlagPopover : ''
                      " trigger="hover" placement="left"></i-bs>
                    <ng-template #episodeFlagPopover>
                      <p class="text-nowrap mb-0">
                        Please include main episode to update sub episodes
                      </p>
                    </ng-template>
                  </td>
                </tr>
              </thead>
            </ng-container>
            <tbody>
              <tr class="table-min-row fs-2">
                <td colspan="10" class="text-end fw-bold">
                  Mapped Line-Item Totals
                </td>
                <td>
                  ${{ep.episodeDetails.totalMappedActualRetailAmount | number : "1.2-2" }}
                </td>
                <td>
                  ${{ep.episodeDetails.totalMappedAdjustedRetailAmount | number : "1.2-2"}}
                </td>
                <td>
                  ${{ep.episodeDetails.totalMappedActualAllowedAmount | number : "1.2-2" }}
                </td>
                <td>
                  ${{ep.episodeDetails.totalMappedAdjustedAllowedAmount | number : "1.2-2" }}
                </td>
              </tr>
              <tr class="table-min-row fs-2">
                <td colspan="10" class="text-end fw-bold">Episode Totals</td>
                <td>
                  ${{ep.episodeDetails.totalActualRetailAmount | number : "1.2-2" }}
                </td>
                <td>
                  ${{ep.episodeDetails.totalAdjustedRetailAmount | number : "1.2-2" }}
                </td>
                <td>
                  ${{ep.episodeDetails.totalActualAllowedAmount | number : "1.2-2" }}
                </td>
                <td>
                  ${{ep.episodeDetails.totalAdjustedAllowedAmount | number : "1.2-2"}}
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
  </div>
</div>