import { Routes } from '@angular/router';

import * as component from '../components';
import * as common from '../common';
import {AuthGuard, ValidTokenGuard, IframeGuard} from '../guards';


export const RoutePublic: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: component.LoginComponent, canActivate: [ValidTokenGuard]},
    { path: 'activate-account', component: component.AccountActivationComponent},
    { path: 'reset-password', component: component.AccountActivationComponent},
    { path: 'active-employees', component: common.HeatmapComponent, canActivate: [AuthGuard]},
    { path: 'service-case/smart-search', component:component.SmartSearchComponent, canActivate:[IframeGuard]},
    { path: 'service-case/care-option-report', component:component.CareOptionReportComponent, canActivate:[IframeGuard]},
    { path: 'invalid', component:common.InvalidComponent},
    { path: 'survey-response/:id', component:component.SurveyResponseComponent}
]