<div class="px-3">
    <app-search-bar [searchConfig]="searchConfig" (baseAction)="openRolesModal('addRole')" [searchList]="rolesList"
        (searchLogEmit)="searchRoles($event)"></app-search-bar>
    <div class="pt-5 mt-5" *ngIf="rolesLoading">
        <loader></loader>
    </div>
    <table class="table table-striped" *ngIf="!rolesLoading">
        <thead>
            <tr>
                <th class="fw-bold">Role Name
                    <!-- <i-bs [name]="reverse ? 'caret-up-fill' : 'caret-down-fill'"></i-bs> -->
                </th>
                <th class="fw-bold">Permissions</th>
                <th class="fw-bold">Created By</th>
                <th class="fw-bold">Created On</th>
                <th class="fw-bold">Updated On</th>
                <th class="fw-bold" style="width: 150px !important;"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngIf="!rolesList.length">
                <td colspan="6" class="fw-bold">Roles not found.</td>
            </tr>
            <tr *ngFor="let role of rolesList |  sort:'asc':'name'">
                <td>{{role.name}}</td>
                <td>
                    <a href="javascript:void(0)" class="link-alt" (click)="openRolesModal('viewPermission', role)"><span
                            class="badge permission-badge p-2">View Permissions</span></a>

                </td>
                <td>
                    {{role.createdBy}}
                </td>
                <td>{{role.createdAt | date: 'MM/dd/yyyy'}}</td>
                <td>{{role.updatedAt | date: 'MM/dd/yyyy'}}</td>
                <td class="p-1">
                    <div mdbDropdown class="dropdown">
                        <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                            aria-expanded="false">
                            <i-bs name="three-dots-vertical"></i-bs>
                        </button>
                        <ul autoClose="true" mdbDropdownMenu class="dropdown-menu dropstart shadow"
                            style="min-width: 120px;">
                            <li>
                                <button class="dropdown-item" type="button" (click)="openRolesModal('editRole', role)">
                                    Edit
                                </button>
                            </li>
                            <li>
                                <button class="dropdown-item" type="button" (click)="openConfirmationModal(role)">
                                    Delete
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>