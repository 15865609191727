import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { ConfirmationModalComponent } from '../../../modal';
import { ProcedureService, ToastrService, HttpCancelService, ExcelService } from '../../../services';
import { Router } from '@angular/router';
import { ExcelHeaders } from 'src/app/constants';


@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit {

  searchKeyword: any = null;
  searchConfig = {
    action: [
      // { label: 'Export', type: 'export', icon: 'download', btnClass: 'btn-clear px-4 pt-2 border'},
      { label: 'Add Procedure', type: 'addProcedure', icon: 'plus', btnClass: 'btn-gradient' }],
    showAdvancedSearch: false,
    showToggle: true,
    formValue: this.searchKeyword,
    module: 'procedure',
    limit: 25,
    exportToggle: true
  }
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;
  procedureList: any = [];
  procedureLoading: boolean = true;
  order = 'procedureName';
  reverse: boolean = false;
  getRequest: any;

  constructor(private modalService: MdbModalService, private procedureService: ProcedureService, private toastrService: ToastrService,
    private router: Router,
    private cancelService: HttpCancelService,
    private excelExportService: ExcelService
  ) { }

  ngOnInit(): void {
    this.getProcedure();
  }

  openConfirmationModal(procedure: any) {
    this.modalRef = this.modalService.open(ConfirmationModalComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        modalTitle: 'Confirmation',
        modalBody: `Are you sure you want to delete <strong>${procedure.procedureName}</strong> ?`,

      }
    });
    this.modalRef.onClose.subscribe((message: any) => {
      if (message == 'confirmDeactivate') {
        this.deleteProcedure(procedure);
      }
    })
  }

  getProcedure(keyword?: any, action?:any) {
    this.procedureLoading = true;
    this.getRequest = this.procedureService.getProcedureList(keyword).pipe(finalize(() => {
      this.procedureLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        if(action === 'all') {
          this.handleExport(response.data.rows);
        }
        else {
          this.procedureList = response.data.rows;
          this.procedureList = this.procedureList.map((x: any) => {
            let cptList = x.procedureMapping.filter((y: any) => y.isPrimary)
            return {
              ...x, primaryCptCodes: cptList?.map((y: any) => y.cptCode).join(', '), collapsed: true
            }
          });
        }
      }
    })
  }

  addProcedure(procedureInfo: any) {
    this.procedureService.addNewProcedure(procedureInfo).pipe(finalize(() => {

    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getProcedure();
      }
    })
  }

  editProcedure(procedureInfo: any) {
    this.procedureService.editProcedure(procedureInfo).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getProcedure();
      }
    })
  }

  deleteProcedure(procedureInfo: any) {
    this.procedureService.deleteProcedure(procedureInfo).pipe(finalize(() => {

    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getProcedure();
      }
    })
  }

  manageProcedure(event: any) {
    switch (event) {
      case 'current':
        this.handleExport();
        break;
      case 'all':
        this.getProcedure({limit:0}, 'all');
        break;
      case 'addProcedure':
        this.router.navigate([`/admin/procedure/create`]);
        break;
      default:
        const queryParams = { name: event.procedureName };
        this.router.navigate([`/admin/procedure/edit/${event.id}`], { queryParams: queryParams });
    }
  }

  searchProcedure(event: any) {
    if (!this.getRequest) {
      this.getProcedure(event);
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getProcedure(event);
    }
  }

  toggleCollapse(item: any) {
    item.collapsed = !item.collapsed;
  }


  handleExport(list?:any) {
    if (list || this.procedureList.length) {
      const content = {
        header: ExcelHeaders.AdminProcedure,
        data: list ? list.map((x: any) => { return { ...x, category: x.category.length ? x.category.map((y: any) => y.name).join(',') : '' } }) : this.procedureList.map((x: any) => { return { ...x, category: x.category.length ? x.category.map((y: any) => y.name).join(',') : '' } }),
        reportTitle: 'Procedure',
        fileName: 'Procedure_Report'
      }
      this.excelExportService.exportExcel(content);
    }
  }
}
