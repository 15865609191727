<form [formGroup]="procedureForm" autocomplete="off" class="onboarding">
  <mdb-tabs [vertical]="true" #procedureTabs class="d-flex align-items-stretch tab-n h-100">
    <mdb-tab>
      <ng-template mdbTabTitle class="pb-3">
        <span class="d-block" [routerLink]="['/admin/procedure']">
          <i-bs name="chevron-left"></i-bs> Go back
        </span>
      </ng-template>
    </mdb-tab>s
    <!-- procedure detail 1st tab -->
    <mdb-tab title="Procedure Details">
      <div class="p-3">
        <p class="fw-bold pt-3 pe-2">Procedure Details</p>
        <div class="col-12 d-flex mb-4 pt-3">
          <label for="procedureName" class="col-sm-3 pt-0">Procedure Name *</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" id="procedureName" formControlName="procedureName" />
          </div>
        </div>
        <div class="col-12 d-flex mb-4">
          <label for="password" class="col-sm-3 pt-0">Category</label>
          <div class="col-sm-5">
            <ng-select class="form-control p-0" [items]="category" bindLabel="name" [selectableGroupAsModel]="false"
              style="height: auto !important;" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
              formControlName="category" placeholder="Select Category">
              <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input type="checkbox" id="{{item.value}}" name="{{item.index}}" class="form-check-input"
                  [checked]="item$.selected" />
                <label class="ps-2" for="item">{{item.name}}</label>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-12 d-flex mb-4">
          <label for="description" class="col-sm-3 pt-0">Description of Services</label>
          <div class="col-sm-5">
            <textarea mdbInput class="form-control" id="description" rows="5" style="height: auto !important;"
              formControlName="description" name="description"></textarea>
          </div>
        </div>
        <div class="col-12 d-flex mb-4">
          <label for="included" class="col-sm-3 pt-0">What's Included</label>
          <div class="col-sm-5">
            <textarea mdbInput class="form-control" id="included" rows="4" style="height: auto !important;"
              formControlName="included" name="included"></textarea>
          </div>
        </div>
        <div class="col-12 d-flex mb-4">
          <div class="col-sm-3 pt-0"></div>
          <div class="col-sm-5">
            <input mdbCheckbox type="checkbox" class="form-check-input" [(ngModel)]="outPatientToggle"
              name="outPatientToggle" [ngModelOptions]="{standalone: true}" [checked]="outPatientToggle" />
            <label for="isOutpatient" class="ps-2">Is Outpatient</label>
          </div>
        </div>
        <hr class="w-100">
        <div class="col-12 d-flex mb-4 pt-2">
          <h5 class="fw-bold  col-sm-3">Pricing List</h5>
          <div class="col-sm-5 d-flex justify-content-end">
            <a href="javascript:void(0)" (click)="addForms('pricingLimit')">
              <i-bs name="plus"></i-bs>Add More
            </a>
          </div>
        </div>
        <div class="col-sm-8">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="fw-bold ps-2" style="width: 200px !important;">State</th>
                <th scope="col" colspan="2" class="fw-bold">Price range</th>
                <th scope="col" class="fw-bold">Action</th>
              </tr>
            </thead>
            <tbody formArrayName="priceLimit">
              <tr *ngIf="!pricingForm.length">
                <th colspan="4" class="fw-bold text-center">No pricings available</th>
              </tr>
              <tr *ngFor="let item of pricingForm.controls; let i = index" [formGroupName]="i">
                <th scope="col-12 d-flex" class="ps-2">
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                    formControlName="state">
                    <ng-option value=0>All</ng-option>
                    <ng-option *ngFor="let option of states | sort:'asc':'name'" value="{{option.value}}">
                      {{option.name}}</ng-option>
                  </ng-select>
                </th>
                <td> <input type="text" class="form-control" id="min" formControlName="min" mask="separator.2"
                    thousandSeparator="," [prefix]="item.value.min ? '$' : ''" placeholder="Min" />
                </td>
                <td> <input type="text" class="form-control" id="max" formControlName="max" mask="separator.2"
                    thousandSeparator="," [prefix]="item.value.max ? '$' : ''" placeholder="Max" />
                </td>
                <td class="pt-3">
                  <a href="javascript:void(0)" class="p-1" (click)="delete(i, item.value, 'priceLimit')">
                    <i-bs name="trash-fill" class="text-danger"></i-bs>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-8 d-flex justify-content-end">
          <button type="button" class="btn btn-primary " [disabled]="procedureForm.invalid" (click)="submitForm()">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="savingProcedure">
              <span class="visually-hidden">Loading...</span>
            </div>Save
          </button>
        </div>

      </div>
    </mdb-tab>
    <!-- procedure mapping 2nd tab -->
    <mdb-tab title="Procedure Mappings" [disabled]="!currentProcedureId">
      <div class="pt-4 ps-2 d-flex justify-content-between">
        <p class="fw-bold pt-3 pe-2 ps-2">Procedure Mappings</p>
        <button type="button" class="btn btn-gradient" (click)="addForms('procedureMapping')">
          <i-bs name="plus"></i-bs>
          Add More
        </button>
      </div>
      <div class="mt-3 mb-3">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="fw-bold" style="width: 550px!important">Procedure Code</th>
              <th scope="col" class="fw-bold text-center">Is Primary</th>
              <th scope="col" class="fw-bold text-center">Action</th>
            </tr>
          </thead>
          <tbody formArrayName="procedureMapping">
            <tr *ngIf="!procedureMappingForm.length">
              <th colspan="3" class="fw-bold text-center">No codes available</th>
            </tr>
            <tr *ngFor="let item of procedureMappingForm.controls; let i = index" [formGroupName]="i">
              <th scope="col-12 d-flex">
                <!-- <div class="input-group">
                  <input type="text" class="form-control" id="procedureCode" formControlName="cptCode" (keyup)="onProcedureKeyup($event)"/>
                </div> -->

                <input mdbInput (ngModelChange)="filter($event)" formControlName="displayProcedureValue"
                  [mdbAutocomplete]="autocomplete" type="text" id="autocomplete" class="form-control" name="cptCode" />
                <mdb-autocomplete #autocomplete="mdbAutocomplete" (selected)="onCptSelect($event, item)">
                  <mdb-option *ngIf="finding">Loading...</mdb-option>
                  <ng-container *ngIf="cptResults.length">
                    <mdb-option *ngFor="let option of cptResults"
                      value="{{option.cptCode + ' - ' + option.shortDescription}}">
                      {{ option.cptCode }} - {{option.shortDescription}}
                    </mdb-option>
                  </ng-container>
                </mdb-autocomplete>
              </th>
              <td class="">
                <div class="d-flex align-items-center justify-content-center">
                  <a href="javascript:void(0)" (click)="toggleIsPrimary(i)">
                    <i-bs [name]="!item.value.isPrimary ? 'toggle-off' : 'toggle-on'"
                      [ngClass]="{'text-primary' : item.value.isPrimary, 'text-gray-main': !item.value.isPrimary}"
                      height="30px" width="30px"></i-bs>
                  </a>
                </div>
              </td>
              <td class="text-center pt-3"> <a href="javascript:void(0)" class="p-1"
                  (click)="delete(i, item.value, 'procedureMapping')">
                  <i-bs name="trash-fill" class="text-danger"></i-bs>
                </a></td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end" *ngIf="procedureMappingForm.length">
          <button type="button" class="btn btn-primary" (click)="submitForm()"
            [disabled]="procedureMappingForm.invalid">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="savingProcedure">
              <span class="visually-hidden">Loading...</span>
            </div>Save
          </button>
        </div>
      </div>
    </mdb-tab>
    <!-- revenue mapping 3rd tab -->
    <mdb-tab title="Revenue Code Mapping" [disabled]="!currentProcedureId">
      <div class="pt-4 ps-2 d-flex justify-content-between">
        <p class="fw-bold pt-3 pe-2 ps-2">Revenue Code Mapping</p>
        <button type="button" class="btn btn-gradient" (click)="addForms('revenueMapping')">
          <i-bs name="plus"></i-bs>
          Add More
        </button>
      </div>
      <div class="mt-3 mb-3">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="fw-bold">Revenue Code</th>
              <th scope="col" class="fw-bold text-center">Action</th>
            </tr>
          </thead>
          <tbody formArrayName="revenueCode">
            <tr *ngIf="!revenueCodeForm.length && !deleting">
              <th colspan="3" class="fw-bold text-center">No codes available</th>
            </tr>
            <div class="pt-5 mt-5" *ngIf="deleting">
              <loader></loader>
            </div>
            <ng-container *ngIf="!deleting">
              <tr *ngFor="let item of revenueCodeForm.controls; let i = index" [formGroupName]="i">
                <th scope="col-12 d-flex">
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                    formControlName="revenueDisplayName" (change)="revenueCodeSelect($event, item)"
                    placeholder="Select revenue code">
                    <ng-option *ngFor="let option of revenueCodeList"
                      value="{{option.revenueCode + ' - ' + option.description}}">
                      {{option.revenueCode}} - {{option.description}}</ng-option>
                  </ng-select>
                </th>
                <td class="text-center pt-3"> <a href="javascript:void(0)" class="p-1"
                    (click)="delete(i, item.value, 'revenueCode')">
                    <i-bs name="trash-fill" class="text-danger"></i-bs>
                  </a>
                </td>
              </tr>
            </ng-container>

          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-primary" *ngIf="revenueCodeForm.length" (click)="submitForm()"
            [disabled]="revenueCodeForm.invalid || savingProcedure">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="savingProcedure">
              <span class="visually-hidden">Loading...</span>
            </div>Save
          </button>
        </div>
      </div>
    </mdb-tab>
  </mdb-tabs>
</form>