import { Component, Input, OnInit } from '@angular/core';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { CompanyService, ToastrService } from 'src/app/services';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import {
  AlertsManagementModelComponent,
  ConfirmationModalComponent,
} from 'src/app/modal';
import { CompanyDropdownConstant } from 'src/app/constants';
import { AlertsModel } from 'src/app/model';
import { finalize } from 'rxjs';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class Alerts implements OnInit {
  @Input() alerts!: any;
  modalRef: MdbModalRef<AlertsManagementModelComponent | ConfirmationModalComponent>;
  searchKeyword: any = null;
  searchConfig = {
    action: { label: 'Add Alert', type: 'addAlert', icon: 'plus' },
    showAdvancedSearch: false,
    showToggle: false,
    formValue: this.searchKeyword,
    module: 'alerts',
    limit: 25,
  };
  constructor(
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    public modalService: MdbModalService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  async openAlertManagemententModel(alert: any, action: any) {
    let alertData: any = {};
    if (alert && action == 'editAlert') {
      alertData = await this.getAlertById(alert);
    }
    this.modalRef = this.modalService.open(AlertsManagementModelComponent, {
      ignoreBackdropClick: true,
      modalClass: 'modal-dialog-centered',
      data: {
        modalTitle: 'Add Alert',
        alerts: CompanyDropdownConstant.alertType,
        alertsModel:
          action == 'editAlert' && alert.alertId
            ? alertData
            : new AlertsModel(),
      },
    });

    this.modalRef.onClose.subscribe((alert: any) => {
      if (action == 'addAlerts' && alert != 'close') {
        this.addAlert(alert);
      }
      if (action == 'editAlert' && alert != 'close') {
        this.editAlert(alert);
      }
    });
  }

  addAlert(alert: any) {
    this.companyService
      .createAlert(alert, this.parentComponent.currentCompanyId)
      .pipe(finalize(() => {}))
      .subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          this.parentComponent.getAlerts();
        }
      });
  }

  getAlertById(alert: any) {
    return new Promise(async (resolve: any) => {
      this.companyService
        .getAlertById(alert, this.parentComponent.currentCompanyId)
        .pipe(finalize(() => {}))
        .subscribe((response: any) => {
          if (response && response.success) {
            resolve(response.data);
          }
        });
    });
  }

  editAlert(alert: any) {
    this.companyService
      .updateAlert(alert, this.parentComponent.currentCompanyId)
      .pipe(finalize(() => {}))
      .subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          this.parentComponent.getAlerts();
        }
      });
  }

  deleteAlert(alert: any) {
    this.companyService
      .deleteAlert(alert, this.parentComponent.currentCompanyId)
      .pipe(finalize(() => {}))
      .subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
          // this.parentComponent.getAlerts();
        }
      });
  }


  openConfirmationModel(index: number, item: any) {
    if (item.alertId) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-sm,  modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete ${item.benefitName}`,
        },
      });
      this.modalRef.onClose.subscribe((message: string) => {
        if (message == 'confirmDeactivate') {
          this.alerts.splice(index, 1);
          this.deleteAlert(item);
        }
      });
    }
  }
}
