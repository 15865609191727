import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { ExcelHeaders, PDFReportConstant } from 'src/app/constants';
import { ExcelService, PdfService, ReportService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';

@Component({
  selector: 'app-advocacy-satisfaction-report',
  templateUrl: './advocacy-satisfaction-report.component.html',
  styleUrls: ['./advocacy-satisfaction-report.component.scss']
})
export class AdvocacySatisfactionReportComponent {
  loading: boolean = false;
  reportDetails: any = false;
  exporting:boolean = false;
  filterParams:any;
  reportConstants = PDFReportConstant.advocacySatisfactionReport;
  constructor(private reportService: ReportService, private excelExportService:ExcelService, private pdfService :PdfService) { }

  handleBaseAction(event: any) {
    if (event == 'reset') {
      this.reportDetails = false;
      this.loading = false;
    }
    else {
      this.loading = true;
      this.filterParams = event;
      this.getReportDetails(event)
    }
  }

  exportExcel() {
    let reportList = this.reportDetails?.satisfactionScoreDetail.map((x:any)=>{
      return {
        ...x, avarageTotalScorePercentage : `${Math.round(x.avarageTotalScorePercentage)}%`, nps: Math.round(x.nps)
      }
    })
    this.reportDetails.companyNpsDetail.nps = Math.round(this.reportDetails?.companyNpsDetail?.nps);
    const content = {
      header: ExcelHeaders.AdvocateSatisfactionReport,
      data: reportList.sort((a: any, b: any) => a.name > b.name ? 1 : -1),
      reportTitle: 'Advocate Satisfaction Report',
      fileName: `Advocate Satisfaction Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      hasFooter: false,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`,
      summaryBlock : [`Alithias Net Promoter Score: ${this.reportDetails?.companyNpsDetail?.nps}`]
    }
    this.excelExportService.exportExcel(content);
  }

  exportPdf() {
    return new Promise(async resolve => {
      this.exporting = true;
      let section:any = document.getElementById("pdf");
      section.style.display = "block";
      let advSatisfactionReport: any = await this.pdfService.captureTableWithHeader('advocate-satisfaction', 
      {header: 'Care Advocacy Satisfaction Report', date:`Report Period: ${moment(this.filterParams?.from).format('MM/DD/YYYY')} to ${moment(this.filterParams?.to).format('MM/DD/YYYY')}`,
      npsScore: `Alithias Net Promoter Score : ${Math.round(this.reportDetails?.companyNpsDetail?.nps)}`});
      let fileName = `advocateSatisfactionReport`
      let pdfString = advSatisfactionReport.output('datauristring');
      resolve({
        filename: fileName + '-' + new Date().getTime() + '.pdf',
        content: pdfString ? pdfString.split(',')[1] : "",
        encoding: 'base64'
      });
      // this.generating = false;
      window.open(advSatisfactionReport.output('bloburl'), '_blank');
      this.exporting = false;
      section.style.display = "none";
      resolve(1)
    })
  }

  getReportDetails(params:any) {
    this.reportService.getAdvocacyReport({ createdFrom: moment(params.from).format('MM/DD/YYYY'), createdTo: moment(params.to).format('MM/DD/YYYY') }).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.reportDetails = response.data;
      }
    })
  }
}
