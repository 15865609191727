<div [formGroup]="ancillaryBenefitForm" class="pt-3 general">
    <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <p class="fw-bold pt-3 ps-2 pe-2">Ancillary Benefit Program</p>
            <div class="spinner-border spinner-border-sm" role="status"
                *ngIf="parentComponent.loadingAncillaryBenifitProgram">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <button type="button" class="btn btn-primary"
            (click)="addInfoForms(ancillaryBenefitForm, 'ancillaryBenefitInfo')">
            <i-bs name="plus" class="pe-1"></i-bs> Add New Ancillary Benefits
        </button>
    </div>
    <div formArrayName="ancillaryBenefitInfo">
        <div *ngIf="!getFormArray(ancillaryBenefitForm, 'ancillaryBenefitInfo').length">
            <p class="text-center">No Ancillary Benefit Program</p>
        </div>
        <div *ngFor="let item of getFormArray(ancillaryBenefitForm, 'ancillaryBenefitInfo').controls; let i = index"
            [formGroupName]="i">
            <div class="col-12 pb-4">
                <button
                    class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
                    type="button" (click)="ancillaryBenefitsCollapse.toggle()"
                    [attr.aria-expanded]="!ancillaryBenefitsCollapse.collapsed" aria-controls="collapseExample">
                    <span class="text-capitalize">
                        <i-bs [name]="!ancillaryBenefitsCollapse.collapsed ? 'chevron-down' : 'chevron-up'"
                            class="pe-1"></i-bs>
                        {{item.get('vendorName')?.value || 'New Ancillary Benefits'}}
                    </span>
                    <span>
                        <i-bs name="trash-fill" class="pull-right text-gray-main"
                            (click)="openConfirmationModel(i, item, 'ancillaryBenefitInfo', ancillaryBenefitForm)"></i-bs>
                    </span>
                </button>
            </div>
            <div class="pb-3" id="collapseExample" mdbCollapse #ancillaryBenefitsCollapse="mdbCollapse"
                [collapsed]="false">
                <div class="px-2 collapse-container">
                    <div class="d-flex justify-content-between pt-3">
                        <div class="col-6">
                            <div class="col-12 d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Program Type</label>
                                <div class="col-7">
                                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                                        formControlName="programType" name="programType">
                                        <ng-option
                                            *ngFor="let option of  parentComponent.dropdownConstants.ancillaryBenefitProgramType| sort:'asc':'name'"
                                            value="{{option.value}}">
                                            {{option.name}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center pt-3">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Name</label>
                                <div class="col-7">
                                    <input type="text " class="form-control" id="vendorName"
                                        formControlName="vendorName" name="vendorName" />
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center pt-3">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Phone</label>
                                <div class="col-7">
                                    <input type="text" class="form-control" id="vendorPhone"
                                        formControlName="vendorPhone" name="vendorPhone" mask="(000) 000-0000" />
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center pt-3">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Member Email</label>
                                <div class="col-7">
                                    <input type="text" class="form-control" id="vendorEmail"
                                        formControlName="vendorEmail" name="vendorEmail"
                                        [pattern]="parentComponent.mailformat" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="col-12 d-flex align-items-center">
                                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Portal URL</label>
                                <div class="col-7">
                                    <input type="text" class="form-control" id="vendorPortalUrl"
                                        formControlName="vendorPortalUrl" name="vendorPortalUrl" />
                                </div>
                            </div>
                            <div class="col-12 pt-3">
                                <div class="d-flex align-items-center">
                                    <label for="name" class="pt-0 col-5 text-end pe-3">Program Notes</label>
                                    <div class="col-7">
                                        <textarea mdbInput class="form-control" id="textAreaExample" rows="3"
                                            formControlName="programNotes" name="programNotes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divider py-3">
                        <hr class="divider-hr">
                        <span class="divider-label fs-3">Vendor Details</span>
                    </div>
                    <table class="table table-div table-reponsive">
                        <thead>
                            <tr>
                                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Contact Name
                                </th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 400px !important;">Contact Type</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 1</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 100px !important;">Ext</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 200px !important;">Phone 2</th>
                                <th scope="col" class="fs-2 fw-bold" style="width: 300px !important;">Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody formArrayName="vendorDetails">
                            <tr *ngIf="!getFormArray(item, 'vendorDetails').length">
                                <th colspan="6" class="fw-bold text-center">No contact info</th>
                            </tr>
                            <tr *ngFor="let contactForm of getFormArray(item, 'vendorDetails').controls; let i = index"
                                [formGroupName]="i">
                                <td> <input type="text" class="form-control" id="contactName"
                                        formControlName="contactName" name="contactName" /></td>
                                <td> <input type="text" class="form-control" id="contactType"
                                        formControlName="contactType" name="contactType" /></td>
                                <td> <input type="text" class="form-control" id="phone1" formControlName="phone1"
                                        name="phone1" mask="(000) 000-0000" /></td>
                                <td> <input type="text" class="form-control" id="extension" formControlName="extension"
                                        name="extension" maxlength="6" minlength="4" /></td>
                                <td> <input type="text" class="form-control" id="phone2" formControlName="phone2"
                                        name="phone2" mask="(000) 000-0000" /></td>
                                <td> <input type="text" class="form-control" id="email" formControlName="email"
                                        name="email" [pattern]="parentComponent.mailformat" /></td>
                                <td>
                                    <i-bs *ngIf="!contactForm.value.loading" name="trash-fill"
                                        class="pointer text-gray-main"
                                        (click)="delete(i, contactForm.value, 'vendorDetails', item)"></i-bs>
                                    <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                                        *ngIf="contactForm.value.loading">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <a class="text-primary pointer" type="button" (click)="addContactForms(item, 'vendorDetails')"><i-bs
                        name="plus"></i-bs>Add
                    More</a>
                <hr class="mt-5">
            </div>
        </div>
        <hr>
    </div>
</div>
<div class="d-flex justify-content-end pb-3" *ngIf="getFormArray(ancillaryBenefitForm, 'ancillaryBenefitInfo').length">
    <button type="button" class="btn btn-primary " (click)="saveForms()" [disabled]="!ancillaryBenefitForm.valid">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
            <span class="visually-hidden">Loading...</span>
        </div>
        Save
    </button>
</div>