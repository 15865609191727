import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ExcelTemplate } from '../constants'

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ExcelService {
  constructor() { }

  exportExcel(reportContent: any) {
    // Create workbook and worksheet
    let workbook = new Workbook();
    let worksheet: any = workbook.addWorksheet(reportContent.reportTitle);
    //Adding Title of the
    const titleRow = worksheet.addRow([reportContent.reportTitle]);
    titleRow.font = { bold: true, size: 15 };
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
    // Adding subtitle
    if (reportContent.subTitle) {
      const subtitleRow = worksheet.addRow([reportContent.subTitle]);
      subtitleRow.font = { bold: true };
      subtitleRow.alignment = { vertical: 'right', horizontal: 'right' };
    }
    if (reportContent.summaryBlock?.length) {
      reportContent.summaryBlock.forEach((x: any) => {
        const subtitleRow = worksheet.addRow([x]);
        subtitleRow.font = { bold: true };
        subtitleRow.alignment = { vertical: 'right', horizontal: 'right' };
      })

    }
    //Adding column headers
    const headerRow: any = worksheet.getRow(worksheet.addRow([]).number + 1);
    headerRow.fill = ExcelTemplate.GREY_COLUMN;
    headerRow.values = Array.from(reportContent.header.values());
    worksheet.columns = Array.from(reportContent.header.keys()).map((y: any, index: any = 0) => {
      return {
        key: y,
        width: !index ? 50 : 15
      };
    })
    //Cell style: fill and Border
    headerRow.eachCell((cell: any) => {
      cell.font = { bold: true }
    })
    reportContent.data.forEach((e: any, i: number) => {
      let row = worksheet.addRow(e);
      if (reportContent.hasFooter && reportContent.data.length === i + 1) {
        row.font = { bold: true };
      }
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, reportContent.fileName + EXCEL_EXTENSION);
    })
  }
}

