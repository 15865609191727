import { Injectable } from '@angular/core';
import { HttpClientService } from "./http-client.service";
import { ApiConstant } from "../constants";
import { map } from 'rxjs/operators';
import { QueryParams } from '../utils';


@Injectable({
    providedIn: 'root'
})
export class ProviderService {
    constructor(
        private http: HttpClientService
    ) { }

    getProvider(keyword:any) {
        const queryParams = QueryParams.addParams(keyword);
        return this.http.get(`${ApiConstant.PROVIDER}?` + queryParams)
        .pipe(map((response:any) => {
            let list = response?.data?.rows;
            list = list.map((x:any)=>{
                return {...x, displayName: x.businessName + ' ('+ x.npi+')'}
            })
            return list;
        }))

    }
}