<div class="px-3">
  <div class="col">
    <div class="">
      <app-report-header></app-report-header>
    </div>
    <div class="pt-4">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-between">
          <p class="fw-bold ps-1">Account Status Update</p>
        </div>
      </div>
      <div class="col-12 pt-3">
        <table class="table table-report">
          <thead>
            <tr class="fs-2">
              <th scope="col col-3" class="fw-bold">
                <div class="d-flex align-items-center justify-content-between">
                  Account
                  <i-bs name="chevron-expand"></i-bs>
                </div>
              </th>
              <th scope="col" class="fw-bold">Brokerage</th>
              <th scope="col" class="fw-bold">TPA</th>
              <th scope="col" class="fw-bold">Utilization</th>
              <th scope="col" class="fw-bold">On Plan</th>
              <th scope="col" class="fw-bold">Dependents</th>
              <th scope="col" class="fw-bold">Total on plan</th>
              <th scope="col" class="fw-bold">Plan Start</th>
              <th scope="col" class="fw-bold">Report Start</th>
              <th scope="col" class="fw-bold">Report End</th>
              <th scope="col" class="fw-bold">Eligibility Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of accountStatusData, let i = index">
              <tr [ngClass]="{'row-striped': i % 2 === 0}" class="fs-2">
                <td>
                  <a href="javascript:void(0)" class="pe-1 text-gray-darker" role="button"
                    [attr.aria-expanded]="!item.collapsed" aria-controls="collapseExample"
                    (click)="toggleCollapse(item)">
                    <i-bs [name]="item.collapsed ? 'plus-circle' : 'dash-circle'"></i-bs>
                  </a>
                  {{ item.companyName }}
                </td>
                <td>{{ item.brokerName }}</td>
                <td>{{ item.tpaName }}</td>
                <td>{{ item.utilization | percent: '1.0-2'}}</td>
                <td>{{ item.totalEmployees }}</td>
                <td>{{ item.totalDependants }}</td>
                <td>{{ item.combinedOnPlan }}</td>
                <td>{{ item.reportPeriodStart }}</td>
                <td>{{ item.reportStartDate }}</td>
                <td>{{ item.reportEndDate }}</td>
                <td>{{ item.eligibilityMonth }}/{{ item.eligibilityYear }}</td>
              </tr>
              <tr style="border:transparent" id="collapseExample" mdbCollapse #item="mdbCollapse"
                [collapsed]="item.collapsed">
                <td colspan="11" style="padding: 0 !important;" class="fs-2">
                  <div class="light-bg">
                    <div class="d-flex col-12 p-2">
                      <div class="col-4 pe-1">
                        <div class="bg-white p-2">
                          <p class="fw-bold">No of Calls</p>
                          <div class="col-12 d-flex ">
                            <div class="col-3">
                              <p>Total Calls:</p>
                              <p>BS Calls:</p>
                              <p>CN Calls:</p>
                            </div>
                            <div class="col-9">
                              <p class="fw-bold">{{ item.totalCalls }}</p>
                              <p class="fw-bold">{{ item.totalBs }}</p>
                              <p class="fw-bold">{{ item.totalCn }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 ps-1">
                        <div class="p-2 bg-white">
                          <p class="fw-bold">Savings</p>
                          <div class="col-12 d-flex">
                            <div class="col-6">
                              <div class="col-12 d-flex">
                                <div class="col-6">
                                  <p>Offered Savings:</p>
                                  <p>Incentive Paid:</p>
                                  <p>Lost Savings:</p>
                                </div>
                                <div class="col-6">
                                  <p class="fw-bold">{{ item.offeredSavings }}</p>
                                  <p class="fw-bold">{{ item.totalIncentive }}</p>
                                  <p class="fw-bold">{{ item.lostSaving }}</p>
                                </div>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="col-12 d-flex">
                                <div class="col-6">
                                  <p>Actual Savings Offered:</p>
                                  <p>Actual Savings YTD:</p>
                                  <p>Offered Savings YTD:</p>
                                </div>
                                <div class="col-6">
                                  <p class="fw-bold">{{ item.actualSavings }}</p>
                                  <p class="fw-bold">{{ item.actualSavingYtd }}</p>
                                  <p class="fw-bold">{{ item.offeredSavingYtd }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>