import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs';
import { DirectPrimaryCareModel } from '../../../../../model';
import {
  CompanyService,
  FormInitService,
  ToastrService,
} from '../../../../../services';
import { CompanyAddEditComponent } from '../company-add-edit.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmationModalComponent } from 'src/app/modal';
@Component({
  selector: 'app-direct-primary-care',
  templateUrl: './direct-primary-care.component.html',
  styleUrls: ['./direct-primary-care.component.scss'],
})
export class DirectPrimaryCareComponent implements OnInit {
  @Input() directPrimaryCare: any;
  directPrimaryCareForm: UntypedFormGroup;
  saving: boolean = false;
  modalRef: MdbModalRef<ConfirmationModalComponent> | null = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formInit: FormInitService,
    public parentComponent: CompanyAddEditComponent,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private modalService: MdbModalService
  ) {
    this.directPrimaryCareForm = this.formBuilder.group({
      directPrimaryInfo: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void { }

  ngOnChanges() {
    this.addDirectPrimaryCareForm(this.directPrimaryCare);
  }

  addDirectPrimaryCareForm(data: any) {
    let formArray = this.getFormArray(
      this.directPrimaryCareForm,
      'directPrimaryInfo'
    );
    formArray.clear();
    if (data) {
      data.forEach((obj: any) => {
        this.addInfoForms(this.directPrimaryCareForm, 'directPrimaryInfo', obj);
      });
    }
  }

  addInfoForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newService = this.initValueBasedForm(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newService) : formArray.insert(0, newService);
    if (value.addressDetail.length) {
      value.addressDetail.forEach((obj: any) => {
        this.addAddressForms(newService, 'addressDetail', obj);
      });
    }
  }

  initValueBasedForm(value?: any) {
    return this.formInit.createFormGroup(
      DirectPrimaryCareModel.setDirectPrimaryCareModel(value)
    );
  }

  getControl(fromControl: any, controlName: string): UntypedFormGroup {
    return fromControl.controls[controlName] as UntypedFormGroup;
  }

  getFormArray(controlName: any, arrayName: string): UntypedFormArray {
    return controlName.controls[arrayName] as UntypedFormArray;
  }

  delete(index: number, item: any, arrayName: any, formControlName: any) {
    item.loading = true;
    let control = <UntypedFormArray>formControlName.controls[arrayName];
    if (item.addressId) {
      this.companyService.deleteDPCAddress(this.parentComponent.currentCompanyId, formControlName.controls['primaryCareId'].value, item).pipe(finalize(() => {
        item.loading = false;
      })).subscribe((response: any) => {
        if (response && response.success) {
          control.removeAt(index);
          this.toastrService.showSuccess(response.message);
        }
      })
    }
    else {
      control.removeAt(index);
    }
  }

  openConfirmationModal(
    index: number,
    item: any,
    arrayName: any,
    formControlName: any
  ) {
    if (
      item.value.primaryCareId
    ) {
      this.modalRef = this.modalService.open(ConfirmationModalComponent, {
        modalClass: 'modal-dialog-centered',
        ignoreBackdropClick: true,
        data: {
          modalTTitle: 'Delete Confirmation',
          modalBody: `Are you sure  you want to delete <strong> ${item.value.clinicName}</strong> ?`,
        },
      });

      this.modalRef.onClose.subscribe((message: any) => {
        if (message == 'confirmDeactivate') {
          let control = <UntypedFormArray>formControlName.controls[arrayName];
          control.removeAt(index);
          this.saveForms()
        }
      });
    } else {
      let control = <UntypedFormArray>formControlName.controls[arrayName];
      control.removeAt(index);
    }
  }

  saveForms() {
    this.saving = true;
    if (this.parentComponent.currentCompanyId) {
      this.companyService
        .updateDirectPrimaryCare(
          this.parentComponent.currentCompanyId,
          this.directPrimaryCareForm.value.directPrimaryInfo
        )
        .pipe(
          finalize(() => {
            this.saving = false;
          })
        )
        .subscribe((response: any) => {
          if (response && response.success) {
            this.toastrService.showSuccess(response.message);
            this.addDirectPrimaryCareForm(response.data);
          }
        });
    }
  }

  async addAddressForms(formName: any, arrayName: any, value?: any) {
    let formArray: any;
    const newContactDetails = this.initAddressForm(value);
    formArray = this.getFormArray(formName, arrayName);
    value ? formArray.push(newContactDetails): formArray.insert(0, newContactDetails);
  }

  initAddressForm(value?: any) {
    return this.formBuilder.group({
      addressLine: [value?.addressLine],
      city: [value?.city],
      state: [value?.state],
      zip: [value?.zip],
      phone: [value?.phone],
      email: [value?.email],
      fax: [value?.fax],
      addressId: [value?.addressId]
    });
  }
}
