import { FormFields } from '../interfaces';

import { Injectable } from "@angular/core";

@Injectable()
export class DirectPrimaryCareModel {
    static setDirectPrimaryCareModel(value? :any){
        let model : FormFields[] = [
            {
                name: "primaryCareId",
                id: "primaryCareId",
                controlType: 'input',
                controlName: "primaryCareId",
                required: true,
                value: value?.primaryCareId || null
            },
            {
                name: "clinicName",
                id: "clinicName",
                controlType: 'input',
                controlName: "clinicName",
                required: true,
                value: value?.clinicName || ''
            },
            {
                name: 'clinicDetails',
                id: 'clinicDetails',
                controlType: 'input',
                controlName: 'clinicDetails',
                required: true,
                value: value?.clinicDetails || ''
            },
            {
                controlType: 'addressDetail',
                controlName: 'addressDetail',
                name: 'addressDetail',
                id: 'addressDetail',
                value: value?.addressDetail || [],
                formArray:[]
            }
        ]
        return model;
    } 
}