import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';

import { ExcelHeaders } from 'src/app/constants';
import { ExcelService, ReportService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';


@Component({
  selector: 'app-lucky-report',
  templateUrl: './lucky-report.component.html',
  styleUrls: ['./lucky-report.component.scss'],
})
export class LuckyReportComponent {
  loading: boolean = false;
  luckyList: any = false;
  exporting: boolean = false;
  filterParams:any;

  constructor(
    private reportService: ReportService,
    private excelExportService: ExcelService
  ) { }

  handleBaseAction(event: any) {
    if (event !== 'reset') {
      this.loading = true;
      this.filterParams = event;
      this.getLuckyReport({
        companyId: event?.company?.alithiasCompanyId,
        createdFrom: moment(event.from).format('MM/DD/YYYY'),
        createdTo: moment(event.to).format('MM/DD/YYYY'),
      });
    } else {
      this.loading = false;
      this.luckyList = false;
    }
  }

  getLuckyReport(params: any) {
    this.reportService
      .getLuckyReport(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.luckyList = response.data[0];
        }
      });
  }

  exportExcel() {
    let reportList = this.luckyList.caseDate.map((item: any) => {
      return {
        caseId: item.caseId,
        procedure: item?.service.length ? item.service[0].name : '',
        dateOfService: item.dateOfService ? moment(item.dateOfService).format('MM/DD/YYYY') : null,
        estCost: (item.providerEstCost?.amount || item.providerEstCost) ? parseInt(item.providerEstCost?.amount || item.providerEstCost) : 0,
        stateAvg: (item.providerStateAverage?.amount || item.providerStateAverage) ?  parseInt(item.providerStateAverage?.amount || item.providerStateAverage) : 0,
        providerName: item.providerName,
        patientName: `${item.patientFirstName + ' ' + item.patientLastName}`,
        employeeId: item.employeeId,
        providerValue: item.providerValue,
        totalPeopleCallForService: '',
        totalPeopleUsedService: '',
        totalPeopleUsedHighValueProvider: '',
        totalPeopleUsedLowValueProvider: '',
      };
    });

    reportList[0].totalPeopleCallForService =
      this.luckyList.peopleCallForService;
    reportList[0].totalPeopleUsedService = this.luckyList.peopleUseTheService;
    reportList[0].totalPeopleUsedHighValueProvider =
      this.luckyList.highValueProvider;
    reportList[0].totalPeopleUsedLowValueProvider =
      this.luckyList.lowValueProvider;
    const content = {
      header: ExcelHeaders.LuckyReport,
      data: reportList,
      reportTitle: 'Lucky Report',
      fileName: `${this.filterParams.company.clientLegalName}-Lucky Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`
    };
    this.excelExportService.exportExcel(content);

  }
}
