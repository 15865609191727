import { Component } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { ExcelHeaders } from 'src/app/constants';
import { ExcelService, ReportService } from 'src/app/services';
import { DateCalc } from 'src/app/utils';

@Component({
  selector: 'app-reason-code-report',
  templateUrl: './reason-code-report.component.html',
  styleUrls: ['./reason-code-report.component.scss']
})
export class ReasonCodeReportComponent {
  loading:boolean=false;
  reasonCodeList:any = false;
  exporting:any;
  filterParams:any;

  constructor(
    private reportService: ReportService,
    private excelExportService: ExcelService
  ) {}

  handleBaseAction(event:any) {
    if (event !== 'reset') {
      this.loading = true;
      this.filterParams = event;
      this.getReasonCodeList({
        companyId: event?.company?.alithiasCompanyId,
        createdFrom: moment(event.from).format('MM/DD/YYYY'),
        createdTo: moment(event.to).format('MM/DD/YYYY'),
      });
    } else {
      this.loading = false;
      this.reasonCodeList = false;
    }
  }

  exportExcelReport() {
    let data = this.reasonCodeList?.serviceRequests.map((x:any)=>{
      return  {
        ...x, createdDate: x.createdDate ? moment(x.createdDate).format('MM/DD/YYYY') :null,
        date : x.date ? moment(x.date).format('MM/DD/YYYY') :null
      }
    })
    const content = {
      header: ExcelHeaders.ReasonCodeReport,
      reportTitle: 'Reason Code Report',
      fileName:`${this.filterParams.company.clientLegalName}-Reason Code Report-${DateCalc.reportFormating(this.filterParams.from)}_${DateCalc.reportFormating(this.filterParams.to)}`,
      data: data,
      subTitle: `From:${moment(this.filterParams.from).format('MM/DD/YYYY')}   To:${moment(this.filterParams.to).format('MM/DD/YYYY')}`,
      hasFooter: false ,
      summaryBlock : [`Total Calls Received : ${this.reasonCodeList.totals[0]?.totalCalls}`,  `Care Navigator Calls : ${this.reasonCodeList.totals[0]?.totalCn}`, 
      `Benefit Support Calls : ${this.reasonCodeList.totals[0]?.totalBs}`,
      `Top Reason Codes CN : ${this.reasonCodeList.careNavCalls.map((x:any)=>x.callRegarding + '(' + x.percentage + '%)')}`,
      `Top Reason Codes BS :  ${this.reasonCodeList.benefitCalls.map((x:any)=>x.callRegarding + '(' + x.percentage + '%)')}`
      ]
    };
    this.excelExportService.exportExcel(content);
  
  }

  getReasonCodeList(params:any) {
    this.reportService.getReasonCodeReport(params).pipe(finalize(()=>{
      this.loading = false;
    })).subscribe((response:any)=>{
      if(response && response.success) {
        this.reasonCodeList = response.data;
      }
    })
  }

}
