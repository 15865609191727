import { Injectable } from '@angular/core';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';


@Injectable({
  providedIn: 'root'
})
export class PdfService {
  captureCareReportScreen(networkList: any, genericNoteList: any) {
    return new Promise(async (resolve) => {
      let pdfSections: any = [];
      const a4PageHeight = 285;
      const a4PageWidth = 200
      await this.addSectionsBeforeProviders(pdfSections);
      await this.addDynamicSection('provider', pdfSections, networkList);
      await this.addDynamicSection('genericNote', pdfSections, genericNoteList);

      let footerImg: any = await this.convertToImageFromHtml("report-footer", true);
      let fillerImg: any = await this.convertToImageFromHtml("blank-space", true);
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF 

      while (this.getHeightByPage(pdfSections, 1) > a4PageHeight) {
        const pageOne = pdfSections.filter((x: any) => x.page == 1);
        pdfSections[pageOne.length - 1].page = 2;
      }

      // generate page 1
      let lastSectionIndex = 0
      pdfSections.filter((x: any) => x.page == 1).forEach((section: any, index: number) => {
        lastSectionIndex = index + 1;
        pdf.addImage(section.img, 'JPEG', 5, this.getHeightByIndex(pdfSections, index), a4PageWidth, section.height);
      })
      let fillerHeight = a4PageHeight - this.getHeightByPage(pdfSections, 1); // calculate fillter image for maintaing footer
      pdf.addImage(fillerImg.img, 'JPEG', 5, this.getHeightByIndex(pdfSections, lastSectionIndex), a4PageWidth, fillerHeight);
      pdf.addImage(footerImg.img, 'JPEG', 5, this.getHeightByIndex(pdfSections, lastSectionIndex) + fillerHeight, a4PageWidth, footerImg.height);
      pdf.setFontSize(8); // set font size
      pdf.setTextColor('#666'); // set text color
      pdf.setFont('Roboto'); // set font
      // pdf.text('Page ' + 1, 190, a4PageHeight + footerImg.height); // add page number

      // generate page 2
      const pageTwoSections = pdfSections.filter((x: any) => x.page == 2);
      if (pageTwoSections.length > 0) {
        pdf.addPage();
        pdf.addImage(pdfSections[0].img, 'JPEG', 5, 0, a4PageWidth, pdfSections[0].height);

        lastSectionIndex = 0;
        pdfSections.filter((x: any) => x.page == 2).forEach((section: any, index: number) => {
          lastSectionIndex = index + 1;
          pdf.addImage(section.img, 'JPEG', 5, this.getHeightByIndex(pageTwoSections, index) + pdfSections[0].height, a4PageWidth, section.height);
        })
        fillerHeight = a4PageHeight - this.getHeightByPage(pdfSections, 2) - pdfSections[0].height;
        pdf.addImage(fillerImg.img, 'JPEG', 5, this.getHeightByIndex(pageTwoSections, lastSectionIndex) + pdfSections[0].height, a4PageWidth, fillerHeight);
        pdf.addImage(footerImg.img, 'JPEG', 5, this.getHeightByIndex(pageTwoSections, lastSectionIndex) + fillerHeight + pdfSections[0].height, a4PageWidth, footerImg.height);
        pdf.setFontSize(8);
        pdf.setTextColor('#666');
        pdf.setFont('Roboto');
        // pdf.text('Page ' + 2 , a4PageHeight, a4PageHeight + footerImg.height);
      }
      resolve(pdf);
    })
  }

  getHeightByPage(pdfSections: any, pageNumber: number) {
    return pdfSections.filter((x: any) => x.page == pageNumber).reduce((partialSum: any, section: any) => partialSum + section.height, 0);
  }
  getHeightByIndex(pdfSections: any, index: number) {
    let totalHeight = 0
    for (let i = 0; i < index; i++) {
      totalHeight += pdfSections[i].height;
    }
    return totalHeight;
  }
  private convertHtmlToCanvas(htmlElementId: any) {
    return new Promise(resolve => {
      let htmlElement: any = document.getElementById(htmlElementId);
      html2canvas(htmlElement, {
        scale: 2,
        scrollX: 0, // top whitespace solution in pdf generation
        scrollY: -window.scrollY  // top whitespace solution in pdf generation
      }).then(canvas => {
        resolve(canvas);
      });
    })
  }

  addSectionsBeforeProviders(pdfSections: any) {
    return new Promise(async (resolve) => {
      let htmlTags = ["care-option-header", "info", "general-info", "service-info", "whats-included", "procedure-info"]
      let i = 0;
      for (const id of htmlTags) {
        const imgObj: any = await this.convertToImageFromHtml(id, true);
        i++;
        imgObj["order"] = i;
        imgObj["page"] = 1;
        pdfSections.push(imgObj);
        if (i === htmlTags.length) {
          resolve(pdfSections);
        }
      }
    })
  }

  convertToImageFromHtml(tag: any, careOption: any) {
    return new Promise(async (resolve) => {
      const canvas: any = await this.convertHtmlToCanvas(tag);
      const imgWidth = 200;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpeg');
      if (careOption) {
        resolve({ tag: tag, img: contentDataURL, height: imgHeight });
      }
      else {
        const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PD
        pdf.addImage(contentDataURL, 'JPEG', 0, 0, 210, imgHeight);
        resolve(pdf);
      }
    });
  }

  addDynamicSection(tagName: any, pdfSections: any, dynamicList: any) {
    return new Promise(async (resolve) => {
      if (dynamicList?.length == 0) {
        resolve('');
      }
      for (let i = 0; i < dynamicList?.length; i++) {
        const imgObj: any = await this.convertToImageFromHtml(`${tagName}-${i}`, true);
        imgObj["order"] = pdfSections.length + 1;
        imgObj["page"] = 1;
        pdfSections.push(imgObj);
        if (i === dynamicList?.length - 1) {
          resolve(pdfSections);
        }
      }
    })
  }

  captureTable(contentId: any, headerContent?: any) {
    return new Promise(async (resolve) => {
      let pdf = new jsPDF();
      pdf.setFont("Helvetica");
      pdf.setFontSize(15);
      pdf.text(headerContent.companyName, 100, 15, { maxWidth: 80, align: 'center' });
      pdf.text(headerContent.header, 150, 15);
      pdf.setFontSize(7);
      pdf.text(headerContent.date, 150, 20);
      if (headerContent.reportPlanDate) {
        pdf.text(headerContent.reportPlanDate, headerContent.reportPlanDate.length == 57 ? 140 : 150, 25);
      }
      // if(headerContent.isBase64) {
      //   pdf.addImage( headerContent.imgPath, 'PNG', 10, 10, 0, 10, '', 'FAST') ;
      // }
      // else{
      //   var img = new Image();
      //   img.src = headerContent.imgPath;
      //   pdf.addImage(img, 'PNG', 10, 10, 0, 10, '', 'FAST') ;
      // }
      let img = new Image();
      img.src = headerContent.imgPath;
      img.onload = () => {
        pdf.addImage(img, 'PNG', 10, 10, 0, 10, '', 'FAST');
        pdf.setDrawColor(214, 214, 214);
        pdf.line(5, headerContent.reportPlanDate ? 27 : 25, 205, headerContent.reportPlanDate ? 27 : 25);
        (pdf as any).autoTable({
          html: `#${contentId}`,
          startY: 30,
          tableWidth: 'auto',
          headStyles: { fillColor: '#d5d5d5', textColor: '#666' },
          styles: { overflow: 'linebreak', fontSize: 7 },
        })
        let numOfPages: any = pdf.internal.pages;
        if (numOfPages > 1) {
          for (let i = 1; i <= numOfPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor('#999');
            pdf.text('Page ' + i, 208 / 2, 290);
          }
        }
        resolve(pdf);
      };
    })
  }

  captureTableWithHeader(contentId: any, headerContent?: any) {
    return new Promise(async (resolve) => {
      let pdf = new jsPDF();
      pdf.setFont("Helvetica");
      pdf.setFontSize(15);
      pdf.text(headerContent.header, 10, 15);
      pdf.setFontSize(8);
      pdf.text(headerContent.date, 10, 22);
      pdf.text(headerContent.npsScore, 10, 28);
      (pdf as any).autoTable({
        html: `#${contentId}`,
        startY: 32,
        headStyles: { fillColor: '#d5d5d5', textColor: '#666' },
        styles: { overflow: 'linebreak', fontSize: 7 }
      })
      // For PDF Pagination
      let numOfPages: any = pdf.internal.pages;
      if (numOfPages > 1) {
        for (let i = 1; i <= numOfPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor('#999');
          pdf.text('Page ' + i, 208 / 2, 290);
        }
      }
      resolve(pdf)
    });
  }
}