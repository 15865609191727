import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { finalize, mergeMap } from 'rxjs';

import {
  CommonListService,
  EnterpriseService,
  HttpCancelService,
  MemberService,
  ProcedureService,
} from 'src/app/services';
import { environment } from '../../../../environments/environment';
import { ClaimsSearchModel, SmartSearchModel } from 'src/app/model';
import { Sanitizer } from '../../../utils';
import { MetaDataDropdownConstant } from 'src/app/constants';

@Component({
  selector: 'app-smart-search',
  templateUrl: './smart-search.component.html',
  styleUrls: ['./smart-search.component.scss'],
})
export class SmartSearchComponent {
  tabs = ['Overall', 'Cash', 'Claims', 'Direct Contract', 'Historical', 'Tier 1'];
  radiusList: any = MetaDataDropdownConstant.radiusConstants;
  distance: any;
  activeTab = 0;
  autocompleteLoader: boolean = false;
  getRequest: any;
  keywordLocationResult: any[];
  claimsSearchModel: ClaimsSearchModel = new ClaimsSearchModel();
  smartSearchModel: SmartSearchModel = new SmartSearchModel();
  searchLocationInput: any = null;
  searchMemberInput: any = null;
  searchProcedureInput: any = null;
  searchCompanyInput: any = null;
  loading: boolean = false;
  providersList: any = [];
  unfilteredProvider: any = [];
  dcList: any = [];
  vitafyUrl = environment.Vitafy_URL;
  keywordProcedureResult: any[];
  keywordCompanyResult: any[];
  keywordMemberResult: any[];
  isIframe: boolean = false;
  procedureName: any = '';
  preSelectedProvider: any = [];
  stateAvg: any = null;

  constructor(
    private enterpriseService: EnterpriseService,
    private route: ActivatedRoute,
    private router: Router,
    private procedureService: ProcedureService,
    private cancelService: HttpCancelService,
    private commonListService: CommonListService,
    private memberService: MemberService,
    private sanitize: Sanitizer
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      if (Object.keys(params).length) {
        let isAlithiasRequest = params['isAlithiasRequest'] ?? false;
        if (isAlithiasRequest) {
          this.isIframe = false;
        } else {
          this.isIframe = true;
          this.smartSearchModel.procedureId = this.sanitize.sanitizeParams(
            params['procedureId']
          );
          this.smartSearchModel.memberId = this.sanitize.sanitizeParams(
            params['memberId']
          );
          this.smartSearchModel.companyId = this.sanitize.sanitizeParams(
            params['companyId']
          );
          this.smartSearchModel.radius = this.sanitize.sanitizeParams(
            params['radius']
          );
          this.distance = `${this.smartSearchModel.radius}mi`;
          if (!this.radiusList?.some((x: any) => x == this.smartSearchModel.radius)) {
            this.radiusList.push(+this.smartSearchModel.radius);
            this.radiusList = this.radiusList.sort((a: any, b: any) => a - b);
          }
          this.smartSearchModel.zipCode = this.sanitize.sanitizeParams(
            params['zipCode']
          );
          this.smartSearchModel.state = this.sanitize.sanitizeParams(
            params['state']
          );
          this.smartSearchModel.selectedProvider = params['selectedProvider']
            ? params['selectedProvider'].includes(',')
              ? params['selectedProvider'].split(',')
              : [params['selectedProvider']]
            : null;
          this.preSelectedProvider = params['selectedProvider']
            ? params['selectedProvider'].includes(',')
              ? params['selectedProvider'].split(',')
              : [params['selectedProvider']]
            : null;
          this.smartSearchModel.zipCode
            ? this.getLocation({ keyword: this.smartSearchModel.zipCode }, true)
            : null;
          this.smartSearchModel.memberId
            ? this.getMemberList(
              { alithiasUID: this.smartSearchModel.memberId },
              true
            )
            : null;
          this.smartSearchModel.procedureId
            ? this.getProcedure(
              { procedureId: this.smartSearchModel.procedureId },
              true
            )
            : null;
          if (this.smartSearchModel.companyId) {
            let company: any = await this.commonListService.getCompanyList(
              { alithiasCompanyId: this.smartSearchModel.companyId },
              'standalone'
            );
            this.searchCompanyInput = company[0];
          }
          this.searchPricing();
        }
      }
    });
  }

  selectTab(tabIndex: any) {
    this.activeTab = tabIndex;
    if (tabIndex > -1) {
      switch (tabIndex) {
        case 0:
          this.providersList = this.unfilteredProvider
            .sort((a: any, b: any) => b.confidenceLevel - a.confidenceLevel)
            .slice(0, 5);
          break;
        case 1:
          this.providersList = this.unfilteredProvider.filter(
            (x: any) => x.price.type === 'Cash'
          );
          break;
        case 2:
          this.providersList = this.unfilteredProvider.filter(
            (x: any) => x.price.type === 'Likely'
          );
          break;
        case 3:
          this.providersList = this.unfilteredProvider.filter(
            (x: any) => x.price.type === 'Contract'
          );
          break;
        case 4:
          this.providersList = this.unfilteredProvider.filter(
            (x: any) => x.price.type == 'Historical'
          );
          break;
        case 5:
          this.providersList = this.unfilteredProvider.filter(
            (x: any) => x.network.code == 'Tier 1'
          );
          break;
      }
    }
  }

  getLocation(params: any, prefill: boolean = false) {
    this.autocompleteLoader = true;
    this.getRequest = this.enterpriseService
      .getLocation(params)
      .pipe(
        finalize(() => {
          this.autocompleteLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.keywordLocationResult = response.data.rows;
          this.keywordLocationResult = this.keywordLocationResult.filter(
            (x: any) => x.zipCode
          );
          prefill
            ? (this.searchLocationInput = this.keywordLocationResult[0])
            : null;
        }
      });
  }

  async searchLocation(event?: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let params = { keyword: event?.target?.value.trim() };
      if (!this.getRequest) {
        this.getLocation(params);
      } else {
        this.cancelService.cancelPendingRequests();
        this.getLocation(params);
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.keywordLocationResult = [];
        this.smartSearchModel.zipCode = null;
        this.smartSearchModel.state = null;
        this.isIframe ? this.changeRouteParams('zipCode', null) : null;
        this.isIframe ? this.changeRouteParams('state', null) : null;
      }
    } else {
      this.keywordLocationResult = [];
      this.smartSearchModel.zipCode = null;
      this.smartSearchModel.state = null;
      this.isIframe ? this.changeRouteParams('zipCode', null) : null;
      this.isIframe ? this.changeRouteParams('state', null) : null;
      return;
    }
  }

  displayLocationValue = (value: any): string => {
    if (value?.zipCode) {
      return value ? `${value.city}, ${value.stateCode} ${value.zipCode}` : '';
    } else {
      return value?.state;
    }
  };

  getProviderList(params: any) {
    this.loading = true;
    this.enterpriseService
      .getPricing(params)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.activeTab = 0;
          this.unfilteredProvider = response.data.rows;
          this.stateAvg = response.data.stateAverage;
          this.unfilteredProvider = this.unfilteredProvider.map((x: any) => {
            return {
              ...x,
              selected: this.smartSearchModel.selectedProvider?.length
                ? this.smartSearchModel.selectedProvider.find(
                  (y: any) => y == x._id
                )
                : false,
            };
          });
          this.providersList = this.unfilteredProvider
            .sort((a: any, b: any) => b.confidenceLevel - a.confidenceLevel)
            .slice(0, 5);
        }
      });
  }

  selectProvider(network: any) {
    if (!this.preSelectedProvider?.find((x: any) => x == network._id)) {
      network.selected = !network.selected;
    }
  }

  submit() {
    let providers = this.providersList.filter((x: any) => x.selected);
    providers = providers.filter(
      (x: any) => !this.preSelectedProvider?.includes(x._id)
    );
    window.parent.postMessage(
      {
        type: 'authenticate',
        token: '',
        providerList: providers,
      },
      this.vitafyUrl // Replace with the actual origin of your parent window
    );
  }

  changeRadius(e: any) {
    this.distance = `${e}mi`;
    this.smartSearchModel.radius = e;
    this.isIframe
      ? this.changeRouteParams('radius', this.smartSearchModel.radius)
      : null;
  }

  async searchProcedure(event?: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let params = { keyword: event?.target?.value.trim() };
      if (!this.getRequest) {
        this.getProcedure(params) as any;
      } else {
        this.cancelService.cancelPendingRequests();
        this.getProcedure(params) as any;
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.smartSearchModel.procedureId = null;
        this.isIframe ? this.changeRouteParams('procedureId', null) : null;
        this.keywordProcedureResult = [];
      }
    } else {
      this.smartSearchModel.procedureId = null;
      this.isIframe ? this.changeRouteParams('procedureId', null) : null;
      this.keywordProcedureResult = [];
      return;
    }
  }
  async searchCompany(event?: any, prefill: boolean = false) {
    let companyList = [];
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let companyName = event?.target?.value.trim();
      let params = {
        keyword: companyName,
        limit: 0,
        companyStatus: true,
      };
      if (!this.getRequest) {
        companyList = (await this.commonListService.getCompanyList(
          params,
          'standalone'
        )) as any;
        this.keywordCompanyResult = companyList;
        prefill
          ? (this.searchCompanyInput = this.keywordCompanyResult[0])
          : null;
      } else {
        this.cancelService.cancelPendingRequests();
        companyList = (await this.commonListService.getCompanyList(
          params,
          'standalone'
        )) as any;
        this.keywordCompanyResult = companyList;
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.smartSearchModel.companyId = null;
        this.keywordCompanyResult = [];
        this.isIframe ? this.changeRouteParams('companyId', null) : null;
      }
    } else {
      this.smartSearchModel.companyId = null;
      this.isIframe ? this.changeRouteParams('companyId', null) : null;
      this.keywordCompanyResult = [];
      return;
    }
  }

  async searchMember(event?: any) {
    if (
      event.target.value.trim() ||
      (!event.target.value.trim() && event.inputType == 'deleteContentBackward')
    ) {
      let memberName = event?.target?.value.trim();
      let params = {
        keyword: memberName,
        limit: 0,
      };
      if (!this.getRequest) {
        this.getMemberList(params) as any;
      } else {
        this.cancelService.cancelPendingRequests();
        this.getMemberList(params) as any;
      }
      if (
        !event.target.value.trim() &&
        event.inputType == 'deleteContentBackward'
      ) {
        this.smartSearchModel.memberId = null;
        this.keywordMemberResult = [];
        this.isIframe ? this.changeRouteParams('memberId', null) : null;
      }
    } else {
      this.smartSearchModel.memberId = null;
      this.keywordMemberResult = [];
      this.isIframe ? this.changeRouteParams('memberId', null) : null;
      return;
    }
  }

  getProcedure(params: any, prefill: boolean = false) {
    this.autocompleteLoader = true;
    this.getRequest = this.procedureService
      .getProcedureList(params, 'standalone')
      .pipe(
        finalize(() => {
          this.autocompleteLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.keywordProcedureResult = response.data.rows;
          this.procedureName = this.keywordProcedureResult[0]?.procedureName;
          prefill
            ? (this.searchProcedureInput = this.keywordProcedureResult[0])
            : null;
        }
      });
  }

  onSmartSearchFieldSelect(event: any, type: string) {
    let eventData = event.option.value;
    switch (type) {
      case 'procedure':
        this.smartSearchModel.procedureId = eventData.procedureId;
        this.isIframe
          ? this.changeRouteParams('procedureId', eventData.procedureId)
          : null;
        this.procedureName = eventData.procedureName;
        break;
      case 'company':
        this.smartSearchModel.companyId = eventData.alithiasCompanyId;
        this.isIframe
          ? this.changeRouteParams('companyId', eventData.alithiasCompanyId)
          : null;
        break;
      case 'member':
        this.smartSearchModel.memberId = eventData.alithiasUID;
        this.isIframe
          ? this.changeRouteParams('memberId', eventData.alithiasUID)
          : null;
        break;
      case 'location':
        if (eventData.zipCode) {
          this.smartSearchModel.zipCode = eventData.zipCode;
          this.isIframe
            ? this.changeRouteParams('zipCode', eventData.zipCode)
            : null;
          // this.smartSearchModel.location = eventData;
        }
    }
  }

  displayProcedureValue = (value: any): string => {
    let procedure = value ? value.procedureName : '';
    return procedure;
  };

  displayCompanyValue = (value: any): string => {
    let company = value ? value.clientLegalName : '';
    return company;
  };

  displayMemberValue = (value: any): string => {
    let member = value ? `${value.firstName} ${value.lastName}` : '';
    return member;
  };

  getMemberList(params: any, prefill: boolean = false) {
    this.autocompleteLoader = true;
    this.getRequest = this.memberService
      .getMemberList(params, 'standalone')
      .pipe(
        finalize(() => {
          this.autocompleteLoader = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.keywordMemberResult = response.data.rows;
          prefill
            ? (this.searchMemberInput = this.keywordMemberResult[0])
            : null;
        }
      });
  }

  searchPricing() {
    let pricingParams: any = {
      procedureId: this.smartSearchModel.procedureId,
      memberId: this.smartSearchModel.memberId,
      radius: this.smartSearchModel.radius,
      zipCode: this.smartSearchModel.zipCode,
      companyId: this.smartSearchModel.companyId,
    };
    this.getProviderList(pricingParams);
    // this.getAndCreateLocalStorage(this.smartSearchModel, 'create');
  }

  getAndCreateLocalStorage(data: any, method: string) {
    if (method === 'get') {
      const localData: string | null =
        localStorage.getItem('smart-search-cart');
      if (!localData) {
        localStorage.setItem('smart-search-cart', JSON.stringify(data));
      } else {
        let parsedData: SmartSearchModel = JSON.parse(localData);
        Object.keys(parsedData).forEach((item) => {
          let key = item as keyof SmartSearchModel;
          this.smartSearchModel[key] = parsedData[key];
        });
      }
    } else {
      const smartSearchData = {
        radius: data.radius,
        location: data.location,
        procedureID: data.procedureID,
        companyId: data.companyId,
        memberId: data.memberId,
        procedureName: data.procedureName.procedureName
          ? data.procedureName.procedureName
          : data.procedureName,
        companyName: data.companyName.clientLegalName
          ? data.companyName.clientLegalName
          : data.companyName,
        zipCode: data.zipCode,
        state: data.state,
        stateCode: data.stateCode,
      };
      let stringifyData = JSON.stringify(smartSearchData);
      localStorage.setItem('smart-search-cart', stringifyData);
    }
  }

  reset() {
    this.smartSearchModel = new SmartSearchModel();
    this.unfilteredProvider = [];
    this.providersList = [];
    this.searchLocationInput = null;
    this.searchCompanyInput = null;
    this.searchProcedureInput = null;
    this.searchMemberInput = null;
    this.keywordCompanyResult = [];
    this.keywordLocationResult = [];
    this.keywordMemberResult = [];
    this.keywordProcedureResult = [];
    this.distance = 0
    this.activeTab = 0;
  }

  changeRouteParams(paramsName: string, value: any) {
    this.route.queryParams
      .pipe(
        mergeMap((params: Params) => {
          const queryParams = { ...params };
          queryParams[paramsName] = value;
          return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          });
        })
      )
      .subscribe();
  }

  checkSelected(provider: any) {
    if (this.preSelectedProvider?.find((x: any) => x == provider._id)) {
      return false;
    } else {
      return true;
    }
  }
}
