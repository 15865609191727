export const PDFReportConstant = {
    incentiveReport: [
        {header:'Closed Date', value:'closedDate'},
        {header:'EE Name', value:'employeeFullName'},
        {header:'DEP Name', value:'depFullName'},
        {header:' Member ID', value:'employeeId'},
        {header:'EE DOB', value:'employeeDob'},
        {header:'Service Option', value: 'dateOfService'},
        {header:'Date of Service', value: 'dateOfService'},
        {header:'State Avg', value: 'providerStateAverage'},
        {header:'Est Cost', value:'providerEstCost'},
        {header:'Incentive to Be Paid', value:'providerIncentives'},
        {header:'Est Cost Savings', value:'providerCostSaving'},
    ],

    savingsReport : [
        {header:'Case Id', value:'caseId'},
        {header:'Closed Date', value:'closedDate'},
        {header:'Procedure Name', value:'procedureName'},
        {header:'Average State Cost', value:'providerStateAverage'},
        {header:'Savings From Lowest PPO Cost', value:'ppoSavings'},
        {header:'Savings From Lowest DC Cost', value: 'dcSavings'},
        {header:'Actual Savings', value: 'actualSavings'},
        {header:'Incentive Paid', value:'incentive'},
        {header:'Lost Opportunity', value:'lostOpportunity'},
    ],

    advocacySatisfactionReport : [
        {header:'Care Advocate', value:'name'},
        {header:'Number of Cases', value:'noOfServiceRequest'},
        {header:'Satisfaction Score', value:'avarageTotalScorePercentage'},
        {header:'Net Promoter Score', value:'nps'}
    ]
}