<div class="general pt-3" [formGroup]="tileDetailForm">
  <div class="d-flex align-items-center pt-3">
    <p class="fw-bold mb-0 ps-2 pe-2">Tiles</p>
  </div>
  <app-search-bar [searchConfig]="searchConfig" [searchList]="tiles" (baseAction)="openEditTileModel(null, 'addTiles')">
  </app-search-bar>
  <div class="total-div pt-3 pe-3">
    <p>Total Results: {{tiles.length || 0}}</p>
  </div>
  <div class="pt-5 mt-5" *ngIf="parentComponent.loadingGeneralDetails">
    <loader></loader>
  </div>
  <div class="table-responsive" *ngIf="!parentComponent.loadingGeneralDetails">
    <!-- <div *ngIf="tiles.length" mdbSortableContainer class="sortable-list" (itemDrop)="onDrop($event)">
      <div mdbDraggable *ngFor="let t of tiles" class="sortable-item">{{t.title}}</div>
    </div> -->
    <table class="table table-striped table-reponsive mb-0">
      <thead>
        <tr class="table-min-row">
          <th class="fw-bold">Title</th>
          <th class="fw-bold">Caption</th>
          <th class="fw-bold">Action Type</th>
          <th class="fw-bold">Visibility</th>
          <!-- <th class="fw-bold" style="width: 300px !important;">Description</th> -->
          <th style="width: 50px !important;"></th>
        </tr>
      </thead>
      <tbody class="table-alert" mdbSortableContainer (itemDrop)="onDrop($event)">
        <tr class="text-center" *ngIf="!tiles.length && !parentComponent.loadingGeneralDetails">
          <td colspan="9" class="fw-bold">Tiles not found.</td>
        </tr>
        <tr class="text-left sortable-item" mdbDraggable
          *ngFor="let t of tiles | filterKeyword:searchConfig.formValue; let i = index">
          <td><span class="">{{t.title}}</span> </td>
          <td><span class="text-break">{{t.caption}}</span></td>
          <td> <span [mdbPopover]="t.action?.value ? actionPopover : ''" trigger="click" placement="left" [ngClass]="{'pointer' : t.action?.value}">
              {{t.action?.type}}</span>
            <ng-template #actionPopover>{{t.action?.type == 'Phone' ? (t.action?.value | phone) :
              t.action?.value}}</ng-template>
          </td>
          <td>
            <div>
              <span class="">
                <a href="javascript:void(0)">
                  <i-bs [name]="!t.isActive ? 'toggle-off' : 'toggle-on'" [ngClass]="{
                      'text-success': t.isActive,
                      'text-gray-main': !t.isActive
                    }" width="32" height="32"></i-bs>
                </a>
              </span>
            </div>
          </td>
          <!-- <td>
            <div>
              <span class="text-break">
                {{t.description}}
              </span>
            </div>
          </td> -->
          <td class="position-relative">
            <div mdbDropdown class="">
              <button class="btn btn-clear btn-floating dropdown-toggle shadow-none" mdbDropdownToggle
                aria-expanded="false">
                <i-bs name="three-dots-vertical"></i-bs>
              </button>
              <ul autoClose="true" mdbDropdownMenu class="dropdown-menu shadow position-absolute bottom-50 end-50">
                <li>
                  <button class="dropdown-item" type="button" (click)="openEditTileModel(t, 'editTiles')">
                    Edit
                  </button>
                </li>
                <li>
                  <button class="dropdown-item" type="button"
                    (click)="openDeleteConfirmationModel(1, t)">Delete</button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>