import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { ApiConstant } from '../constants';
import { QueryParams } from '../utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(private http: HttpClientService) {}

  getPricingList(filterParams: any = {}) {
    const keyValues = QueryParams.addParams(filterParams);
    return this.http.get(`${ApiConstant.CLAIMS}/pricing?` + keyValues).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getPricingById(pricingId: string) {
    return this.http.get(`${ApiConstant.CLAIMS}/pricing/${pricingId}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updatePricing(pricingId: string, body: any) {
    return this.http.put(`${ApiConstant.CLAIMS}/pricing/${pricingId}`, body).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getProcedureEpisodes(provider: any) {
    const npi = provider.providerNPI;
    delete provider['providerNPI'];
    const keyValues = QueryParams.addParams(provider);
    return this.http
      .get(`${ApiConstant.CLAIMS}/${npi}/episodes?` + keyValues)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getEpisodeDetail(params: any, npi: any, episodeId: any) {
    const keyValues = QueryParams.addParams(params);
    return this.http
      .get(
        `${ApiConstant.CLAIMS}/${npi}/episodes/${episodeId}/episodes-detail?` +
          keyValues
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateEpisode(episode:any, body:any) {
    return this.http.put(`${ApiConstant.CLAIMS}/${episode.npi}/episodes/${episode.episodeKey}?procedureID=${episode.procedureID}&groupNetworkID=${episode.groupNetworkID}`, body).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getEpisodeById(episode:any) {
    return this.http.get(`${ApiConstant.CLAIMS}/${episode.npi}/episodes/${episode.episodeKey}?procedureID=${episode.procedureID}&groupNetworkID=${episode.groupNetworkID}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateEpisodeDetail(episode: any, body: any) {
    return this.http
      .patch(
        `${ApiConstant.CLAIMS}/${episode.facilityNPI}/episodes/${episode.episodeKey}/episode-detail/${episode._id}`,
        body
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getEpisodeMappedDetail(episode: any) {
    return this.http
      .get(
        `${ApiConstant.CLAIMS}/${episode.facilityNPI}/episodes/${episode.episodeKey}/episode-detail`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
