<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title fw-bold" id="exampleModalLabel">{{ modalTitle }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close('close')"></button>
  </div>
  <div class="model-body">
    <form class="px-2" #alertForm="ngForm">
      <div class="md-form p-2">
        <label for="" class="form-label">Alert Type</label>
        <div class="mb-3">
          <ng-select [(ngModel)]="alertsModel.alertType" class="form-control p-0" [items]="alerts" name="alerts"
            required bindLabel="name" bindValue="value" [selectableGroupAsModel]="false" [closeOnSelect]="true"
            [searchable]="true">
            <ng-template class="d-flex flex-row" ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <label>{{ item.name }}</label>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <!-- <div class="md-form px-2">
        <label for="" class="form-label" *ngIf="alertsModel.alertType == 'benefitCallOuts'">Benefit Name</label>
        <label for="" class="form-label" *ngIf="alertsModel.alertType == 'careNavigationCallOuts'">Benefit Name</label>
        <label for="" class="form-label" *ngIf="alertsModel.alertType == 'benefitCallOuts'">Benefit Name</label>

        <div class="input-group mb-3">
          <input mdbInput type="text" class="form-control" [(ngModel)]="alertsModel.benefitName" name="benefitName"
            id="benefit-name" aria-describedby="basic-addon3" #benefitName="ngModel" />
        </div>
      </div> -->
      <div class="md-form px-2">
        <!-- <label for="" class="form-label">Benefit Detail</label> -->
        <label for="" class="form-label" *ngIf="alertsModel.alertType == 'benefitCallOuts'">Benefit Detail</label>
        <label for="" class="form-label" *ngIf="alertsModel.alertType == 'careNavigationCallOuts'">Care Navigation Detail</label>
        <label for="" class="form-label" *ngIf="alertsModel.alertType == 'keyRequirementReminders'">Key Reminders</label>
        <div class="input-group mb-3">
          <textarea class="form-control h-auto" [(ngModel)]="alertsModel.benefitDetail" name="benefitDetail"
            aria-label="With textarea" rows="3" #benefitDetail="ngModel"></textarea>
        </div>
      </div>
      <div class="md-form px-2 d-flex align-items-center pb-3">
        <label for="" class="form-label pe-3 m-0">Alert Notification</label>
        <a href="javascript:void(0)">
          <i-bs [name]="!alertToggle ? 'toggle-off': 'toggle-on'" [ngClass]="{
          'text-success': alertToggle,
          'text-gray-main': !alertToggle
        }" (click)="chnageAlert()" width="32" height="32"></i-bs>
        </a>

      </div>
    </form>
  </div>
  <div class="model-footer d-flex justify-content-between p-2 bg-gray-lighter">
    <button type="button" class="btn btn-light" data-mdb-ripple-color="dark"
      (click)="modalRef.close('close')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="alertForm.invalid">Save</button>
  </div>
</div>