import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateCalc {
    static calculateDate(dateType: any) {
        let from: any = null;
        let to: any = null;
        switch (dateType) {
            case 'today':
                from = moment();
                break;
            case 'yesterday':
                from = moment().subtract(1, "days").format("MM/DD/YYYY");
                break;
            case 'last7days':
                from = moment().subtract(7, "days").endOf("day");
                to = moment();
                break;
            case 'thisMonth':
                from = moment().startOf('month');
                to = moment();
                break;
            case 'lastMonth':
                from = moment().startOf('month').subtract(1, 'months');
                to = moment().startOf('month').subtract(1, 'months').endOf('month');
                break;
            case 'lastQuarter':
                from = moment().subtract(1, 'quarter').startOf('quarter');
                to = moment().subtract(1, 'quarter').endOf('quarter');
                break;
            case 'ytd':
                from = moment().startOf('year');
                to = moment();
                break;
            case 'lastYear':
                from = moment().subtract(1, 'year').startOf('year');
                to = moment().subtract(1, 'year').endOf('year');
                break;
        }
        return { from: from ? from.format('MM/DD/YYYY') : null, to: to ? to.format('MM/DD/YYYY') : null }
    }

    static calculatePlanDate(filterType: any, planStart: any) {
        let from: any = null;
        let to: any = null;
        switch (filterType) {
            case 'thisMonth':
                from = moment().startOf('month').format('MM/DD/YYYY');
                to = moment().format('MM/DD/YYYY');
                break;
            case 'lastMonth':
                from = moment().startOf('month').subtract(1, 'months').format('MM/DD/YYYY');
                to = moment().startOf('month').subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
                break;
            case 'ytd':
                if (planStart) {
                    to = moment().format('MM/DD/YYYY');
                    from = this.convertPlandDateToYTD(to, planStart);
                }
                else {
                    from = moment().startOf('year').format('MM/DD/YYYY');
                    to = moment().format('MM/DD/YYYY');
                }
                break;
            case 'lastYear':
                if (planStart) {
                    const planMonth = new Date(planStart).getMonth();
                    const planDate = new Date(planStart).getDate();
                    from = moment(new Date(new Date().getFullYear() - 1, planMonth, planDate)).format('MM/DD/YYYY');
                    to = moment(new Date(new Date().getFullYear(), planMonth, planDate - 1)).format('MM/DD/YYYY');
                    if (moment(to).isAfter(moment())) {
                        from = moment(new Date(new Date().getFullYear() - 2, planMonth, planDate)).format('MM/DD/YYYY');
                        to = moment(new Date(new Date().getFullYear() - 1, planMonth, planDate - 1)).format('MM/DD/YYYY');
                    }
                }
                else {
                    from = moment().startOf('year').subtract(12, 'months').format('MM/DD/YYYY');
                    to = moment().endOf('year').subtract(12, 'months').format('MM/DD/YYYY');
                }
                break;
            case 'lastQuarter':
                if (planStart) {
                    let customQuarters = [];
                    let quarterYear = new Date().getFullYear();
                    const quarterMonth = new Date(planStart).getMonth();
                    const quarterDate = new Date(planStart).getDate();
                    let qStart: any;
                    let qEnd: any;
                    for (let i = 1; i <= 4; i++) {
                        if (i == 1) {
                            const firstQStartDate = moment(new Date(quarterYear, quarterMonth, quarterDate)).format('MM/DD/YYYY');
                            if (firstQStartDate && moment(firstQStartDate).isAfter(moment())) {
                                quarterYear = new Date().getFullYear() - 1
                                qStart = moment(new Date(quarterYear, quarterMonth, quarterDate)).format('MM/DD/YYYY');
                                qEnd = moment(new Date(quarterYear, quarterMonth + 3, quarterDate - 1)).format('MM/DD/YYYY');
                            } else {
                                qStart = moment(new Date(quarterYear, quarterMonth, quarterDate)).format('MM/DD/YYYY');
                                qEnd = moment(new Date(quarterYear, quarterMonth + 3, quarterDate - 1)).format('MM/DD/YYYY');
                            }
                        }
                        else {
                            qStart = moment(new Date(qEnd)).add(1, 'days').format('MM/DD/YYYY');
                            qEnd = moment(new Date(qEnd)).add(3, 'months').format('MM/DD/YYYY')
                        }
                        customQuarters.push({ quarter: i, qStart: qStart, qEnd: qEnd, currentQuarter: moment(new Date()).isBetween(new Date(qStart), new Date(qEnd)) });
                    }
                    let currentQuarter: any = customQuarters.find(x => x.currentQuarter);
                    if (currentQuarter) {
                        from = moment(this.calculateQuarter(currentQuarter.qStart)).format('MM/DD/YYYY');
                        to = moment(this.subtractOneDay(currentQuarter.qStart)).format('MM/DD/YYYY');
                    }
                    //if current date does not belong to any quarter then subtract 1 year 
                    else {
                        currentQuarter = customQuarters.find(x => x.quarter == 3);
                        from = moment(new Date(currentQuarter.qStart)).subtract(1, 'years').format('MM/DD/YYYY');
                        to = moment(new Date(currentQuarter.qEnd)).subtract(1, 'years').format('MM/DD/YYYY');
                    }
                }
                else {
                    let currentQuaterNumber = moment().quarter();
                    if (currentQuaterNumber === 1) {
                        from = moment().startOf('year').subtract(3, 'months').format('MM/DD/YYYY');
                        to = moment().startOf('year').subtract(1, 'day').format('MM/DD/YYYY');
                    } else {
                        from = moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY');
                        to = moment().subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY');
                    }
                }
                break;
        }
        return { from: from ? new Date(from) : null, to: to ? new Date(to) : null }

    }

    static convertPlandDateToYTD(endDate?: any, startDate?: any) {
        let pm = new Date(startDate).getMonth();
        let pd = new Date(startDate).getDate();
        let date = new Date((endDate ? new Date(endDate).getFullYear() : new Date().getFullYear()), pm, pd)
        if ((endDate && moment(date).isAfter(endDate) || (!endDate && moment(date).isAfter(moment())))) {
            date = new Date(new Date(date).getFullYear() - 1, pm, pd)
        }
        return moment(date).format('MM/DD/YYYY');
    }

    static calculateQuarter(qStartDate: any) {
        var newDate = new Date(qStartDate);
        newDate.setMonth(newDate.getMonth() - 3);
        return newDate;
    }

    static subtractOneDay(date: any) {
        var newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        return newDate;
    }

    static workdayCount(start: any, end: any) {
        start = moment(start);
        end = moment(end);
        let first = start.clone().endOf('week'); // end of first week
        let last = end.clone().startOf('week'); // start of last week
        let days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
        let wfirst = first.day() - start.day(); // check first week
        if (start.day() == 0) --wfirst; // -1 if start with sunday 
        let wlast = end.day() - last.day(); // check last week
        if (end.day() == 6) --wlast; // -1 if end with saturday
        return wfirst + Math.floor(days) + wlast; // get the total
    }

    static reportFormating(dateString: any) {
        const date = new Date(dateString);

        // Extract the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

        // Concatenate the parts in MMDDYY format
        return `${month}${day}${year}`;
    }

}