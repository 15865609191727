<div [formGroup]="preferredProviderForm" class="pt-4 general">
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <p class="fw-bold pt-3 ps-2 pe-2">Preferred Provider</p>
      <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingPreferredProvider">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <button type="button" class="btn btn-primary"
      (click)="addInfoForms(preferredProviderForm, 'preferredProviderInfo')">
      <i-bs name="plus" class="pe-1"></i-bs> Add New Provider
    </button>
  </div>
  <div formArrayName="preferredProviderInfo">
    <div *ngIf="
        !getFormArray(preferredProviderForm, 'preferredProviderInfo').length
      ">
      <p class="text-center">No new provider</p>
    </div>
    <div *ngFor="
        let item of getFormArray(preferredProviderForm, 'preferredProviderInfo')
          .controls;
        let i = index
      " [formGroupName]="i">
      <div class="col-12 pb-4">
        <button
          class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
          type="button" (click)="preferredProviderCollapse.toggle()"
          [attr.aria-expanded]="!preferredProviderCollapse.collapsed" aria-controls="collapseExample">
          <span class="text-capitalize">
            <i-bs [name]="
                !preferredProviderCollapse.collapsed
                  ? 'chevron-down'
                  : 'chevron-up'
              " class="pe-1"></i-bs>
            {{ item.get("vendorName")?.value || "New Provider" }}
          </span>
          <span>
            <i-bs name="trash-fill" class="pull-right text-gray-main" (click)="
                delete(i, item, 'preferredProviderInfo', preferredProviderForm)
              "></i-bs>
          </span>
        </button>
      </div>
      <div class="pb-3" id="collapseExample" mdbCollapse #preferredProviderCollapse="mdbCollapse" [collapsed]="false">
        <div class="px-2 collapse-container">
          <div class="col-12 d-flex justify-content-between">
            <div class="col-6">
              <div class="d-flex">
                <label for="name" class="pt-0 col-5 text-end pe-3"></label>
                <input mdbCheckbox class="form-check-input me-4 col-2" type="checkbox" id="preferredProviderAvailable"
                  formControlName="preferredProviderAvailable" name="preferredProviderAvailable" />
                <label class="form-check-label col-8">Preferred Provider Available</label>
              </div>
              <div class="d-flex align-items-center pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Preferred Provider Type</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="preferredProgramType"
                    formControlName="preferredProgramType" name="preferredProgramType" />
                </div>
              </div>
              <div class="d-flex align-items-center pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Name</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="vendorName" formControlName="vendorName"
                    name="vendorName" />
                </div>
              </div>
              <div class="d-flex align-items-center pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Phone</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="vendorPhone" formControlName="vendorPhone"
                    name="vendorPhone" mask="(000) 000-0000" />
                </div>
              </div>
              <div class="d-flex align-items-center pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Email</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="vendorEmail" formControlName="vendorEmail"
                    name="vendorEmail" [pattern]="parentComponent.mailformat" />
                </div>
              </div>

            </div>
            <div class="col-6">
              <div class="d-flex align-items-center pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Vendor Portal URL</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="vendorPortalUrl" formControlName="vendorPortalUrl"
                    name="vendorPortalUrl" />
                </div>
              </div>
              <div class="d-flex pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Network Limitation</label>
                <div class="col-7">
                  <textarea mdbInput class="form-control" id="networkLimitation" rows="3"
                    formControlName="networkLimitation" name="networkLimitation"></textarea>
                </div>
              </div>
              <div class="d-flex pt-3">
                <label for="name" class="pt-0 col-5 text-end pe-3">Provider Notes</label>
                <div class="col-7">
                  <textarea mdbInput class="form-control" id="providerNotes" rows="3" formControlName="providerNotes"
                    name="programNotes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div class="d-flex justify-content-end pb-3" *ngIf="
        getFormArray(preferredProviderForm, 'preferredProviderInfo').length
      ">
      <button type="button" class="btn btn-primary" (click)="saveForms()" [disabled]="!preferredProviderForm.valid">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="saving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </div>
</div>