<mdb-tabs class="general pt-2" #accountDetailsTabs [justified]="true" (activeTabChange)="onTabChange($event)">
  <!-- General details 1st tab start -->
  <mdb-tab title="General Details" [formGroup]="accountGeneralDetails">
    <!-- client information section -->
    <div class="">
      <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Client Information</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="parentComponent.loadingGeneralDetails">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="col-6 ">
          <div class="col-12 pt-3 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Client Logo</label>
            <div class="col-7 d-flex  align-items-center">
              <div>
                <div class="logo-bg p-2 d-flex">
                  <img *ngIf="parentComponent.getControl('logo').get('filePath')?.value"
                    [src]="sanitizer.bypassSecurityTrustResourceUrl(parentComponent.getControl('logo').get('filePath')?.value)"
                    class="client-logo" alt="" />
                  <i-bs name="building" class="text-gray-main client-logo" height="48px" width="48px"
                    *ngIf="!parentComponent.getControl('logo').get('filePath')?.value || !parentComponent.currentCompanyId"></i-bs>
                </div>
              </div>
              <div>
                <i-bs name="upload" *ngIf="!this.uploadingLogo " class="pointer text-gray-main ps-3 pointer" height="16"
                  width="16" (click)="openUploadFileModal()" title="Upload Logo"></i-bs>
                <div class="spinner-border spinner-border-sm ms-3" role="status" *ngIf="this.uploadingLogo">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pt-3 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Company Status</label>
            <div class="col-7">
              <a href="javascript:void(0)">
                <i-bs [name]="
                    !parentComponent.isActiveSwitch ? 'toggle-off' : 'toggle-on'
                  " (click)="parentComponent.toggleIsPrimary()" [ngClass]="{
                    'text-success': parentComponent.isActiveSwitch,
                    'text-gray-main': !parentComponent.isActiveSwitch
                  }" width="32" height="32"></i-bs>
              </a>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="col-12 d-flex align-items-center" *ngIf="accountGeneralDetails.value.id">
            <label for="name" class="pt-0 col-5 text-end pe-3">Alithias Company ID</label>
            <div class="col-7">
              <input type="text" class="form-control" id="group" formControlName="alithiasCompanyId"
                name="alithiasCompanyId" />
            </div>
          </div>
          <div class="col-12 d-flex align-items-center  pt-3">
            <label for="name" class="pt-0 col-5 text-end pe-3">Client Legal Name</label>
            <div class="col-7">
              <input type="text" class="form-control" id="legalName" formControlName="clientLegalName" required
                name="clientLegalName" />
            </div>
          </div>
          <div class="col-12 pt-3 d-flex align-items-center">
            <label for="name" class="pt-0 col-5 text-end pe-3">Group #</label>
            <div class="col-7">
              <input type="text" required class="form-control" id="group" formControlName="groupNumber"
                name="groupNumber" />
            </div>
          </div>
          <div class="col-12 pt-3 d-flex">
            <label for="name" class="pt-2 col-5 text-end pe-3"><i-bs name="info-circle" class="text-primary pe-2"
                mdbTooltip="Please type a name and press 'Enter' to add multiple DBA Names."></i-bs> DBA Name </label>
            <div class="col-7">
              <input type="text" class="form-control mb-2" id="legalName" (keydown.enter)="handleAkaEnter($event)"
                [(ngModel)]="dbaName" name="dbaName" [ngModelOptions]="{standalone: true}" />
              <span class="badge multi-option me-2 mb-1" *ngFor="let x of accountGeneralDetails.value.akaName">
                <i-bs name="x" class="badge-x-icon pointer" (click)="deleteAKAName(x)"></i-bs>
                <span class="px-2">{{x}}</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <table class="table table-reponsive">
        <thead>
          <tr>
            <th scope="col" class="fs-2 fw-bold">Company Division</th>
            <th scope="col" class="fs-2 fw-bold">
              Sub Group
            </th>
            <!-- <th scope="col" class="fs-2 fw-bold">AKA Name</th> -->
            <th scope="col" class="fs-2 fw-bold"></th>
          </tr>
        </thead>
        <tbody formArrayName="subsidiary">
          <tr *ngIf="!parentComponent.getFormArray('subsidiary').length">
            <th colspan="4" class="fw-bold text-center">No subsidiaries</th>
          </tr>
          <tr *ngFor="
              let item of parentComponent.getFormArray('subsidiary').controls;
              let i = index
            " [formGroupName]="i">
            <td>
              <input type="text" class="form-control" formControlName="subsidiaryCompanies"
                name="subsidiaryCompanies" />
            </td>
            <td>
              <input type="text" class="form-control" formControlName="subGroup" name="subGroup" />
            </td>
            <!-- <td>
              <input type="text" class="form-control" formControlName="akaName" name="akaName" />
            </td> -->
            <td>
              <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                (click)="parentComponent.delete(i, item.value, 'subsidiary')"></i-bs>
              <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <a class="text-primary pointer" type="button" (click)="parentComponent.addForms('subsidiary')"><i-bs
          name="plus"></i-bs>Add More</a>
    </div>
    <hr class="mt-5" />
    <!-- address/phone section -->
    <div class="">
      <p class="fw-bold pt-3 ps-2">Address/Phone</p>
      <div class="col-12">
        <div class="d-flex justify-content-between pt-3">
          <div class="col-6" [formGroup]="parentComponent.getControl('address')">
            <div class="d-flex">
              <div class="col-5 text-end">
                <label for="name" class="pe-3">Address</label>
              </div>
              <div class="col-7">
                <input type="text" class="form-control" formControlName="addressLine1" name="addressLine1"
                  placeholder="Address Line 1" />
                <div class="pt-2">
                  <input type="text" class="form-control" formControlName="addressLine2" name="addressLine2"
                    placeholder="Address Line 2" />
                </div>
                <div class="pt-2">
                  <div class="col-12">
                    <div class="d-flex">
                      <div class="col-5">
                        <input type="text" class="form-control" formControlName="city" name="city" placeholder="City" />
                      </div>
                      <div class="col-4 ps-2">
                        <!-- <input type="text" class="form-control" formControlName="state"  name="state" /> -->
                        <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                          formControlName="state" name="state">
                          <ng-option value='' disabled>State</ng-option>
                          <ng-option *ngFor="let option of statesList | sort:'asc':'value'" value="{{ option.value }}">
                            {{ option.value }}</ng-option>
                        </ng-select>
                      </div>
                      <div class="col-3 ps-2">
                        <input type="text" class="form-control" formControlName="zip" name="zip" placeholder="Zip" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex">
            <label for="name" class="pt-2 col-5 text-end pe-3">Phone</label>
            <div class="col-7">
              <input type="text" class="form-control" id="group" formControlName="phone" name="phone"
                mask='(000) 000-0000' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-5" />
    <div>
      <p class="fw-bold pt-3 ps-2">Client Contacts</p>
      <div class="pt-2">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                Contact Name
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Contact Title
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 1
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                Ext
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 2
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Email
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="clientContacts">
            <tr *ngIf="!parentComponent.getFormArray('clientContacts').length">
              <th colspan="6" class="fw-bold text-center">
                No client contacts
              </th>
            </tr>
            <tr *ngFor="
                let item of parentComponent.getFormArray('clientContacts')
                  .controls;
                let i = index
              " [formGroupName]="i">
              <td>
                <input type="text" class="form-control" formControlName="contactName" name="contactName" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="contactTitle" name="contactTitle" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="phone1" name="phone1" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="extension" name="extension" maxlength="6"
                  minlength="4" />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="phone2" name="phone2" mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" formControlName="email" name="email"
                  [pattern]="parentComponent.mailformat" />
              </td>
              <td>
                <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                  (click)="parentComponent.delete(i, item.value, 'clientContacts')"></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <a class="text-primary pointer" type="button" (click)="parentComponent.addForms('clientContacts')"><i-bs
            name="plus"></i-bs>Add More</a>
      </div>
    </div>
    <hr class="mt-5" />
    <div class="d-flex justify-content-end pb-3">
      <button type="button" class="btn btn-primary" [disabled]="!accountGeneralDetails.valid"
        (click)="parentComponent.saveAccountdetails()">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="parentComponent.saving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </mdb-tab>
  <!-- Electronic files tab -->
  <mdb-tab title="Electronic Files Provider" [formGroup]="electronicProviderForm"
    [disabled]="!parentComponent.currentCompanyId">
    <div class="">
      <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Electronic Files Provider</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-3">
        <div class="col-6 d-flex align-items-center">
          <label for="name" class="pt-0 col-5 text-end pe-3">File Source</label>
          <div class="col-7">
            <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
              formControlName="fileSource" name="fileSource">
              <ng-option *ngFor="let option of dropdownConstants.invoiceToList | sort:'asc':'name'"
                [value]="option.value">
                {{ option.name }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <table class="table table-reponsive">
        <thead>
          <tr>
            <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
              Contact Name
            </th>
            <th scope="col" class="fs-2 fw-bold" style="width: 400px !important">
              File Type
            </th>
            <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
              Phone 1
            </th>
            <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
              Ext
            </th>
            <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
              Phone 2
            </th>
            <th scope="col" class="fs-2 fw-bold" style="width: 400px !important">
              Email
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody formArrayName="contactInfo">
          <tr *ngIf="!getFormArray(electronicProviderForm, 'contactInfo').length">
            <th colspan="6" class="fw-bold text-center">No contact info</th>
          </tr>
          <tr *ngFor="
              let item of getFormArray(electronicProviderForm, 'contactInfo')
                .controls;
              let i = index
            " [formGroupName]="i">
            <td>
              <input type="text" class="form-control" id="contactName" formControlName="contactName"
                name="contactName" />
            </td>
            <td>
              <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                formControlName="fileType" name="fileType">
                <ng-option *ngFor="let option of dropdownConstants.electronicFileTypes | sort: 'asc':'name'"
                  [value]="option.value">
                  {{ option.name }}</ng-option>
              </ng-select>
            </td>
            <td>
              <input type="text" class="form-control" id="phone1" formControlName="phone1" name="phone1"
                mask='(000) 000-0000' />
            </td>
            <td>
              <input type="text" class="form-control" id="extension" formControlName="extension" name="extension"
                maxlength="6" minlength="4" />
            </td>
            <td>
              <input type="text" class="form-control" id="phone2" formControlName="phone2" name="phone2"
                mask='(000) 000-0000' />
            </td>
            <td>
              <input type="text" class="form-control" id="group" formControlName="email" name="email"
                [pattern]="parentComponent.mailformat" />
            </td>
            <td>
              <i-bs *ngIf="!item.value.loading" name="trash-fill" class="pointer text-gray-main"
                (click)="delete(i, item.value, 'contactInfo', electronicProviderForm)"></i-bs>
              <div class="spinner-border spinner-border-sm text-gray-main" role="status" *ngIf="item.value.loading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <a class="text-primary pointer" type="button"
        (click)="addContactForms(electronicProviderForm, 'contactInfo')"><i-bs name="plus"></i-bs>Add More</a>
      <hr class="mt-5" />
      <div class="d-flex justify-content-end pb-3">
        <button type="button" class="btn btn-primary" (click)="saveForms('fileProvider')"
          [disabled]="!electronicProviderForm.valid">
          <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
            <span class="visually-hidden">Loading...</span>
          </div>
          Save
        </button>
      </div>
    </div>
  </mdb-tab>
  <!-- Tpa informatiom tab -->
  <mdb-tab title="TPA Information" [formGroup]="tpaInformationForm" [disabled]="!parentComponent.currentCompanyId">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">TPA Information</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <button type="button" class="btn btn-primary" (click)="addInfoForms(tpaInformationForm, 'tpaInformation')">
        <i-bs name="plus" class="pe-1"></i-bs> Add New TPA
      </button>
    </div>
    <div formArrayName="tpaInformation">
      <div *ngIf="!getFormArray(tpaInformationForm, 'tpaInformation').length">
        <p class="text-center">No TPA Information</p>
      </div>
      <div *ngFor="
          let item of getFormArray(tpaInformationForm, 'tpaInformation')
            .controls;
          let i = index
        " [formGroupName]="i">
        <div class="col-12 pb-3">
          <button
            class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
            type="button" (click)="tpaInformation.toggle()" [attr.aria-expanded]="!tpaInformation.collapsed"
            aria-controls="collapseExample">
            <span class="text-capitalize">
              <i-bs [name]="
                  !tpaInformation.collapsed ? 'chevron-down' : 'chevron-up'
                " class="pe-1"></i-bs>
              {{ item.get("tpaName")?.value || "New TPA" }}
            </span>
            <span>
              <i-bs name="trash-fill" class="pull-right text-gray-main"
                (click)="delete(i, item.value, 'tpaInformation', tpaInformationForm)"></i-bs>
            </span>
          </button>
        </div>
        <div class="" id="collapseExample" mdbCollapse #tpaInformation="mdbCollapse" [collapsed]="false">
          <div class="px-2">
            <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">TPA Name</label>
                <div class="col-7">
                  <input type="text " class="form-control" id="tpaName" formControlName="tpaName" name="tpaName" />
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Customer Service Phone</label>
                <div class="col-7">
                  <input type="text" class="form-control" id="servicePhone" formControlName="servicePhone"
                    mask='(000) 000-0000' name="servicePhone" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">TPA Start Date</label>
                <div class="col-7">
                  <input [mdbDatepicker]="startDate" type="text" class="form-control" id="startDate"
                    (click)="startDate.open()" formControlName="tpaStartDate" name="tpaStartDate" autocomplete="off" />
                  <mdb-datepicker-toggle [mdbDatepicker]="startDate"></mdb-datepicker-toggle>
                  <mdb-datepicker #startDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">TPA End Date</label>
                <div class="col-7">
                  <input [mdbDatepicker]="endDate" type="text" class="form-control" id="endDate"
                    (blur)="checkDate($event, item, 'tpaEndDate')" (click)="endDate.open()" formControlName="tpaEndDate"
                    name="tpaEndDate" autocomplete="off"
                    [ngClass]="{'warning-input' : item.get('tpaEndDate')?.value}" />
                  <mdb-datepicker-toggle [mdbDatepicker]="endDate"></mdb-datepicker-toggle>
                  <mdb-datepicker #endDate [inline]="true" [format]="'mm/dd/yyyy'"
                    [minDate]="item.get('tpaStartDate')?.value"
                    (closed)="datePickerClosed(item.get('tpaEndDate')?.value, item, 'tpaEndDate')"></mdb-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-2">
            <table class="table table-reponsive">
              <thead>
                <tr>
                  <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                    Client Account Manager
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                    Phone 1
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                    Ext
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                    Phone 2
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                    Email
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody formArrayName="customerServiceContactInfo">
                <tr *ngIf="
                    !getFormArray(item, 'customerServiceContactInfo').length
                  ">
                  <th colspan="6" class="fw-bold text-center">
                    No contact info
                  </th>
                </tr>
                <tr *ngFor="
                    let contactForm of getFormArray(
                      item,
                      'customerServiceContactInfo'
                    ).controls;
                    let i = index
                  " [formGroupName]="i">
                  <td>
                    <input type="text" class="form-control" id="contactName" formControlName="contactName"
                      name="contactName" />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="phone1" formControlName="phone1" name="phone1"
                      mask='(000) 000-0000' />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="extension" formControlName="extension" name="extension"
                      maxlength="6" minlength="4" />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="phone2" formControlName="phone2" name="phone2"
                      mask='(000) 000-0000' />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="email" formControlName="email" name="email"
                      [pattern]="parentComponent.mailformat" />
                  </td>
                  <td>
                    <div class="d-flex text-center">
                      <div class="px-2">
                        <i-bs *ngIf="!contactForm.value.loading" name="trash-fill" class="pointer text-gray-main"
                          (click)="delete(i, contactForm.value, 'customerServiceContactInfo', item)" height="18"
                          width="18"></i-bs>
                        <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                          *ngIf="contactForm.value.loading">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <a class="text-primary pointer" type="button"
              (click)="addContactForms(item, 'customerServiceContactInfo')"><i-bs name="plus"></i-bs>Add More</a>
            <hr class="mt-5" />
            <p class="fw-bold pt-3 ps-3 fs-2">TPA Web Portal</p>
            <div class="d-flex justify-content-between py-3">
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Portal Link</label>
                <div class="col-7">
                  <input type="text" class="form-control" id="webPortalLink" formControlName="webPortalLink"
                    name="webPortalLink" />
                </div>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                    Portal Access Contact
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                    Phone 1
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                    Ext
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                    Phone 2
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                    Email
                  </th>
                  <th scope="col" class="fs-2 fw-bold">Action</th>
                </tr>
              </thead>
              <tbody formArrayName="webContactInfo">
                <tr *ngIf="!getFormArray(item, 'webContactInfo').length">
                  <th colspan="6" class="fw-bold text-center">
                    No contact info
                  </th>
                </tr>
                <tr *ngFor="
                    let webContactForm of getFormArray(item, 'webContactInfo')
                      .controls;
                    let i = index
                  " [formGroupName]="i">
                  <td>
                    <input type="text" class="form-control" id="contactName" formControlName="contactName"
                      name="contactName" />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="phone1" formControlName="phone1" name="phone1"
                      mask='(000) 000-0000' />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="extension" formControlName="extension" name="extension"
                      maxlength="6" minlength="4" />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="phone2" formControlName="phone2" name="phone2"
                      mask='(000) 000-0000' />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="email" formControlName="email" name="email"
                      [pattern]="parentComponent.mailformat" />
                  </td>
                  <td>
                    <div class="d-flex text-center">
                      <div class="px-2">
                        <i-bs *ngIf="!webContactForm.value.loading" name="trash-fill" class="pointer text-gray-main"
                          height="18" width="18"
                          (click)="  delete(i, webContactForm.value, 'webContactInfo', item)"></i-bs>
                        <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                          *ngIf="webContactForm.value.loading">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      <div class="px-2" *ngIf="webContactForm.value.contactId">
                        <i-bs name="upload" class="pointer text-gray-main" height="18" width="18"
                          *ngIf="!webContactForm.value.uploading"
                          (click)="openUploadFileModal(webContactForm.value, item.value)"></i-bs>
                        <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                          *ngIf="webContactForm.value.uploading">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
            <a class="text-primary pointer" type="button" (click)="addContactForms(item, 'webContactInfo')"><i-bs
                name="plus"></i-bs>Add More</a>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end pb-3" *ngIf="getFormArray(tpaInformationForm, 'tpaInformation').length">
      <button type="button" class="btn btn-primary" (click)="saveForms('tpaInformation')">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </mdb-tab>
  <!-- Broker Information -->
  <mdb-tab title="Broker Information" [formGroup]="brokerDetailForm" [disabled]="!parentComponent.currentCompanyId">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Broker Information</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <button type="button" class="btn btn-primary" (click)="addBrokerForm(brokerDetailForm, 'brokerInfo')">
        <i-bs name="plus" class="pe-1"></i-bs> Add New Broker
      </button>
    </div>
    <div formArrayName="brokerInfo" class="position-relative">
      <div *ngIf="!getFormArray(brokerDetailForm, 'brokerInfo').length">
        <p class="text-center">No Broker Information</p>
      </div>
      <div *ngFor="
          let broker of getFormArray(brokerDetailForm, 'brokerInfo').controls;
          let i = index
        " [formGroupName]="i" class="collapse-container">
        <div class="col-12 pb-3">
          <button
            class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
            type="button" (click)="brokerInfo.toggle()" [attr.aria-expanded]="!brokerInfo.collapsed"
            aria-controls="collapseExample">
            <span class="text-capitalize">
              <i-bs [name]="!brokerInfo.collapsed ? 'chevron-down' : 'chevron-up'" class="pe-1"></i-bs>
              {{ broker.get("brokerAgencyName")?.value || "New Broker" }}
            </span>
            <span>
              <i-bs name="trash-fill" class="pull-right text-gray-main"
                (click)="delete(i, broker.value, 'brokerInfo', brokerDetailForm)"></i-bs>
            </span>
          </button>
        </div>
        <div class="" id="collapseExample" mdbCollapse #brokerInfo="mdbCollapse" [collapsed]="false">
          <div class="px-2">
            <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Broker Agency Name</label>
                <div class="col-7">
                  <input type="text" class="form-control" id="brokerAgencyName" formControlName="brokerAgencyName"
                    name="brokerAgencyName" />
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Main Phone</label>
                <div class="col-7">
                  <input type="text" class="form-control" id="mainPhone" formControlName="mainPhone" name="mainPhone"
                    mask='(000) 000-0000' />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Start Date</label>
                <div class="col-7">
                  <input [mdbDatepicker]="brokerStartDate" type="text" class="form-control" id="brokerStartDate"
                    (click)="brokerStartDate.open()" formControlName="startDate" name="startDate" autocomplete="off" />
                  <mdb-datepicker-toggle [mdbDatepicker]="brokerStartDate"></mdb-datepicker-toggle>
                  <mdb-datepicker #brokerStartDate [inline]="true" [format]="'mm/dd/yyyy'"></mdb-datepicker>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">End Date</label>
                <div class="col-7">
                  <input [mdbDatepicker]="brokerEndDate" type="text" class="form-control" id="brokerEndDate"
                    [ngClass]="{'warning-input' :broker.get('endDate')?.value}" (click)="brokerEndDate.open()"
                    formControlName="endDate" name="endDate" autocomplete="off"
                    (blur)="checkDate($event, broker, 'endDate')" />
                  <mdb-datepicker-toggle [mdbDatepicker]="brokerEndDate"></mdb-datepicker-toggle>
                  <mdb-datepicker #brokerEndDate [inline]="true" [format]="'mm/dd/yyyy'"
                    (closed)="datePickerClosed(broker.get('endDate')?.value, broker, 'endDate')"
                    [minDate]="broker.get('startDate')?.value"></mdb-datepicker>
                </div>
              </div>
            </div>
          </div>
          <div class="divider py-3">
            <hr class="divider-hr">
            <span class="divider-label fs-3">Broker Detail</span>
          </div>
          <div class="pt-3">
            <table class="table table-div table-reponsive">
              <thead>
                <tr>
                  <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                    Name
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                    Title
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                    Phone 1
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                    Ext
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 300px !important">
                    Phone 2
                  </th>
                  <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                    Email
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody formArrayName="brokerDetails">
                <tr *ngIf="!getFormArray(broker, 'brokerDetails').length">
                  <th colspan="6" class="fw-bold text-center">
                    No contact info
                  </th>
                </tr>
                <tr *ngFor="
                    let contactForm of getFormArray(broker, 'brokerDetails')
                      .controls;
                    let i = index
                  " [formGroupName]="i">
                  <td>
                    <input type="text" class="form-control" id="contactName" formControlName="contactName"
                      name="contactName" />
                  </td>
                  <td>
                    <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                      formControlName="title">
                      <ng-option *ngFor="let option of dropdownConstants.brokerTitle | sort: 'asc':'name'"
                        [value]="option.value">
                        {{ option.name }}</ng-option>
                    </ng-select>
                  </td>
                  <td>
                    <input type="text" class="form-control" id="phone1" formControlName="phone1" name="phone1"
                      mask='(000) 000-0000' />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="extension" formControlName="extension" name="extension"
                      maxlength="6" minlength="4" />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="phone2" formControlName="phone2" name="phone2"
                      mask='(000) 000-0000' />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="email" formControlName="email" name="email"
                      [pattern]="parentComponent.mailformat" />
                  </td>
                  <td>
                    <i-bs *ngIf="!contactForm.value.loading" name="trash-fill" class="pointer text-gray-main"
                      (click)="delete(i, contactForm.value, 'brokerDetails', broker)"></i-bs>
                    <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                      *ngIf="contactForm.value.loading">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <!-- <i-bs name="trash-fill"></i-bs> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <a class="text-primary pointer" type="button" (click)="addContactForms(broker, 'brokerDetails')"><i-bs
                name="plus"></i-bs>Add More</a>
            <hr class="mt-5" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end pb-3">
      <button type="button" class="btn btn-primary" *ngIf="getFormArray(brokerDetailForm, 'brokerInfo').length"
        (click)="saveForms('brokerInfo')">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </mdb-tab>
  <!-- client reporting tab -->
  <mdb-tab title="Client Reporting" [formGroup]="clientReportingForm" [disabled]="!parentComponent.currentCompanyId">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Client Reporting</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <button type="button" class="btn btn-primary" (click)="
          addClientReportForm(clientReportingForm, 'clientReportingInfo')
        ">
        <i-bs name="plus" class="pe-1"></i-bs> Add New Report
      </button>
    </div>
    <div formArrayName="clientReportingInfo" class="position-relative">
      <div *ngIf="!getFormArray(clientReportingForm, 'clientReportingInfo').length">
        <p class="text-center">No Report Information</p>
      </div>
      <div *ngFor="
          let report of getFormArray(clientReportingForm, 'clientReportingInfo')
            .controls;
          let i = index
        " [formGroupName]="i" class="collapse-container pb-3">
        <div class="col-12 p-2">
          <button
            class="btn btn-gray-light text-black row-btn text-start fw-bold d-flex justify-content-between align-items-center w-100"
            type="button" (click)="clientReport.toggle()" [attr.aria-expanded]="!clientReport.collapsed"
            aria-controls="collapseExample">
            <span class="text-capitalize">
              <i-bs [name]="!clientReport.collapsed ? 'chevron-down' : 'chevron-up'" class="pe-1"></i-bs>
              {{ report.get("reportName")?.value || "New Client Report" }}
            </span>
            <span>

              <i-bs name="trash-fill" class="pull-right text-gray-main"
                (click)="delete(i, report.value, 'clientReportingInfo', clientReportingForm)"></i-bs>
              <!-- <i-bs name="trash-fill" class="pull-right text-gray-main" (click)="
                  delete(i, report, 'clientReportingInfo', clientReportingForm)
                "></i-bs> -->
            </span>
          </button>
        </div>
        <div class="" id="collapseExample" mdbCollapse #clientReport="mdbCollapse" [collapsed]="false">
          <div class="px-2">
            <div class="d-flex justify-content-between pt-3">
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Report Name</label>
                <!-- <div class="col-7">
                  <input type="text" class="form-control" id="reportName" formControlName="reportName"
                    name="reportName" />
                </div> -->
                <div class="col-7">
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                    formControlName="reportName" name="reportName">
                    <ng-option *ngFor="let option of dropdownConstants.reportTypeList | sort: 'asc':'label'" [value]="option.value">
                      {{ option.label }}</ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-6 d-flex align-items-center">
                <label for="name" class="pt-0 col-5 text-end pe-3">Frequency</label>
                <div class="col-7">
                  <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result"
                    formControlName="frequency" name="frequency">
                    <ng-option *ngFor="
                      let option of dropdownConstants.frequencyList | sort: 'asc':'name'
                    " [value]="option.value">
                      {{ option.name }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="divider py-3">
            <hr class="divider-hr">
            <span class="divider-label fs-3">Recipient Detail</span>
          </div>
          <div class="pt-2">
            <table class="table table-div table-reponsive">
              <thead>
                <tr>
                  <th scope="col" class="fs-2 fw-bold">Report Recipient Name</th>
                  <th scope="col" class="fs-2 fw-bold">
                    Report Recipient Email
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody formArrayName="reportReceipentInfo">
                <tr *ngIf="!getFormArray(report, 'reportReceipentInfo').length">
                  <th colspan="6" class="fw-bold text-center">
                    No contact info
                  </th>
                </tr>
                <tr *ngFor="
                    let recipient of getFormArray(report, 'reportReceipentInfo')
                      .controls;
                    let i = index
                  " [formGroupName]="i">
                  <td>
                    <input type="text" class="form-control" id="reportRecipientName"
                      formControlName="reportRecipientName" name="reportRecipientName" />
                  </td>
                  <td>
                    <input type="text" class="form-control" id="reportRecipientEmail"
                      formControlName="reportRecipientEmail" name="reportRecipientEmail" />
                  </td>
                  <td>
                    <i-bs *ngIf="!recipient.value.loading" name="trash-fill" class="pointer text-gray-main"
                      (click)="delete(i, recipient.value, 'reportReceipentInfo', report)"></i-bs>
                    <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                      *ngIf="recipient.value.loading">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <a class="text-primary pointer" type="button" (click)="addContactForms(report, 'reportReceipentInfo')"><i-bs
                name="plus"></i-bs>Add More</a>
            <hr class="mt-5" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end pb-3">
      <button type="button" class="btn btn-primary" *ngIf="
          getFormArray(clientReportingForm, 'clientReportingInfo')
            .length
        " (click)="saveForms('clientReportingInfo')">
        <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
          <span class="visually-hidden">Loading...</span>
        </div>
        Save
      </button>
    </div>
  </mdb-tab>
  <!-- Assigned Account Manager -->
  <mdb-tab title="Account/Advocate Assignments" [formGroup]="assignedAccounManagerForm"
    [disabled]="!parentComponent.currentCompanyId">
    <div>
      <div class="d-flex align-items-center">
        <p class="fw-bold pt-3 ps-2 pe-2">Account/Advocate Assignments</p>
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="infoLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="pt-2">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Name
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 400px !important">
                Role
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 1
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                Ext
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Email
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="assignedAccountManager">
            <tr *ngIf="
                !getFormArray(
                  assignedAccounManagerForm,
                  'assignedAccountManager'
                ).length
              ">
              <th colspan="6" class="fw-bold text-center">No contact info</th>
            </tr>
            <tr *ngFor="
                let accountMgr of getFormArray(
                  assignedAccounManagerForm,
                  'assignedAccountManager'
                ).controls;
                let i = index
              " [formGroupName]="i">
              <td>
                <input type="text" class="form-control" id="name" formControlName="name" name="name" />
              </td>
              <td>
                <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result" formControlName="role"
                  name="role">
                  <ng-option *ngFor="
                      let option of dropdownConstants.assignedAccountManager | sort: 'asc':'name'
                    " [value]="option.value">
                    {{ option.name }}</ng-option>
                </ng-select>
              </td>
              <td>
                <input type="text" class="form-control" id="phone" formControlName="phone" name="phone"
                  mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" id="extension" formControlName="extension" name="extension"
                  maxlength="6" minlength="4" />
              </td>
              <td>
                <input type="text" class="form-control" id="email" formControlName="email" name="email"
                  [pattern]="parentComponent.mailformat" />
              </td>
              <td>
                <i-bs *ngIf="!accountMgr.value.loading" name="trash-fill" class="pointer text-gray-main"
                  (click)="delete(i, accountMgr.value, 'assignedAccountManager', assignedAccounManagerForm)"></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                  *ngIf="accountMgr.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <a class="text-primary pointer" type="button" (click)="
            addContactForms(assignedAccounManagerForm, 'assignedAccountManager')
          "><i-bs name="plus"></i-bs>Add More</a>
      </div>
      <hr class="mt-5" />
      <p class="fw-bold pt-3 ps-2">Assigned Advocate</p>
      <div class="pt-2">
        <table class="table table-reponsive">
          <thead>
            <tr>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Name
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 400px !important">
                Role
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 200px !important">
                Phone 1
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 100px !important">
                Ext
              </th>
              <th scope="col" class="fs-2 fw-bold" style="width: 500px !important">
                Email
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="assignedAdvocate">
            <tr *ngIf="
                !getFormArray(assignedAccounManagerForm, 'assignedAdvocate')
                  .length
              ">
              <th colspan="6" class="fw-bold text-center">No contact info</th>
            </tr>
            <tr *ngFor="
                let assignedAdv of getFormArray(
                  assignedAccounManagerForm,
                  'assignedAdvocate'
                ).controls;
                let i = index
              " [formGroupName]="i">
              <td>
                <input type="text" class="form-control" id="name" formControlName="name" name="name" />
              </td>
              <td>
                <ng-select [selectOnTab]="true" class="form-control p-0" notFoundText="No Result" formControlName="role"
                  name="role">
                  <ng-option *ngFor="let option of dropdownConstants.assignedAdvocates | sort: 'asc':'name'"
                    [value]="option.value">
                    {{ option.name }}</ng-option>
                </ng-select>
              </td>
              <td>
                <input type="text" class="form-control" id="phone" formControlName="phone" name="phone"
                  mask='(000) 000-0000' />
              </td>
              <td>
                <input type="text" class="form-control" id="extension" formControlName="extension" name="extension"
                  maxlength="6" minlength="4" />
              </td>
              <td>
                <input type="text" class="form-control" id="email" formControlName="email" name="email"
                  [pattern]="parentComponent.mailformat" />
              </td>
              <td>
                <i-bs *ngIf="!assignedAdv.value.loading" name="trash-fill" class="pointer text-gray-main"
                  (click)=" delete( i,assignedAdv.value,'assignedAdvocate',assignedAccounManagerForm )"></i-bs>
                <div class="spinner-border spinner-border-sm text-gray-main" role="status"
                  *ngIf="assignedAdv.value.loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <a class="text-primary pointer" type="button" (click)="
            addContactForms(assignedAccounManagerForm, 'assignedAdvocate')
          "><i-bs name="plus"></i-bs>Add More</a>
        <hr class="mt-5" />
        <div class="d-flex justify-content-end pb-3" *ngIf="
            getFormArray(assignedAccounManagerForm, 'assignedAdvocate')
              .length ||
            getFormArray(assignedAccounManagerForm, 'assignedAccountManager')
              .length
          ">
          <button type="button" class="btn btn-primary" (click)="saveForms('assignedAccountManager')">
            <div class="spinner-grow spinner-grow-sm me-1" role="status" *ngIf="infoSaving">
              <span class="visually-hidden">Loading...</span>
            </div>
            Save
          </button>
        </div>
      </div>
    </div>
  </mdb-tab>
</mdb-tabs>