import { Component, OnInit } from '@angular/core';
import { ExcelService, PricingService, ToastrService } from 'src/app/services';
import { finalize } from 'rxjs';
import { ExcelHeaders } from 'src/app/constants';
import { Router } from '@angular/router';
import { QueryParams } from 'src/app/utils';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  searchKeyword: any = null;
  pricingLoading: boolean = false;
  getRequest: any;
  searchConfig = {
    action: [],
    module: 'pricing',
    limit: 100,
    distance: 30,
  };
  hasSearched: boolean = false;

  pricingList: any = [];
  pricingDetail: any = {};
  pricingTabs = ['Overview', "What's Included"];
  params = {
    procedureID: null,
    networkID: null,
    providerZip: null,
    distance: 30,
    limit: this.searchConfig.limit,
    stateCode: null,
    procedureName: '',
  };
  order = 'practiceAddressCity';
  reverse: boolean = false;

  constructor(
    private pricingService: PricingService,
    private excelExportService: ExcelService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLocalStorageData();
    if (
      this.params.procedureID &&
      (this.params.stateCode || this.params.providerZip)
    ) {
      this.getPricingList({
        procedureID: this.params.procedureID,
        stateCode: this.params.stateCode,
        providerZip: this.params.providerZip,
        networkID: this.params.networkID,
      });
    }
  }

  getLocalStorageData() {
    const savedFilter: any = localStorage.getItem('pricing');
    if (savedFilter) {
      let parsedData = JSON.parse(savedFilter);
      this.params.procedureID = parsedData.procedureID;
      this.params.networkID = parsedData.networkID;
      this.params.procedureName = parsedData.procedureName;
      this.params.stateCode = parsedData.stateCode;
      this.params.providerZip = parsedData.providerZip;
    }
  }

  handleLogEmit(event: any) {
    if (!event.providerZip && !event.procedureID && !event.networkID) {
      this.pricingLoading = false;
      this.pricingList = [];
    } else {
      this.params = event;
      this.getPricingList({
        ...event,
        limit: this.searchConfig.limit,
        distance: this.searchConfig.distance,
      });
    }
  }

  selectTab(pricing: any, index: number) {
    pricing.activeTab = index;
  }

  getPricingList(params: any) {
    this.pricingList = [];
    this.pricingLoading = true;
    this.hasSearched = false;
    delete params['procedureName'];
    this.getRequest = this.pricingService
      .getPricingList(params)
      .pipe(
        finalize(() => {
          this.pricingLoading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.reverse = false;
          if (response.data.rows.length == 0) {
            this.hasSearched = true;
          }
          this.pricingList = response.data;
          this.pricingList.rows = this.pricingList.rows.map((x: any) => {
            let discount  =  x.discount?.toString();
            return {
              ...x,
              collapsed: true,
              activeTab: 0,
              discount: Number(discount.trim()),
              included:
                (x.anesthesiaFlag ? 'A' : '') +
                (x.facilityFlag ? 'F' : '') +
                (x.physicianFlag ? 'P' : ''),
            };
          });
          // this.sort({sortBy: 'providerName'});
        }
      });
  }


  updatePricing(pricing: any, activeFlag: boolean) {
    pricing.loading = true;
    pricing.activeFlag = activeFlag ? false : true;
    this.getRequest = this.pricingService
      .updatePricing(pricing._id, { activeFlag: pricing.activeFlag })
      .pipe(
        finalize(() => {
          pricing.loading = false;
        })
      )
      .subscribe((response: any) => {
        if (response && response.success) {
          this.toastrService.showSuccess(response.message);
        }
      });
  }

  searchPricing(event?: any) {
    let params = {
      ...this.params,
      distance: this.searchConfig.distance,
      limit: this.searchConfig.limit,
    };
    if (
      this.params.procedureID &&
      (this.params.stateCode || this.params.providerZip)
    ) {
      this.getPricingList(params);
    }
  }

  exportPricing() {
    if (this.pricingList) {
      let reportData = this.pricingList.rows.map((x: any) => {
        return {
          ...x,
          likelyCost: '$' + x.likelyCost?.toFixed(2),
          retailPrice: '$' + x.retailPrice?.toFixed(2),
          discount: x.discount + '%',
          included:
            (x.anesthesiaFlag ? 'Anesthesia' : '') +
            (x.facilityFlag
              ? x.anesthesiaFlag
                ? ', Facility'
                : 'Facility'
              : '') +
            (x.physicianFlag
              ? x.facilityFlag || x.anesthesiaFlag
                ? ', Physician'
                : 'Physician'
              : ''),
        };
      });
      const content = {
        header: ExcelHeaders.AdminPricing,
        data: reportData,
        reportTitle: 'Pricing',
        fileName: 'Pricing_Report',
      };
      this.excelExportService.exportExcel(content);
    }
  }

  getEpisodeDetail(pricingInfo: any) {
    let params = QueryParams.addParams({
      providerNPI: pricingInfo.providerNPI,
      procedureID: this.params?.procedureID,
      networkID: pricingInfo?.networkID,
      networkName: pricingInfo?.networkName,
      procedureName: this.params.procedureName,
    })
    let url = '/admin/episode-detail?' + params
    window.open(url, '_blank');
  }

  sort(columnObj: any) {
    if (columnObj.sortBy && !this.reverse) {
      this.order = columnObj.sortBy;
      this.pricingList.rows = this.pricingList.rows.sort((a: any, b: any) => a[columnObj['sortBy']] > b[columnObj['sortBy']] ? 1 : -1);
      this.reverse = !this.reverse;
    } else {
      this.pricingList.rows = this.pricingList.rows.sort((a: any, b: any) => a[columnObj['sortBy']] < b[columnObj['sortBy']] ? 1 : -1);
      this.order = columnObj.sortBy;
      this.reverse = false
    }
  }
}
