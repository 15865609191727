import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';

import { ConfirmationModalComponent, RolesPermissionModalComponent } from '../../../modal';
import { RolesModel } from '../../../model';
import { UserService, ToastrService, HttpCancelService } from '../../../services';

@Component({
  selector: 'app-roles-permission',
  templateUrl: './roles-permission.component.html',
  styleUrls: ['./roles-permission.component.scss']
})
export class RolesPermissionComponent implements OnInit {

  modalRef: MdbModalRef<RolesPermissionModalComponent | ConfirmationModalComponent> | null = null;
  searchKeyword: any = null;
  searchConfig = {
    action: { label: 'Add Roles', type: 'addRole', icon:'plus' },
    showAdvancedSearch: false,
    showToggle: true,
    formValue: this.searchKeyword,
    module: 'roles',
    limit : 25
  }

  rolesLoading: boolean = true;
  rolesList: any = [];
  order = 'name';
  reverse: boolean = false;
  getRequest: any;


  constructor(private modalService: MdbModalService, private userService: UserService, private toastrService: ToastrService, private cancelService: HttpCancelService) { }

  ngOnInit(): void {
    this.getRolesList();
  }

  openRolesModal(event: any, role?: any) {
    this.modalRef = this.modalService.open(RolesPermissionModalComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        modalTitle: event == 'viewPermission' ? `Permission View - ${role.name}` : (event == 'editRole' ? `Edit Permissions - ${role.name}` : 'Add Roles'),
        rolesModel: role || new RolesModel(),
        event: event
      }
    })
    this.modalRef.onClose.subscribe((roleInfo: any) => {
      if (event === 'addRole' && roleInfo !== 'close') {
        this.addNewRole(roleInfo);
      }
      else if (event === 'editRole' && roleInfo !== 'close') {
        this.editRole(roleInfo);
      }
    })
  }

  openConfirmationModal(role: any) {
    this.modalRef = this.modalService.open(ConfirmationModalComponent, {
      modalClass: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
      data: {
        modalTitle: 'Confirmation',
        modalBody: `Are you sure you want to delete <strong> ${role.name}</strong>?`
      }
    })
    this.modalRef.onClose.subscribe((message: any) => {
      if (message == 'confirmDeactivate') {
        this.deleteRole(role);
      }
    })
  }

  getRolesList(keywords?: any) {
    this.rolesLoading = true;
    this.userService.getRolesList(keywords).pipe(finalize(() => {
      this.rolesLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.rolesList = response.data.data.roles;
      }
    })
  }

  addNewRole(newRole: any) {
    if (newRole.permissions.length) {
      newRole.permissions = newRole.permissions.map((x: any) => {
        return {
          resource: x.resource, accessLevel: x.accessLevel
        }
      })
    }
    this.userService.addNewRole(newRole).pipe(finalize(() => {
      this.rolesLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getRolesList();

      }
    })
  }

  editRole(roleInfo: any) {
    this.userService.editRole(roleInfo).pipe(finalize(() => {
      this.rolesLoading = false;
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess(response.message);
        this.getRolesList();
      }

    })
  }

  deleteRole(roleInfo: any) {
    this.userService.deleteRole(roleInfo).pipe(finalize(() => {
    })).subscribe((response: any) => {
      if (response && response.success) {
        this.toastrService.showSuccess('Role deleted sucessfully.');
        this.getRolesList();
      }
    })
  }
  setOrder(value: string) {
    if (this.order == value) {
      this.reverse = !this.reverse;
    }
    else {
      this.order = value;
      this.reverse = false;
    }
  }


  searchRoles(event: any) {
    if (!this.getRequest) {
      this.getRolesList(event);
    }
    else {
      this.cancelService.cancelPendingRequests();
      this.getRolesList(event);
    }
  }

}
