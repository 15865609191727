import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Convert the word to lowercase and then capitalize the first letter of each word
    return value.toLowerCase().replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
  }
}
